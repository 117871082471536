:root {
    --poppins: "Poppins";

    --Inter:"Inter"

}

/* @font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.eot');
    src: url('../fonts/Poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Bold.eot');
    src: url('../fonts/Poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins/Poppins-Bold.woff') format('woff'),
        url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype'),
        url('../fonts/Poppins/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
} */

.landing-page {
    overflow-x: hidden;
}

.header-wrapper {
    padding: 30px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background-color: transparent;
}

.header-wrapper .container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.header-wrapper.fixed {
    background-color: #1D1E1C;
}

.landing-page .logo-wrapper {
    width: 31%;
    text-align: left;
}

.landing-page .logo-wrapper img {
    max-width: 130px;
    outline: none;
}

.right-side-wrapper {
    width: 35%;
    text-align: right;
}

.header-wrapper .navigation {
    position: relative;
}

.navigation ul {
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.navigation ul li {
    list-style: none;
    font-size: 12px;
    color: #fff;
    color: var(--white);
    margin-left: 15px;
    text-transform: uppercase;
}

.navigation ul li a,
.navigation ul li a:focus,
.navigation ul li a:hover {
    color: #fff;
    color: var(--white);
    text-decoration: none;
}

.navigation-wrapepr .navigation .closeIcon {
    display: none;
}

/* Banner */
.banner-block {
    padding: 200px 0 50px;
    position: relative;
    background-color: #1D1E1C;
    z-index: 2;
}

.banner-block:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
    background-image: url("../images/banner-shape.svg");
    background-size: 100% 100%;
    bottom: -130px;
}

.banner-block .container {
    position: relative;
    z-index: 2;
}

.reverse-column {
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.banner-block .banner-img img {
    max-height: 500px;
}

.banner-block .banner-content {
    margin: 0;
}

.banner-block .banner-content h1 {
    font-size: 73px;
    margin: 0 0 35px;
    color: #fff;
    font-family: var(--Inter);
    font-weight: 700;
}

.banner-block .banner-content h1 span {
    color: var(--primary);
    transition: all 0.5s ease 0s;
}

.banner-block .banner-content h3 {
    font-size: 26px;
    color: var(--gold);
}

.banner-block .banner-content h2,
.banner-block .banner-content h3 {
    margin: 0 0 10px;
    font-weight: 600;
}

.banner-block .banner-content h2 {
    font-size: 31px;
    color: #9B9787;
    line-height: 1.3em;
    font-family: var(--Inter);
}

.banner-block .banner-content h2,
.banner-block .banner-content h3 {
    margin: 0 0 10px;
    font-weight: 600;
    font-family: var(--popins);
}

.banner-content h2 {
    font-family: var(--ave);
    font-size: 55px;
    line-height: 1.1em;
    margin: 0 0 25px;
    font-weight: 900;
    letter-spacing: 1.7px;
    text-transform: capitalize;
    color: var(--primary);
}

.banner-block .social-icons-2 {
    margin: 20px 0 0;
    display: inline-block;
}

.social-icons-2 ul {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.social-icons-2 li {
    display: inline-block;
    margin: 0 25px 0 0;
}

.social-icons-2 li a {
    font-size: 30px;
    color: #d4ab41;
    color: var(--primary);
}

.social-icons-2 li img,
.social-icons-2 li svg {
    width: 25px;
}

.social-icons-2 li svg path {
    fill: var(--gold);
}

.about-wrapper {
    padding: 190px 0 100px;
    text-align: center;
    position: relative;
    /* background-image: url("../images/about-bg-img.png");
    background-position: 50%;
    background-size: cover;
    background-attachment: fixed; */
}

.about-wrapper-bg {
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
}

.about-wrapper .about-wrapper-bg div {
    /* background-image: url("../images/about-bg-img.png"); */
}

.about-wrapper:after {
    content: "";
    background-color: #000;
    opacity: 0.69;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.about-wrapper.bottom-part {
    padding: 80px 0;
}

.about-wrapper .container {
    position: relative;
    z-index: 3;
}

.about-wrapper h2,
.about-wrapper h4 {
    color: #fff;
    max-width: 950px;
    margin: 0 auto 15px;
    font-size: 35px;
    font-weight: 600;
    line-height: 1.5em;
    font-family: var(--Inter);
    font-weight: 700;
}

.about-wrapper p {
    font-family: var(--Inter);
    font-size: 22px;
    font-weight: 400;
    color: #fff;
    max-width: 650px;
    margin: 0 auto;
    line-height: 1.7em;
}

.about-wrapper .btn {
    margin-top: 20px;
}

.bottom-part h2 {
    font-family: var(--Inter);
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 2.4px;
    max-width: 85%;
}

.bottom-part h4 {
    font-family: var(--Inter);
    font-size: 35px;
    font-weight: 600;
    max-width: 85%;
}

.landing-page .sign-up-btn {
    min-width: 190px;
    padding: 15px 25px;
    background: rgba(29, 30, 28, 0.53);
    border-radius: 31px;
    border-color: #9B9787;
    font-size: 18px;
}

.landing-page .sign-up-btn:focus,
.landing-page .sign-up-btn:hover {
    color: #fff;
    background: rgba(29, 30, 28, 0.90);
    border-color: rgba(29, 30, 28, 0.90);
}

.special-content-wrapper {
    padding: 80px 0;
}

.reverse-column {
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.special-content-wrapper .row .col-md-6 {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
}

.special-content-img {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
}

.special-content-img img {
    max-height: 450px;
}

.special-content {
    max-width: 750px;
    margin: 0 auto;
}

.special-content h2,
.special-content p {
    font-family: var(--Inter);
    color: #282828;
    margin: 0 0 10px;
    font-size: 30px;
    font-weight: 600;
}

.special-content p {
    font-family: var(--Inter);
    font-size: 21px;
    line-height: 1.7em;
    font-weight: 300;
    color: #000000;
}

.special-content a {
    color: var(--gold);
    /* font-size: 16px; */
    text-decoration: none;
}

.special-content .social-icons-2 {
    margin: 15px 0 0;
}

.faq-wrapper {
    margin: 80px 0 0;
}

.faq {
    max-width: 80%;
    margin: 0 auto;
    width: 750px;
}

.header-content h2 {
    font-family: var(--Inter);
    font-size: 30px;
    font-weight: 600;
    color: #000;
    margin: 0 0 15px;
    text-transform: uppercase;
    line-height: 1.1em;
}

.accordian .card {
    border: none;
    border-bottom: 0.5px solid hsla(0, 0%, 50.2%, 0.15);
    border-radius: 0;
}

.accordian .card-header,
.accordian .card .collapse {
    position: relative;
}

.accordian .card-header:before {
    top: 30px;
    right: 45px;
    font-size: 20px;
}

.accordian .card-header:before,
.accordian .card-header a:before {
    position: absolute;
    top: 0;
    right: 0;
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: var(--primary-color);
}

.accordian .card-header a,
.accordian .card-header h5 {
    color: var(--primary-color);
    width: 100%;
    display: inline-block;
    padding-right: 30px;
    position: relative;
    text-decoration: none;
    margin: 0;
    cursor: pointer;
}

.accordion-header {
    font-family: var(--Inter);
    font-weight: 400;
}

.accordion-header .accordion-button:focus,
.accordion-header .accordion-button:not(.collapsed),
.accordion-header .accordion-button {
    font-family: var(--Inter);
    font-weight: 300;
    font-size: 19px;
}

.accordian .card-body,
.accordian .card-body p {
    font-family: var(--Inter);
    font-weight: 300;
    font-size: 18px;
    line-height: 1.6em;
}

.accordian .card-header {
    padding: 25px 15px;
    font-size: 18px;
    font-weight: 400;
    background-color: transparent;
    border-bottom: 0.5px solid hsla(0, 0%, 50.2%, 0.1);
}

.faq-extra-content {
    margin: 60px 0 0;
    text-align: center;
}

.faq-extra-content .btn {
    margin: 0 0 35px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* background-color: var(--gold); */
}

.landing-page .footer-wrapper {
    padding: 15px 0;
    float: left;
    width: 100%;
    background-color: #1D1E1C;
}

.footer-wrapper .copyright p {
    font-family: var(--Inter);
    font-weight: 300;
    font-size: 16px;
    color: #fff;
    margin: 0;
    text-align: left;
}

.footer-wrapper .social-icons {
    margin: 0;
    justify-content: end;
}

.footer-wrapper .social-icons li a {
    color: #fff;
    font-size: 30px;
}

.backToTop {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--gold);
    color: #fff;
    position: fixed;
    bottom: 55px;
    right: 45px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    z-index: 9;
    cursor: pointer;
    opacity: 0;
    transition: all 0.25s ease-in 0s;
    transform: translateX(100px);
}

.backToTop.show {
    opacity: 1;
    transform: translateX(0);
}

.small-blob-wrapper,
.big-blob-wrapper {
    position: relative;
}

.big-blob-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url("../images/big-blob.png");
    background-position: top;
    background-size: 100% 90%;
    background-repeat: no-repeat;

    z-index: 0;
}

.small-blob-wrapper .special-content-wrapper,
.big-blob-wrapper .special-content-wrapper {
    position: relative;
    z-index: 1;
}

.small-blob-wrapper .small-blob-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url("../images/small-blob.png");
    background-position: 0 0;
    background-size: auto 150%;
    background-repeat: no-repeat;
    z-index: 0;
}

.small-blob-wrapper .special-content h2,
.small-blob-wrapper .special-content p {
    max-width: 100%;
}

.small-blob-wrapper .special-content {
    margin-bottom: 45px;
}

.special-content-logo-imgs {
    margin: 20px 0 20px;
    width: 100%;
    display: block;
}

.special-content-logo-imgs ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.special-content-logo-imgs ul li {
    list-style: none;
    margin: 0 20px 0 0;
}

.special-content-logo-imgs.small-logos ul li img {
    max-width: 40px;
}

.special-content-logo-imgs.big-logos ul li img {
    max-width: 70px;
}

.special-content-logo-imgs .btn {
    margin-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.15);
}

.accordion-button:focus,
.accordion-button:not(.collapsed),
.accordion-button {
    background-color: transparent;
    padding: 25px 55px 25px 15px;
    position: relative;
    color: #000;
    font-size: 18px;
    outline: none;
    border-color: rgba(128, 128, 128, 0.15);
    box-shadow: none;
    font-weight: 600;
    line-height: 1.4em;
}

.accordion-item .accordion-button:before {
    position: absolute;
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: var(--primary-color);

    /* top: 25px; */
    right: 25px;
    font-size: 20px;
}

.accordion-item .accordion-button:after {
    display: none;
}

.accordion-button:not(.collapsed) {
    box-shadow: none;
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.15);
}

.accordion-button:not(.collapsed):before {
    content: "\f068";
}

.accordion-body {
    font-family: var(--Inter);
    font-weight: 300;
    font-size: 16px;
    line-height: 1.6em;
    padding: 20px 15px;
    color: #606060;
}

.accordion-body a {
    color: var(--gold);
}

.how-it-works-wrapper {
    margin: 0 0 15px;
}

.how-it-works-wrapper .special-content {
    max-width: 550px;
    margin: 0 auto 55px;
}

.how-it-works-wrapper .special-content-img {
    margin-bottom: 15px;
}

.how-it-works-wrapper h3 {
    font-size: 22px;
    line-height: 1.4em;
    text-align: center;
    margin-top: 25px;
}

.upcoming-integrations-wrapper {
    padding: 50px 0 100px;
}

.upcoming-integrations-wrapper .logo-slider {
    padding: 0 15px;
}

.upcoming-integrations-wrapper .logo-slider .slick-track {
    display: flex;
    align-items: center;
}

.upcoming-integrations-wrapper .logo-slider .item {
    text-align: center;
}

.upcoming-integrations-wrapper .logo-slider .item img {
    margin: 0 auto;
    max-width: 90%;
}