:root {
  --miniSidebar: #f2f2f2;
}



/* register Company */

.user-detail .input-container1 {
  flex-direction: column !important;
  max-width: 350px !important;
  margin-top: 0 !important;
}

.user-detail .input-container1 .input-group {
  margin-bottom: 20px;
}

.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  padding: 1rem 2rem;
  z-index: 2;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.app__navbar-roi {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;
  /* position: fixed; */
}

.app__navbar-roi img {
  width: 134px;
  /* position: fixed; */
}

.btn-roi-template {
  padding: 7px 15px;
  border-radius: 8.023px;

  box-shadow: 0px 1.00293px 4.01173px 0px rgba(0, 0, 0, 0.25);
  font-weight: 400;

  background: #fff;
}

/* .app__navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
} */

.new-page-wrapper {
  /* min-height: 100vh; */
  position: relative;
  background: linear-gradient(180deg, #fbfffb 0%, #fbfdff 100%);
  width: 100%;
  height: 100dvh;
}


.app__navbar-links li:hover {
  color: var(--color-grey);
}

.app__navbar-login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.app__navbar-login a {
  margin: 0 1rem;
  text-decoration: none;
  transition: 0.5s ease;
}

.app__navbar-login a:hover {
  border-bottom: 1px solid var(--color-golden);
}

/* .app__navbar-login div {
  width: 1px;
  height: 30px;
  background: var(--color-grey);
} */

.app__navbar-smallscreen {
  display: none;
}

.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-black);
  transition: 0.5s ease;

  flex-direction: column;
  z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: var(--color-golden);
  cursor: pointer;

  position: absolute;
  top: 20px;
  right: 20px;
}

.app__navbar-smallscreen_links {
  list-style: none;
}

.app__navbar-smallscreen_links li {
  margin: 2rem;
  cursor: pointer;
  color: var(--color-golden);
  font-size: 2rem;
  text-align: center;
  font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
  color: var(--color-white);
}

@media screen and (min-width: 2000px) {
  .app__navbar-logo img {
    width: 210px;
  }
}

@media screen and (max-width: 1150px) {
  .app__navbar-links {
    display: none;
  }

  .app__navbar-smallscreen {
    display: flex;
  }
}

@media screen and (max-width: 650px) {
  .app__navbar {
    padding: 1rem;
  }

  .navbar_btns{
    display: none;
  }

  /* .app__navbar-login {
    display: none;
  } */


  .app__navbar-logo img {
    width: 110px;
  }

  .btn-roi-template{
    margin-left: 8px;
  }
}

/* slider */
.form-dot-main {
  /* left: 450px; */
  right: 361px;
  width: 20%;
  position: absolute;
  top: 145px;
  z-index: 3;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.form-dot {
  width: 42px;
  height: 8px;
  flex-shrink: 0;
  display: inline-block;
  border-radius: 13px;
  /* background: #79C258;
border-radius: 13px; */
  background: #d9d9d9;
  margin-left: 20px;
  /* position: absolute; */
}

.active-page {
  background: #79c258 !important;
}

.new-page-wrapper .slick-slider .slick-dots {
  position: absolute;
  /* bottom: 450px; */
  /* position: fixed; */
}
.new-page-wrapper .slick-slider .slick-dots li {
  margin: 0px 40px;
}
.new-page-wrapper .slick-slider .slick-dots button:before {
  width: 50px;
  height: 10px;
  border: 1px solid var(--green);
  content: '';
  border-radius: 13px;
  opacity: 1;
}

.new-page-wrapper .slick-slider .slick-dots .slick-active button:before {
  background-color: var(--green);
}

/* sliderCompoenet1 */

.slider-component {
  display: flex;
  height: 100%;
}
.register_company_form{
  height: 100%;
}

.slider-component-img {
  flex: 1;
  position: relative;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  height: 100dvh;
}

.slider-component-img-text {
  width: 65%;
  margin-left: 67px;

  color: #fff;
  font-family: Inter;
  font-size: 83px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.59);
  /* position: absolute;
top: 150px;
  left: 67px; */
}

.slider-component-img > img {
  /* width: 450px;
  height: px; */
  /* object-fit: cover; */

  width: auto;
  height: auto;
  flex-shrink: 0;
}

.slider-component-content {
  flex: 1;
  /* position: relative; */
  display: flex;
  /* justify-content: center;
  align-items: center; */
  /* width: 100%;
  height: 100%; */
  align-items: center;
}

.slider-component-content .slider-content-inputs .tell-us,
.slider-component-content .slider-content .tell-us {
  font-size: 18px !important;
  color: #262626;

  font-family: Inter;
  font-weight: 700;
}
.slider-component-content .slider-content {
  margin-top: 65px;
  margin-left: 70px;
  /* margin-right: 80px; */
  margin-bottom: 50px;

  width: 60%;
}
.slider-component-content .slider-content .slider-content-inputs {
  display: flex;
  flex-direction: column;
}

/* Slider component 2 */

/* Slider component 3 */
.slider-component-content .slider-content .system-roles .row {
  margin-left: 0 !important;
}
.slider-component-content .slider-content .system-roles .role-row {
  /* width: 80%; */
  margin-bottom: 8px;
  padding: 10px;
  border-radius: 0px 0px 3.009px 3.009px;
  background: #f5f9f0;
}

 .system_invite_users{
  display: flex;
  justify-content: right;
  align-items: center;
}
.cutsomers-page-listing .role-row {
  /* width: 80%; */
  margin-bottom: 6px;
  margin-right: 6px;
  /* padding: 15px; */
  height: 69px;
  border-radius: 8.023px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
}

.cutsomers-page-listing .role-row.role-edit-icon-row{
  justify-content: flex-start;
  margin-right: 0px;
}

.cutsomers-page-listing .user-content-center {
  justify-content: center;
}

.cutsomers-page-listing .appointment_icon {
  padding: 0 !important;
}
.cutsomers-page-listing .row .role-head {
  margin-bottom: 8px;
  margin-right: 4px;
  /* margin-left: 4px; */
  padding: 0 15px;

  color: #000;

  font-family: 'Inter';
  font-size: 19.05px;
  font-weight: 600;
}

.cutsomers-page-listing .customers-list {
  /* position: relative;
  overflow: hidden; */
  /* height: calc(100vh - 355px); */
}
.cutsomers-page-listing .customers-list .row {
  margin: 0;
}

.cutsomers-page-listing .search-tabs-icons {
  flex: 2;
}

.dispatch-screen .search-tabs-icons {
  flex: 2;
  justify-content: flex-start !important;
    margin-left: 30px;
}

.cutsomers-page-listing .customer-img > img {
  width: 49px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}

.cutsomers-page-listing .company_logo > img {
  width: 149.304px;
  height: 34px;
}

.shareable_list .company_logo{
  margin-bottom: 30px;
}
.cutsomers-page-listing .customer_list_dropdown, .dispatch-screen .customer_list_dropdown {
  display: flex;
  flex: 3;
}

.cutsomers-page-listing .icons-row-3 {
  width: 30% !important;
}

.cutsomers-page-listing .icons-row-2 {
  width: 13.666667%;
}

.customersListBlock.cutsomers-page-listing .icons-row-2{width: 14.6%;}

.cutsomers-page-listing .customer_list_dropdown .input-group {
  margin: 0 25px 0 0;
}

.cutsomers-page-listing .customer_list_dropdown .input-group .form-control, .dispatch-screen .customer_list_dropdown .input-group .form-control {
  padding: 13px 15px;
}

/* old css */
.company-info-screen {
  padding: 20px;
  margin-left: 3px;
  max-width: 863px;
}

.company-info-screen .company-top-q {
  /* padding: 20px; */
  text-align: center;
}

.input-container1 {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  /* Adjust the max-width based on your design */
  margin: 0 auto; /* Center the input containers */
  margin-top: 60px;
}

/* .input-container1 > input{

    border-radius: 7.651px !important;
border: 0.954px solid #D9D9D9 !important ;
box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
  } */

.custom-form-label {
  display: inline-block !important;
}

.input-container2 {
  display: flex;
  justify-content: space-between;
  max-width: 800px; /* Adjust the max-width based on your design */
  margin: 0 auto; /* Center the input containers */
  margin-top: 20px;
}

.input-container3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* max-width: 800px; */
  /* height: 150px; */
  margin: 0 auto; /* Center the input containers */
  margin-top: 20px;
}

.decribe-text {
  text-align: left;
  max-width: 800px; /* Adjust the max-width based on your design */
  margin: 0 auto; /* Center the input containers */
  margin-top: 20px;
}

.blank-box {
  width: 85px;
  height: 43px;
  object-fit: cover;
  /* border-radius: 12%; */
  /* border: 3px solid transparent; */
  margin-left: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
  background: #eaeaea;
}

.box-content {
  font-size: small;
  margin: 10px;
  text-align: left;
  flex: 1.3;
}

.input-group {
  flex: 1;
  flex-direction: column;
  /* margin: 0 10px; */
}

.input-container3 .selected-box {
  border: 4.768px solid #52bd41 !important;
}
.input-container3 .box {
  flex: 1;
  /* margin: 0 10px; */
  /* margin-bottom: 5px; */
  display: flex;
  border-radius: 7.651px;
  border: 4.768px solid #d9d9d9;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  cursor: pointer;
}

.input-container3 .box-content h5{
  -webkit-font-smoothing: antialiased; /* For WebKit browsers */
  -moz-osx-font-smoothing: grayscale; /* For Firefox on macOS */
  text-rendering: optimizeLegibility;
}

.register_company_form .slider-content h1.main-slide-head{
  -webkit-font-smoothing: antialiased; /* For WebKit browsers */
  -moz-osx-font-smoothing: grayscale; /* For Firefox on macOS */
  text-rendering: optimizeLegibility;
}

.input-group > input , .general-input-container .input-group > div > input {
  border-radius: 7.651px !important;
  border: 0.954px solid #d9d9d9 !important ;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
  height: fit-content;
  width: auto !important;
}

.general-input-container .input-group > div > input{
  width: -webkit-fill-available !important;
  height: 49px;
  text-overflow: ellipsis;
}

.general-input-container .input-group > div .cutsom-control-menu .drop-icon{
  top: 18px;
}
/* .input-group > form{

  } */
label {
  display: block;
  margin-bottom: 5px;
}

/* slider component2 */

.container1 {
  max-width: 900px; /* Adjust the max-width based on your design */
  margin: 0 auto; /* Center the input containers */
  margin-top: 15px;
  text-align: left;
}

.slider2-inner-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.slider2-inner-container .title {
  flex: 1;
  padding: 8px;
  border-radius: 7.651px;
  border: 1px solid #d9d9d9;
  background: #f4f4f4;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
}

.slider2-inner-container .scheduler-dropdown {
  flex: 1;
}

.slider2-inner-container .menus-dropdowns {
  flex: 1;
}

.dropdown .dropdown-toggle {
  text-align: left;
  color: #000;

  font-family: Inter;
  /* font-size: 17px; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  content: none !important;
  padding: 15px 10px;
  background: linear-gradient(180deg, #fbfffb 0%, #fbfdff 100%);
  border-radius: 7.651px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
  width: -webkit-fill-available;
}

.menus-dropdowns .dropdown .dropdown-toggle {
  text-align: left;
  color: #000;

  font-family: Inter;
  /* font-size: 17px; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  content: none !important;
  padding: 8px;
  background: linear-gradient(180deg, #fbfffb 0%, #fbfdff 100%);
  border-radius: 7.651px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
  width: -webkit-fill-available;
}

.template_menus_screen .dropdown .dropdown-toggle, .template_triggers_screen .dropdown .dropdown-toggle, .system_role_screen .dropdown .dropdown-toggle{
  height: 46px;
}

.template_triggers_screen .slider2-inner-container {
  gap: 20px;
}

.template_triggers_screen .slider2-inner-container span{
  margin-left: 0 !important;
}

/* .dropdown-toggle::after {
  margin-left: 6.5em;
  vertical-align: .255em;
  content: "";
  border: solid var(--lightgray);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  left: auto;
  right: 18px;
  top: 18px;
  color: var(--lightgray) !important;
} */

.dropdown-toggle::after {
  content: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #52bd41 !important;
}

.slider2-inner-container .called-title {
  flex: 2;
  padding-left: 30px;
  display: flex;
  align-items: center;
  /* margin-left: 20px; */
}

.slider2-inner-container span {
margin-left: 25px;
  display: flex;
  flex: 0.5;
  align-items: center;
}
.slider2-inner-container .roles-dropdown {
  /* margin-right: 50px; */
  flex: 2;
  padding: 8px;
  background: linear-gradient(180deg, #fbfffb 0%, #fbfdff 100%);
  border-radius: 7.651px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
}
.slider2-inner-container .scheduler-dropdown2 {
  flex: 2;
  /* padding-left: 30px; */
  display: flex;
  align-items: center;
  /* margin-left: 20px; */
}

.slider2-inner-container .scheduler-dropdown2 span {
  /* padding-left: 40px; */
}

.slider2-inner-container .btn-add-type {
  flex: 1;
  margin-left: 20px;
}
.slider2-inner-container .called-title span {
  /* padding-left: 20px; */
  flex: 1;
}
.slider2-inner-container .called-title .customer-title-input {
  flex: 2;
  display: flex;
  flex-direction: column;
}
.slider2-inner-container .slider3-called-title {
  display: flex;
  flex: 2;
}
.slider2-inner-container .slider3-called-title span {
  /* padding-left: 20px; */
  flex: 1;
}

.slider2-inner-container .slider3-called-title > input {
  flex: 2;
  border-radius: 7.651px !important;
  border: 0.954px solid #d9d9d9 !important ;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
}

.slider2-inner-container .called-title .customer-title-input > input {
  border-radius: 7.651px !important;
  border: 0.954px solid #d9d9d9 !important ;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
  /* margin-right: 70px; */
}

.slider2-inner-container .scheduler-items {
  padding: 8px;
  width: 618px;
  border-radius: 0px 0px 3.009px 3.009px;
  background: #f5f9f0;
}

.slider2-inner-container .deliverables-options {
  padding: 8px;
  width: 618px;
  display: flex;
  /* background: #F5F9F0; */
}

.slider2-inner-container .deliverables-options div {
  flex: 1;
}

.slider2-inner-container .deliverables-options div > span {
  cursor: pointer;
}

.slider-3-roles-conatiner .system-role-list .text-green {
  color: #52bd41;
  font-family: Inter;
  /* font-size: 17px; */
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}
.slider3-container .catalog-button-option .text-green {
  color: #52bd41;
  font-family: Inter;
  color: #000000;
  /* font-size: 17px; */
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

/* slider-component3 */

.slider3-container {
  max-width: 900px; /* Adjust the max-width based on your design */
  margin: 0 auto; /* Center the input containers */
  margin-top: 15px;
  text-align: left;
}

.slider3-container .role-label {
}

.slider3-Top-container {
  display: flex;
  margin-bottom: 25px;
}

.slider3-Top-container > label {
  flex: 1;
}
.slider3-Top-container .svg-team {
  flex: 1;
}
.slider3-Top-container .svg-team img {
  display: inline;
  padding-right: 5px;

  width: 21.658px;
  height: 22.682px;
  margin-bottom: 2px;
}

.slider3-Top-container .svg-team .btn-roi-template {
  margin-left: 15px;
}

.slider-3-roles-conatiner .role-row {
  /* width: 80%; */
  margin-bottom: 8px;
  padding: 10px;
  border-radius: 0px 0px 3.009px 3.009px;
  background: #f5f9f0;
  
}
.align_item_center{
  display: flex;
  align-items: center;
}

.slider-3-roles-conatiner .system-role-list {
  width: 80%;
}

.company-info-screen .container1 .customer-types ul {
  list-style: disc !important;
}

.system-role-list .role-row{
  white-space: normal;
  word-break: break-all;
}
/* accordian */

.menus-accordian .accordion-item .accordion-button:before {
  transition: 200ms ease;
  content: '\f054' !important;
  right: auto;
  left: 7px;
  font-size: 15px;
  top: 26px !important;
}

.menus-accordian .accordion-button:not(.collapsed):before {
  transform: rotate(90deg);
}
.menus-accordian .accordion-button {
  width: 30%;
}
.system-role-list .menus-accordian .accordion-button{position: static;}
.menus-accordian .co-founder-accord-btn-holder .accordion-button{width: 47%;}
.all-menus-accordian .accordion-button {
  width: 100%;
}
.menus-accordian .accordion-header .accordion-button {
  padding-left: 25px !important;
}

.accordion-header .accordion-button:focus,
.accordion-header .accordion-button:not(.collapsed),
.accordion-header .accordion-button {
  font-size: 15px !important;
  font-family: Inter !important;
}

.menus-accordian .collapse {
  visibility: visible !important;
}

.menus-accordian .menu-close-icon {
  cursor: pointer;
  /* position: absolute; */
  /* top: 50%;
  transform: translateY(-50%); */
  /* right: -440px; */
  font-size: 18px;
}

/* 
.all-menus-accordian .menu-close-icon {

  right: -375px;
  
} */

.menus-accordian .accordion-item {
  border-radius: 0px 0px 3.009px 3.009px;
  background: #f5f9f0;
}

.menus-accordian .accordion-body {
  padding: 20px 25px !important;
}
/* catatolg accordion */


.catalog-content .accordion-item .accordion-button:before, .invoice_catalog_item .accordion-item .accordion-button:before{
  transition: 200ms ease;
  content: '\f054' !important;
}

.catalog-content .accordion-button:not(.collapsed):before, .invoice_catalog_item .accordion-button:not(.collapsed):before  {
  transform: rotate(90deg);
}

.accordion-header .accordion-button:focus,
.accordion-header .accordion-button:not(.collapsed),
.accordion-header .accordion-button {
  /* font-size: 14px !important; */
  font-family: inherit !important;
  border-bottom: none;
  color: #272727;
  font: 15px Avenir;
  padding-left: 0;
  padding-right: 0;
}

.catalog-content .collapse, .invoice_catalog_item .collapse {
  visibility: visible !important;
}

.catalog-content .input-icon, .invoice_catalog_item .input-icon {
  position: absolute;
  right: 8px;
}

.catalog-content .accordion-item, .invoice_catalog_item .accordion-item  {
  border-radius: 0px 0px 3.009px 3.009px;
  background: none;
  border-bottom: none;
}

.catalog-content .accordion-item .accordion-body, .invoice_catalog_item .accordion-item .accordion-body {
  color: #272727;
  font: 15px Avenir;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

/* siderComponent5 */

.slider3-container .catalog-button-option {
  border-radius: 7.651px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
  padding: 13px 16px;
  font-weight: 400;
  /* display: inline-block; */
}

.selected-catalog {
  border-radius: 7.651px;
  border: 1px solid #d9d9d9;
  background: #52bd41;
  box-shadow: 0px 0.96px 3.83px 0px #00000040 !important;

  color: white;
  font-weight: 500 !important;
}

.slider3-container .catalog-main {
  display: flex;
}

.slider3-container .catalog-main .catalog-tabs {
  flex: 1;
}

.slider3-container .catalog-main .catalog-add-btns {
  flex: 2;
}

/* slider component 6 */
.slider6-inner-container {
  /* width: 90%; */
}

/* .new-page-wrapper .custom-modal{
  height: auto !important;
  display: inline-block !important;
} */

/* Roi Template */

.mini-sidebar {
  /* padding: 15px 0; */
  /* width: 200px; */
  /* z-index: 9999; */

  /* margin-top: 30px; */
  margin-left: 30px;
  width: 60%;
  height: 100vh;
  position: sticky;
  padding-top: 30px;
  /* display: flex; */
  /* flex-direction: column; */
  /* position: fixed; */
  top: 0;
  left: 0;

  transition: all 0.5s ease 0s;
}
.mini-sidebar h1 {
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdcdc;
}

.settings-main {
  display: flex;
}

.minisidebar-main {
  flex: 1;
  background-color: var(--miniSidebar);
}

.setting-subpages {
  flex: 1;
  background: #F9F9F9;
  padding: 25px 25px 15px;
}
.setting-subpages .messages-header {
  height: 40px;
}

.template__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 2rem;
  z-index: 2;
}

.page-content-wrapper .dropdown .dropdown-toggle {
  text-align: left;
  color: #000;

  font-family: Inter;
  /* font-size: 17px; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  content: none !important;

  border-radius: 8.023px;
  background: #fff;

  border: 1px solid #d9d9d9;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
  width: -webkit-fill-available;
  width: -moz-available;
}

.roi-template-dropdown {
  /* margin-left: 30px; */
  width: 300.012px;
  height: 46.164px;
}
.roi-template-dropdown.roi_custom_template_dropdown{margin-left: 3px;}
.additional-menus {
  max-height: 0;
  transition: all 0.5s ease;
  overflow: hidden;
}
.menus-visible {
  max-height: 100%;
  overflow: visible;
}
.sidebar-menu-wrapper.open .additional-menus .navigation-items {
  margin-top: 10px !important;
}

.menus-accordian .accordion-btn {
  padding: 2px 10px;
  color: #fff;
  border-radius: 2.675px;
  box-shadow: 0px 0.88831px 3.55325px 0px rgba(0, 0, 0, 0.25);
}

.dropdown-menu {
  max-height: 244px;
  overflow-y: auto;
}

.send-refferal-form-wrapper.fixed-width-inputs .dropdown-menu{
  max-height: 174px;
  overflow-y: auto;
}

.dropdown-menu::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
.dropdown-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.dropdown-menu::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 50px;
}
/* Handle on hover */
.dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
  border-radius: 50px;
}

/* settings-panels */

.roi-template-settings .panel-wrapper .panel-primary {
  width: 220px !important;
  padding: 0 !important;
  background-color: #f2f2f2;
  border: none !important;
}

.roi-template-settings .panel-wrapper .panel-secondary {
  padding: 0 !important;
}

.invite-length {
  color: #52bd41;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sidebar-menu-wrapper .padding {
  height: 100dvh !important;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
}

/* @media (min-width: 768px) {
  .col-md-2 {
    flex: 0 0 auto;
    width: 10%;
  }
} */

.home_services > div > h1 > img {
  display: inline;
  width: 20px;
  filter: brightness(0);
  margin-bottom: 5px;
  margin-right: 5px;
}

.home_services > div > h1 > div {
  width: 40px;
  height: 30px;
  border-radius: 50%;
  padding: 8px;
  display: inline-block;
  margin-left: 5px;
  /* background-color: #edf6ed; */
  color: #707070;
  align-items: center;
  text-align: center;
}

.home_services > div > .company_images {
  display: flex;
}

.home_services > div > .company_images > img {
  margin-top: 20px;
  width: 100px;
  margin-right: 25px;
}

.payment-card .funnel .buy-btn-wrapper .img_icon > img {
  padding: 5px;
}
.payment-card .funnel .buy-btn-wrapper .img_icon {
  width: 30px;
  height: 30px;
  /* height: 28px; */
  background: #fff;
  /* padding: 5px; */

  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project_management .appointment-date-wrapper .date-wrapper {
  margin: 0 !important;
}

.project_management .messages-header .event-tabs {
  width: auto !important;
  position: relative;
}

.project_management .messages-header .tabs-style-2 {
  margin: 0 0 0 30px !important;
}

.project_management
  .messages-header
  .event-tabs
  .appointment-date-wrapper
  .selected-date {
  width: 240px;
  background: none !important;
  margin: 0 !important;
}

.project_management .milestones .milestone_tasks {
  display: flex;
  margin-bottom: 14px;
}

/* .project_management .milestone_tasks > h1{
  
} */

.project_management .milestone_tasks .line_bar {
  border-radius: 14px;
}

.custom-modal-content .catalog-model {
  width: -webkit-fill-available;
}

.catalog-model .input-group {
  margin-bottom: 12px;
}

.company-info-screen .catalog-content {
  /* padding: 0px 40px 40px 40px; */
  width: 70%;
  margin-left: 10%;
  align-items: center;
  justify-content: center;
}

.setting-subpages .company-info-screen {
  padding: 0 !important;
}

.company-info-screen .catalog-content .input-group {
  margin-bottom: 12px;
}

.company-info-screen .catalog-content .input-catalog-text-area-group{margin-bottom: 12px;}

.company-info-screen .catalog-content .input-catalog-text-area-group > textarea{
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
}

.company-info-screen .catalog-content .catalog-radio-btns {
  display: flex;
  justify-content: space-between;
}

.company-info-screen
  .catalog-content
  .catalog-radio-btns
  .catalog-incentive-dropdown {
  /* flex: 1; */
}
.company-info-screen
  .catalog-content
  .catalog-radio-btns
  .catalog-incentive-dropdown
  .dropdown
  .dropdown-toggle {
  padding: 10px 10px !important;
  /* width: max-content !important; */
}

.company-info-screen .catalog-content .catalog-radio-btns > span {
  /* flex: 1; */
}


.dispatch-inner-content {
  display: flex;
}

.dispatch-inner-content .dispatch_slider{
  /* flex: 1; */
  overflow: hidden;
}
.dispacth_catalog_card{
  padding: 20px 15px;
  border-radius: 2.792px;
  background: #FFF;
  width: 240px;
  /* margin-bottom: 10px; */
}
.dispacth_catalog_card .catalog_card_cotent{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dispacth_catalog_card .roi-service-icons{
  padding: 0 !important;
}

.dispatch-inner-content .catalog_flex{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dispatch_user_img {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dispatch_user_img > img{
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid transparent;
}


.dispatch-inner-content .payment-card .message-user-item {
  padding: 0 0 0 5px !important;
}
.dispatch-screen {
  padding: 25px 20px 15px !important;
}
.dispatch-screen .appointment-date-wrapper .date-wrapper {
  margin: 0 !important;
}
.dispatch-screen .messages-header {
  text-align: center;
}

.dispatch-screen .messages-header .event-tabs {
  width: auto !important;
  position: relative;
}

.dispatch-screen .messages-header .tabs-style-2 {
  margin: 0 0 0 0 !important;
}

.dispatch-screen
  .messages-header
  .event-tabs
  .appointment-date-wrapper
  .selected-date {
  width: 248px;
  background: none !important;
  margin: 0 !important;
}

.dispatch-screen .dispatch-inner-content .dispacth-pending {
  margin-top: 20px;
  text-align: center;
  color: #52bd41;
  font-weight: 600;
  margin-bottom: 10px;
}

.dispatch-screen .dispatch-inner-content .catalog-card {
  width: 161px;
}

.dispatch-screen
  .dispatch-inner-content
  .slider-card
  .user-content
  .card-title {
  font-size: 14px;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin-bottom: 5px;
}

.dispatch-screen
  .dispatch-inner-content
  .slider-card
  .user-content{
    height: 72px;
  }

.dispatch-screen .appointment-date-wrapper .date-wrapper .selected-date {
  font-weight: 600;
}

.dispatch-screen .dispatch-inner-content .dispacth-time {
  margin-top: 15px;
  text-align: center;
  border-right: 1px #c7c7c7 solid;
  /* margin-left: 5px; */
}

.dispatch-screen .dispatch-inner-content .time-slot {
  color: #52bd41;
  font-weight: 600;
  padding: 0 20px;
  /* padding: 0 20px; */
  padding-right: 39px;
  padding-left: 30px;
  /* margin-right: 10px; */
  border-right: 1px #c7c7c7 solid;
}


.dispatch-screen .catalog_flex .messages-header .event-tabs .appointment-date-wrapper{
width: 210px;
}

.dispatch-screen .dispatch-inner-content .dispacth-time .percentage {
  padding-left: 0 !important;
  color: #262626;
  font-family: Inter;
  font-size: 13.04px;
  font-style: normal;
  font-weight: 500;
}

.dispatch-card {
  padding: 0;
  border-radius: 0px;
  background: none;
}

.dispatch-screen .dispatch-list .dispatch-card .message-user-item {
  padding: 0 !important;
  border-bottom: none;
}
.dispatch-screen .dispatch-list .dispatch-card {
  margin-bottom: 15px;
}

.dispatch-screen .card-item .payment-card {
  height: 72px;
  width: 121px;
  padding: 11px 5px !important;
  margin-right: 12px;
}
.dispatch-screen .appointment_icon {
  margin-left: 8px !important;
  padding: 0 !important;
}
.dispatch-screen .appointment_icon1 {
  margin: 2px !important;
  padding: 0 6px !important;
}

.Reward_main .reward-top {
  /* display: flex;
 
  justify-content: space-between; */
}
.Reward_main .reward-top .reward-main {
  /* margin-right: 10px !important; */
}

.Reward_main .reward-top .reward-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4.61px;
  border: 1px solid #f1eded;

  background: #fff;
  padding: 6px 9px;
  text-align: center;
  margin-right: 10px;
  width: 121px;
  height: 80px;
}
.Reward_main .reward-top .reward-card > span {
  margin-bottom: 4px;
  /* font-weight: 600; */
  font-family: Inter;
  color: #000;
  font-size: 14px;
}
.Reward_main .reward-top .reward-card > span > img {
  display: inline;
  margin-bottom: 3px;
  margin-left: 3px;
}
.Reward_main .reward-top .reward-card > span > i {
  margin-left: 3px;
}

.template-exchange-tab .roi-template-general {
  display: flex;
  flex-wrap: wrap;
  /* justify-content:space-between; */
  gap: 25px;
}

.template-exchange-tab .roi-template-general .general-setting {
  flex-basis: 30%;
}

.roi-template-general .general-video {
  width: 275px;
  height: 171px;
  background: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roi-template-general .trigers-checkbox {
  margin-left: 20px;
}

.template-name-btns {
  margin-top: 30px;
  margin-bottom: 15px;
}
.template-name-btns > span {
  padding: 4px 8px;
  border-radius: 2.675px;
  background: #363636;
  box-shadow: 0px 0.888px 3.553px 0px rgba(0, 0, 0, 0.25);
  color: white;
  margin-right: 5px;
  font-weight: 700;
}

.template-name-btns .template-name-btn-white {
  border: 1px solid #000;
  background: #f9f9f9 !important;
  color: #363636 !important;
  font-weight: 700;
}

.roi-template-general .general-setting {
  position: relative;
}
.roi-template-general .general-setting > h1 {
  margin-bottom: 12px;
}
.roi-template-general .general-setting > div {
  margin-bottom: 12px;
  /* font-weight: 600; */
}

.roi-template-general .general-setting .img-span > img {
  /* float: right; */
  display: inline;
  margin-bottom: 5px;
}

.roi-template-general .general-setting .view-more {
  width: 275px;
  display: flex;
  justify-content: space-between;
}

.roi-template-general .general-setting .view-more > span {
  cursor: pointer;
  color: #45b033;
  /* font-family: Inter; */
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.roi-template-general .general-setting .view-more > i {
  font-size: 20px;
}
.roi-template-general .general-setting .img-span {
}

.roi-template-general .general-setting .cost-rating {
  width: 275px;
  display: flex;
  justify-content: space-between;
}

 .template_heart  > i{
  font-size: 19px;
}

.roi-template-general .general-setting .img-consulting {
  width: 275px;
  display: flex;
  justify-content: space-between;
}

.roi-template-general .general-setting .img-consulting {
  border-radius: 3px;
  background: #f5f9f0;
  padding: 5px 5px;
}

.roi-template-general .general-setting .img-consulting .btn.btn-green {
  padding: 4px 14px !important;

  font-size: 14px;
}

.roi-template-general .general-setting .img-consulting > img {
  /* margin: 0 auto;
  height: 100%;
  width: auto; */
}

.roi-template-general .general-setting .img-consulting > div > img,
.img-consulting > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid transparent;
  display: inline;
}

.leaderboard-page .category-btns-wrapper-slider .btn,
.category-btns-wrapper .btn {
  border-radius: 6.249px !important;
  border: 1px solid #c8c8c8 !important;
  padding: 6px 6px !important;
}

.leaderboard-page .category-btns-wrapper-slider .btn{min-width: 100px;}

.leaderboard-page .category-btns-wrapper-slider .btn.active,
.category-btns-wrapper-slider .btn:hover,
.category-btns-wrapper .btn.active,
.category-btns-wrapper .btn:hover {
  background: #272727 !important;
  color: white;
  font-family: Inter !important;
}

.category-btns-wrapper-slider .btn {
  font-family: Inter !important;
  color: #272727;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.leaderboard-subCard {
  /* margin: 10px; */
  padding: 8px 8px;
  border-radius: 3px;
  background: #f5f9f0;
  /* border: 1px solid #F5F9F0; */
  /* position: relative; */
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
}

.leaderboard-page .leaderBoard-content .money-detail {
  padding: 16px 8px;
  display: flex;
  justify-content: space-between;
  background-color: var(--primary);
  border-radius: 3px 3px 0px 0px;
}

.leaderboard-page .leaderBoard-content .money-detail > span {
  color: #fff;

  font-weight: 700;
}

.leaderboard-page .leaderBoard-content .money-detail > div > span {
  color: #fff;

  font-size: 17.78px;

  font-weight: 700;
}

.leaderboard-page .leaderBoard-content .average-sale {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.leaderboard-page .leaderBoard-content .average-sale > div {
  font-weight: 600;
}
.leaderboard-page .leaderBoard-content .message-user-item .user-content > h2 {
  font-family: Inter !important;
  font-weight: 700 !important;
  font-size: 16.5px;
}

.leaderboard-page .message-user-item .user-content {
  padding: 0 !important;
}

.leaderboard-page .bottom-img-card .payment-cart-subCard {
  padding: 6px 8px !important;
}

.leaderboard-page .bottom-img-card .payment-cart-subCard > span {
  font-size: 14.5px;
}

.leaderboard-page .leaderboard-card-black .money-detail {
  background-color: #383838;
}

.leaderboard-page .img-consulting > img {
  width: 50px !important;
  height: 50px !important;
}
.leaderboard-page .users-number {
  display: flex;
  justify-content: center;
  align-items: center;
}
.leaderboard-page .users-number > span {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #272727;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border: 3px solid transparent;
}
.leaderboard-page .average-sales {
  display: inline !important;
}
/* 
.roi-template-general .general-setting .trigers-checkbox{

  font-weight: 300 !important;
} */

/* create-template-detail */

.create-template .create-template-content {
  /* margin-bottom: 80px; */
}
.create-template .create-template-content > div > div > div > h1 {
  margin-bottom: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}
.create-template .create-template-content .template-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  /* padding-bottom: 10px; */
  border-bottom: 1px solid #e4e4e4;
}

.create-template .create-template-content .payment_milestone_flex {
  /* display: flex;
  justify-content: space-between; */
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e4e4;
}
.payment_milestone_flex .dropdown-toggle{
  /* padding: 12px 10px; */
  margin-left: 1px;
}


.create-template .create-template-content .payment-schedule {
  border-bottom: none !important;
  position: relative;
}

.create-template .create-template-content .template-flex-total {
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
  border-radius: 3px;
  /* background: #f5f9f0; */
  margin-bottom: 10px;
}

.create-template .create-template-content .template-text {
  color: #52bd41;
}

.template_text {
  color: #52bd41 !important;
}

.create-template .create-template-content > .form-conrtol,
textarea {
  border-radius: 8.023px !important;
}

.create-template .create-template-content .template-btns {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.create-template .create-template-content .template-btns * {
  margin: auto;
  margin-bottom: 10px;
}

.custom-scroll
  div[style='position: absolute; inset: 0px; overflow: scroll; margin-right: -17px; margin-bottom: -17px;']::-webkit-scrollbar,
.custom-scroll
  div[style='position: absolute;inset: 0px;overflow: scroll;margin-right: -17px;margin-bottom: -17px;']::-webkit-scrollbar,
.custom-scroll
  div[style='position: absolute; inset: 0px; overflow: scroll; margin-right: -19px; margin-bottom: -19px;']::-webkit-scrollbar,
.custom-scroll
  div[style='position: absolute;inset: 0px;overflow: scroll;margin-right: -19px;margin-bottom: -19px;']::-webkit-scrollbar {
  width: 0 !important;
  background-color: transparent;
  scrollbar-width: none !important;
}

.custom-scroll
  div[style='position: absolute; inset: 0px; overflow: scroll; margin-right: -17px; margin-bottom: -17px;'],
.custom-scroll
  div[style='position: absolute;inset: 0px;overflow: scroll;margin-right: -17px;margin-bottom: -17px;'],
.custom-scroll
  div[style='position: absolute; inset: 0px; overflow: scroll; margin-right: -19px; margin-bottom: -19px;'],
.custom-scroll
  div[style='position: absolute;inset: 0px;overflow: scroll;margin-right: -19px;margin-bottom: -19px;'] {
  margin-right: 0px !important;
  margin-bottom: -17px !important;
  scrollbar-width: none !important;
}

.create-template .create-template-content .template-flex-black {
  display: flex;
  justify-content: space-between;
  padding: 12px 12px;
  border-radius: 3px;
  background: #383838 !important;
  color: white;
  margin-bottom: 15px;
  /* height: 47px; */
}

.template-flex-black::placeholder {
  color: #fff;
  /* opacity: 0.8; */
 }

.call-center-page .phone-call-icon {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* display: inline; */
  background-color: #34c759;
  margin-left: 10px;
}

.call-center-page .phone-input {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;

  width: -webkit-fill-available;
}

/* .call-center-page .phone-input >span{
  text-align: center;

 } */
.call-center-page .messages-header .event-tabs {
  width: 348px !important;
}

.call-center-page .call-center-bottom-icons {
  display: flex;
  justify-content: space-between;

  margin: 30px 60px;
}

.call-center-page .call-center-num-btns {
  font-size: 9px;
}

.call-center-page .call-center-bottom-icons > i {
  font-size: 30px;
  color: #7e7b70;
}

.call-center-page .call-center-bottom-icons .icon-circles:before {
  color: #7e7b70;
}
.adjust_icon .icon-circles:before {
  color: #ffffff;
}

.schedule-activities-page {
  padding: 20px 20px 15px;
}
.schedule-activities-page .appointment-date-wrapper .date-wrapper {
  /* margin: 0 !important; */
  width: 325px;
  margin-left: 20px;
  /* padding-left: 30px; */
}

.schedule-activities-page .appointment-date-wrapper .selected-date {
  padding: 12px !important;
}

.schedule-activities-page .search-form {
  justify-content: center;
}

.schedule-activities-page .activities-listing {
  /* display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px; */
  position: absolute;
  width: -webkit-fill-available;
}
.schedule-activities-page .activities-listing .time {
  flex: 1;
  font-size: 14px;
}

.schedule-activities-page .activities-listing .activity-line {
  background: #e9e9e9;
  height: 2px;
  flex: 16;
  width: -webkit-fill-available;
}

.schedule-activities-page .activities-main {
  position: relative;
}

.schedule-activities-page .activities-main .activity-item {
  /* padding: 14px; */
  width: 300px;
  position: absolute;
  border-radius: 5px;
  border: 1px solid #f1eded;
  background: #2eb518;
  color: #fff;
  /* height: 85px; */
  /* top: 6px; */
  left: 70px;

  cursor: pointer;
}

.schedule-activities-page .activities-main .activity-item > * {
  /* margin-bottom: 5px; */
}

/* .schedule-activities-page .input-group {
  width: 30% !important;

  flex: none !important;
} */

.schedule-activities-page .appointment-date-wrapper {
  margin-top: 10px;
  /* width: 78%; */
}

.projects-graph-page .roi-template-dropdown {
  margin-left: 0 !important;
}

.projects-graph-page .search-tabs-icons {
  flex: 2;
}

.projects-graph-page .dropdown-search {
  flex: 3;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.projects-graph-page .roi-template-dropdown {
  width: auto !important;
  height: auto !important;
  flex: 1;
}

.projects-graph-page .input-group {
  margin: 0 10px;
}

.project-graph {
  position: relative;
}

.project-graph .project-task {
  position: absolute;
  top: 60px;
  left: 50px;
  width: 380px;
  border-radius: 8.023px;
  background: #f7f7f7;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.project-graph .project-task .duration {
  display: flex;
  justify-content: space-between;
  background: #272727;
  color: #fff;
  padding: 8px 16px;
  border-radius: 8.023px 8.023px 0px 0px;
}

.project-graph .project-task .task-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-graph .tasks-listing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* width: 133px; */

  margin-bottom: 60px;
}
.project-graph .tasks-listing .date {
  width: 126px;
  font-size: 14px;
}

.project-graph .tasks-listing .activity-line {
  background: #e9e9e9;
  height: 583px;
  width: 2px;
  align-items: center;
}
.project-graph .bar-icon {
  display: flex;
  justify-content: space-between;
}

.project-graph .bar-icon .bar-main {
  flex: 4;
}
.project-graph .bar-icon .task-bar {
  height: 12px !important;
}

.project-graph .bar-icon .task-bar .progress-bar {
  background-color: #F7A300 !important;
}

.project-graph .bar-icon .task-layer-icon {
  flex: 1;
}

.project-graph .bar-icon .task-layer-icon > img {
  float: right;
}

.project-graph .project-task-inner-content {
  padding: 8px 12px;
  border-left: 8px solid #15bff4;
  border-bottom-left-radius: 8.023px;
}

.project-task .bottom-image-icon {
  display: flex;
  justify-content: space-between;
}

.project-task .bottom-image-icon .task-images {
  display: flex;
}

.project-task .bottom-image-icon .task-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-task .bottom-image-icon .task-images .img-consulting > img {
  width: 50px !important;
  height: 50px !important;
}

.project-task .dots {
  flex: 1;

  font-size: 30px;
  padding-top: 13px;
  padding-left: 0 !important;
}

.project-task .task-icons .appointment_icon1 {
  margin-left: 0 !important;
}

.project-task .task-icons {
  margin-top: 8px;
}

.projects-graph-page .adjust-height {
  height: calc(100vh - 141px);
}

.project-graph .project-line-bar {
  top: 0;
  /* width: 80px; */
  height: 17px;
  border-radius: 14px;
  background-color: #68c559;
  position: absolute;
}

.project-graph .green-line {
  position: absolute;
  top: 0;
}

.project-graph .green-line .line {
  top: 30px;
  width: 3px;
  height: 583px;
  background-color: #52bd41;
}

.project-graph .green-line .today-date {
  color: #fff;
  position: absolute;
  font-family: Inter;
  font-size: 14.836px;
  background-color: #272727;
  border-radius: 6px;
  width: 150px;

  padding: 8px;
}

.roi-template-settings .sidebar-modal {
  padding: 25px 25px 50px 10px !important;
}

.sliding-icons {
  font-size: 22px !important;
}

.customer-product-page .slick-list .slick-track > div {
  width: auto !important;
}

.customer-product-page .slick-list .slick-track > div > div > Button {
  width: 50px !important;
  height: 50px;
  /* background: #272727 !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 8px;
}

.customer-product-page
  .slick-list
  .slick-track
  > div
  > div
  .product-sliding-btn {
  background: #272727 !important;
}

.customer-product-page .btn.active {
  color: white !important;
  background-color: #272727 !important;
}

.customer-product-page .btn {
  color: #262626 !important;
}

.customer-search-page .product-listing .trending-product-item {
  display: flex;
  flex-direction: column;
}

.customer-search-page
  .product-listing
  .trending-product-item
  .trending-product-img
  > img {
  width: 253px;
  object-fit: cover;
  height: 253px;
  border-radius: 4px;
}

.customer-search-page .product-listing .trending-product-item > * {
  margin-bottom: 8px;
}

.customer-search-page .product-listing .all-product-item {
  display: flex;
  flex-direction: column;
}

.customer-search-page
  .product-listing
  .all-product-item
  .all-product-img
  > img {
  width: 169px;
  object-fit: cover;
  height: 169px;
  border-radius: 4px;
}

.customer-search-page .product-listing .all-product-item > * {
  margin-bottom: 8px;
}

.customer-search-page .refferal-list-wrapper {
  height: calc(100vh - 155px);
}

.cutomer-scan-qr-page {
}
.cutomer-scan-qr-page .scan-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cutomer-scan-qr-page .scan-content > * {
  margin-bottom: 12px;
}
.showActive {
  color: #52bd41;
  font-weight: 600;
}

.footer-content > img {
  display: inline;
  margin-right: 12px;
}

.footer-content {
  padding: 8px 20px;
  border-radius: 32px;
  border: 1px solid #a6a6a6;
  width: fit-content;
}

.company-profile-block {
  position: relative;

  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
}

.consult-page .profile-calendar .react-calendar {
  /* border-radius: 10px !important;
  padding: 20px; */
}

.company-profile-block > img {
  height: 151px;
  object-fit: cover;
  width: 100%;
  object-position: 0px -93px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.company-profile-block .profile-block-img {
  position: absolute;
  width: 83px;
  height: 83px;
  top: 110px;
  left: 33px;
  
}

.company-profile-block .profile-block-img > img {
  border-radius: 5px;
  object-fit: cover;
  height: 83px;
  width: 83px;
  border: 1px solid #EDEDED
}

.company-profile-block .profile-share {
  float: right;
  margin-top: 33px;
  margin-right: 40px;
  color: #68b245;
}

.company-profile-block .company-profile-block-iner-content {
  margin-top: 10px;
  padding: 33px;
}

.company-profile-block .company-profile-block-iner-content > h1 {
  color: #2b2b2b;

  font-family: Helvetica;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 8px;
}

.company-profile-block .company-profile-block-iner-content > p {
  color: #3b3b3b;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */

  margin-top: 5px;
  margin-bottom: 5px;
  overflow-wrap:break-word
}

.company-profile-block .company-profile-block-iner-content .profile-hashtag {
  color: #7c7c7c;
  /* font-family: Helvetica Neue;
font-size: 15px; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.company-profile-block .company-profile-block-iner-content .show_more-btn {
  margin-top: 7px;
  color: #68b245;

  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: block;
  cursor: pointer;
}

.portfolio-tab-screen {
  /* height: 250px; */
  padding: 8px;
}

.profile-block-sidebar .menus-dropdowns .dropdown .dropdown-toggle {
  padding: 13px;
  background: none;
  border-radius: 36px;
  border: 1px solid #d7d7d7;
  box-shadow: none;
}
.portfolio-tab-screen .slider-image > img {
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 5px;
}
/* .portfolio-tab-screen .event-card .event-slider-image > * {

  margin-bottom: 10px !important;
} */

.profile-meetups-tab {
  padding: 8px;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.profile-meetups-tab .event-slider-image > img {
  width: 282px;
  height: 101.68px;
  object-fit: cover;
  border-radius: 5px;
}

.profile-meetups-tab .event-card > * {
  margin-bottom: 10px;
}

.profile-meetups-tab .event-card .event-date {
  color: #817d6c;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.profile-meetups-tab .event-card .event-type {
  color: #333;
  font-family: Helvetica;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile-meetups-tab .event-location {
  color: #464646;

  font-family: Helvetica;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  height: 15px;
}

.profile-meetups-tab .slick-slide {
  /* margin: 0 27px; */
}

.profile-block-sidebar .card-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-block-sidebar .card-button .btn.btn-green {
  border-radius: 36px;
}
.profile-block-sidebar .portfolio-tab-screen .show-more-btn{
  margin-top: 30px;
}

.profile-block-sidebar .show-more-btn {
  margin-top: auto;
  /* margin-top: 30px; */
  text-align: center;
  color: #68b245;
  /* font-family: Helvetica; */
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.profile-block-sidebar {
  background-color: #f4f3f1;
  border-radius: 10px;
  /* padding: 5px; */
}
.profile-block-sidebar .messages-header {
  padding-top: 20px;
  margin-left: 10px;
}

.profile-block-sidebar .slick-slider .slick-dots .slick-active button:before {
  background-color: #9b9787;
}

.profile-block-sidebar .slick-slider .slick-dots button:before {
  border: 1px solid #9b9787;
}

.portfolio-tab-screen {
  background: white;
  border-radius: 10px;
}

.portfolio-tab-screen .payment-methods {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 70%;
  /* align-items: center; */
  margin-left: 40px;
}

.calender-wrapper .menus-dropdowns .dropdown .dropdown-toggle {
  /* padding: 13px; */
  background: none;
  border-radius: 36px;
  border: 1px solid #d7d7d7;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calender-wrapper {
  position: relative;
}

.calender-wrapper .menus-dropdowns {
  position: absolute;
  width: 36%;
  z-index: 1000;
  left: 10px;
  top: 0px;
}

.calender-wrapper .react-calendar__navigation {
  /* margin-bottom: 3em !important; */
  margin-top: 1em;
}

.calendar_margin {
  margin-bottom: 3.5em !important;
}

.calender-wrapper .react-calendar__month-view__weekdays {
  padding: 6px 18px;
}

.task-page {
  /* padding: 4px 20px 15px; */
}

.task-list-waraper {
  /* max-width: 40%; */
}

@media screen and (max-width: 1150px) {
  .task-list-waraper,
  .task-page .messages-header {
    /* max-width: 70% !important; */
  }
}
@media screen and (max-width: 650px) {
  .task-list-waraper,
  .task-page .messages-header {
    /* max-width: 100% !important; */
  }
}

.task-page .refferal-list-wrapper {
  justify-content: center;
  align-items: center;
}

.task-page .action-listing {
  position: relative;
}

.task-page .task-list-waraper .task-list-title {
  color: #080808;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.task-page .task-list-waraper .action-listing {
  margin-bottom: 20px !important;
}

.task-page .board-list-wrapper .action-listing {
  margin-bottom: 15px !important;
}

.task-page .task-list-waraper .action-listing .arrow-up {
  display: flex;
}

.task-page .task-list-waraper .action-listing .arrow-up > span {
  color: #7e7b70;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task-page .action-listing .task-item .red-bar {
  width: 8px;
  background-color: #c14545;
  height: 54px;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 38px;
}

.task-page .action-listing .single-line-text {
  padding: 0 !important;
}

.task-page .board-list-wrapper .action-listing {
  width: 330px !important;
}

@media screen and (max-width: 1280px) {
  .task-page .board-list-wrapper .action-listing,
  .task-page .board-list-wrapper .board-header {
    width: 308px !important;
  }
}

.task-page .board-list-wrapper .action-listing .task-img {
  /* width: 307px; */
  height: 178px;
  background: #d9d9d9;
}

.task-page .board-list-wrapper .action-listing .task-item .red-bar {
  top: 219px !important;
}

.task-page .board-list-wrapper .action-listing {
  margin-right: 20px;
}

.task-page .board-list-wrapper .board-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 330px;
  margin-right: 20px;
}
.task-page .board-list-wrapper .board-header > * {
  margin-bottom: 15px;
}
.task-page .board-list-wrapper .add-task-btn {
  color: #52bd41;

  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  height: 38px;
  border-radius: 3px;
  border: 1px solid #68b245;
}

.task-page .board-list-wrapper .task-board-list-card {
  display: flex;
}

/* .task-page .board-list-wrapper .task-board-list-card .task-user-img{

  flex: 1;
} */

.task-page .board-list-wrapper .task-board-list-card .task-profile-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.task-page .board-list-wrapper .task-board-list-card .task-profile-img > img {
  width: 54px;
  height: 54px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid transparent;
}

.task-page .board-list-wrapper .task-board-list-card .task-content {
  flex: 4;
}

.register-phone .slider-component-img {
  background: linear-gradient(0deg, #272727 0%, #272727 100%), #d9d9d9;
}

.register-phone .slider-component-img .slider-component-img-text {
  font-size: 70px !important;
  /* margin-right: 120px !important; */
  margin-left: 0 !important;
}

.register-phone .slider-component-img .bottom-text {
  margin-top: 20px;
  width: 50%;
  /* margin-right: 120px; */
  color: #fff;

  font-family: Inter;
  font-size: 20.2px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.register-phone .slider-component-img .starter-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 175px;
}

.register-phone .slider-component-img .register-page1-text {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-left: 60px;
}

.register-phone .slider-component-img .roi-logo {
  position: absolute;
  top: 55px;
  left: 60px;
}

.register-phone .slider-component-content .user-type {
  width: 80%;
}

.register-phone .new-btn_login:hover {
  color: #fff;
}

.register-phone .new-btn_login:focus {
  /* b272727ackground: linear-gradient(180deg, #FBFFFB 0%, #FBFDFF 100%) !important; */
  color: #fff !important;
  /* border: 0.954px solid #d9d9d9 !important; */
}

/* .register-phone .new-btn_customer:focus{

  color: #FFF !important;
  background: #272727 !important;
} */
.setting-subpages .general {
  margin-left: 3px;
  padding-bottom: 30px;
}
.setting-subpages .general .general-top {
  display: flex;
  justify-content: space-between;
  margin-right: 25px;
}

.setting-subpages .general .general-input-container {
  display: flex;
  justify-content: space-between;
}

.setting-subpages .general .general-input-container .input-group {
  /* padding: 0 10px; */
  margin-right: 20px;
}
.setting-subpages .general .general-input-container .input-group .email-title {
  display: flex;
  justify-content: space-between;
}

.setting-subpages .general .general-input-container .input-group .email-title .form label{
  width: auto !important;
}
.setting-subpages
  .general
  .general-input-container
  .input-group
  .email-title
  > span {
  color: #45b033;
  text-align: right;
  /* font-family: Inter; */
  /* font-size: 16px; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width:45%;
  height: 16.8px;
}

.setting-subpages .general .input-group > input {
  padding: 13px 10px !important;
}


.setting-subpages .general .general-top > h1 {
  font-size: 17.056px !important;
}

.setting-subpages .general .general-top .form-switch {
  margin-left: 10px;
  display: inline-block;
}

.setting-subpages .general .general-top .form-switch .form-check-input {
  --bs-form-check-bg: #a1ab95 !important;

  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255, 255, 255, 1)'/></svg>") !important;
}
.setting-subpages .general .poc-btn {
  cursor: pointer;
  border-radius: 8.023px;
  border: 3px solid #52bd41;
  box-shadow: 0px 1.003px 4.012px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 16px;

  color: #52bd41;
  font-family: Inter;
  font-size: 17.056px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.setting-subpages .general .add-secondary-poc {
  margin-top: 3rem !important;
}

.fix-width-template {
  max-width: 863px;
}

.okrs-page .okrs-content .okrs-card > h1 {
  font-size: 16px;
  margin-bottom: 1rem;
}

.okrs-page .okrs-content .okrs-card .okrs-progress-bar {
  margin-bottom: 18px;
}
.okrs-page .okrs-content .okrs-card .okrs-progress-bar > div {
  margin-bottom: 0.5rem;
}

.okrs-page .okrs-content .okrs-card .okrs-progress-bar .okr-bar,.project_bar .task-bar {
  height: 10.453px !important;
}

.okrs-page .okrs-content .okrs-card .warning-bar .progress-bar {
  background-color: #F7A300 !important;
}

.okrs-page .okrs-content .okrs-card .success-bar .progress-bar , .project_bar .progress-bar{
  background-color: #52bd41 !important;
}

.okrs-page .okrs-content .okrs-card {
  padding: 8px;
  border-radius: 5px;
}

.okrs-page .okrs-content .okrs-card-main {
  border: 1px solid #f1eded;

  background: #fff;
}

.okrs-page .okrs-content .okrs-card-main .card-bottom {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  border-radius: 0px 0px 3px 3px;
  background: #f5f9f0;
  margin: 5px;
}

.okrs-page .okrs-content .okrs-card-main .card-bottom > span {
  color: #2eb518;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.okrs-page .okrs-content .okrs-card-main .card-bottom .okr-percentage {
  color: #272727;
  /* text-align: right; */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-page .form-page-content .form-card-main {
  border-radius: 5.015px;
  border: 1.003px solid #f6f5f5;
  background: #fff;
  margin-bottom: 13px;
}

.form-page .form-page-content .form-card-main .form-card-bottom {
  display: flex;
  justify-content: space-between;
  padding: 12px 25px;
  border-radius: 5px;
  background: #eeece6;
}

.form-page .form-page-content .form-card-main .form-listing-card {
  padding: 15px 25px;
  position: relative;
}

.form-page .form-page-content .form-card-main .form-listing-card .line-bar {
  width: 8px;
  height: 54px;
  border-radius: 5px;
  background: #c14545;
  position: absolute;
  left: 0;
}

.form-page .form-page-content .form-card-main .form-listing-card > div {
  margin-bottom: 5px;
}

.fix-width-roi-forms {
  width: -webkit-fill-available;
  max-width: 550px;
  padding-right: 15px !important;
}

.create-form-page .create-form-page-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.create-form-page .create-form-page-top > h1 {
  color: #383838;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.create-form-page .create-form-page-top > img {
  width: 25.99px;
  height: 27.414px;
}

.roi-form-input > input {
  border-radius: 8.023px !important;
  /* border: 1px solid #ECECEC ; */
  background: #fff;
  padding: 12px 15px !important;
}

.roi-form-input {
  margin-bottom: 15px;
}

.form-black-tag {
  padding: 12px 15px;
  border-radius: 3px;
  background: #383838;
  color: #fff;
}

.roi-form-dropdown .dropdown .dropdown-toggle {
  border-radius: 8.023px !important;
  /* border: 1px solid #ECECEC; */
  background: #fff;
  box-shadow: none !important;
  padding: 14px 15px !important;
}

.messages-notes-field-form-block .roi-form-dropdown .dropdown .dropdown-toggle{
  padding: 12.1px 15px !important;
}

.create-form-page .create-form-page-content .statement-btns > span {
  color: #2eb518;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: block;
  margin-bottom: 1rem;
}

.create-agreement-page .create-agreement-page-content .statement-btns > span {
  color: #2eb518;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: block;
  margin-bottom: 1rem;
}

.agreements-proposals-page .agreements-proposals-content .agreement-card-main {
  border-radius: 5px;
  border: 1px solid #f1eded;
  background: #fff;
  margin-bottom: 13px;
}

.agreements-proposals-page
  .agreements-proposals-content
  .agreement-card-main
  .agrement-card {
  padding: 10px 10px;
}

.agreements-proposals-page
  .agreements-proposals-content
  .agreement-card-main
  .agrement-card
  > h1 {
  color: #000;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
}

.service-title {
  color: #707070;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.agreements-proposals-page
  .agreements-proposals-content
  .agreement-card-main
  .agreement-card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 3px 3px;
  background: #f5f9f0;
  padding: 10px 10px;
  margin: 4px 4px;
}

.agreements-proposals-page
  .agreements-proposals-content
  .agreement-card-main
  .agreement-card-bottom
  > div
  > .btn.btn-green {
  padding: 5px 16px !important;

  /* font-size: 14px; */
}

.roi-service-icons {
  margin: 10px 0;
  display: flex;
  /* justify-content: space-between; */
}
.roi-service-icons .service-icon {
  border-radius: 2.579px;
  background: #E8E6DF;
  width: 36px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.roi-service-icons > * {
  margin-right: 5px;
}

.roi-service-icons .w-25 {
  width: 25px !important;
}

.roi-service-icons .w-28 {
  width: 28px !important;
}

.roi-service-icons .w-29 {
  width: 29px !important;
}

.roi-service-icons .service-icon-time {
  width: 50px;
  height: 23px;
  border-radius: 2.579px;
  background: #E8E6DF;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.payment-service-customer-icons-holder .roi-service-icons .service-icon-time {
  width: 50px;
  height: 23px;
  border-radius: 2.579px;
  background: #E8E6DF;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  padding: 0 0 0 3px;
}
.roi-service-icons .service-icon-time > span {
  color: #222321;
  /* text-align: right; */
  font-family: Inter;
  font-size: 8.382px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  word-spacing: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.messages-header .template-img {
  width: 25.99px;
  height: 27.414px;
}

.create-agreement-page .create-agreement-page-content .section-1 .text-editor {
  border-radius: 3px;
  background: #f5f9f0;
  padding: 15px 25px;
  color: #898989;
  font-family: Inter;
  font-size: 15.047px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.create-agreement-page
  .create-agreement-page-content
  .section-1
  .preview-section {
  display: flex;
  justify-content: space-between;
}

.create-agreement-page
  .create-agreement-page-content
  .section-1
  .preview-section
  > span {
  color: #3bba26;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.payment-card-main {
  border-radius: 5px;
  border: 1px solid #f1eded;
  background: #fff;
  padding: 4px;
  margin-top: 10px;
}

.payment-card-main .payment-service-icons {
  padding: 0 5px;
}

.payment-card-main .payment-card-bottom {
  display: flex;
  justify-content: space-between;
  border-radius: 0px 0px 3px 3px;
  background: #f5f9f0;
  padding: 5px 5px;
}

.payment-card-main .payment-content-card {
  padding: 5px 5px;
  display: flex;
  align-items: flex-start;
}

.payment-card-main .payment-card-bottom .payment-card-icons {
  display: flex;
}
.payment-card-main .payment-card-bottom .payment-card-icons .payment-icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background-color: #262626;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.payment-card-main .payment-card-bottom .payment-card-icons .payment-icon > i {
  color: #fff;
  font-size: 18px;
}

.payment-card-main .payment-content-card .task-profile-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.payment-card-main .payment-content-card .task-profile-img > img {
  width: 54px;
  height: 54px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid transparent;
}

.payment-card-main .payment-content-card .task-content {
  flex: 4;
  width: calc(100% - 200px)
}

.payment-card-main .payment-content-card .task-content > h1 {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.payment-card-main .payment-content-card .task-content > span {
  color: #707070;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: block;
}
.payment-card-main .payment-content-card .task-content .project-title {
  color: #707070;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.payment-card-main .payment-content-card .task-content > * {
  margin-bottom: 10px;
}

.payment-card-main .payment-card-bottom .pay-btn .btn.btn-green {
  padding: 7px 25px !important;
  border-radius: 2.579px !important;
}
.payment-card-main .payment-card-bottom .pay-btn {
  display: flex;
  align-items: center;
}

/* h3 {
  font-size: 18px; 
  margin-bottom: 10px; 
} */

.location-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px; /* Adjust as needed for space above the table */
}

.location-table th,
.location-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.location-table td .fas.fa-times{cursor: pointer;}

.location-table th {
  background-color: #f2f2f2;
}

.file-upload-dropdown .dropdown .dropdown-toggle {
  background: #fff !important;

  /* box-shadow: 0px 1.003px 4.012px 0px rgba(0, 0, 0, 0.25) !important; */
}

.upload-file-page .upload-file-top > h1 {
  color: #000;
  font-family: Inter;
  font-size: 21.062px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}

.upload-file-page .upload-file-bottom {
  /* margin-top: 15px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: relative;
}
.upload-file-page .upload-file-bottom .btn.btn-green {
  /* padding: 10px 50px; */
  position: absolute;
  bottom: 0;
}

.upload-file-page .upload-file-top > div {
  color: #726f6f;
  font-family: Inter;
  font-size: 16.047px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 15px;
}
.catalog_page .upload-file-top .list_icon_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticket-img-wrapper {
  width: 180px;
  height: 110px;
  border-radius: 25px;
  background: #9b9787;
  color: #fff;
  font-size: 23px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 1px 1px rgb(0 0 0 / 16%);
  position: relative;
  /* cursor: pointer; */
}

.ticket-img-wrapper p{
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  cursor: pointer;
}


.file-upload-wrapper {
  border-radius: 5px;
  background: #f2f1ec;
  /* width: 346px; */
  height: 132px;
  flex-shrink: 0;

  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.file-upload-wrapper > span {
  /* display: inline-block; */
  color: #726f6f;
  font-family: Inter;
  font-size: 16.047px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.file-upload-wrapper > img {
  width: 54px;
  height: 39px;
  margin-bottom: 10px;
}

.file-upload-wrapper input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}
.upload-file-content .uploaded-files-wrapper {
padding: 3px;
}

.upload-file-content .uploaded-files-wrapper .uploaded-file {
  display: flex;
  flex-direction: column;
  padding: 8px 5px 5px 5px;
  border-radius: 5.015px;
  border: 1px solid #eaeaea;
  background: #fff;
  gap: 12px;
}

.upload-file-content.upload{
  margin-bottom: 50px;
}

.upload_file_bottom{

  padding: 18px 10px;
  background-color: #F9F8F5;
}

.upload-file-content .uploaded-files-wrapper .uploaded-file .file-pdf-img {
  border-radius: 5.015px;
  background: #d9d9d9;

  width: 78.229px;
  height: 94.276px;
}

.upload-file-content .uploaded-files-wrapper .uploaded-file .file-detail {
  flex: 4;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 5px;
}

.uploaded-file .share_icon{
  color: var(--gold);/*#9B9787*/
  font-size: 20px;
}

.upload-file-content .uploaded-files-wrapper .uploaded-file .file-detail > h1 {
  color: #000;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.upload-file-content
  .uploaded-files-wrapper
  .uploaded-file
  .file-detail
  > span {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.roi-search-form {
  display: flex;
  justify-content: space-between;
}

.roi-search-form .btn {
  background-color: transparent;
  border-color: transparent;
  border-radius: 10px;
  color: #262626;
  font-size: 18px;
  padding: 5px 5px 5px 15px;
  display: flex;
  align-items: center;
}

.roi-search-form .btn.active {
  color: var(--primary);
}

.exhange-dropdown-header {
  /* display: flex;
  justify-content: space-between; */
  width: 80%;
  display: flex;
}
.exhange-dropdown-header .exhange-dropdown {
  /* flex: 1; */
  display: flex;
  justify-content: space-between;

  width: 50%;
  margin-left: 3px;
}

.exhange-dropdown-header .exhange-dropdown .exch_dropown {
  /* width: -webkit-fill-available; */
  width: 100%;
}

.setting-subpages .dropdown-menu, .shareable_list .dropdown-menu , .gallery_dropdown .dropdown-menu{
  z-index: 1040;
}
.exhange-dropdown-header .roi-search-form {
  /* flex: 1; */
  /* display: inline; */
  width: 50%;
}

.verify-roi-phone .auth-form form .react-tel-input .form-control {
  color: #000;
}

.verify-roi-phone .auth-form form .react-tel-input .selected-flag .arrow {
  border-top-color: #000;
}

.verify-roi-phone .auth-form form .form-label {
  color: #000;
}

.verify-roi-phone .auth-form form .form-control {
  border-bottom: 0.954px solid #d9d9d9;
  color: #000;
}

.responsive_next_btn{
 display: none;

}

.register-component2 .next-btn {
  position: absolute;
  top: 15px;
  right: 25px;
  
}

.register-component2 .back-btn , .register_company_form .back-btn, .customer_form_back-btn{
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 20;
}

.register-component2.slider-component-content .back-btn{
  left: 0px;
  margin-left: 70px;
}

.register-component2.slider-component-content .back-btn .btn.new-btn {
  font-size: 14px;
  font-weight: 600;
  padding: 13px 25px;
  letter-spacing: normal;
}

.register_company_page_form .back-btn{
  left: 0px;
  margin-left: 70px;
}

.no_slots_found{
display: none !important;
}

.categories_modal{
  width: 50%;
  left: unset;
}


@media screen and (max-width: 650px) {


  .categories_modal{
    width: 100%;
    left: unset;
  }

  .tranformation_filter.category-btns-wrapper-slider .btn{
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;



  } 

  .custom_tabs{
    display: block !important;
  }



  .footer-content > img {
    margin: 0;
    display: block;
    padding: 0 20px;
}

 .verify-roi-phone  .slider-component-content .slider-content {
  margin-left: unset;
 margin-top: unset;
  width: 100%;

 }

 /* .next_button{
  display: block !important;
 } */
 .verify-roi-phone.slider-component, .slider-component.register-phone{

  flex-direction: column;
  height: 100dvh;
  background: linear-gradient(0deg, #272727 0%, #272727 100%), #d9d9d9;
padding:  20px;
 }

.slider-component-img .starter-img{
  display: none;
 }

.register-phone .slider-component-img .register-page1-text{
margin-left: unset;
justify-content: unset;
margin-top: 50%;

 }
 .register-phone .slider-component-img .slider-component-img-text{
  font-size: 17px !important;
 }
 .register-phone .slider-component-img .slider-component-img-text{
  margin-bottom: 10px;
 }

 .register-phone .slider-component-img .profile_selection{
  display: none;
 }
 .register-phone .slider-component-img .roi-logo {
 
  top: 0;
  left: 0;
 }
 .register-phone .slider-component-img .bottom-text{
  margin-top: 0;
  font-size: 16.2px;
  width: auto;
  text-wrap: wrap;
 }

 .register-phone .slider-component-img{
  flex: unset;
  height: 25%;
  background: unset;
 }

 .register-phone .slider-component-content{
  flex: unset;
 }
 .register-phone .register-component2 h1, .register-phone .auth-logo h3{
  color: #fff ;
 }

 .register-phone .register-component2 h1{
  display: none;
 }

 .verify-roi-phone .auth-form form .react-tel-input .form-control{
  color: #fff;
 }

 .register-component2 .next-btn {
  /* position: absolute;
  top: 15px;
  right: 25px; */
  display: none;
}

.responsive_next_btn{
  
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: auto;
 
  bottom: 0px;
 

}

.customer_btn{ 
  display: block !important;
}

.responsive_next_btn .btn.new-btn{
  padding: 10px 90px;

}

.responsive_next_btn.login_next_btn{
  position: absolute;
  text-align: center;
  margin: auto;
  bottom: 12px;
  right: 23%;
}

.responsive_next_btn .btn.new-btn{
  border-radius: 4px;
}



/* .register__company .responsive_next_btn .btn.new-btn{
  bottom: 75px;
} */





.verify-roi-phone .auth-form form .form-control, .verify-roi-phone .auth-form form .form-label{
  color: #fff ;
}

.register-phone .slider-content h1{
  color: #fff ;
  text-align: center;
}
.update-number{
  color: #fff ;
}

.create_company_form .slider-component-img{
  display: none;
}

.create_company_form .slider-component-content{
  height: 100%;
}

.navbar_bg{
  background: linear-gradient(180deg, #fbfffb 0%, #fbfdff 100%);
  z-index: 5;
}

.register-phone .slider-component-content .user-type{
  width: auto;
  align-items: center;
}

.register-phone .slider-component-content .user-type h3{
  color: #fff ;
}

 .create_company_form .slider-component-content .slider-content{
  width: 100%;
  margin-left: unset;
  padding: 0 25px;
}

.roles_page .slider-content{
  margin-top: unset;
}

.create_company_in_app .slider-component-content .slider-content {

  padding: 0 25px;
}

.account_type .slider-component-content .slider-content{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: unset;
}

.input-group.user-type .new-btn_login{
  box-shadow:  0px 0.95637px 3.82547px 0px rgba(255, 255, 255, 0.5) !important;
}


.profile-calendar-main .timing-wrapper{
  z-index: 1004;
}

.timing-slider .slick-slider {
  width: 50%;
}

.timing-slider{
  justify-content: center;
}


.timing-slider .slick-slider .slick-list{
  /* height: 350px !important; */
}

.timing-slider .slick-arrow{
  width: 196px;
}

.no_slots_found{
  display: block !important;
  }

  .calender-wrapper .consult_dropdown{
width: 160px;
  }

  .calender-wrapper .consult_type_dropdown{
    width: 160px;
  }
  .height_0{
    height: 0 !important;
  }

  .over_flow_hidden{
    overflow: hidden !important
  }
  /* .portfolio-tab-screen .slider-image > img {
    width: 170px;
    height: 170px;
  }

  .profile-meetups-tab .event-slider-image > img{
    width: 365px;
    height: 152.68px;
  } */

  .mini-sidebar .mini-sidebar-tab ul {
    display: flex;
    flex-direction: column;
  }

  .mini-sidebar  .tabs-style-2 ul li {
    width: fit-content;

    
   
}


form .react-tel-input .selected-flag .arrow {
  border-top-color: white !important;
}

form .react-tel-input .flag-dropdown.open .selected-flag .arrow {
  border-bottom-color: white !important;
}



.create_group_tabs .create_group_tabs{
  padding: 0 !important;
}


.slider-component-img-text{
text-shadow: none;
}

.nominated-by-content span.nomination-date{
  color: #fff;
}

.nominated-by-content h3{
  color: #fff;
}

.leaderShip-button::before{
  width: 125px;
}

.custom-modal.company_invite_modal .close {
  right: 32px !important;
}
.company_invite_Screen .invite-page-search .search-form{
  margin-top: 45px;
}
}


.react-tel-input .flag-dropdown {
  top: 2px !important;
 }




.register-component2 {
  position: relative;
}


.dispatch-inner-content .roi-service-icons {
  padding-left: 5px;
  margin: 0;
}

/* .setting-subpages .custom-modal {
  align-items: unset !important;
} */

.pickDrop-page .pickdrop-top > h1 {
  color: #000;
  font-family: Inter;
  font-size: 21.062px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}

.pickDrop-page .pickdrop-top > div {
  color: #726f6f;
  font-family: Inter;
  font-size: 16.047px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 15px;
}

.pickDrop-page .pick-drop-content {
}

.pickDrop-page .pick-drop-content .show-details {
  color: #52bd41;
  font-family: Inter;
  font-size: 15.044px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.pickDrop-page .pick-drop-content .pick-up-location > h1 {
  color: #000;
  font-family: Inter;
  font-size: 19.056px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pickDrop-page .pick-drop-content .pickup-location-input {
  border-radius: 8.023px;
  background: #fff;
  box-shadow: 0px 1.003px 4.012px 0px rgba(0, 0, 0, 0.25);
  padding: 15px 10px;
  margin: 0 4px;
}

.pickDrop-page .pick-drop-content .location-time-btns {
  display: flex;
  justify-content: space-around;
}
.pickDrop-page .pick-drop-content .location-time-btns > span {
  color: #000;
  font-family: Inter;
  font-size: 16.047px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8.023px;
  border: 1.003px solid #e0e0e0;
  background: #fff;
  padding: 15px 15px;
}
.pickDrop-page .pick-drop-content .select-wrapper{padding: 0 3px;}
.pickDrop-page .schedule-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pickDrop-page .schedule-btn .btn.btn-green {
  padding: 13px 60px;
  color: #fff;
  font-family: Inter;
  /* font-size: 19.056px; */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pickDrop-page .selected-time {
  border: 3.009px solid #52bd41 !important;
}

.appointment-header .consulting-top > h1 {
  color: #000;
  font-family: Inter;
  font-size: 21.062px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}

.appointment-header .consulting-top > div {
  color: #726f6f;
  font-family: Inter;
  font-size: 16.047px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 15px;
}

.non-profit-page .news_heading {
  color: #000;

  font-family: Inter;
  font-size: 21.062px;
}
.non-profit-page  .news-card > * {
  margin-bottom: 10px;
}
.non-profit-page  .news-card {
  padding: 12px 14px;
  display: flex;
  flex-direction: column;
  border-radius: 5.015px;
  border: 1px solid #eaeaea;
  background: #fff;
}

.non-profit-page  .news-card .news-img {
  height: 178.522px;
  border-radius: 5.015px;
  background: #d9d9d9;
}

.non-profit-page  .news-card .news-title {
  color: #000;

  font-family: Inter;
  font-size: 14.041px;
  font-style: normal;
  font-weight: 700;
}

.non-profit-page  .news-card .news-description {
  color: #726f6f;

  font-family: Inter;
  font-size: 16.047px;
}

.non-profit-page  .news-card .news-read-more-btn {
  color: #52bd41;

  font-family: Inter;
  font-size: 15.044px;
  font-style: normal;
  font-weight: 700;
}

.non-profit-page .btn.btn-dark {
  padding: 13px 90px !important;
  box-shadow: 0px 1.003px 4.012px 0px rgba(0, 0, 0, 0.25);
  /* font-size: 15px; */
  font-family: Inter;
}

.non-profit-page .nonprofit-top > h1 {
  color: #000;
  font-family: Inter;
  font-size: 21.062px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}

.non-profit-page .nonprofit-top > div {
  color: #726f6f;
  font-family: Inter;
  font-size: 16.047px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}
.panel-secondary .dropdown-menu {
  width: -webkit-fill-available;
}
.panel-primary .dropdown-menu {
  width: -webkit-fill-available;
}

.task-page .task-max-width {
  max-width: 1059px;
}

.slider-content-inputs .auth-form {
  z-index: 4;
}

.task-page .messages-header {
  /* max-width: 40%; */
}

.market-home-page .home_services .home-tab .home-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e6e6e6;
}

.market-home-page .home_services .home-tab .home-top .home-title-img > img {
  width: 24px;
  height: 22.714px;
  filter: brightness(0);
  margin-right: 10px;
}

.market-home-page .home_services .home-tab .home-top .home-title-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.market-home-page .home_services .home-tab .home-top .home-title-img > h1 {
  margin-top: 3px;
  font-size: 16px;
  font-style: 15px;
}

.market-home-page .home_services .home-tab .home-top .service-count {
  font-weight: 700;
  color: var(--primary);
  margin-top: 3px;
}

.retail-tab .service-count {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.market-home-page .home_services .home-tab .company-logo-slider {
  /* height: 150px; */
  margin: 10px 0;
}

.market-home-page
  .home_services
  .home-tab
  .company-logo-slider
  .company-logo-card {
}

.market-home-page
  .home_services
  .home-tab
  .company-logo-slider
  .company-logo-card
  > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  /* object-fit: cover; */
}

.market-home-page .home_services .home-tab .ride-distance {
  color: #a8a494;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 15px;
}

.enter-destination-input {
  position: relative;
}
.enter-destination-input > input {
  border-radius: 8.023px;
  background: #fff;
  box-shadow: 0px 1.003px 4.012px 0px rgba(0, 0, 0, 0.25);
  padding: 14px 15px;
}

.market-home-page .home_services {
  padding: 0 2px;
}

.enter-destination-input .destination-btn {
  border-radius: 23.067px;
  border: 1.003px solid #e2e2e2;
  padding: 8px 15px;
  font-weight: 700;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px; /* Adjust the left position as needed */
}

.destination-input-main {
  display: flex;
  align-items: center;
}

.market-home-page
  .home_services
  .retail-tab
  .retail-product-slider
  .retail-product-img
  > img {
  width: 151px;
  object-fit: cover;
  height: 151px;
  border-radius: 4px;
}

.market-home-page .market-page-wrapper {
  height: calc(100vh - 106px);
}

.ROI-score-screen .score-screen-top .score-number-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.ROI-score-screen .score-screen-top .score-number {
  flex: 1;
  color: #000;

  font-family: Helvetica;
  /* font-size: 14px; */
  font-style: normal;
  font-weight: 700;
}

.ROI-score-screen .score-screen-top {
  border-radius: 5px;
  background: #eeece6;
  padding: 15px 12px;
}

.ROI-score-screen .score-screen-top .score-bar {
  flex: 4;
}
.ROI-score-screen .score-screen-top .score-bar .okr-bar {
  height: 10.453px !important;
}

.ROI-score-screen .score-screen-top .score-bar .progress-bar {
  background-color: #F7A300 !important;
}
.ROI-score-screen .score-screen-top .score-bar .progress {
  background: #e0e0e0 !important;
}
.ROI-score-screen .score-screen-top .get-started {
  display: flex;
  justify-content: space-between;
}

.ROI-score-screen .score-screen-top .get-started > span {
  color: #52bd41;

  font-family: Helvetica;
  /* font-size: 14px; */
  font-style: normal;
  font-weight: 700;
}

.roi-score-bar-card {
  border-radius: 5px;
  border: 1px solid #f1eded;
  background: #fff;
  padding: 15px 12px 5px 12px;
}
.roi-score-bar-card > h1 {
  font-size: 16px;
  margin-bottom: 1rem;
}

.roi-score-bar-card .okrs-progress-bar {
  margin-bottom: 18px;
}
.roi-score-bar-card .okrs-progress-bar > div {
  margin-bottom: 0.5rem;
}

.roi-score-bar-card .okrs-progress-bar .okr-bar {
  height: 10.453px !important;
}

.roi-score-bar-card .warning-bar .progress-bar {
  background-color: #F7A300 !important;
}

.roi-score-bar-card .success-bar .progress-bar {
  background-color: #52bd41 !important;
}

.button-padding {
  padding: 10px 27px !important;
}

.profile-button-padding {
  padding: 10px 60px !important;
}

.profile-page .profile-top-card .profile_img_wrapper {
  display: flex;
  gap: 15px;
}

.profile-page .profile-top-card .profile_img_wrapper .profile_img {
  position: relative;
}

.profile-page .profile-top-card .profile_img_wrapper .profile_img .badge-check {
  position: absolute;
  right: -5px;
  bottom: 12px;
}
.profile-page .profile-top-card .profile_img_wrapper .profile_img > img {
  width: 70px;
  height: 71.298px;
  border-radius: 50%;
  object-fit: cover;
  /* padding-top: 5px; */
  margin-top: 5px;
}

.profile-page .profile-top-card .profile_img_wrapper .profile_detail {
  flex: 1;
}

.profile-page
  .profile-top-card
  .profile_img_wrapper
  .profile_detail
  .profile_name {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
  margin: 0 10px 10px 0;
}

.profile-page
  .profile-top-card
  .profile_img_wrapper
  .profile_detail
  .profile_name
  > img {
  width: 18.544px;
  height: 22.478px;
}

.profile-page
  .profile-top-card
  .profile_img_wrapper
  .profile_detail
  .profile_name
  > div
  > h1 {
  color: #5a5a5a;

  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
}

.profile-page .profile-top-card .profile_img_wrapper .profile_detail .location {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-page
  .profile-top-card
  .profile_img_wrapper
  .profile_detail
  .profile_icons{
  display: flex;
  justify-content: space-between;
  gap: 12px;
}



.profile-phone-message-icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 8px; */
}

.profile-phone-message-icon > i {
  color: var(--primary);
  font-size: 18px;
}

.profile-page .profile-top-card .profile_img_wrapper .profile_detail {
  /* padding-left: 8px; */
}
.profile-page .profile-top-card .profile_img_wrapper .profile_detail > * {
  /* margin-bottom: 5px; */
}

.profile-page
  .profile-top-card
  .profile_img_wrapper
  .profile_detail
  .service-type-title,
.profile-page
  .profile-top-card
  .profile_img_wrapper
  .profile_detail
  .location
  .location_name {
  color: #707070;
  font-size: 16px;
  /* font-family: Helvetica-Neue; */
}

.profile-page
  .profile-top-card
  .profile_img_wrapper
  .profile_detail
  .service-type-title {
  /* margin-bottom: 3px; */
}
.profile-page .profile-top-card .notes-card {
  border-radius: 0px 0px 3px 3px;
  background: #f5f9f0;
  padding: 18px 13px;

  cursor: pointer;
}

.profile-page .profile-top-card .notes-card .notes-card-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.profile-page .profile-top-card .notes-card .notes-title {
  color: #2b2b2b;

  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
}

.profile-page .profile-top-card .notes-card .notes-count {
  color: #707070;
  font-family: Inter;
  font-size: 15.5px;
}

.profile-page .profile-top-card .notes-card .note_detail_main > * {
  color: #5a5a5a;

  font-family: Inter;
  font-size: 16px;
}
.profile-page .profile-top-card .notes-card .note_detail_main .text-note {
  margin-bottom: 8px;
}

.profile-page .profile-top-card .notes-card .note_detail_main .notes-detail {
  display: flex;
  justify-content: space-between;
}

.profile-project-tab-screen .create-project-card,
.profile-property-tab-screen .add-property-card {
  border-radius: 5px;
  background: #f5f9f0;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.profile-project-tab-screen .create-project-card > div,
.profile-property-tab-screen .add-property-card > div {
  color: #707070;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
}

.profile-property-tab-screen .add-property-card > div {
  width: 80%;
  text-align: center;
  line-height: normal;
}

.profile-page .select-wrapper {
  flex: 1;
  font-size: 14px Inter !important;
}

/* .dropdown-search-icon {
  margin-top: 15px;
} */

.dropdown-search-icon .select-wrapper {
  flex: 1;
}

.dropdown-search-icon .filter-icon {
  display: flex;
  align-items: center;
}
.dropdown-search-icon .filter-icon .btn {
  background-color: transparent;
  border-color: transparent;
  border-radius: 10px;
  color: var(--lightgray);
  font-size: 22px;
  padding: 5px 5px 5px 15px;
}
.dropdown-search-icon .gallery-custom-filter-new-icon .btn{padding: 5px 0px 5px 10px;}
.dropdown-search-icon .gallery-custom-filter-new-icon .gal-custom-filter-btn{
  padding-left: 0px;
  margin-left: 10px;
}
.dropdown-search-icon .filter-icon.filter-profile-gallery-icon .btn{padding-top: 10px;}

.dropdown-search-icon .select-wrapper .dropdown .dropdown-toggle {
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important; */
  font-size: 16px;
  font-weight: 500;
  border:1px solid #e0e0e0;
  background: #fff;
}
.profile-property-tab-screen .property-item-card {
  padding: 4px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #f1eded;
}
.profile-property-tab-screen .property-item-card .property-item-card-bottom {
  border-radius: 0px 0px 3px 3px;
  background: #f5f9f0;
  padding: 20px 5px;
  display: flex;
  justify-content: space-between;
}
.profile-property-tab-screen
  .property-item-card
  .property-item-card-bottom
  > span {
  border-radius: 3.132px;
  border: 1.214px solid #bfbbbb;
  padding: 8px 10px;
  font-weight: 600;
  font-size: 14px;
}

.profile-property-tab-screen
  .property-item-card
  .property-item-card-bottom
  .property-card-icons
  .p-bottom-icon {
  width: 46.5px;
  height: 38px;
  border-radius: 3.132px;
  border: 1.214px solid #f1eded;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.property-item-card-bottom .property-card-icons .p-bottom-icon > i {
  font-size: 22px;
}

.property-item-card-bottom .property-card-icons .p-bottom-icon > img {
  width: 21.093px;
  height: 24.478px;
  filter: brightness(0);
}
.property-item-card-bottom .property-card-icons .p-camera-icon > img {
  width: 24.857px;
  height: 21.214px;
}

.property-item-card-bottom .property-card-icons {
  display: flex;
  gap: 10px;
}

.profile-property-tab-screen .property-item-card .property-card-content-main {
  padding: 5px;
}
.profile-property-tab-screen
  .property-item-card
  .property-card-content
  .property-item-img {
  width: 79px;
  height: 79px;
  border-radius: 15px;
  background-color: #e8e6df;
}
.profile-property-tab-screen .property-item-card .property-card-content {
  display: flex;
  gap: 10px;
}

.profile-property-tab-screen .property-item-card .property-item-content {
  flex: 1;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
  color: #707070;
}

.profile-property-tab-screen
  .property-item-card
  .property-item-content
  .p-item-title {
  font-weight: 500;
}

.attendence-page .checkIn-tab-screen .checkIn-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.attendence-page .checkIn-tab-screen .checkIn-content .checkIn-qr-img {
  width: 154.009px;
  height: 154.083px;
}
.attendence-page .checkIn-tab-screen .checkIn-content > p {
  color: #707070;
}

.attendence-tab-screen
  .attendence-content
  .attendence-detail-card-main
  .attendence-detail-card {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px 14px;
}
.attendence-tab-screen .attendence-content .attendence-detail-card-main {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #f1eded;

  background: #fff;
}

.attendence-detail-card-main .attendence-detail-card > h1 {
  font-size: 16px;
  font-family: Inter;
  /* font-weight: 700; */
}
.attendence-detail-card-main .attendence-detail-card > span {
  color: #7e7b70;
}
.attendence-tab-screen
  .attendence-content
  .attendence-detail-card-main
  .detail-card-bottom {
  border-radius: 0px 0px 3px 3px;
  background: #f5f9f0;
  padding: 10px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attendence-detail-card-main .detail-card-bottom .a-qr-icon > img {
  width: 32px;
  height: 23px;
}

.attendence-detail-card-main .detail-card-bottom .a-qr-icon {
  border-radius: 3.132px;
  border: 1.214px solid #f1eded;
  background: #fff;
  padding: 5px;
}

.attendence-tab-screen .attendence-item-list {
  display: flex;
  gap: 12px;
  border-radius: 5px;
  border: 1px solid #f1eded;
  background: #fff;
  padding: 14px 8px;
}

.attendence-tab-screen .attendence-item-list .user_profile_img > img {
  width: 54px;
  height: 54px;
  object-fit: cover;
  border-radius: 50%;
}

.attendence-tab-screen .attendence-item-list .checkIn_user_detail .user_name {
  flex: 1;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.attendence-tab-screen
  .attendence-item-list
  .checkIn_user_detail
  .user_name
  > h1 {
  font-family: Inter;
  font-size: 16px;
}
.attendence-tab-screen
  .attendence-item-list
  .checkIn_user_detail
  .user_name
  > span {
  margin-top: 2px;
}

.attendence-tab-screen .attendence-item-list .checkIn_user_detail {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.attendence-tab-screen
  .attendence-item-list
  .checkIn_user_detail
  .check_in_time {
  display: flex;
  color: #7e7b70;
  align-items: flex-end;
  padding-bottom: 2px;
  padding-right: 8px;
}

.funnel-card-icons .p-bottom-icon {
  width: 46.5px;
  height: 38px;
  border-radius: 3.132px;
  border: 1.214px solid #f1eded;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.funnel-card-icons .p-bottom-icon > i {
  font-size: 21px;
}

.funnel-card-icons .p-bottom-icon > img {
  width: 21.093px;
  height: 22.478px;
  filter: brightness(0);
}

.funnel-card-icons .p-camera-icon > img {
  width: 24.857px;
  height: 21.214px;
}

.funnel-card-icons {
  display: flex;
  gap: 10px;
}

.funnel-page-content > h1 {
  color: #080808;

  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px;
}

.payment-card-3rd-text {
  display: flex;
  justify-content: space-between;
}

.payment-card-main .payment-card-bottom .calendar-btn .btn.btn-green {
  padding: 6px 22px !important;
  border-radius: 2.579px !important;
  font-size: 13px;
}

.schedule-activities-page .Reward_main .reward-top .reward-card {
  width: 124px;
  height: 87px;
  align-items: center;
  justify-content: center;
}

.schedule-activities-page .schedule_list_tab {
  max-width: 420px;
}

.schedule-activities-page .activity-search-wrapper {
  max-width: 420px;
}

.schedule-activities-page .Reward_main .search-tabs-wrapper {
  max-width: 420px;
}

.schedule-activities-slider .slick-list .slick-track {
  margin-left: 0 !important;
}

.Business_profile_page .business_profile_top .company_profile_card , .company_detail_card .company_profile_card{
  display: flex;
}
.Business_profile_page
  .business_profile_top
  .company_profile_card
  .company_logo, .company_detail_card .company_profile_card .company_logo {
  width: 74px;
  height: 74px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Business_profile_page
  .business_profile_top
  .company_profile_card
  .company_logo > img{
    width: 74px;
    height: 74px;
    border-radius: 5px;
    object-fit: cover;
  }

.company_detail_card .company_profile_card .company_logo > img{
  width: 74px;
  height: 74px;
  object-fit: cover;
  border-radius: 12px;
}

.Business_profile_page
  .business_profile_top
  .company_profile_card
  .company_logo .badge-check {
    position: absolute;
    right: -10px;
    bottom: -5px;
  }

.Business_profile_page
  .business_profile_top
  .company_profile_card
  .company_content, .company_detail_card .company_profile_card .company_content{
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  gap: 10px;
  padding-left: 15px;
  padding-top: 10px;
}

.Business_profile_page
  .business_profile_top
  .company_profile_card
  .company_content
  .rating
  > img, .company_detail_card .company_profile_card .company_content
  .rating
  > img{
  display: inline;
}

.Business_profile_page
  .business_profile_top
  .company_profile_card
  .company_content
  .company_title, .company_detail_card .company_profile_card .company_content
  .company_title {
  font-size: 17px;
  color: #5a5a5a;

  font-weight: 600;
}
.company_name_flex{
  display: flex;
  justify-content: space-between;
}

.Business_profile_page
  .business_profile_top
  .company_profile_card
  .company_content
  .company_rating_content, .company_detail_card .company_profile_card .company_content
  .company_rating_content{
  display: flex;
  justify-content: space-between;
}
.Business_profile_page
  .business_profile_top
  .company_profile_card
  .company_content
  .company_rating_content
  > img, .company_detail_card .company_profile_card .company_content
  .company_rating_content
  > img {
  width: 18.603px;
  height: 19.624px;
  filter: brightness(4);
}

.Business_profile_page .business_profile_top .bio-title, .company_detail_card .bio-title  {
  color: #7c7c7c;
  font-weight: 400;
}

.Business_profile_page .business_profile_top .bio-title .profile-description{
  color: #7c7c7c;
  font-weight: 400;
}

.Business_profile_page .no-results-wrapper .no-results-content .filter-btn-wrapper .btn{
  margin: 0 !important;
}

.poc_sent_card {
  display: flex;
  gap: 15px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #f1eded;
  padding: 12px;
}

.customer_care_card {
  display: flex;
  gap: 15px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  padding: 14px;
}

.customer-care-business-card-holder .customer_care_card{
  margin-bottom: 8px;
  border-radius: 8px;
}


/* .customer_care_card .customer_care_card_content{
  flex: 1;
  display: flex;
} */


.customer_care_card_content .customer_imgs{
display: flex;
gap: 5px;
}

.customer_care_card_content .customer_imgs >img{
  width: 35px;
  height: 34px;
  object-fit: cover;
  border-radius: 50%;
}

.customer_care_card_content .customer_imgs .remaining_div{
  width: 35px;
  height: 34px;
  object-fit: cover;
  border-radius: 50%;
  /* background-color: #e9e6df; */
  background-color: #f7f6f2;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: white; */
  font-weight: 500;
}

.customer_imgs{
  display: flex;
  gap: 5px;
  }

  .customer_imgs >img{
    width: 35px;
    height: 34px;
    object-fit: cover;
    border-radius: 50%;
  }

  .customer_imgs .remaining_div{
    width: 35px;
    height: 34px;
    object-fit: cover;
    border-radius: 50%;
    /* background-color: #e9e6df; */
    background-color: #f7f6f2;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: white; */
    font-weight: 500;
  }


.poc_sent_card .poc_card_img {
}

.poc_sent_card .poc_card_content , .customer_care_card .customer_care_card_content{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.customer_care_card .customer_care_card_content{
  gap: 12px !important;
}

.poc_sent_card .poc_card_content > h1 , .customer_care_card .customer_care_card_content > h1 {
  font-size: 16px;
  font-weight: 600;
  color: #262626;

  font-family: Inter;
}

.poc_sent_card .poc_card_content span {
  font-weight: 500;
  color: #262626;
}

.poc_sent_card .poc_card_img > img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid transparent;
}

.poc_sent_card .poc_send_icon, .customer_care_card .poc_send_icon  {
  color: #52bd41;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  font-size: 19px;
  /* padding-right: 8px; */
}

.view_more_btn {
  color: #49b038;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-bottom: 1rem !important;

  cursor: pointer;
  display: inline-block;
}

.dropdown_with_three_dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown_with_three_dots .icon {
  font-size: 20px;
  margin-left: 10px;
  color: var(--lightgray);
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dropdown_with_three_dots .icon.active {
  color: var(--gold);
}

.dropdown_with_three_dots .select-wrapper {
  flex: 1;
}
.Business_profile_page .business_profile_content {
  display: flex;
  justify-content: center;
}
.Business_profile_page .business_profile_content .before_after_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.Business_profile_page .business_profile_content .before_after_content > div {
  text-align: center;
  color: #000;
  font-weight: 700;
}

.Business_profile_page
  .business_profile_content
  .before_after_content
  .before_after_img
  > img {
  width: 174px;
  height: 174px;
  border-radius: 5.918px;
  object-fit: cover;
}

.poc_main .poc_name {
  font-weight: 700;
  color: #262626;
}

.offering_card {
  display: flex;
  gap: 15px;
  border-radius: 5.015px;
  border: 1.003px solid #f6f5f5;

  background: #fff;
  padding: 8px;
}

.offering_card .offering_card_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.offering_card .offering_card_img {
  width: 94.276px;
  height: 94.276px;
  border-radius: 5.015px;
  background: #d9d9d9;
}

.offering_card .offering_card_content > * {
  margin-bottom: 5px;
}
.offering_card .offering_card_content .offer_title {
  font-weight: 600;
  color: #000;
}

.offering_card .offering_card_content .offer_time {
  font-weight: 400;
  color: #000;
}

.offering_card .offering_card_content .offer_category {
  font-weight: 400;
  color: #7e7b70;
}

.offering_card .offering_card_content .offer_cost {
  font-weight: 600;
  color: #000;
}

.sidebar-menu-wrapper:not(.open) .shift_button {
  display: none;
}

.sidebar-menu-wrapper:not(.open) .sidebar_bar_main{
  display: none;
}


.shift_button.btn.btn-primary {
  margin-top: 20px;
  border-radius: 2.579px;
  font-family: Inter;
  padding: 10px 10px !important;
  width: 60%;
}

.shift_button.btn.btn-primary:focus,
.shift_button.btn.btn-primary.active,
.profile-links-btns.success-decline-btn-block .btn.btn-primary:focus,
.profile-links-btns.success-decline-btn-block .btn.btn-primary.active{
  outline: none;
  background-color: var(--primary);
  border-color: var(--primary);
  color:#fff;
}

:not(.btn-check) + .btn.shift_button.btn-primary:active,
:not(.btn-check) + .btn.profile-links-btns.success-decline-btn-block.btn-primary:active{
  border-color: var(--green);
  background: var(--green);
}

.setting-subpages .fix-width-template .spinner {
  background-color: #F9F9F9 !important;
  z-index: 50 !important;
}

.no_record_main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51vh;
  width: 100%;
}
.no_record_main > div {
  font-size: 17px;
  font-weight: 700;
}

.mini-sidebar .mini-sidebar-tab ul > li {
  margin: 8px 0 !important;
}

.mini-sidebar .mini-sidebar-tab ul li .btn {
  font-weight: 500 !important;
  font-family: 'Inter' !important;
  color: #686868;
  font-size: 16px;
}

.mini-sidebar .tabs-style-2 ul li .btn.active {
}

.mini-sidebar .mini-sidebar-tab ul li .btn.active {
  color: var(--green);
  font-weight: 500 !important;
  font-family: 'Inter' !important;
}

.mini-sidebar .mini-sidebar-tab ul li .btn:focus,
.mini-sidebar .mini-sidebar-tab ul li .btn:hover {
  color: var(--green);

  font-family: 'Inter' !important;
}
.triger_plus_main{
  display: flex;
  align-items: center;
}
.triger_plus {
  /* margin-left: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary);
  padding: 10px;
  border-radius: 5px;
  height: 32px;
  cursor: pointer;
}

.triger_plus > i {
  color: white;
}
.location_plus {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary);

  border-radius: 5px;
  height: 32px;
  cursor: pointer;
}

.location_plus > i {
  color: white;
}


.general-input-container .input-group .location-title{
  display: flex;
  justify-content: space-between;
  position: relative;
}

.general-input-container .input-group  .css-1wy0on6, .recruitment_filter .css-1wy0on6 , .user_location .css-1wy0on6{
  display: none;
}

.general-input-container .input-group .location-title .location_plus{
  border-radius: 3px;
  padding: 8px;
  height: 26px;
  /* margin: 0 5px 5px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
    right: 5px;
    bottom: 5px;
}


.template_schheduler .customer-types ul >li{
margin-bottom: 7px;
}



.create_company_in_app .sliding-panel-wrapper {
/* padding: 0 !important; */
}

.create_company_in_app .sliding-panel-wrapper .close{
  top:15px;
}

.main-slide-head{
  font-weight: 700;
  font-size: 26px;
  line-height: 31.47px;
}

.company_invite_Screen{
  padding: 0 12px;
}


.business_qr_content{
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px; */
  
}
.qr_flex_div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.business_qr_content .company_qr_dropdown {
  padding: 0 25px 0 10px !important;
  /* width: 295px; */
}
.business_qr_content .company_profile_img> img{
  
width: 102px;
height: 102px;
object-fit: cover;
}

.business_qr_content .company_qr_img , .personal_qr_content .company_qr_img{
  /* width: 50%;
  height: auto; */
  display: flex;
  justify-content: center;
}

.business_qr_content .scan_qr_logo> img , .personal_qr_content .scan_qr_logo> img{
  width: 45px;
  height: 45px;
  
}

.business_qr_content .scan_qr_logo> span, .personal_qr_content .scan_qr_logo> span{
  font-weight: 600;
  margin-bottom: 10px;
}
.business_qr_content .scan_qr_logo , .personal_qr_content .scan_qr_logo{
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.personal_qr_content .personal_img >img{
  width: 103px;
height: 102px;
object-fit: cover;
border-radius: 50%;
}

.catalog_detail_slider .catalog_slider_img{
 
width: 242.651px;
height: 242.651px;
border-radius: 5.015px;
background: #D9D9D9;
}

.catalog_slider_main{
  display: flex;
  justify-content: center;
  align-items: center;
}

.catalog_detail_screen .cataolog_item_title{
  color: #000;
  font-family: Inter;
  font-size: 19.056px;
  font-weight: 700;

}

.catalog_detail_screen .cataog_category{
  color: #7E7B70;
  font-family: Inter;
  font-size: 17.05px;

  font-weight: 400;

}


.catalog_detail_screen .catalog_detail_content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.catalog_detail_screen .catalog_detail_content .catalog_size_btns .size_title{
  font-weight: 700;
  color: #585858;

font-family: Inter;
font-size: 17.05px;
}

.catalog_detail_screen .catalog_detail_content .catalog_size_btns .size_btns_flex{
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 25px;
}

.catalog_detail_screen .catalog_detail_content .catalog_size_btns .size_btns_flex >span{
  border-radius: 8.023px;
border: 1.003px solid #E0E0E0;
background: #FFF;
width: 91.267px;
height: 56.164px;

color: #000;

font-family: Inter;
font-size: 16.047px;
font-weight: 500;
/* text-align: center;
vertical-align: middle; */
/* margin: auto; */
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
}

.catalog_detail_content .catalog_cart_card{
  display: flex;
  justify-content: space-between;
  align-items: center;
 
  border-radius: 5.015px;
  padding: 11px 0;
}

.catalog_detail_content .catalog_cart_card >h1{
  font-size: 17px;
}
.catalog_detail_content .catalog_cart_card >span{
  font-size: 17px;
  color: #585858;
  font-weight: 700;
}

.bg_cart{
  background: #F5F9F0;
  
}

.catalog_detail_content .catalog_cart_card .catalog_detail_icons{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.catalog_detail_content .catalog_cart_card .catalog_detail_icons .heart_icon{
/* text-align: center; */
}

.border_size_btn{
  border: 3.009px solid #52BD41 !important;
}

.catalog_detail_screen .product_buy_btn{
  display: flex;
  align-items: center;
  justify-content: center;
}

.catalog_detail_screen .product_buy_btn .btn.btn-green{
  padding: 12px 60px;
font-size: 17px;
}

.catalog_detail_screen  .slick-slider .slick-dots button:before {

  border: 1px solid var(--green);
 border: none;
  /* border-radius: 13px; */
  opacity: 1;
  background-color: #D9D9D9;
}


.catalog_detail_screen .slick-slider .slick-dots .slick-active button:before {
  background-color: #9E9E9E;
 
}



.short_videos .video_box{
  width: 144px;
height: 192px;
border-radius: 5.015px;
background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 61%, #000 100%);
}

.short_videos .video_box >img{
  width: 144px;
  height: 192px;
  border-radius: 5.015px;
  object-fit: cover;
}

.play_center_content .view_all_btn{
  color: #49B038;
  font-weight: 600;
}
.play_center_audios .audio_card{
  border-radius: 5.015px;
background: #222321;
/* width: 356px; */
height: 89px;
padding: 14px;
}

.play_center_audios .audio_card .audio_title_flex{
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;
  align-items: center;
}

.play_center_audios .audio_card .audio_title_flex .like_icon{
  font-size: 23px;
  /* font-weight: 300; */
}

.play_center_audios .audio_card .audio_line_flex .play_icon {
  color: #2eb518;
  font-size: 25px;
}

.play_center_audios .audio_card .audio_line_flex{
display: flex;
gap: 10px;
align-items: center;
position: relative;
}

.play_center_audios .audio_card .audio_line_flex .audio_line_bar{
  height: 2px;
  background-color: #FFF;
  flex: 1;
}

.play_center_audios .audio_card .audio_line_flex .audio_time{
  font-size: 14px;
  border-radius: 5.015px;
background: rgba(0, 0, 0, 0.78);
color: #FFF;
padding: 2px;
}

.play_center_audios .audio_card .audio_line_flex .audio_music_btn{
position: absolute;
width: 20px;
height: 20px;
background-color: #D9D9D9;
border-radius: 50%;
left: 35px;
}

.create-sessionEvents form label, .ticket_options form label{
  width: auto !important;
}

.fleet_Screen .sidebar-modal{
  /* top: 60px !important; */
}

.fleet_Screen .sidebar-modal > div > iframe{
  width: 100%;
  height: 100vh;
}

.CreateSession-invite-radio-btns {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  margin: 15px 0;
}


.CreateSession-invite-radio-btns .form-check-input, .expense_toogle .form-check-input{
  margin-top: 0.1em !important;
}
.create-sessionEvents .invitation_outside .stage-person-img{
  margin: 0 !important;
}

/* .create-group .invitation_outside .stage-person-img > img{
  width: 48px !important;
  min-width: 48px !important;
  height: 48px !important;
} */
.create_company_div{
  padding: 10px 7px 5px 15px;
  margin-bottom: 10px;
}

.create_company_div >p{
  color: #222321;
  padding: 0 !important;
 


/* font-family: 'Inter'; */
}

.userType .sidebar_menu-dropdown  li{
    padding: 0 !important;
    border-bottom: none !important;
     margin-bottom: 0 !important;
    
}
.userType .sidebar_menu-dropdown ul.dropdown li .btn.btn-primary{
 
}

.userType .sidebar_menu-dropdown .tabs-style-2 ul li{
  /* margin: 0; */
}
.userType .sidebar_menu-dropdown .tabs-style-2 ul li .btn{
  font-size: 13px !important;
}

.userType .sidebar_menu-dropdown{
  padding: 10px 0 0 15px;
}

.cart_count_main{
  display: inline-flex !important;

}
.cart_count{
  /* width: 27.831px !important;
height: 21.062px !important; */
border-radius: 13px;
background-color: #EDF6ED;
display: flex;
align-items: center;
justify-content: center;
padding: 2px 10px;
margin-left: 1px;
}


.fleet_schedule_item{
  display: flex;
  gap: 12px;
  padding: 14px 10px 8px 10px;
  border-radius: 5.015px;
border: 1.003px solid #F6F5F5;

background: #FFF;
cursor: pointer;
margin: 0 20px;
  /* z-index: 4; */
}

.fleet_schedule_item .schedule_item_content > div{
  font-weight: 600;
 
}
.fleet_schedule_item .schedule_item_content >span{
  font-weight: 400;
  color: #000;
}

.fleet_schedule_item .schedule_item_content >p{
  font-weight: 400;
  color: #7E7B70;;
}

.fleet_schedule_item .schedule_item_content {
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  padding: 3px 0 ;
}

.fleet_schedule_item .schedule_item_img > img{
  width: 94.276px;
height: 94.276px;
object-fit: cover;
border-radius: 5.015px;
}

.fleet_schedule_item .fleet_item_icons {
  display: flex;
  /* justify-content: space-between; */
  gap: 12px;
}

.fleet_schedule_item .fleet_item_icons .profile-phone-message-icon{
  border: 1px solid #F1EDED;
  width: 38px !important;
  height: 38px !important;
}

.fleet_schedule_item .fleet_item_icons .profile-phone-message-icon > i {

  font-size: 17px;
}

.schedule_item_content .call_msg_flex{
  display: flex;
  align-items: center;
  gap: 45px;
}

.flee_item_main{
  /* height: 90vh; */
  /* padding-bottom: 35px; */
/* margin-top: 78vh; */
position: relative;
top: 56vh;
}

.flee_item_main .fleet_schedule_item{
  /* position: absolute; */


}

.fleet_schedule_item_main {
  bottom: 35px;

  /* position: relative; */
  /* display: flex !important;
  flex-direction: column;
  justify-content: flex-end; */
 
}


.business_market_tab{


}
.business_market_tab .market_toogle{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
 
}

.business_market_tab .market_toogle .form-check-input{
  margin-top: 0.15em !important;
}

/* .business_market_tab .market_toogle .form-check-input:checked{
  background-color: #EDE8DC;
  border-color: #E0DDC9;
}

.business_market_tab .market_toogle .form-switch .form-check-input:focus, .business_market_tab .market_toogle .form-switch .form-check-input:checked{
  --bs-form-switch-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(82, 189, 65, 1)'/></svg>") !important
} */


.business_market_tab .company_detail_card{
  background-color: #FFF;
  padding: 20px 20px 8px 8px ;
  border-radius: 2px;
}

.business_market_tab .linkedIn_btn{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
 color: #2eb518;
 font-weight: 600;
 cursor: pointer;

}

.business_market_tab .announcement_card{
  background-color: #FFF;
  /* padding: 20px 0; */
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
}
.business_market_tab .announcement_card > div{
  padding: 20px 0;
}

.business_market_tab .announcement_card .profile-description{
  margin: 0 !important;
}

.business_market_tab .announcement_card >span{
  padding: 20px 16px;
}


.company_detail_bottm_card{
  border-top: 0.94px solid #E5E5E5;
  background-color: #FFF;
  padding: 15px 0;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
}

.business_market_tab .announcement_card >div{
  color: #1D1E1C;
  font-weight: 600;
}

 .announmentt{
  border-top: 0.94px solid #E5E5E5;
  padding: 15px 0;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
 .announmentt >p, .announmentt > div > p {
  color: #52BD41;
  font-weight: 600;
 

  cursor: pointer;
}

.update_banner_card .banner_img_card{
  padding: 8px 8px 10px 8px;
  background-color: #FFF;
  border-radius: 2px;
}

.update_banner_card .banner_img_card >img{
  height: 86px;
  object-fit: cover;
  width: 100%;
  object-position: 0px -54px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.branding_component .update_banner_card .banner_img_card >img{
  
  object-position: unset !important;
 
}

.social_media_icons ul{
  display: flex;
  gap: 10px;
}

.social_media_icons ul li a >i{
  font-size: 18px;
}


.company_detail_card .company_detail_card_content{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 8px;
}

.update_bio_btn{
  color: #52BD41;
  font-weight: 600;
  cursor: pointer;
}

.company_detail_card .company_detail_card_content .company_location{
  display: flex;
  gap: 10px;
}

.company_detail_card .company_detail_card_content .company_location >span{
  color: #707070;
}

.non-profit-page .search-input-location{
  /* width: 164% !important; */
}

.profile-calendar .react-calendar{
  /* max-width: 80%; */
}

.profile-calendar{
  margin: 10px 5px 0 0;
}

.profile-calendar-main .timing-wrapper {
  padding: 0 !important;
}

.profile-calendar-main .timing-wrapper{
  margin: 30px 10px 0 0;
}
.profile-calendar-main .timing_cloumn{
  width: 30% !important;
}

.profile-calendar-main{
  background-color: #FFF;
  border-radius: 10px;
  height: 440px;
}


.profile-calendar-main .calendar_column{
  max-width: 70% !important;
}

.calendar_column.appointment_cal_holder{max-width: 71.5%!important;}

.profile-calendar-main .calendar_column{
  padding-right: 0 !important;
  padding-left: 25px;
}

.slider_transform_class{
  transform: translate3d(0px, 0px, 0px) !important;
}

.slider_transition_class{
 transition: all 0.5s ease 0s;
}
.adjust_slick_height{
  height: 264px !important;
}



.leaderboard_detail_screen .leaderboard_card{
  border-radius: 5px;
  border: 1px solid #F1EDED;
  
  background: #FFF;
}

.leaderboard_detail_screen .leaderboard_card .leaderboard_card_top{
  border-radius: 3px 3px 0px 0px;
  background: #383838;
  display: flex;
  justify-content: space-between;
  padding: 15px 12px;
  margin: 8px 5px;
  font-weight: 700;
  color: #FFF;
}

.leaderboard_greeen .leaderboard_card_top{
  color: #FFF;
  background: #2EB518 !important;
}

.leaderboard_detail_screen .leaderboard_card .leaderboard_inner_card{
padding: 10px 8px;
display: flex;
flex-direction: column;
gap: 10px;
}

.leaderboard_detail_screen .leaderboard_card .leaderboard_inner_card{
  padding: 0 16px ;
}

.leaderboard_detail_screen .leaderboard_card .leaderboard_inner_card .ld_list_card{
background-color: #F5F9F0;

padding: 13px 0;

}

.leaderboard_detail_screen .leaderboard_card .leaderboard_inner_card .ld_list_card ul{
  padding: 0 25px;
}

.leaderboard_detail_screen .leaderboard_card .leaderboard_inner_card .ld_list_card ul li span{
  position: relative;
  left: -6px;
}

.leaderboard_detail_screen .leaderboard_card .leaderboard_inner_card .ld_list_card> span{
  font-weight: 700;
  padding: 0 8px;
  color:#2eb518
}
.leaderboard_detail_screen .leaderboard_card .leaderboard_inner_card .ld_list_card ul li{
list-style: disc !important;
padding-left: 0;
margin-bottom: 8px;
}

.leaderboard_detail_screen .leaderboard_card .leaderboard_inner_card .ld_rate_flex{
  display: flex;
  gap: 15px;
}

.leaderboard_inner_card >span{
  font-weight: 600;
}

.leaderboard_detail_screen .leaderboard_card .leaderboard_card_bottom{
  border-radius: 0px 0px 3px 3px;
  background: #F5F9F0;
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  margin: 8px 5px;
  align-items: center;
  height: 49px;
}
.leaderboard_card_bottom >span{
  font-weight: 600;
}
.leaderboard_card_bottom .btn.btn-green{
  border-radius: 2.579px !important;

  padding: 7px 15px !important;
}

.invoice_due >span{
  color: #000;
  font-weight: 700;
}

.invoice_pay_btn .btn.btn-green{
  padding: 10px 50px !important;
}


.invoice_detail_page .template-flex-total .template-text {
  font-weight: 700;
 color: #4ABE60 !important;
}

.f_400{
  font-weight: 400 !important;
}

.f_600{
font-weight: 500;
}

.f_700{
  font-weight: 700;
}

.invoice_detail_page .template-text {
  color: #000 !important;
}

.shareables_page {
  /* padding: 25px 20px 15px; */
}
.shareables_page .cutsomers-page-listing .role-row{
  background: white;
}

.slider-screen-main .Reward_main .reward-top{
max-width: 400px;
margin: auto;
}


.slider-screen-main .Reward_main .reward-top .reward-card{
  width: 123px !important;
}

.slider-screen-main .Reward_main {
  background: #EEECE6;
  padding: 15px 0 ;
}

.analytics_slider_bg {
  background: #EEECE6;
  padding: 15px 15px 15px 20px;

}

.slider-screen-main .Reward_main .reward-top .slider_heading,.analytics_slider_heading{
  color: #000;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.top_slider_main .Reward_main{
  padding: 20px 15px 0px 15px;
}
.top_slider_main .Reward_main .reward-top{
  max-width: 400px;
}

.shareable_list  .customer_list_dropdown .input-group,.dispatch-screen  .customer_list_dropdown .input-group  {
  margin: 0 0 0 25px !important;
}




.discoverbuilder_width{
  max-width: 420px;
}

.filter_btns_wraper .shareable_filter_btn{
  border-radius: 6.249px;
border: 1px solid #C8C8C8;
width: 108px;
/* padding: 0 20px; */
height: 30px;
display: flex;
justify-content: center;
align-items: center;
font-weight: 700;
cursor: pointer;
}

.filter_btns_wraper {
  display: flex;
  gap: 10px;
}

.filter_active{
  background: #272727;
  color:#FFF; ;
}

.shareable_list .cutsomers-page-listing .row .role-head { 
  font-size: 17px !important;

}


.dispatch-screen .category-btns-wrapper .slick-list .slick-track {
  margin-left: 0 !important;
}

.dispatch-screen .category-btns-wrapper .category-btns-wrapper-slider{
  padding: 0 !important;
}

.custom_primary_width{
  max-width: 400px;
  /* padding: 25px 20px 15px; */
  padding: 0 20px 0 0;
}

.project_list_view{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.project_list_view .project_list_card{
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #F1EDED;
  background: #FFF;

}
.project_list_view .project_list_card .list_card_main{
display: flex;
gap: 15px;
padding: 5px;
}

.project_list_card .list_card_main .list_card_content{
  flex: 1 ;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0 14px 0;
}

.project_list_card .list_card_main .list_card_content .roi-service-icons{
  margin: 0 !important;
}

.project_list_card .list_card_bottom{
  border-radius: 3px;
background: #F7F6F3;
padding: 10px 8px;
display: flex;
flex-direction: column;
gap: 8px;
}

.list_card_main .project_img{
  width: 91px;
height: 91px;
background: #FAF1C4;
border-radius: 10px;
}


/* .create-ticket-tabs{
  width: 55% !important;
} */

.primary_panel_wraper{
  padding:20px 20px 0px;
  
}

.analytcis_page .analytics_slider_bg{
  padding: 20px 5px 15px 5px !important;
}

.schedule_activity  .Reward_main .reward-top{
  max-width: 100% !important;
}

.referal_page_content,.primary_panel_content{
  flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
}

.funnel_page .task-page{
padding: 0 !important;
}

.funnel_page .task-page .messages-header{
  max-width: 379px !important;
}

@media screen and (min-width: 650px){
  .timing_slider_prev_btn{
    top:78px !important
  }
  
  .timing_slider_next_btn{
    /* top:0px !important */
    bottom: -1px !important;
  }
}

.template_menus_screen .menus_btns{
  padding: 8px 0 0 4px;
display: flex;
gap: 10px;
}
.template_menus_screen .menus_btns .form-check-input[type=checkbox]{
  cursor: pointer;
}
.template_menus_screen .menus_btns .form-check,.template_menus_screen .menus_btns .form-check > label {
  margin-bottom: 0 !important;
}


.create_session .css-1wy0on6, .snap_location .css-1wy0on6,.create-schedule .css-1wy0on6 {
  display: none;
}

.location_clear_icon{
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
}

.rideShare_modal{
 

  min-height: 50vh;
  width: 100%;

  background-color: #f7f6f2;
}

.ride_share_screen .gg-map-wrapper .gg-map iframe{
  height: 100%;
}

/* .slidingpanel_margin{
  margin-top: 30px !important;
} */

.non-profit-page .search-tabs-wrapper .search-form .search-input-location .form-control {
 
  width: 163%;
  margin-left: 1px;

}

.non-profit-page .search-tabs-wrapper .search-form .search-nonprofit-input-location .form-control{margin-left: 3px;}

.consulting-main {
  margin-bottom: 10px;
}

.payment_invoice .payment-card-main{
  margin: 0 0 14px 0 !important;
}

.retail_catalog_item{
  margin-bottom: 14px;
}



.edit-opportunity-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.edit-opportunity-modal .filter-headings h2 {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}
.edit-opportunity-modal .btn.btn-dark {
  width: max-content;
  padding: 13px 45px;
  margin: 19px auto 0;
}

.new-menu-open .main-menu {transform: translateY(0px); opacity: 1; visibility: visible;z-index: 9;}
.new-menu-open .toggle-btn span:first-child{transform: rotate(-45deg);top: 10px;}
    .new-menu-open .toggle-btn span:nth-child(2){display: none;}
    .new-menu-open .toggle-btn span:nth-child(3){transform: rotate(45deg); top: 10px;}


.announmentt > div input{
  width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 5;
    cursor: pointer;
    position: absolute;
    padding: 15px 0;
    
}



.company_description{
  overflow-wrap:break-word
}

.company_description .profile-description{
  margin: 0 !important;
}

.company-profile-block .profile-description{
  font-size: 16px !important;
  overflow-wrap:break-word;
  margin: 10px 0 5px !important;
}

/* body div.Profile_page {
  overflow: visible !important;
} */

.profile_page_meetup_recruitement{
  height: 355.27px;
}

.calender-wrapper .consult_dropdown{

  z-index: 1003;
  top: 30px
  

}

.calender-wrapper .consult_type_dropdown{
  top: 73px;
}


.business_profile_location .css-1wa3eu0-placeholder {
color: hsl(0, 0%, 20%) !important;
}



@media screen and (max-width: 650px) {
  .calender-wrapper .react-calendar__month-view__weekdays {
    padding: 6px 7px;
    margin: 0 4px;
}

.profile-calendar-main .calendar_column {
  max-width: 95% !important;
  padding-right: 0 !important;
  padding-left: 5px;

}


.profile-calendar .react-calendar {
  margin-left: 13px;

}
}


.company_user_detail{
  display: flex;
  align-items: center;
  margin: 10px 0;
  gap: 10px;
}

.company_user_detail > img{

    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
 

}


.company_user_detail .company_user_name{
  font-weight: 500;
}

.invitation_outside .add_contact_img > img{
  height: 48px;
  width: 48px;
  border: 2px solid transparent;
  margin-right: 7px;
}


.btn_span {
  color: #52bd41;
  font-family: "Inter";
  font-size: 15.044px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}


/* .standalone-tab-1{
  display: none;
}

.standalone-tab-1.active{
  display: inherit;
}

.standalone-tab-2{
  display: none;
}

.standalone-tab-2.active{
  display: inherit;
} */

.roi_crm_tab-flex{
  display: flex;
  gap: 22px;
}

.roi_crm_tab-flex .menu{
  max-width: auto !important;
}

.crm_border{
  border-bottom: 1px solid #333333;
}
.roi_crm_tab-flex .roi_crm_item{
  margin-bottom: 16px;
}

.roi_crm_tab-flex .roi_crm_item >h3{
  color: #1B1D4F;
}

.roi_crm_tab-flex .roi_crm_item > div >p{
  color: #333333;
  font: 15px !important;
}

.roi_crm_tab-flex .roi_crm_img >img{
  height: 488px;
  object-fit: cover;
  object-position: 0px -15px;
}

.roi_crm_tab-flex .demo-btn-main{
  background-color: #000000;
  height: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roi_crm_tab-flex .demo-btn-main .demo-btn{
  padding: 10px 26px;
  border-radius: 5px;
  background-color: #76b956;
  color: white;
  cursor: pointer;
}
.roi_crm_tab-flex .demo-btn-main .demo-btn:hover{
  background-color: white;
  transition: all 0.4s ease-in-out;
}
.roi_crm_tab-flex .demo-btn-main .demo-btn:hover a{

  color:#76b956
}

.crm_box{
  /* height: 86px; */

  width: 184px;
  padding: 12px 15px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 14px;
}

.roi_crm_tab-flex .menu{
  max-width: 100% !important;
}





#bottom-bar .mobile::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
#bottom-bar .mobile::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
#bottom-bar .mobile::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 50px;
  
}
/* Handle on hover */
#bottom-bar .mobile::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
  border-radius: 50px;
}


.payment-service-customer-icons-holder .service-icon img{
  width: 15px;
  height: 15px;
  object-fit: cover;
}


.panel-secondary .custom_animated_tabs  .custom-tabs.event-tabs{
width: 294px;
height: 44px;
}
.panel-secondary .custom-create-ticket-tabs .custom_animated_tabs .custom-tabs.event-tabs{width: auto;}


.custom_animated_tabs .event-tabs ul li{
  width: 50%;
}
.custom-create-ticket-tabs .custom_animated_tabs .event-tabs ul li{width: auto;}
.custom-checklist-style-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.custom-checklist-style-holder .form-label{
  flex:1;
  margin-bottom:0px;
  padding-top: 1px;
}
.custom-checklist-style-holder .form-check{
  min-height: auto;
  margin-bottom: 0px;
}
.custom-checklist-style-holder .custom-checklist-plus-icon{
  color:var(--lightgray);
  padding-top: 3px;
}
.custom-checklist-style-holder .form-check.form-switch label{
  margin-top: 0px;
  margin-bottom: 0px;
}
.custom-checklist-style-holder .form-check-input{margin-top: 0.15rem;}
.custom_animated_tabs .custom-tabs ul li .btn {
  padding: 10px 35px;
  font-weight: 500;
  font-size: .875rem;
}

.custom_animated_tabs .custom-tabs ul li .btn:focus{
  background: transparent;
  color: #272727;
}

.snaptshot-tab ul li .btn{
  padding: 9px 35px !important;
}


.edit_company_logo >input{
  width: 7%;
    height: 7%;
    opacity: 0;
    top: 120px;
    right: 0;
    z-index: 5;
    cursor: pointer;
    position: absolute;
    padding: 15px 0;
}


.Business_profile_page .img-gallery-wrapper{
  padding-right: 15px !important;
}


.Business_profile_page .refferal-business-list-wrapper .img-gallery-wrapper{padding-right: 0px!important;}

.btn-green.btn.btn-primary {
  border-color: var(--primary);
  background-color: var(--primary);
  color: #fff;

}

.phone_placeholder  > input::placeholder {
 color: #AEAEAE;
}


.profile_page_tranformation .custom-modal-content{
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

.profile_page_tranformation{
  z-index: 1004;
 
}
.profile_tranformation_slider{
  /* height: 100px; */
  height: auto;
}
.profile_tranformation_slider .slick-slider .slick-list .slick-track .slick-slide > div {padding: 0 5px;}

.poc_invite_dropdown  .dropdown .dropdown-toggle{
  box-shadow: none;
  padding: 15px 15px;
}

.poc_invite_dropdown {
  margin-bottom: 8px !important;
}


.poc_delete > i {
  color: #52bd41;
}

.poc_invite_page .invite-persons-list{
  padding: 0 !important;
}

.secondary_pocs_listing .icons-row-2 {
  width: 22.666667%
}

.cutsomers-page-listing.secondary_pocs_listing .icons-row-2{
  width: 23.93%;
}
.appointment_list_cost .appointment-cost-wrapper{
  gap: 30px;
}

.appointment_add_icon .icon {
  
  margin-left: 10px;
  color: var(--lightgray);
  width: 25px;
  height: 25px;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  cursor: pointer;
  font-size: 17px;
 
  
 
}

.appointment_add_icon .icon > i{
  margin-top: 5px;
}

.appointment_add_icon .icon .icon-plus:before{
  vertical-align: middle;
}

.profile_page_dropdown .dropdown-menu{
  width: -webkit-fill-available;
}


.add_location .css-2b097c-container{
  margin: 10px 0 ;
}

.add_location .map-custom-search-form > .form-control .css-2b097c-container{
  margin: 0px;
}

@media screen and (max-width: 460px) {
  .snaptshot-tab ul li .btn {
    padding: 9px 23px !important;
}

}



.new_landing_page .react-calendar__tile--now{
border-radius: 50%;
color: #0f5ad9 !important;
  font-weight:600;
  position: relative;
}

.new_landing_page .react-calendar__tile--now > abbr::after {
  content: '';
  position: absolute;
  bottom: 8px; /* Adjust this value to position the dot as needed */
  left: 50%; /* Center the dot horizontally */
  transform: translateX(-50%);
  width: 6px; /* Adjust the size of the dot */
  height: 6px; /* Adjust the size of the dot */
  background-color: #0f5ad9; /* Set the color of the dot */
  border-radius: 50%;
}

.new_landing_page .react-calendar button{
  margin: 4px !important;
}


@media screen and (max-width: 650px) {
  .landing_calendar_modal{
    display: none !important;
  }
}

.Consult-Page .calender-wrapper  .react-calendar__tile--now{
  color: #0f5ad9 !important;
  font-weight:600;
  position: relative;
}

.Consult-Page .calender-wrapper  .react-calendar__tile--now > abbr::after {
  content: '';
  position: absolute;
  bottom: 4px; /* Adjust this value to position the dot as needed */
  left: 50%; /* Center the dot horizontally */
  transform: translateX(-50%);
  width: 3px; /* Adjust the size of the dot */
  height: 3px; /* Adjust the size of the dot */
  background-color: #0f5ad9; /* Set the color of the dot */
  border-radius: 50%;
}
.react-calendar__tile:disabled{
  background-color: unset !important;
  color: #757575;
}

.new_landing_page .react-calendar__tile--active  {
  background-color: #0f5ad9 !important;
  border-radius:50% !important;
  color : white !important;
}

.calender-wrapper .react-calendar__tile--now:enabled:hover,
.calender-wrapper .react-calendar__tile--now:enabled:focus  {
  background-color: #0f5ad9 !important;
  border-radius:50% !important;
  color : white !important;
}


/* .live_chat_toogle .form-switch .form-check-input{
  margin-left: 0 !important;
} */


/* .live_chat_list .new-msg-item{
  padding-left: 10px !important;
}

.live_chat_list .msg-content{
  display: unset;
}

.live_chat_list .new-msg-item .msg-content-right{
  margin: 0;
  flex: none;
} */

.live_chat_list .new-msg-item {
  padding-left: 62px !important;
}

.live_chatbox {white-space: pre-wrap; padding: 6px 8px; border-radius: 10px 10px 0 10px; max-width: 75%; color: #fff; font-size: 0.80rem; background: var(--green);}

.login_user_msg {
  background: var(--green) !important; 
}

.login_user_msg  .msg-content .msg-content-right > *, .login_user_msg  .emoji-icons-wrapper .emoji-icons-item.add-new-reaction, .login_user_msg .dot-options, .login_user_msg  .msg-content .msg-content-right h2 span, .login_user_msg .msg-user-replies-wrapper .reaction-count{
  color: white;
}

.live_chat_box .message-form{
  margin-bottom: 79.2px !important;
}

.image_input{
position: relative;
}

.image_input > input{
  position: absolute;
  width: 20%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 5;
  cursor: pointer;
}


.message-form .msg-img{
margin: 0 10px !important;
}
.message-smartchat-form .msg-img{top: 5px;}

/* .live_chat_box .emoji-icons-wrapper .emoji-icons-item{
  border-radius: 50%;
    width: 31px;
    height: 26px;
}  */

 .live_chat_box .with-counts{
  border-radius: 25px;
  width: auto !important;
}

.msg_option{
  margin: 0 !important;
}


.live_chat_box .emoji-icons-wrapper{
  margin: 10px -5px;
}


.industry_name{
  color: #272727;
  font-weight: 600;
  font-size: 16px;
}
.show_all_btn{
  color: var(--primary);
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}

.poc_w_90{
  width: 90%;
}

.no_record_market{
  height: 50dvh;
  display: flex;
  align-items: center;
}

.vendor_item{
  display: flex;
  gap: 12px;
  background: rgb(255, 255, 255);
  padding: 14px;
  margin-bottom: 14px;
  cursor: pointer;
  width: 100%;
 
}

.customer-product-vendor-list-holder .vendor_item{border-radius: 8px;}

.vendor_item .vendor_item_img {
  /* height: 94px;
  width: 94px; */
  position: relative;
  border-radius: 5.015px;
  width: 30%;
  display: flex;
  align-items: center;
}
.vendor_item .vendor_item_img > img{

  height: 94px;
  width: 94px;
  object-fit: cover;
  border-radius: 5.015px;

}

.vendor_item .vendor_item_detail{
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
  margin-top: 8px;
 
  width: 70%;

}

.vendor_item .vendor_item_detail .v_company_name{
  color: #000;
  font-size: 15px;
  font-weight: 500;
}


.vendor_item .vendor_item_detail .v_company_categories{

  color: rgb(126, 123, 112);
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow : hidden;
  

}

.vendor_item .vendor_item_detail .v_company_bio{


  color: #000;
  font-size: 14px;
  font-weight: 400;
  width: 100%;


  white-space: normal;
  word-wrap: break-word;

  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow : hidden;
 
  /* overflow-wrap: break-word; */
  /* -webkit-box-orient: vertical; */
  /* white-space: nowrap;  */
    overflow: hidden; 
    text-overflow: ellipsis;
                                             
}


@media screen and (max-width: 360px) {

  .calender-wrapper .consult_dropdown{
    width: 150px;
      }

      .calender-wrapper .consult_type_dropdown{
        width: 150px;
      }

}

@media screen and (max-width: 375px) {

  .poc_sent_card .poc_card_img > img {
    width: 50px;
    height: 50px;
  }
  .customer_care_card{
    padding: 8px;
  }

}

.calender-wrapper .react-calendar__navigation__arrow.react-calendar__navigation__next-button{
  padding-left: 2px !important;
  padding-bottom: 2px;
}

.calender-wrapper .react-calendar__navigation__arrow.react-calendar__navigation__prev-button{
  /* padding-right: 2px !important; */
  padding-bottom: 2px;
}


.invoice_input_field{
  background: none !important;
    border: none !important;
    /* width: auto !important;
    max-width: max-content; */
    height: auto;
    margin: 0 !important;
    padding: 0 !important;
    text-align: right;
    font-size: 15px !important;
    border-radius: unset;
}

.invoice_input_field:hover{
  background: none;
}

.invoice_input_field:focus{
  background: none;
}

.invoice_cost {
  color: #52bd41;
  display: flex;
  align-items: center;
  justify-content: center;
}

#invoice_cost_span{
 
    position: absolute;
    opacity: 0;
    z-index: -100;
    white-space: pre;
    /* display: none; */
 
}

/* .skip_button > span{
  text-decoration: underline;
} */
.skip_btn{
  font-size: 17px;
  font-weight: 700;
  margin-right: 30px;
  /* text-decoration: underline; */
  
}

.skip_btn { 
  position: relative;
}

.skip_btn::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust this value to control the distance between text and underline */
  width: 100%;
  border-bottom: 0.12em solid; /* Adjust this value to control the thickness of the underline */
}

/* .skip_btn { 
  -webkit-text-underline-position: under;
  -ms-text-underline-position: below;
  text-underline-position: under; 
  text-decoration-thickness: 6px;
} */


.slider2-inner-container .menus-dropdowns .dropdown-toggle.btn .text-container , .catalog_product_item .text-container,.invoice_catalog_item .text-container  {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; 
  display: inline-block; 
  max-width: calc(100% - 20px);
  margin-top: 2px; 
  /* vertical-align: middle;  */
}

.slider2-inner-container .menus-dropdowns.roles_dropdown .dropdown-menu .dropdown-item, .invoice_catalog_item .dropdown-menu .dropdown-item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; 
  /* display: inline-block;  */
  /* max-width: calc(100% - 20px); 
  vertical-align: middle;  */
}




.setting-subpages .catalog_screen .spinner{
  background-color: #F9F9F9;
}

.live_chat_toogle_d{
  /* width: 50%; */
  margin-bottom: 15px;

}

.live_chat_toogle_d .form-check-input{
  margin-top: 0;
  margin-left: 6px;
}

.scroll_hidden{
  overflow: hidden !important;
}

.image_input > span{
  margin-bottom: 4px;
  cursor: pointer;
  
}

.landing_page_icon{
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomPart-menu{
  padding-top: 5px;
  opacity: 0.9;
  background-color: #272727;
  position: absolute;
  bottom: 15px;
width: 80%;
  
}


.show-on-public .create-ticket-tabs{
  margin: 0 0 15px 0;
}

.invoice_calender-wrapper{
  position: absolute;
  top: 25px;
  right: 0;
  width: 100%;
  text-align: right;
  /* z-index: 5; */
}

.estimate_tab > h1{
  margin-bottom: 10px;
}

.estimate_group_name{
  max-width: 60%;
}

.add_on_input{
  max-width: 40%;
  text-align: right;
}
.standard_double_input{
  display: flex;
  background: #383838;
  border-radius: 8.023px ;
}

.standard_double_input .template-flex-black{
  margin-bottom: 0 !important;
}

.standard_double_input >input{
  background: none !important;
  border: none !important;
  border-radius: unset;
}

.f_600_h{

  font-weight: 600;
}

.fit_content{
  width: fit-content;
}

.strategy_block{
  background-color: #FFFFFF;
  padding: 32px 24px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.strategy_block .strategy_block_heading{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 20px;

}
.strategy_block_heading .h_logo{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
 width: 60px;
 height: 60px;
  border-radius: 50%;
}
.strategy_block_heading .strategy_h{
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
.strategy_block_img {
  background-color: #262626;
  border-radius: 15px 15px 15px 15px;
}

.strategy_block_img img{
  height: 200px;
  width: 318px;
  object-fit: contain;
  vertical-align: middle;
    display: inline-block;
  object-position: center center;
  border-radius: 15px 15px 15px 15px;

}

.strategy_block_list ul {
  display: flex;
  flex-direction: column;

  gap: 10px;
  
}

.strategy_block_list ul li span{
  margin-right: 5px;
}

.strategy_block_list ul li span i{
  font-size: 14px;
  color: #737373;
}

/* .strategy_block{
  background-color: #FFFFFF;
  display: flex;
    --background-transition: 0.3s;
    border-style: none;
    --border-style: none;
    --border-radius: 15px 15px 15px 15px;
    --padding-block-start: 32px;
    --padding-block-end: 32px;
    --padding-inline-start: 24px;
    --padding-inline-end: 24px;
} */





.read_more_button {
  position: absolute;
  width: 100%;
  left:0;
  top: 50%;
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
}


.read_more_button button a {
  width: 200px;
  /* padding: 12px 48px; */
  text-align: center;

  background-color: #68B245;
  z-index: 1;



    font-size: 15px;
    padding: 12px 18px;
    border-radius: 3px;
    color: #fff;
  

    transition: all .3s;
}

.read_more_button a:hover{
  color: #fff;
}

.strategy_block_img_wrap {
  position: relative;
 
  width: 100%;
  height: 100%;
}

.strategy_block_img_wrap:hover .read_more_button {
  opacity: 1;
}

.btn_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px ;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.strategy_block_img_wrap:hover .btn_overlay {
  display: block;
  background: rgb(0 0 0 / 60%);
}

.strategy_sec .container .sec-head h2{
  color: #fff;
  font-size: 48px;
    font-weight: 700;
    line-height: 1.2em;
}

.strategy_sec .container .sec-head  .head_1{

  color: #68B245;
  font-weight: 500;
}

.strategy_sec .container .sec-head  p{

  color: #fff;

}

.invoice_detail .profile-description, .leaderboard_detail_screen .profile-description{
  margin: 0px 0 5px;
}


.industry_menu_container:hover .industry_new_menu a{
  color: #68B245;
}

.industry_menu_container:hover .industry_image{
  background-color: #68B245;
}

.accordion_sec .accordion-header{

    border-bottom: 0.5px solid rgba(128, 128, 128, 0.15);
}

.accordion_sec .accordion-header .accordion-button{
  padding: 0;
}
.accordion_sec .accordion-body{
  padding: 10px;
  background-color: #68B2451A;
  

}

.accordion_sec .accordion-item{
  border: none;
}
.accordion_sec .li_item{
  margin-bottom: 15px;
}


.accordion_sec  .accordion-item .accordion-button:before{
  content: '\f054' !important;
  top: 3px;
  font-size: 12px;
  transform: rotate(90deg);
  color: #1f2124;
  right: 3px;
  /* font-weight: 500; */
}

.accordion_sec .accordion-button:not(.collapsed):before {
  transform: rotate(270deg);
}

.accordion_flex {
  font-size: 16px;
    line-height: 1.5;
    color: #1f2124;
    font-weight: 500;
}

.accordion_flex > span{
  font-size: 15px;
 
}


.check_icon{
  background: #68b245;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.check_icon > i{
  color: #fff;
  font-size: 12px !important;

}

.grey_icon{
  background-color: #7C8498 ;
}


.check_sub_icon{
  background: #68b245;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.check_sub_icon > i{
  color: #fff;
  font-size: 8px !important;

}


.f__400{
  color: #000000;
  font-weight: 400;
}

.create-template .invoice_loader .spinner{
  width: 550px !important;
}

.payment_invoice.primary_panel_content{
  padding: 20px 20px 5px !important;
}

.font_18{
  font-size: 18px;
}


/* .poc_invite_page .invite-page-search{
  padding-right: 0px !important;
} */

.oppo_item_address{
  margin: 0 15px 0 0 !important;
}

.system_role_screen .btn-roi-template{
  border: 1.8px solid #52BD41
}

.company-info-screen span.btn-roi-template.as-link.mt-3-bold{pointer-events: none;}

.setting-subpages .general .css-yk16xz-control,.setting-subpages .general .css-1pahdxg-control{
  border-radius: 7.651px !important;
  border: 0.954px solid #d9d9d9 !important;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
  height: 49px;
  width: auto !important;
}
.company-details .general-input-container .css-2b097c-container{position: relative;}
.setting-subpages .general .location-item > td > i{
  color: var(--red);
}

.industry_dropdown .dropdown .dropdown-toggle{
  height: 49px;
}
.btn_padding{
  padding: 11px 27px !important;
}

.catalog_li_item{
  text-overflow: ellipsis;
  overflow: hidden;
  width: 124px;

}

/* .setting-subpages .general .css-2b097c-container .css-2613qy-menu{
  position: relative;
  z-index: 999999 !important;

} */

.setting-subpages .catalog_modal{
  align-items: center !important;
}
.msg_send_btn .icon-plane:before{
color: var(--green);
}

.invoice_delete > i {
  color:  var(--green);
}

.catalog_product_item{
  padding: 10px;
  background: #f0f0f0;
  border-radius: 8.023px;
  display: flex;
  justify-content: space-between;
}

.estimate_detail_p{
  padding: 0 8px;
}

ul.invoices_suggestion li{
  border-bottom: 1px solid #dcdcdc;
}

ul.invoices_suggestion li:last-child {
border-bottom: none;
}

.estimate_name_modal textarea {
  resize: none;
} 

.create_company_form .slider-content .form-label {
color: #262626;
font-weight: 600;
}



.roi-tags-icons {
  /* margin: 20px 0px 20px 0px;
  display: flex;
  gap: 10px; */
  /* justify-content: space-between; */
}
.roi-tags-icons .tag-icon {
  border-radius: 3.59px;
  background: #E8E6DF;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.roi-tags-icons > * {
  /* margin-right: 5px; */
}

.roi-tags-icons .w-41 {
  width: 41px !important;
}

.roi-tags-icons .w-25 {
  width: 25px !important;
}

.roi-tags-icons .w-28 {
  width: 28px !important;
}

.roi-tags-icons .w-29 {
  width: 29px !important;
}

.roi-tags-icons .tag-icon-time {
  width: 100px !important;
  height: 40px;
  border-radius: 3.59px;
  background: #E8E6DF;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.roi-tags-icons .tag-icon-time > span {
  color: #222321;
  /* text-align: right; */
  font-family: 'Inter';
  font-size: 11.68px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


 .overview_flex {
display: flex;
align-items: center;
justify-content: space-between;
}
.green_dot{
  background-color: var(--green);
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.blue_dot{
  background-color: #3597C2;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.yellow_dot{
  background-color: rgb(254, 190, 19);
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.profile_overview  .lead_button{
  padding: 8px 25px;
  border: 1px solid #222321;
  border-radius: 8.02px;
  cursor: pointer;
  
  
}



.lead_button_dropdown .dropdown .dropdown-toggle{
  padding: 11px 0 !important;
  border: 1px solid #222321;
  border-radius: 8.02px;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  
}

.profile_overview  .update_phase{
  padding: 10px 19px;
  border: 1px solid #222321;
  border-radius: 8.02px;
  display: inline-block;
  cursor: pointer;
}

.overview_flex > span{
  font-weight: 500;
}

.text_green{
  color: #52bd41 !important;
}

.profile_view_more{
  color: #52bd41;
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0 0 0;
  cursor: pointer;
}

.event-icon-profile-head{padding-top: 3px;}
.edit-profile-headings{padding-left: 17px;}
.user_notes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #E8E6DF;
  padding: 15px;
  border-radius: 0px 0px 3px 3px;
  margin-bottom: 15px;
  }

  .profile-block-wrapper.profile-from-leftPanel .user_notes{margin-top: 10px;}

  .user_badge_check{
    position: absolute;
    bottom: -4px;
    right: -4px;
  }

  .roi_msg_logo img{
    width: 30px !important;
    height: 30px !important;
    min-width: unset !important;
  }

  .chatlist .new-msg-item .msg-content-left {
    margin: 0 0 0 -68px !important;
   
  }

  .scheduler_name{
    padding: 3px 10px;
  }

  h2.scheduler_name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }



  .react-datepicker__day--highlighted:not(.react-datepicker__day--selected){
    background-color: unset !important;
    border-radius: unset !important;
    color: unset !important;
    /* height: 27.19px;
    width: 27.19px; */
  }

  .react-datepicker__day--highlighted::after {
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  background-color: #262626; /* Change color as needed */
  border-radius: 50%;
  margin: -6px 0  2px 12px;
  /* position: absolute; */
  bottom: 0;
  /* left: 50%;
  transform: translateX(-50%); */
}

.react-datepicker__day--selected.react-datepicker__day--highlighted::after{
  background-color: white;
  /* height: 27.19px;
  width: 27.19px; */
}

.profile-block-wrapper .events-icon ul li{
  color: var(--lightgray) !important;
}


.profile-block-wrapper .social-icons ul li i {
  
  color: var(--lightgray)
}

.profile-block-wrapper .events-icon ul li .event-icon-text{
  color: var(--lightgray)
}


.offering_card_h{
  height: 254px;
}


/* .bg_transparent{
  bottom: 15px;
    opacity: 0.9;
    padding-top: 0px;
    position: absolute !important;
    left: 0;
    right: 0;

} */


.bg_transparent{
  background:rgba(247, 246, 242, 0.7);
  bottom: 15px;
  opacity: 1;
  padding-top: 0px;
  position: absolute !important;
  left: 0;
  right: 0;
  padding-bottom: 0px;

  z-index: 998 !important;
}

.roi-custom-select-wrapper::after{right: 10px;}

.bg_transparent > .invite-btns{
  padding-top: 10px;
  padding-bottom: 10px;
}

.schedule_user_appointment .custom_animated_tabs .custom-tabs.event-tabs{
width: auto !important;
}

.chat_title_input{
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
  
}

.chat_title_input .roi-form-input{
  flex: 1;
  margin-bottom: 0;
}
.chat_title_input .input_icon > i{
  font-size: 23px;
  color: #9B9787;
}

.gpt_detail_icons .input_icon > i{
  font-size: 21px;
  color: #9B9787;
}


.initial_request {
  display: flex;
  gap: 20px;
  align-items: center;
  /* justify-content: center; */
}
.initial_request .initial_request_img img{
  height: 23px;
  width: 23px;
  object-fit: cover;

}

.responding_request{
  padding: 10px 15px;
  background: #FAFAFA;
  border-radius: 8.02px;
border: 1px solid #E9E9E9;
}


.responding_request .stars_flex{
display: flex;
gap: 20px;
padding: 10px 0;
}

.responding_request .stars_flex .stars_text{
  height: 1px;
  background-color: #E4E4E4;
  width: 30%;
}
.responding_request .stars_flex .empty_div{
  width: 23px;
}

.share_able_icon img{
  filter: brightness(4);
}


.gpt_detail_icons{
  display: flex;
  gap: 20px;
}

.gpt_sliding_btns .btn{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  padding: 6px 4px !important;
  /* max-width: calc(100% - 20px); */
}

.deal_item{
  padding: 15px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8.023px;
  margin-bottom: 13px;
  border: 5px solid white;
}

.filter-btn-wrapper .set_opacity{
  /* background: rgba(76, 175, 80, 0.3); */
  
}


.appointment_detail_drpdown{
  display: flex;
gap: 20px;
align-items: center;
margin-top: 20px;
}

.appointment_detail_drpdown .roi-form-dropdown{
width: 50%;

}

.pipeline_phase{
  border-radius: 5px;
  background: #2C2C2C;
  padding: 15px 20px;
  color: #FFFFFF;
  /* flex: 0 0 auto; */
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  flex-basis: 90%;
  /* align-items: center; */
}

.pipeline_customer_alias{
  border-radius: 5px;
  background: #2C2C2C;
  padding: 15px 20px;
  color: hsl(0, 0%, 100%);
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  flex-basis: 90%;
  /* align-items: center; */
}

.pipeline_alias_role{
  border-radius: 5px;
  background: #2C2C2C;
  padding: 13px 16px 0;
  color: #FFFFFF;
  flex: 1;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.pipeline_phase > .alias_name ,.pipeline_customer_alias .alias_name{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.alias_permission_flex{
  display: flex;
  gap: 4px;

}

.alias_permission_flex .form-check{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
}
.pipeline_alias_role .alias_name{
  margin-top: 7px;
}


.alias_permission_flex .form-check .form-check-input {
  float: none;
  margin-left: 0;
}

.alias_permission_flex .form-check label {
  pointer-events: none;
  cursor: default;
}

.panel-secondary .menus_gallery .spinner{
  background-color: #f7f6f2;
}
.user_profile .menus_gallery .spinner{
  background-color: #ffffff;
}


.loader_hieght{
  height: 20vh;
  position: relative;
}

.invite_loader_hieght{
  height: 55vh;
  position: relative;
}

.likelihood_color_flex{
  margin-top: 10px;
  display: flex;
  gap: 15px;
}
.likelihood_color_flex .deal_status{
  width: 34px;
  height: 34px;
  border-radius: 50%;
  /* cursor: pointer; */
  /* background-color: #3597C2; */
}

.likelihood_color_flex .deal_status_border{
  width: 46px;
  height: 46px;
  border-radius: 50%;
  /* cursor: pointer; */
 
  display: flex;
  align-items: center;
  justify-content: center;
}


.deal_heading{
  font-size: 15px !important;
  color: #000000;
  font-weight: 500;
}

.added_deals_list{
  display: flex;
  flex-direction: column;
  gap: 13px;
 
}


.view_more_btn {
  cursor: pointer;
  color: #45b033;
  /* font-family: Inter; */
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  
}

.phase_item{
  padding: 20px 15px;
  background: #ffffff;
  border-radius: 8.01px;
  margin-bottom: 13px;
  
}

.pipeline_item{
  padding: 10px 10px;
  background: #ffffff;
  border-radius: 8.01px;
  margin-bottom: 13px;
  display: flex;
  flex-direction: column;
  gap: 10px;

}
.pipeline_item > div{display: flex;}
.pipeline_item div > b{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.deal_total_cost{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  font-weight: 600;
}

.search_deal_list{
  margin-bottom: 80px;
}

.rc-time-picker {
  position: relative;
  display: inline-block; /* Ensure the container spans only the width of its content */
}

.createaction-custom-timepicker .rc-time-picker{width: 100%;}

.rc-time-picker-input {
  padding-left: 30px; /* Adjust padding to accommodate icon */
  pointer-events: none;
}

.rc-time-picker::after {
  /* content: '\f017';  */
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; /* Regular weight */
  font-size: 13px; /* Adjust size as needed */

  position: absolute;
  top: 53%;
  right: 10px; /* Adjust as needed */
  transform: translateY(-50%);
  cursor: pointer;
}

.rc-time-picker-panel-select, .rc-time-picker .rc-time-picker-input, .rc-time-picker-panel-input-wrap .rc-time-picker-panel-input{
  text-transform:uppercase;
  
}

.rc-time-picker-panel-input-wrap .rc-time-picker-panel-input{
  pointer-events: none;
}

.rc-time-picker-panel-select{
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* li.rc-time-picker-panel-select-option-selected{
  background: #e8dfcd !important;
} */

.rc-time-picker-panel-inner{
  margin-top: 47px;
  margin-left: 2px;
}
.rc-time-picker-panel-input-wrap{
  display: none;
}

/* .rc-time-picker-panel-input-wrap .rc-time-picker-panel-input{
pointer-events: none;
} */

.phases_item{
  gap: 9px;
}

.mb_12{
  margin-bottom: 12px;
}


.catalog_screen .input-group input[type='text'],.catalog_screen .input-group input[type='number'], .catalog_screen .input-group input[type='email'], .catalog_screen .input-group input[type='password'],.catalog-content-extended .input-group input[type='text'],.catalog-content-extended .input-group input[type='number'], .catalog-content-extended .input-group input[type='email'], .catalog-content-extended .input-group input[type='password']{
  padding: 12px 15px !important;
}


.catalog_screen .dropdown .dropdown-toggle{
  padding: 15px 15px !important;
  max-width: 400px;
  overflow: hidden;
  text-overflow:ellipsis;
}

.company-info-screen.catalog_screen .dropdown-item.active{
  overflow: hidden;
  text-overflow: ellipsis;
}

.css-yk16xz-control, .css-1pahdxg-control{
  cursor:  text !important;
}

.pb_15{
padding-bottom: 15px;
}

.setting-subpages .roi_template_spinner .spinner{
  background-color: #F9F9F9;
}

.creator_name{
  color: #707070;
  font-size: 14px;
  flex: 1;
}


.customer_user_row .discover-builders-slider .swiper-button-next,
.customer_user_row .discover-builders-slider .swiper-button-prev {


  top: 54% !important;
  padding: 58px 15px;
}

.draggableWidth{
  width: 349px;
}

.sliding-panel-wrapper  .close_icon_right{
  right: 46% !important;
}

.recruitment_dropdown .dropdown .dropdown-toggle{
  padding: 12px 15px !important;
}



.text_overflow_container {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; 
  display: inline-block; 
  max-width: 535px;
  margin-top: 2px; 
  /* vertical-align: middle;  */
}



.create-catalogue-accord-holder .accordion-item .accordion-button:before{
font-size: 17px !important;
}


.repeat_date_dropdown .dropdown .dropdown-toggle{
  padding: 10px 15px !important;
  border-radius: 25px !important;
}

.repeat_date_dropdown .dropdown-menu{
  /* width: 110px !important; */
}

.meetup_date_repeat .rc-time-picker{
  width: -webkit-fill-available;
  width: -moz-available;
}


.additional_menu_icon::after{
  position: absolute;
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /* color: var(--primary-color); */
  top: 0px;
  right: 15px;
  transition: 200ms ease;
  transform: rotate(0deg);
}

.rotate_down.additional_menu_icon::after {
  transform: rotate(90deg);
}


.create_session_timepicker .rc-time-picker{
  width: -webkit-fill-available;
  /* width: -moz-available; */
}

.create_session_timepicker .input-icon{
  top: 54% !important;
}

.create_session_timepicker .rc-time-picker::after{
  right: 18px !important;
}

.deal_item_desc{
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word
}

.css-1pahdxg-control .css-1g6gooi input{
  border-radius: 0 !important;
}

.ScheduleScreen_Style.poc_invite_page.fix-width-forms.primary_panel_content, .in_person_detail.fix-width-forms{padding-right: 0 !important;}

.btn.btn-dark.dark_btn{
background-color: #424242 !important;
border-color:#424242 !important ;
}


.company_dots {
  display: inline-block; /* or display: block; */
  width: 62px; /* Adjust width as needed */
  height: 10px; /* Adjust height as needed */
 
  background-color: #D9D9D9; /* Example background color */
  border-radius: 13px;
}

.bg_green{
  background-color : var(--green) !important
}

.bg_black{
  background-color: black;
}


.membership_page{
  margin-right: 11%;
}

.membership_page .form-switch {
  margin-left: 10px;
  display: inline-block;
}

.membership_level_fields{
  background-color: #f1f2f2;
  padding: 10px;
  /* border-radius: 13px; */
}


.membership_page .membership_description textarea{
  border-radius: 7.651px !important;
  border: 0.954px solid #d9d9d9 !important;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
  height: -moz-fit-content;
  height: fit-content;
  width: auto !important;
}




.membership_pading{
  padding: 0 10px ;
}

/* .membership_page .select-wrapper::after{
  z-index: 3;
} */

.recruitment_slider .slick-arrow.slick-prev.slick-disabled:before{
  display: none;
  /* color: rgba(0, 0, 0, 0.5) !important; */
}

.recruitment_slider .slick-arrow.slick-next.slick-disabled:before{
  display: none;
  /* color: rgba(0, 0, 0, 0.5) !important; */
}

.detail_btn{
  color: #52bd41;
}

.service_subscription{
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  max-width: 100px;

}

.task_assigne_icon > i{
  color: #a39f8d;
}



.create-circle-panel .ScheduleScreen_Style .invite-page-search{

  padding-right: 0px !important;
}


.font_color{
  color : var(--green) !important
}

.out_of_stock{
  color: #e74c3c;
  font-weight: 500;
}

.customer_name{
  border-radius: 5px;
  background: #E8E6DF;
  padding: 12px 15px;
  color: #000000;
  /* flex: 0 0 auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  /* flex-basis: 90%; */
  font-weight: 500;
  /* align-items: center; */
}

.comapny_changeLoader .spinner{
  padding-left: 250px;
  background-color: #000 !important;
}

.comapny_changeLoader .spinner .spinner-border{
  color: white;
}

.comapny_changeLoader_text{
  top: 60%;
  z-index: 1033;
  position: absolute;
  color: white;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: 0px;
  width:calc(100% - 250px);
}

.sidebar-menu-wrapper .userType > .company_dropdown .spinner{
  background-color: unset;
  padding-left: 0 !important;
}

.tag_card{
  border: 3.5px solid #eaeaea;
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  margin: 0 0 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tag_card2{
  border: 0.5px solid #eaeaea;
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  margin: 0 0 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
  
}

.tag_icon > img{
  width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 3.59px;
}

.customer_btn{
  display: none;
}

.purchase_order_span{
  color: var(--light);
  font-size: 14px;
  margin-top: .25em;
}

.event_detail_icon{
  margin-left: 0 !important;
  justify-content: unset !important;
}

.event_detail_icon span, .event_detail_icon i{
  font-size: 22px;
}

.event_detail_icon span .path1:before{
  color: var(--lightgray);
}
.event_detail_icon span .path2:before{
  color: white;
}
.event_detail_icon span .path3:before{
  color: white;
}

.slider_no_record{
  margin: 8px 0 20px;
}

.event_map_slider .slick-slide {
padding: 0;

}

.event_map_slider{
  margin: 5px 0 20px;
}

.ticket-free-wrapper h3 {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  color: var(--dark);

}

.fa-sync-alt.margin{
  margin: 0px 0px 0px 2px;
}

.event_detail.user-profile-img > img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}
span.clear-btn.margin{
  margin: 0 0 0 10px;
}
.toogle_btn .form-check.form-switch{
  margin-bottom: 0;
}

.toogle_btn .form-check.form-switch label {
  margin-top: 3.3px;
}

.form_label{
  margin-top: 2px;
}

.filter-btn-wrapper .dropdown-toggle.btn{
  margin: 0;
  padding: 13px 10px;
  box-shadow: none;
}

.filter-btn-wrapper.btn{
  margin: 0;
}
/* .map_slider_card_main{
  padding-left: 30px;
  background: #ffffff;
  border-radius: 25px;
} */

.map_slider_card{
  background: #e8e8e9;
  border-radius: 25px;
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e9;
 padding-left: 30px;
  width: 100%;
}

.map_slider_card .person-img {
  margin: 5px -15px 5px 5px;
  position: relative;
  z-index: 2;
}
.map_slider_card .person-img img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  object-fit: cover;
}

.map_slider_card .person-web-link {
  flex: 1 1;
  background: #ffffff;
  padding: 13px 10px 13px 20px;
  /* border-radius: 30px 25px 25px 30px; */
  border-radius: 25px;
  text-align: center;

  width: 80%;
}

.map_slider_card .person-web-link a {
  color: #000000;
  text-decoration: none;
  font-size: 14px;
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}


.create_company_in_app.z_index{
  z-index: 1032;
}


.tags_heading{
  padding-left: 18px;
  font-weight: 500;
}

.setting_btn .btn:disabled,.get_started_btn.btn:disabled,.remove-user-text > div .btn:disabled, .personal_invite .btn:disabled{
  opacity: 1;
}
.pipeline_dropdown .dropdown-menu{
  width: 364px;
}
.linked_tag{
 
  text-decoration: underline;
  color: var(--green);
}

.unset{
  align-items: unset !important;
}

.estimate_contact{
  height: 26px;
}

.expense_card{
  border: 1.5px solid #eaeaea;
  background: #fff;
  padding: 15px 10px;
  border-radius: 15px;
  margin: 0 0 20px;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16); */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
}

.payment_milestone_list ul li{
  list-style: disc !important;
  padding-left: 0;
  margin-bottom: 4px;
  }

  .payment_milestone_list ul{
    padding: 0 18px;
  }

  /* .multiselection_dropdown .dropdown-item.active::before{
    content: \f078;
    font-family: "Font Awesome 5 Free";
    background-color: white;
  } */

  .multiselection_dropdown .dropdown-item.active{
    background-color: transparent !important;
    color: #000000;
  }

  .projects-graph-page .multiselection_dropdown .dropdown-item.active{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .multiselection_dropdown .dropdown-item.active::before {
    content: url('../images/svg-icons/company-check.svg'); 
    font-family: "Font Awesome 5 Free";
    font-weight: 900; 
    /* position: absolute; */
    float: right;
    /* padding-top: 5px; */
    color: var(--green);
    
}


.multiselection_dropdown .dropdown-menu .dropdown-item:focus{
background-color: transparent;
color: inherit;
}


.multiselection_dropdown .dropdown-menu .dropdown-item:focus{
background-color: transparent !important;
color: inherit;
}

.milestone_accodrdion .input-group > input{
  width: 100% !important;
}
.milestone_accodrdion .input-group > input[type='date']{
  padding: 12px 15px !important;
}


.reply_date_info .message-date {
    font-size: 13px;
    color: #818181;
    margin-top: 2px;
}

.payment_card_btn .btn{
  padding: 13px 60px !important;
}

.align_header .search-tabs-icons span:has(i){
  margin-top: 0 !important;
}

.profile_img {
  margin: 0 15px 0 0;
}

.profile_img img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.circle_loader .spinner{
  width: 535px;
}
/* .profile_name h2{
  font-weight: 600 !important;
} */

.create-catalogue-accord-holder.accordion .accordion-body{
  font-family: 'inter';
}

.roles_name{
  text-transform: capitalize;
}
.fixed_width_page{
  max-width: 860px;
}
.fixed_padding{
  padding: 0 20px !important ;
}

.padding_top{
  padding-top:  20px !important ;
}

.padding_b{
  padding-bottom: 85px;
}

.schedule_location.location-field.form-control .css-26l3qy-menu{
  width: 101.5% !important;
  max-width: none;
}

.reply_emoji_icon{
  border: 1px solid #f7f6f2;
  border-radius: 25px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  vertical-align: top;
  cursor: pointer;
  background: #fff;
  font-size: 17px;
  color: var(--dark);
}

.reply_reactions .reaction-count{
  font-size: 14px;
}


.capture-with-camera-wrapper .video-img {
  margin-left: 0;
}

/* .capture-with-camera-wrapper .video-img img {
  max-width: 100%;
  margin: auto;
} */

.capture-with-camera-wrapper .video-img img {
  max-width: 100%;
  /* max-width: 100%; */
  /* height: 300px;
  width: 350px; */
  width: 100%;
  height: auto;
  margin: auto;
}
.header_heading > h2{
  font-size: 16px;
}

.recruitment_detail .icon-info-down {
  font-size: 22px;
  cursor: pointer;
}

.recruitment_detail .icon-info-down .path1:before {
  
  color: #fff;
}

.recruitment_detail .icon-info-down .path3:before,
.icon-info-down .path2:before {
  color: rgb(66, 66, 66);
}
.recruitment_user_detail .profile_img > img{
width: 50px;
height: 50px;
}

.recruitment_user_detail .profile_name{
  font-size: 16px;
  font-weight: 600;
}

/* .img-wrapper.business img {
  width: 100%;
  height: 100%;
  border-radius: 15%;
  object-fit: cover;
} */

.border_radius_15{
  border-radius: 15% !important;
}


.task_completed {
  cursor: default !important; 
  pointer-events: none !important; 
}

.task_completed svg {
  pointer-events: none !important; /* Ensure the SVG itself can't be interacted with */
}

.task_board_img > img {
  height: 178px;
  width: auto;
  object-fit: cover;
}

.task-page .board-list-wrapper .action-listing .task-item .red-bar.sub_task{
top: 33px !important;
}

.task-page .board-list-wrapper .action-listing .task-item .green{
/* background-color: #2e7d32 !important; */
background-color: transparent !important;
}
.task-page .board-list-wrapper .action-listing .task-item .yellow{
background-color: #f9a825 !important;
}

.create_recruitment .create-ticket-form .location-field.form-control .css-26l3qy-menu .css-11unzgr,
.sessioneventFilter .location-box .location-field.form-control .css-26l3qy-menu .css-11unzgr,
.serach-filter-wrapper.ScheduleScreen.create-schedule .location-field.form-control .css-26l3qy-menu .css-11unzgr{
  max-height: 182px !important;
  scrollbar-width: none;
}
.serach-filter-wrapper.ScheduleScreen.create-schedule .location-field.form-control .css-26l3qy-menu .css-11unzgr{
  max-height: 200px !important;
}

/* .all_builders-wrapp-listing .btn.btn-primary:focus-within, .multiselection_sliding_btns .btn.btn-primary:focus-within{

  background-color: transparent;
  color: #000;
}

.all_builders-wrapp-listing .btn.btn-primary:hover, .multiselection_sliding_btns .btn.btn-primary:hover{
color: #ffffff;
} */


.calendar_close{
  position: absolute;
  right: 15px;
  top: 72px;
  color: #a39f8d;
  font-size: 28px;
  cursor: pointer;
  z-index: 99;
  background: unset;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.ticket-dates-wrapper .calendar_close{
  right: -193px;
  top: -5px;
  z-index: 999;
}
.meetup_detail .fix-width-forms, .create_catalog_group .refferal-list-wrapper.fix-width-forms.fixed-width-inputs, .chat_reply_list.fixed-width-inputs.fix-width-forms{
  padding-right: 0 !important;
}

.img_w_h{
  width: 40px !important;
  height: 40px !important;
}

.session_icon {

  color: var(--gold);
}

.list_card_main .company_project_img img{
  width: 91px;
  height: 91px;
  border-radius: 10px;
  object-fit: cover;
}

.project_alias_role{
  border-radius: 5px;
  background: #2C2C2C;
  padding: 13px 16px ;
  color: #FFFFFF;
  flex: 1;
  display: flex;
  justify-content: space-between;

  /* align-items: center; */
}

.main-vertical-scroll-holder{
  padding-bottom: 50px!important;
}