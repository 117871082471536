@font-face {
  font-family: 'icomoon';
  src:  url('./icomoon.eot?2fk631');
  src:  url('./icomoon.eot?2fk631#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?2fk631') format('truetype'),
    url('./icomoon.woff?2fk631') format('woff'),
    url('./icomoon.svg?2fk631#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-table-lamp:before {
  content: "\e94d";
}
.icon-snapshot:before {
  content: "\e94c";
}
.icon-smiley-plus:before {
  content: "\e94a";
}
.icon-hangup-2:before {
  content: "\e948";
}
.icon-camera-rotate-2:before {
  content: "\e949";
}
.icon-Asset-1:before {
  content: "\e947";
}
.icon-bars-2:before {
  content: "\e935";
}
.icon-hangup:before {
  content: "\e942";
}
.icon-payments:before {
  content: "\e938";
}
.icon-builders:before {
  content: "\e93c";
}
.icon-link:before {
  content: "\e943";
}
.icon-history:before {
  content: "\e937";
}
.icon-feedback:before {
  content: "\e939";
}
.icon-widgets:before {
  content: "\e93a";
}
.icon-referrals:before {
  content: "\e93b";
}
.icon-setting_preferences:before {
  content: "\e93d";
}
.icon-posts:before {
  content: "\e93e";
}
.icon-appointments:before {
  content: "\e940";
}
.icon-gifts:before {
  content: "\e941";
}
.icon-gold-block:before {
  content: "\e936";
}
.icon-info .path1:before {
  content: "\e900";
  color: rgb(4, 5, 4);
}
.icon-info .path2:before {
  content: "\e901";
  margin-left: -1.1640625em;
  color: rgb(255, 255, 255);
}
.icon-info .path3:before {
  content: "\e902";
  margin-left: -1.1640625em;
  color: rgb(255, 255, 255);
}
.icon-info-down .path1:before {
  content: "\e903";
  color: rgb(212, 171, 65);
}
.icon-info-down .path2:before {
  content: "\e904";
  margin-left: -0.859375em;
  color: rgb(4, 5, 4);
}
.icon-info-down .path3:before {
  content: "\e905";
  margin-left: -0.859375em;
  color: rgb(4, 5, 4);
}
.icon-android-svg:before {
  content: "\e906";
}
.icon-applaud:before {
  content: "\e907";
}
.icon-aroplane:before {
  content: "\e908";
}
.icon-bullseye:before {
  content: "\e909";
}
.icon-calendar:before {
  content: "\e90a";
}
.icon-camera:before {
  content: "\e90b";
}
.icon-camera-rotate:before {
  content: "\e90c";
}
.icon-car:before {
  content: "\e90d";
}
.icon-caution:before {
  content: "\e90e";
}
.icon-cc-card:before {
  content: "\e90f";
}
.icon-circles:before {
  content: "\e910";
  color: #52bd41;
  /* color: #C48E1D; */
  /* color: #a39f8d; */
}
.icon-clone:before {
  content: "\e911";
}
.icon-share:before {
  content: "\e944";
}
.icon-coffee-cup:before {
  content: "\e912";
}
.icon-crown:before {
  content: "\e913";
}
.icon-crown-2:before {
  content: "\e914";
}
.icon-store:before {
  content: "\e915";
}
.icon-dollar-sign:before {
  content: "\e916";
}
.icon-edit-icon:before {
  content: "\e917";
}
.icon-file-icon:before {
  content: "\e918";
}
.icon-filter-icon:before {
  content: "\e919";
}
.icon-gallery-img:before {
  content: "\e91a";
}
.icon-globe:before {
  content: "\e91b";
}
.icon-Hamburger:before {
  content: "\e91c";
}
.icon-hand:before {
  content: "\e91d";
}
.icon-camera-outline:before {
  content: "\e91e";
}
.icon-home:before {
  content: "\e91f";
}
.icon-injection:before {
  content: "\e920";
}
.icon-like-icon:before {
  content: "\e921";
}
.icon-lock:before {
  content: "\e922";
}
.icon-logout:before {
  content: "\e923";
}
.icon-map-pin:before {
  content: "\e924";
}
.icon-plane:before {
  content: "\e926";
}
.icon-play-icon:before {
  content: "\e927";
}
.icon-plus:before {
  content: "\e928";
}
.icon-right-arrow:before {
  content: "\e929";
}
.icon-scan-icon:before {
  content: "\e92a";
}
.icon-share-option:before {
  content: "\e92b";
}
.icon-sound-bars:before {
  content: "\e92c";
}
.icon-tickets:before {
  content: "\e92d";
}
.icon-unlike:before {
  content: "\e92e";
}
.icon-user:before {
  content: "\e92f";
}
.icon-meetups:before {
  content: "\e93f";
}
.icon-user-group:before {
  content: "\e930";
}
.icon-user-group-m:before {
  content: "\e931";
}
.icon-user-plus:before {
  content: "\e94b";
}
.icon-video-camera:before {
  content: "\e932";
}
.icon-video-camera-2:before {
  content: "\e933";
}
.icon-mic:before {
  content: "\e934";
}
.icon-mute-2:before {
  content: "\e945";
}
.icon-mic-2:before {
  content: "\e946";
}
.icon-mute-icon:before {
  content: "\e925";
}
