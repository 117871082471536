$palette:(#000000,
    #808080,
    #3e3e3e,
    #cbe7df,
    #ee7778,
    #f3abaa,
    #fdd02b,
    #d3ab01,
    #e56a1f,
    #3677c1,
    #28549b,
    #3eb750,
    #e32a69,
    #31054a,
    #8a3a9d,
    #28bfae,
    #C48E1D,
);
// $palette1: #000000;
// $palette2: #808080;
// $palette3: #3e3e3e;
// $palette4: #cbe7df;
// $palette5: #ee7778;
// $palette6: #f3abaa;
// $palette7: #fdd02b;
// $palette8: #d3ab01;
// $palette9: #e56a1f;
// $palette10: #3677c1;
// $palette11: #28549b;
// $palette12: #3eb750;
// $palette13: #e32a69;
// $palette14: #31054a;
// $palette15: #8a3a9d;
// $palette16: #28bfae;
// $palette17: #C48E1D;

@for $i from 1 through 17 {

    .palette-#{$i} {

        // Profile Section

        .heading-links a {
            color: nth($palette, $i);
        }

        .event-btn .btn {
            background-color: nth($palette, $i);
            border-color: nth($palette, $i);

            &:hover,
            &:focus {
                background-color: transparent;
                color: nth($palette, $i);
            }
        }

        .event-tabs {
            .activeHandle {
                background-color: nth($palette, $i);
            }

            &.tabs-style-2 {
                ul li {
                    .btn {
                        &:focus {
                            color: #c1bdad;
                        }

                        &:hover,
                        &.active {
                            color: nth($palette, $i);
                        }
                    }
                }
            }

            ul li {
                .btn {

                    &:hover,
                    &:focus,
                    &.active {
                        background-color: transparent;
                    }
                }
            }
        }

        .search-tabs-icons {
            .icon.active {
                color: nth($palette, $i);
            }
        }

        .calender-wrapper {
            .react-calendar__navigation__arrow {
                color: nth($palette, $i);

                &:enabled:hover {
                    background: nth($palette, $i);
                    color: #fff;
                    border-color: nth($palette, $i);
                }
            }

            .react-calendar__tile {
                &.react-calendar__tile--active {
                    background-color: nth($palette, $i);
                }
            }
        }

        .timing-wrapper {
            .timing-slider .slick-arrow {
                background-color: nth($palette, $i);
            }

            .btn-wrapper .btn {
                background-color: nth($palette, $i);
                border-color: nth($palette, $i);

                &:focus,
                &:hover {
                    background-color: transparent;
                    color: nth($palette, $i);
                }
            }
        }

        .appointment-fields-wrapper .btn-wrapper .btn {
            background-color: nth($palette, $i);
            border-color: nth($palette, $i);

            &:focus,
            &:hover {
                background-color: transparent;
                color: nth($palette, $i);
            }
        }

        // Sessions Section
        .persons-img-list .img-item {

            span.owner-icon,
            span.speaker-icon {
                color: nth($palette, $i);
            }

            span.online-icon {
                background-color: nth($palette, $i);
            }
        }

        // Events Section
        .oppo-share-icon i,
        .opportunity-item .hearticon i,
        .events-place-like .event-heart,
        .event-share {
            color: nth($palette, $i);
        }

        // Profile Section 
        .profile-share-icon span,
        .show-more-link,
        .show-more-link a,
        .profile-share-icon a,
        .social-icons ul li a,
        .events-icon ul li a {
            color: nth($palette, $i);
        }

        // Donation Form 
        .donation-form {
            .numbers-field .numbers span.active {
                background-color: nth($palette, $i);
                border-color: nth($palette, $i);
            }
        }

        .follow-btn {
            .btn {
                background-color: nth($palette, $i);
                border-color: nth($palette, $i);

                i {
                    margin-right: 5px
                }

                &:focus,
                &:hover {
                    background-color: transparent;
                    color: nth($palette, $i);
                }
            }
        }

        .form-check-input:checked {
            background-color: nth($palette, $i);
            border-color: nth($palette, $i);
        }

    }

    .color-list {
        .color-item.palette-#{$i} {
            background-color: nth($palette, $i);
        }
    }

}