.textforerror {
  font-size: 0.875em;
  margin-top: 0.25rem;
  font-size: 0.875em;
}

.disable-button {
  opacity: 0.25;
}

.disabled-Class {
  pointer-events: none;
  color: #dedcd3;
}

.disabled-Class_2 {
  pointer-events: none;
  cursor: default;
  color: gray;
}

.disabled-Class_3 {
  color: #fff;
  cursor: default !important;
  background: #c48e1d !important;
  border-color: #c48e1d !important;
}

.grey-class {
  color: gray !important;
  cursor: pointer !important;
}

.grey-class-btn {
  background-color: gray !important;
  border-color: gray !important;
  cursor: pointer !important;
  color: #fff !important;
  transition: 0.2s ease;
}

.loader-container {
  position: relative;
}

.disabled-class {
  pointer-events: none;
  color: gray;
  background-color: gainsboro;
}

.single-line-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.onboarding-profile .profile-img img {
  width: 95px;
  height: 95px;
}

.auth-form .react-tel-input .country-list {
  overflow-y: auto;
}

.custom-modal-content {
  justify-content: center;
}

.fieldset.payment-checkbox .form-check-input[type='checkbox'] {
  margin-top: 0px !important;
}

.footer-menu-popup li.active a {
  color: #fff;
}

.events-wrapper h2,
.events-wrapper p {
  word-break: break-word;
}

.reply-message .msg-content-right {
  margin-left: 5px;
}

.reply-message .msg-smartchat-contnt-right{margin-left: 0px;}

.gallery-imgs .gallery-item {
  height: 100%;
}

.gallery-imgs img {
  min-height: 100%;
  object-fit: cover;
}

.get-paid-text .no-results-link:hover {
  color: #c48e1d;
}

.branding-option .form-switch,
.branding-option .form-check-input,
.branding-option .form-check-label {
  cursor: pointer;
}

.deposit-form-row.row.mx-0 {
  gap: 16px;
}

.deposit-form-row .fieldset {
  flex: 1;
  padding: 0;
  position: relative;
}

.location-list-wrapper ul li span {
  justify-content: space-between;
  align-items: baseline;
  padding: 0 0px;
}

.location-list-wrapper ul li span i {
  position: relative;
  top: 1.5px;
}

.reply-msg .users-imgs.as-link.d-flex > img {
  width: 25px;
  height: 25px;
  border: 2px solid #ededed;
  border-radius: 50%;
  margin: 0 0 0 -10px;
  background-color: #ededed;
  cursor: pointer;
}

.reply-msg .reaction-count {
  margin-left: 5px;
}

.qr-link {
  word-break: break-word;
  text-align: center;
}

.opportunity-item .hearticon,
.opportunity-item .address.as-link {
  cursor: default !important;
  /* pointer-events: none; */
}

.events-img-slider .slick-dots li {
  z-index: 9;
}

.events-img-slider .slick-dots li,
.events-img-slider .slick-dots li button {
  width: 10px;
  height: 10px;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.msg-usr-img,
.msg-usr-img img {
  cursor: pointer;
}

.frequency-box .form-check input {
  margin-top: 1px;
}

.funds-frequency input,
.funds-frequency label {
  cursor: pointer;
}

.message-user-item .user-content {
  min-width: 45%;
  max-width: 100%;
  padding-right: 20px;
  flex: 1;
}
.payment-user-item .user-content {
  min-width: 45%;
  max-width: 100%;
  padding-right: 20px;
  flex: 1;
  margin-top: 5px;
}

.payment-search-wrapper {
  /* margin: 20px 0 20px; */
  display: flex;
  flex-direction: column;
}

.payment-search-wrapper .search-input {
  flex: 1;
  margin: 0 10px 0 0;
}

.payment-search-wrapper > div {
  height: 100%;
  flex: 1;
}
.onboarding-questions-wrapper label {
  color: #464646;
}

.onboarding-questions-wrapper .checkbox-list li.active {
  color: #c48e1d;
}

.no-link .message-other-users .users-imgs img,
.no-link {
  cursor: default !important;
}

.referral-cards .persons-name-list li {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.single-line-text-left {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
  text-align: left;
}

.opportunity-item.card .oppo-address span {
  flex: auto;
}

.opportunity-item.card .oppo-address .address {
  margin: 5px 15px 0 0;
}

.opportunity-item.card .oppo-address .address i {
  font-size: 13px;
  margin-right: 1px;
}

.session-heading .users-text {
  opacity: 0.41;
}

.messages-wrapper .reply-wrapper .close {
  top: 12px;
}

.messages-wrapper .message-form.message-smartchat-form .reply-wrapper .close{
  top: 5px;
  right: 5px!important;
}

.create-ticket-form .search-tabs-wrapper p {
  font-size: 15px;
  color: #4d4d4d;
  margin: 0 0 8px;
}

ul.custom-suggestions {
  background: #fff;
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
  max-height: 200px;
  overflow-y: auto;
}

ul.custom-suggestions li {
  list-style: none;
  padding: 6px 15px;
  transition: 200ms ease;
  line-height: 1.5;
}

ul.custom-suggestions li:hover {
  background-color: #52bd41;
  color:#fff;
}

ul.custom-suggestions::-webkit-scrollbar {
  display: none;
}

.gallery-imgs .gallery-item div {
  height: 100%;
}

.location-field.form-control.snap_location  .css-1g6gooi{
  border-radius: 8px;
  width: 100%;
  position: relative;
  /* left: -5px; */
}

.location-field.form-control.snap_location .css-2613qy-menu{background:green!important;}
.location-field.form-control.schedule_location .css-2613qy-menu{
  /* max-height: 100px!important;
  overflow-y: auto!important; */
}

.filter-search-location-container .css-2613qy-menu{
  width: 100%;
  max-width: 100%;
}

.location-field.form-control.snap_location > div > div{
  border-radius: 8px;
  /* position: relative; */
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.ticket-dates-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100% !important;
}

.custom-scrollbar {
  height: 100%;
  overflow-y: auto;
}

.ticket-dates-wrapper::-webkit-scrollbar,
.custom-scrollbar::-webkit-scrollbar,
.custom-new-vertical-scroll::-webkit-scrollbar,
body::-webkit-scrollbar {
  background: transparent;
  width: 6px;
  position: absolute;
  z-index: 99;
}

.ticket-dates-wrapper::-webkit-scrollbar-thumb,
.custom-scrollbar::-webkit-scrollbar-thumb,
.custom-new-vertical-scroll::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 99;
  position: absolute;
}

.checkbox-list .sub-oppo-type-list {
  margin: 0;
}

.checkbox-list .sub-oppo-type-list span {
  margin: 15px 0 0 30px;
  display: inline-block;
}

.sidebar-navigation .btn.btn-dark {
  background: #c48e1d !important;
}

input[type='date']::-webkit-calendar-picker-indicator,
input[type='datetime-local']::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

input[type='time']::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

input[type='date']::-moz-calendar-picker-indicator,
input[type='datetime-local']::-moz-calendar-picker-indicator,
input[type='time']::-moz-calendar-picker-indicator,
input[type='time']::-moz-calendar-picker-indicator {
  cursor: pointer;
}

.custom-snapshot-user-scrollbar.custom-scrollbar::-webkit-scrollbar-thumb{
  background: transparent;
}
.custom-snapshot-user-scrollbar.custom-scrollbar::-webkit-scrollbar{width: 0px;}
.custom-snapshot-user-scrollbar.custom-scrollbar{
  padding-right: 0px;
}
.custom-snapshot-user-scrollbar.custom-scrollbar >.notify-actions{
  padding-right: 0px;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  margin: 10px 0;
  cursor: pointer !important;
}

.css-yk16xz-control:focus {
  border-color: hsl(0, 0%, 80%);
}

.css-1pahdxg-control {
  box-shadow: none !important;
}

.css-1pahdxg-control:hover {
  border-color: hsl(0, 0%, 80%);
}

.events-icons-wrapper.snapshot-icons {
  position: relative;
}

.events-icons-wrapper.snapshot-icons .right-icons span {
  /* margin: 0 0 0px 0px;
    position: absolute;
    right: 0;
    top: 0; */
}

.css-yk16xz-control,
.css-1pahdxg-control {
  margin: 0;
}

/* react-tel-input dropdown */
.react-tel-input .country-list {
  margin-top: 0 !important;
  width: 100% !important;
}

.react-tel-input .selected-flag .flag {
  margin-top: -25px !important;
  margin-left: 5px;
}

.react-tel-input .flag-dropdown {
  position: relative !important;
  z-index: 5;
}

.visits-list .icon-circles:before {
  color: #a39f8d;
}

.fix-width-forms.builder-form {
  min-height: 100%;
}

.fix-width-forms.builder-form > div:nth-child(2) {
  height: calc(100% - 200px) !important;
}

.skip-btn.close.as-link {
  margin-right: 0;
}

.visits-list ul li {
  margin-bottom: 12.5px !important;
}

.visits-list ul li span {
  display: flex;
  align-items: flex-end;
}

.visits-list ul li i {
  margin-right: 12px !important;
}

.onboarding-questions-wrapper .previous-steps .d-flex .as-link {
  font-size: 14px;
  margin-bottom: 2.5px;
}

.reply-wrapper.user-name-list {
  padding: 0 !important;
  overflow: hidden;
}

.history-chat .emoji-icons-wrapper .emoji-icons-item,
.history-chat .as-link,
.messages-header-icons.disable-button span {
  cursor: default;
}

.profile-content-infos .profile-address .icon-aroplane {
  font-size: 24px !important;
  margin-right: 1px !important;
  margin-right: -1px!important;
}

.filter-wrapper .btn:focus,
.filter-wrapper .btn:hover {
  background-color: #52bd41 !important;
  color: white !important;
}

.deposit-form-row .fieldset select {
  -webkit-appearance: none;
}

.deposit-form-row .fieldset:after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: var(--lightgray);
  position: absolute;
  top: 68%;
  right: 14px;
  transform: translateY(-50%);
  line-height: 1em;
  font-size: 13px;
}

.ticket-qty-wrapper {
  position: relative;
}

.ticket-qty-wrapper select.form-control {
  -webkit-appearance: none;
  padding-right: 24px;
  background: transparent;
  cursor: pointer;
}

.ticket-qty-wrapper:after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #272727;
  position: absolute;
  top: 52%;
  right: 9px;
  transform: translateY(-50%);
  line-height: 1em;
  font-size: 13px;
  z-index: -9;
}

.availability-tabs.as-link {
  cursor: default;
}

.appointment-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.appointment-form .fieldset select {
  -webkit-appearance: none;
}

.appointment-form .fieldset .row .col-md-6 {
  position: relative;
}

.appointment-form .fieldset .row .col-md-6:after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: var(--lightgray);
  position: absolute;
  top: 50%;
  right: 28px;
  transform: translateY(-50%);
  line-height: 1em;
  font-size: 13px;
}

.claim-ticket {
  margin-bottom: 16px;
  gap: 8px;
}

.claim-ticket .btn.btn-dark {
  white-space: nowrap;
}

.check-time {
  white-space: nowrap;
  justify-content: flex-end !important;
  text-align: end;
  color: #c48e1d;
  cursor: pointer;
  margin-right: 4px !important;
}

.settign-integ-btns button {
  white-space: nowrap;
}

button.btn.show-more:hover,
button.btn.show-more:focus {
  background-color: transparent !important;
  color: var(--primary) !important;
}

.sessions-content-wrapper.appointment {
  width: 100%;
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #faf8f8!important;
  border: 1px solid #dee2e6 !important;;
}

.panel-primary .sliding-panel-content,
.panel-secondary .sliding-panel-content {
  /* margin-top: 30px !important; */
  /* comment For Analytics Slider */
}

.panel-primary .sliding-panel-content .sliding-panel-content,
.panel-secondary .sliding-panel-content .sliding-panel-content {
  margin-top: 0 !important;
}

/* New appointment cards styling */
.refferals-list-item.new-refferal-list {
  justify-content: space-between;
}

.refferals-list-item.new-refferal-list .refferal-img {
  margin-right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.new-refferal-list .refferal-info-que {
  margin: 14px 0 0;
  font-weight: 600;
}

.new-refferal-list .refferal-content .refferal-date,
.new-refferal-list .refferal-content .refferal-info-que {
  color: #464646;
  padding-top: 11px;
}

.new-refferal-list .refferal-content span.refferal-auther {
  color: #969696;
  margin-bottom: 3px;
}

.new-refferal-list .refferal-content h2 {
  margin-bottom: 2px;
}

.new-refferal-list .refferal-img img {
  height: 40px;
  min-width: auto;
  width: 40px;
}

.close.history-link {
  color: #969696;
  font-size: 15px;
}

.close.history-link.active {
  color: #c48e1d;
}

.appointment .skillset-desired-bg-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.appointment .skillset-desired-bg-wrapper .d-block b {
  display: block;
  margin-bottom: 5px;
}

.refferal-like-unlike {
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
}

.refferal-like-unlike .refferal-icons i {
  color: #969696;
}

.refferal-like-unlike .refferal-icons i.active {
  color: var(--gold);
}

.new-refferal-list .refferal-like-unlike {
  margin: 10px 0 0;
}

.new-refferal-list.to-me .refferal-info-que.text-right {
  display: inline;
  font-weight: normal;
  font-size: 12px;
}

.persons-img-gallery {
  display: flex;
  height: 73px;
}

.appointment-call {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.appointment-call .persons-img-list{
  margin: 0;
  display: flex;
}
.appointment-call .persons-img-list .img-item{
  margin: 0;
}


.permission-modal-content .btn {
  margin-right: 0 !important;
}

.address.as-link.mt-3.d-block {
  margin-top: 12px !important;
}

.filter-wrapper .checkbox-list li {
  cursor: default;
}

.filter-wrapper .checkbox-list li[class] {
  cursor: pointer;
}

.custom-modal-content .select-wrapper select {
  width: 100%;
  height: auto;
  outline: none;
  padding: 10px 15px;
  border-radius: 25px;
  font-family: var(--ave);
  font-size: 14px;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid var(--borders);
  color: var(--dark);
  font-weight: 400;
}

.custom-modal-content .form-label {
  text-align: left;
}

.custom-modal-content .select-wrapper {
  margin-bottom: 16px;
}

.card.profile-block-wrapper .slick-slider .slick-dots {
  bottom: 0 !important;
  position: relative;
  margin-bottom: 12px;
}

.event-btn-wrapper.permission-modal-btns {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.new-session-btn-wrapper .copy-link-text {
  display: flex;
  align-items: flex-end;
}

.sidebar-heading-wrapper h2 {
  word-break: break-word !important;
}

div.quoted-msg div.msg-container div.msg-content-text > p {
  overflow: hidden;
}

.events-place-like .address i {
  font-size: 16px;
  margin: 0 4px 0 0;
}

.rmdp-left i,
.rmdp-right i {
  margin-top: 6.5px;
}

.rmdp-right i {
  margin-right: 2px;
}

.rmdp-left i {
  margin-left: 2px;
}

.sliding-panel-wrapper .close {
  right: 15px !important;
}

.session-profile-wrapper .custom-tabs ul li .btn {
  padding: 12px 18px !important;
}

.settign-integ-btns {
  margin-bottom: 14px;
}

/* .stage-list-item .stage-person-img {
  width: auto;
} */

.snapshot-preview-wrapper img {
  width: 100%;
}

.injection-icon-wrapper i.icon-injection {
  cursor: default;
}

.event-btn-wrapper p {
  cursor: default;
}

.session-videos-slider.verticle-slider {
  margin: 15px 0;
}

.disable-button {
  cursor: default !important;
}

.invite-persons-wrapper .headings-wrapper {
  display: flex;
  justify-content: space-between;
}

.serach-filter-wrapper.create-tickets-wrapper
  .invite-persons-heading:not(.headings-wrapper .invite-persons-heading) {
  /* margin-top: 16px; */
}

.messages-wrapper .sidebar-heading-wrapper.with-panel {
  margin-right: 20px;
  margin-bottom: 10px !important;
}

.replies-user-wrapper .message-user-item .user-content p {
  margin-right: 15px;
}

.reply-box-wrapper div::-webkit-scrollbar {
  display: none;
}

.invite-page-wrapper {
  width: 100%;
}

.events-place-like .address.as-link {
  cursor: pointer;
}

.sidebar-heading-wrapper {
  margin-top: 3px !important;
  margin-bottom: 16px ;
}

.sidebar-heading-wrapper.sidebar-event-detail-holder{margin-top: 0px!important;}

.appointment-types-wrapper .appointment-payment .as-link,
.appointment-types-wrapper .appointment-payment {
  color: var(--grey);
}

.reply-box-wrapper > div > div:first-child {
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}

.text-right.vertical-ellipsis-icon {
  position: relative;
  top: 10px;
}

.all-snapshot-detail-users.text-right.vertical-ellipsis-icon{top: 5px;}

.sidebar-heading-wrapper .user-profile-img img {
  margin-right: 0;
}

.auth-form .btn {
  text-transform: capitalize !important;
}

.reply-box-wrapper {
  margin-top: 12px;
}

.persons-img-gallery .slick-slider {
  width: 100%;
}

.persons-img-gallery .slick-slider .slick-dots {
  bottom: 0px;
}

.persons-img-gallery .slick-slider .slick-dots li {
  width: 12px;
  height: 12px;
}

.persons-img-gallery .slick-slider .slick-dots li button {
  width: 10px;
  height: 10px;
}

.reply-modal-video {
  height: 100%;
  object-fit: contain;
}

.heading.fix-width-forms h2,
.profile-content-infos h2 {
  font-size: 18px;
}

.snapshot-slider .slick-list {
  height: auto !important;
}

.snapshot-slider .slick-list .slick-slide {
  height: 0;
}

.snapshot-slider .slick-slide.slick-current {
  /* for chat full page slider snapshots */
  height: 100% !important;
}

.snapshot-slider .slick-list .slick-active.slick-current {
  height: 100%;
}

.snapshot-slider .slick-dots {
  max-width: 550px;
}

.persons-img-gallery .slick-track {
  margin-left: 0;
}

.persons-img-gallery .slick-track .slick-dots {
  margin-left: 0;
}

.images-list.large-size.mt-3.event-detail-img-list {
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.images-list.large-size.mt-3 .images-list-item {
  margin: 0px 6px 6px 0px;
  cursor: pointer;
}

.images-list.large-size.mt-3 .images-list-item .camera-icon {
  margin: 0px;
}

.images-list.large-size.mt-3 .events-desc-wrapper {
  margin: 0 0 6px !important;
}

.preferences-forms .spinner {
  max-width: 550px;
  margin: auto;
}

.sidebar-heading-wrapper.with-panel {
  margin-right: 0 !important;
}

.custom-modal_mine {
  position: absolute;
  top: 8%;
  left: 0;
  background: rgba(237, 237, 237, 0.9);
  height: 100%;
  width: 100%;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  z-index: 99;
}

.frequency-box .form-check-input[type='checkbox'] ~ .form-check-label {
  margin-top: 0px;
}

.qr-img,
.qr-link {
  padding: 0 25px 0 10px;
}

.persons-img-gallery .slick-slider .slick-dots {
  position: relative;
  bottom: 20px;
}

.loader-container.profile-details .search-tabs-icons {
  width: auto;
  padding-right: 15px;
}

.loader-container.profile-details .card {
  overflow: hidden;
}

.praise-list-wrapper .praise-user-info {
  align-items: flex-start;
}

.filter-category-list .checkbox-list h2 {
  font-size: 15px;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  /* background-color: #c58f4f !important; */
  background-color: #429734 !important;
  color: #fff !important;
}

.rmdp-day.rmdp-today span {
  background-color: var(--gold) !important;
  color: #fff;
}

.rmdp-day.rmdp-today span:hover {
  color: #fff !important;
}

.rmdp-day-picker
  .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden)
  span:hover {
  background-color: #d6d3c7;
  color: #272727;
}

.rmdp-day-picker .rmdp-week-day {
  color: #272727;
}

.rmdp-calendar .rmdp-arrow {
  border-color: #272727 !important;
}

.rmdp-calendar .rmdp-arrow-container:hover {
  background-color: #272727 !important;
}

.rmdp-calendar .rmdp-arrow-container:hover .rmdp-arrow {
  border-color: #fff !important;
}

.bottom-icons .fa-sharp.fa-solid.fa-book,
.bottom-icons .icon-tickets {
  color: #a39f8d;
}

.fix-width-forms .intrested-for-wrapper {
  position: relative;
}

.refferal-content.as-link .single-line-text {
  font-size: 16px;
}

.close.as-link.as-link.notes-icon {
  right: 0 !important;
  font-size: 20px;
  top: 22px;
}

.img-item.as-link.mr-0 {
  margin-right: 0 !important;
}

/* Css for custom tabs */
.custom-tabs.event-tabs.dark-tabs {
  white-space: nowrap;
}

/* .custom-tabs.dark-tabs .activeHandle {
  background-color: transparent;
} */
/* .session-profile-wrapper .custom-tabs ul li .active.btn,
.custom-tabs ul li .active.btn {
  background: #272727 !important;
  border-radius: 50px;
} */
.dark-tabs.event-tabs .btn {
  border-radius: 50px;
}

.stage-likes-icons i {
  position: relative;
  top: 4px;
}

.refferals-list-item {
  align-items: flex-start;
}

.messages-profile-heading.with-panel {
  padding-right: 0px !important;
}

.leave-btn-wrapper .users-icons {
  cursor: default !important;
}

.profile-name-headings.with-panel {
  padding-right: 0 !important;
  padding-top: 13px;
}

.sidebar-heading-wrapper .heading .backbtn {
  width: max-content;
}

.calender-wrapepr {
  text-align: center;
}

.form-control[readonly] {
  background-color: #fff !important;
}

.session-item .session-heading .users-text i {
  margin-bottom: 8px;
}

.refferal-content {
  flex: 1;
}

input#custom-switch {
  cursor: pointer;
}

.stage-header .video-icon i {
  margin-left: 0 !important;
}

.tooltip.bs-tooltip-top {
  margin-bottom: 4px !important;
}

.messages-users-list .create-ticket-form .info-text {
  margin-bottom: 0;
}

.refferal-right-side-links .invited-by-wrapper .user-img {
  margin-right: 0;
}

.refferal-right-side-links .invited-by-wrapper {
  margin-top: 0;
}

.refferal-bottom-right-links .bottom-icons span {
  align-items: flex-end;
}

.bottom-icons .fas.fa-microphone {
  color: #a39f8d;
}

.publicLink:hover {
  color: #68b245;
}

.custom-modal-content .filter-wrapper {
  padding-right: 2px;
}

.custom-modal-content .filter-btn-wrapper {
  padding-top: 16px;
}
.custom-modal-content .filter-wrapper {
  scrollbar-width: thin; /* Options: auto, thin, none */
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* thumb-color background-color */
}
.custom-modal-content .filter-wrapper::-webkit-scrollbar {
  background: transparent;
  width: 6px;
  position: absolute;
  z-index: 99;
}

.custom-modal-content .filter-wrapper::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  z-index: 99;
  position: absolute;
}

.tooltip {
  position: fixed !important;
  width: auto;
  overflow: hidden;
}

.duration-cost .icons-wrapper .icon {
  cursor: default !important;
}

.persons-img-list .img-item span.owner-icon {
  right: auto;
  left: -2px;
}

.no-results-content.pending-payment-results {
  max-width: initial;
}

.no-results-content.pending-payment-results .message-user-item .user-content {
  min-width: auto;
  width: auto;
  text-align: left;
}

.no-results-content.pending-payment-results
  .message-user-item
  .user-content
  h2 {
  margin: 0 0 5px 0;
}

.user-content p {
  margin-bottom: 5px !important;
}

.replies-user-wrapper .user-content h2 {
  margin-bottom: 0;
}

.replies-user-wrapper .user-content p {
  margin-bottom: 2px !important;
}

.category-btns-wrapper-slider .slick-prev {
  left: -16px;
}

.category-btns-wrapper-slider .slick-next {
  right: -16px;
}

.loader-container.profile-details
  .sliding-panel-wrapper
  .close:not(.custom-modal .close) {
  position: relative;
  top: 0;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 5px;
  background: transparent;
  right: 0 !important;
}

.loader-container.profile-details .sliding-panel-wrapper {
  padding-top: 20px;
}

.loader-container.profile-details
  .d-flex.align-items-center.justify-content-between
  .search-tabs-icons {
  max-width: 100%;
}

.auth-form .form-group .info-text {
  word-break: break-word !important;
}

.react-scrollbar > div:first-child {
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  overflow: auto !important;
  scrollbar-width: none;
  /* Hide the scrollbar track */
  -ms-overflow-style: none;
  /* Hide the scrollbar track in IE/Edge */
}

.react-scrollbar > div:first-child::-webkit-scrollbar {
  -webkit-appearance: none;
  -moz-appearance: none !important;
  appearance: none;
}

.sliding-panel-content .react-scrollbar .filter-wrapper {
  /* padding-right: 0; */
  overflow-x: hidden;
}

.events-icon ul li .icon-applaud {
  position: relative;
  top: 1px;
}

.opportunity-item.card .oppo-address .video-details {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.oppo-address .persons-img-list,
.oppo-address .persons-img-list .img-item {
  margin-bottom: 0;
}

.sidebar-menu-wrapper:not(.open) .sidebar-navigation {
  margin-top: 0;
}

.sidebar-menu-wrapper.open .bars-icon .fa-angle-right {
  transform: rotate(180deg);
}

.custom-scroll-content.appointment-page-content {
  padding: 25px 15px 25px 0px;
}

.custom-scroll-content.appointment-page-content .text-right.d-flex {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.sidebar-menu-wrapper {
  z-index: 9999;
}

.calender-wrapper,
.calender-wrapepr {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calender-wrapper > div:first-child {
  /* width: max-content; */
}

/* //--------------Rehman's Css Below---------------------// */

.maxWidth-maxContent {
  max-width: max-content !important;
}

.opportunity-item.card.as-link.change {
  position: relative;
}

.opportunity-item.card.as-link.change .oppo-address {
  width: 92%;
}

.opportunity-item.card.as-link.change .oppo-address .address,
.opportunity-item.card.as-link.change .oppo-address .injeaction-text {
  max-width: 50% !important;
}

.opportunity-item.card.as-link.change .hearticon {
  position: absolute;
  bottom: 22px;
  right: 20px;
}

.appointment-form .fieldset .row .CvvColumn.col-md-6:after {
  display: none !important;
}

.refferals-list.notification-listing
  .refferals-list-item.card
  .refferal-like-unlike
  .refferal-icons {
  height: inherit !important;
  width: inherit !important;
  margin-left: 15px;
}

.forwidth {
  width: 76%;
}

.profile-img.upload-user-img.removeCursor {
  cursor: default !important;
}

.profile-img.upload-user-img.removeCursor
  input[type='file']::-webkit-file-upload-button {
  display: none;
}

/* .profile-img.upload-user-img.removeCursor img,
.profile-img.upload-user-img.removeCursor .icon-camera,
.profile-img.upload-user-img.removeCursor label {
  cursor: default !important;
  z-index: 9 !important;
} */

.profile-img.upload-user-img input[type='file'] {
  z-index: 99999;
}

.emoji-list-wrapper {
  width: 100%;
  overflow-x: hidden !important;
}

.emoji-list-wrapper .emoji-item{display: block;}
.emoji-list-wrapper .row div:last-child .emoji-item{margin-bottom: 0px;}
.emoji-list-wrapper .row div:nth-last-child(2) .emoji-item{margin-bottom: 0px;}
.emoji-list-wrapper .row div:nth-child(13) .emoji-item{
  display: flex;
  justify-content: center;
}
.refferals-list-item
  .refferal-content
  .refferal-auther
  .read-more-read-less
  button.btn {
  margin-top: 0 !important;
}

.modal-header {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.btn-link {
  color: var(--primary);
  font-family: 'Inter';
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  margin-bottom: 0 !important;
}

.startTabs_btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.startTabs_btns .btn-link {
  margin-top: 22px;
}

.startTabs_btns .btn-link:hover {
  color: var(--primary);
}

.startTabs .visits-list .modalTitle {
  text-align: center;
  font-family: 'Inter';
  font-size: 18px;
}

.startTabs .visits-list .modalCaution {
  text-align: center;
  font-family: 'Inter';
}

.startTabs .modal-header {
  height: 200px;
}

.startTabs .modal-header img {
  height: 100%;
}

.startTabs button.btn-dark {
  font-family: 'Inter';
}

.sessions-counter {
  background: var(--primary);
  padding: 10px 20px;
}

.sessions-counter p {
  color: white;
  font-family: 'Hevetica';
}

.form-switch.switchBlock {
  padding: 0px;
}

.form-switch.switchBlock input {
  height: 18px;
}

.publicLink {
  text-decoration: none;
  list-style: none;
  color: black;
  /* font-family: 'HelveticaBold'; */
  /* text-align: center; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-weight: 500;
  font-size: 13px;
  text-decoration: underline;
}

/* .userType .dropdown:before {
    content: "";
    position: absolute;
    top: -6px;
    left: 20px;
    width: 0;
    height: 0;
    box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.05);
    border-top: 6px solid #FFFFFF;
    border-right: 6px solid #FFFFFF;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    transform: rotate(-45deg);
    mix-blend-mode: multiple;
  } */
.no-results-wrapper.no-appointments .no-results-content .noRecord-title {
  /* font-family: 'HelveticaBold'; */
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}



.no-results-wrapper.no-appointments .no-results-content .btn-wrapper button {
  /* font-family: 'HelveticaBold'; */
  cursor: pointer;
}

.no-results-wrapper .btn-wrapper button.btn-dark {
  font-family: 'Inter';
}

.NotifyMe {
  position: relative;
}

.NotifyMe .notifyCount {
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: var(--green);
  color: #fff;
  font-size: 12px;
  font-family: 'HelveticaNeue';
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -5px;
}

.conferenceSession .stage-header .stage-header-icon {
  margin-left: 18px !important;
}

.otherProfileView .sliding-panel-wrapper {
  /* padding-right: 0px !important; */
}

.otherProfileView
  .sliding-panel-wrapper
  .sliding-panel-content
  .fix-width-forms {
  padding-right: 15px !important;
}

.sessionRolls-icon {
  position: relative;
  display: flex;
  justify-content: center;
}

.sessionRolls-icon .dropdown-content {
  position: absolute;
  top: 100%;
  background: #fff;
  width: 165px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: 0.3s ease;
  z-index: 99999999 !important;
  padding: 15px;
  margin-top: 15px;
}

.sessionRolls-icon .dropdown-content:before {
  content: '';
  position: absolute;
  top: -6px;
  left: 76px;
  width: 0;
  height: 0;
  box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.05);
  border-top: 6px solid #ffffff;
  border-right: 6px solid #ffffff;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
  transform: rotate(-45deg);
  mix-blend-mode: multiple;
}

.sessionRolls-icon .dropdown-content .listItems {
  margin: 0px 0px 8px !important;
  display: flex;
  align-items: center;
}

.sessionRolls-icon .dropdown-content .listItems img {
  width: 20px;
}

.sessionRolls-icon .dropdown-content :last-child {
  margin-bottom: 0px !important;
}

.sessionRolls-icon .dropdown-content .listItems p {
  font-size: 13px;
  font-family: 'Helvetica';
  margin: 0 !important;
  padding-left: 10px;
}

.otherProfileView .modal-header-icons span {
  margin-right: 15px !important;
}

.sessionRolls-icon.leftPosition .dropdown-content {
  left: 0 !important;
}

.sessionRolls-icon.leftPosition .dropdown-content::before {
  left: 14px !important;
}

.sessionRolls-icon.leftPosition.changeRolls-sheet .dropdown-content {
  left: -18px !important;
}

.otherProfileView .modal-header-icons span.action-icon.disableIcon i {
  color: #a0a0a0 !important;
}

.onboarding-profile.create-profile .info-text p {
  word-break: break-word;
}

.dont-forget-events {
  background-color: transparent !important;
}

.dont-forget-events .icons-wrapper span {
  text-decoration: none !important;
  color: var(--gold);
}

.users-text.usercount-type {
  display: flex;
  align-items: center;
  opacity: 1 !important;
  font-family: 'inter';
}

.users-text.usercount-type img {
  margin-right: 6px;
}

.users-text.usercount-type span.userCount {
  padding-right: 6px;
}

.no-results-wrapper.payment-no-results .img-bg-wrapper {
  max-width: 110px !important;
  margin: 15px auto 20px !important;
}

.no-results-wrapper.payment-no-results .no-results-content h2,
.no-results-wrapper.payment-no-results .no-results-content p {
  margin-bottom: 6px !important;
}

.cardIcon {
  margin-left: 10px;
}

.createCircle.search-tabs-icons .icon {
  margin-left: 0px !important;
}

.cursor-default {
  cursor: default !important;
}

.events-mini-images {
  margin-left: 8px !important;
}

.actionCount {
  height: 25px;
  width: 25px;
  min-width: 25px !important;
  border-radius: 50px;
  background: var(--grey);
  color: #fff;
  font-size: 12px;
  font-family: 'inter';
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

/* .no-record-notification {
  padding-top: 80px;
} */

.action-listing {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  border: 1px solid #eaeaea;
}

.action-detail-refferal-listing{
    padding-left: 0.5rem;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: 5px;
}

h2.actions-title {
  color: #272727;
  font-size: 15px;
  font-family: 'inter';
}

.action-listing .session-cam p.p-reg {
  padding-left: 6px;
}

.action-listing .session-cam p.p-reg.p-txt-area{padding-left: 0px;}

.redDot {
  height: 7px;
  width: 7px;
  background-color: var(--red);
  border-radius: 5px;
  margin-left: 4px;
}

.actionsCategories {
  color: var(--grey);
}

.LabelTabs {
  display: flex;
  align-items: center;
}

.mini-image {
  height: 37px;
  width: 37px;
  border-radius: 50px;
  overflow: hidden;
  object-fit: cover;
}

.p-bold {
  /* font-family: 'HelveticaBold'; */
  font-size: 14px;
  margin: 0;
}

.card-container {
  border-radius: 25px;
  background: #fff;
  border: 1px solid #eaeaea;
  margin: 0 0 15px;
  padding: 20px 0px;
}

.page-snapshots-container{margin:0 15px 10px;}

.padd-with-borderContaier {
  padding: 0px 20px 10px;
  border-bottom: 1px solid #e5e5e5;
}

.p20-horizental {
  padding: 10px 20px 0px;
}
.msg-card-date-holder{padding-right: 5px;}
.msg-date-refer-notes-icon i{
  color:var(--gold);
}
.preview-page p {
  padding-right: 10px;
}

img.ScanQr {
  margin-right: 20px;
}

.show-on-public p {
  padding-right: 45px !important;
}

.padd-with-borderContaier .tab-select select {
  text-align: left !important;
}

img.miniSnapShot {
  height: 36px;
  width: 36px;
  border-radius: 4px;
  overflow: hidden;
  object-fit: cover;
  background-color: #ebebeb;
}

.page-snapshots p {
  padding-left: 10px;
}

p.preview-message {
  color: var(--gold);
}

.profileNotesSheet .followUp {
  display: flex;
  align-items: center;
  font-size: 20px !important;
}

.profileNotesSheet .followUp p {
  padding-left: 10px;
 
}

.profileNotesSheet .category-btns-wrapper-slider .btn {
  font-family: 'Helvetica' !important;
}

.profileNotesSheet .card-container .msg-date {
  color: var(--lightgray) !important;
}

.profileNotesSheet .card-container .padd-with-borderContaier p.chatMsg {
  padding-left: 0px !important;
}

.profileNotesSheet .message-form {
  justify-content: space-between !important;
}

.profileNotesSheet .message-form.message-ellipse-form-holder{width: 95%;}
.profileNotesSheet .message-form.message-ellipse-form-holder .message-form .form-icon.send-btn{margin: 0px;}

.profileNotesSheet .message-form .form-input {
  width: 80% !important;
  max-width: 80%;
}

.profileNotesSheet .message-form .form-input-text {
  width: 100% !important;
  max-width: 100%;
}

.profileNotesSheet .message-form textarea.form-control {
  max-height: 130px !important;
  border-radius: 9px !important;
  resize: none;
}

.profileNotesSheet .message-form .form-icon.send-btn {
  margin: 0x 0px 0px 0px !important;
}

.page-wrapper.menu-open .page-content-wrapper.MessagesNoteSheet {
  padding-left: 0px !important;
  height: 100% !important;
}

.page-content-wrapper.profileNotesSheet.MessagesNoteSheet.MessagesNewNoteSheet{margin: 0 20px;}

.MessagesNoteSheet .serach-filter-wrapper {
  width: 100% !important;
  max-width: 100% !important;
  height: 100%;
}

.icon.notesIcon {
  height: 18px;
  width: 18px;
}

.leaderShip-button {
  background: var(--grey);
  color: white !important;
  padding: 10px 20px;
  border-radius: 40px;
  position: relative;
  /* cursor: pointer; */
}

.leaderShip-button::before {
  content: '';
  height: 1px;
  width: 200px;
  background: #e2e2e2;
  z-index: -1;
  position: absolute;
  left: 50%;
  top: 50%;
}

.messagesLeadership-block .search-tabs-icons .icon {
  font-size: 24px !important;
  margin-left: 15px !important;
}

.messages-wrapper.fix-width-forms.message-chat-box-holder .messagesLeadership-block .search-tabs-icons .icon{margin-left: 0px!important;}
.messages-wrapper.fix-width-forms.message-chat-box-holder .messagesLeadership-block .search-tabs-icons .icon.actionsIcon{
  margin: 0 10px!important;
}

.messagesLeadership-block .search-tabs-icons .icon.dotsIcon {
  width: inherit;
  height: inherit;
}

.messagesLeadership-block .invite-persons-heading {
  margin: 15px 0px !important;
}
.messages-wrapper.fix-width-forms.message-chat-box-holder .messagesLeadership-block .invite-persons-heading{
  margin-left: 20px!important;
  margin-right: 20px!important;
}
.shareables-items .profile-name h2 {
  font-size: 16px !important;
}

.shareables-items .profile-name .events-icon {
  margin: 8px 0 0 !important;
}

.shareables-items .profile-img-wrapper .profile-img img {
  height: 67px !important;
  width: 67px !important;
}

.shareables-items .profile-content-infos .other-infos {
  margin-bottom: 10px;
}

.snapshotsWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.snapshotsWrap img {
  height: 120px;
  width: 120px;
  border-radius: 21px;
  overflow: hidden;
  object-fit: cover;
}

.gold-color {
  color: var(--gold) !important;
}

.opportunity-details.serach-filter-wrapper
  .leave-btn-wrapper
  .users-icons.as-link {
  cursor: pointer !important;
}

#popover-basic .popover-body {
  background-color: black;
  color: #fff !important;
  border-radius: 8px;
  padding: 6px 10px;
  font-family: 'HelveticaNeue';
}

#popover-basic .popover-arrow::before {
  border-bottom-color: black !important;
}

#popover-basic .popover-arrow::after {
  border-bottom-color: black !important;
}

.new-session-modal-wrapper.createSessionModal {
  max-width: 480px !important;
}

.new-session-modal-wrapper.createSessionModal .new-session-modal {
  text-align: center;
  padding: 0px 50px;
}

.new-session-modal-wrapper.createSessionModal .new-session-modal button.btn {
  /* font-family: 'HelveticaBold'; */
  padding: 10px 30px;
}

.chatSnapshots {
  gap: 5px;
}

.chatSnapshots .snapShotsImg {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.chatSnapshots .snapShotsImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px !important;
}

.snapShotsImg span.overlay {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 9;
  cursor: pointer;
  font-family: 'HelveticaBold';
  color: #fff;
  background-color: var(--gold);
  opacity: 0.8;
  position: absolute;
  left: 0;
  top: 0;
}

.messages-notes-field-form-block .fieldset.TageInput .form-control{
  padding: 5.7px 15px;
}

.TageInput .form-control .rti--container {
  width: auto !important;
  border: none !important;
  padding: 0px;
  outline: none !important;
  text-decoration: none !important;
  display: flex;
  flex-wrap: wrap;
  --rti-main: none !important;
}

.TageInput .form-control .rti--container .rti--input {
  /* max-width: fit-content !important; */
  border: none !important;
  padding: 0;
  outline: none !important;
  text-decoration: none !important;
  background: transparent;
  color:#000;
  padding: 5px 7px;
  border-radius: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}

.TageInput .form-control .rti--container .rti--input:focus,
.TageInput .form-control .rti--container .rti--input:focus:hover{
  background: transparent!important;
}

.TageInput .form-control .rti--container .rti--input::placeholder{
  color:#000;
  background: transparent;
}

.TageInput .form-control .rti--container .rti--input::-ms-input-placeholder{color:#000;}

.TageInput .form-control .rti--container span.rti--tag{
  background: var(--green);
  color: #fff;
}

.TageInput .form-control .rti--container span.rti--tag button:hover{color:#fff;}

.no-records img {
  height: 160px;
}

.no-records button.btn.btn.btn-dark {
  font-family: 'Inter';
}

#googlecomplete .css-26l3qy-menu {
  text-align: left !important;
  justify-content: flex-start !important;
  width: 100% !important;
}

.selercted-user {
  border: 5px solid var(--gold) !important;
}

.create-workspace .images-list .work-snapshots .snapshots-images {
  width: 108px;
  height: 108px;
  border: 1px solid #e6e6e6;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
}

.create-workspace .images-list .work-snapshots .snapshots-images img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.create-workspace .images-list .work-snapshots .snapshots-images.add-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--grey);
  font-size: 22px;
  color: #fff;
}

.for-grey {
  color: var(--grey) !important;
  /* font-weight: 400 !important; */
}

.sliding-panel-content .profile-gallery-wrapper .segmentControls {
  width: 85%;
  margin: auto;
}

.sliding-panel-content .profile-gallery-wrapper .segmentControls div button {
  font-size: 12px;
}

.segmentControls .space-x-1 > :not([hidden]) ~ :not([hidden]) {
  margin-left: 0px !important;
  left: 0;
}

.appointments_payment .user-img {
  height: 64px;
  width: 64px;
  border-radius: 40px;
  overflow: hidden;
  position: relative;
}

.appointments_payment .user-img img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.no-results-content.appointments_pendings {
  max-width: 350px !important;
}

.appointments_payment .user-content h2 {
  font-size: 15px !important;
}

.appointments_payment.message-user-item {
  padding-bottom: 12px !important;
}

.events-time-item.as-link.active.selected {
  background-color: var(--grey);
}

.text-yellow {
  color: var(--gold) !important;
}

.camera-options-list.chat-camera-sheet ul li {
  display: flex;
  align-items: center;
}

li.as-link.private-reply-holder > img{padding-top: 5px;}

.camera-options-list.chat-camera-sheet ul li i {
  margin-right: 0px !important;
}

.camera-options-list.chat-camera-sheet ul li p.p-reg {
  padding-left: 10px;
}

.refferals-list.notification-listing .refferal-content .refferal-info-que {
  margin-top: 0px !important;
}

.refferals-list.notification-listing .refferal-img img {
  width: 64px !important;
  height: 64px !important;
  min-width: auto;
}

.invite-persons-list .row .col-xl-3 .stage-list-item .stage-person-img img,
.persons-imgs-wrapper.search-network .stage-list-item .stage-person-img img {
  margin: auto;
}

.invite-persons-list .row .col-xl-3 .stage-list-item .stage-person-name {
  padding-left: 0px;
  width: 70px;
  margin: 2px auto 0px;
}

.invite-persons-list.system-roles-invite-provider .row .col-xl-3 .stage-list-item .stage-person-name{margin-top: 0px;}

.gallery-imgs.my-snapshots .contact [class^='col-'] {
  margin-bottom: 10px;
}

.gallery-imgs.my-snapshots .gallery-item {
  height: 108px !important;
  min-height: 100% !important;
}

.gallery-imgs.my-snapshots .gallery-item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.conferenceSession
  .stage-list-wrapper
  .row
  .stage-list-item
  .stage-person-img
  img {
  margin: auto !important;
}

.all-pending-users span.infoIcon {
  background: var(--grey);
  padding: 3px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: white;
  font-size: 18px;
  transform: rotate(88deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container.payment-lists .message-user-item .user-img img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 50%;
  border: none !important;
}

.loader-container.payment-lists .message-user-item .user-content p.pricing {
  margin-bottom: 0px !important;
}

.text-gey {
  color: var(--grey) !important;
}

.schedule-screen .addcontact-image,
.create-sessionEvents .addcontact-image {
  height: 48px;
  width: 48px;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
}

.schedule-screen .addcontact-image img,
.create-sessionEvents .addcontact-image img {
  height: 100%;
  width: 100%;
  min-width: 48px !important;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}

.schedule-screen .duration-cost label {
  font-weight: normal;
}

.availability-modal .custom-modal.medium .custom-modal-content {
  max-width: 550px !important;
}

.availability-modal .new-session-modal-wrapper.createSessionModal {
  max-width: 480px !important;
  min-width: 480px;
}

.availability-modal
  .new-session-modal-wrapper.createSessionModal
  .new-session-modal {
  padding: 0px 0px !important;
}

.availability-modal .new-session-modal p.p-reg {
  padding: 0px 100px;
}

.availability-modal .new-session-modal .availability-days {
  max-width: 100% !important;
}

.availability-modal .new-session-modal button.add-time {
  display: block !important;
  margin: auto;
  background:  var(--gold);
  opacity: 1;
}

.availability-modal .new-session-modal button.no-thanks {
  color: var(--gold);
  /* font-family: 'HelveticaBold'; */
  margin-top: 15px;
  background: transparent !important;
  border: none;
  outline: none;
}

.profile-gallery-wrapper {
  /* padding-right: 15px !important; */
  /* for scroll overlap issue */
}

.profile-gallery{
  padding-right: 15px !important;
}

.schedule-users-listing .swiper-slide .discover-builders-wrapper {
  padding: 15px 0px !important;
}

.schedule-users-listing .swiper-slide {
  margin-right: 15px !important;
}

.available-hours .no-results-wrapper .no-results-content button.btn {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #fff !important;
}

.available-hours .btn.selected-slot {
  background-color: #e8dfcd !important;
  color: black !important;
  border-color: #e8dfcd !important;
}

.history-chat
  .messages-list
  .msg-container
  .msg-user-replies-wrapper
  .as-link.reaction-count {
  cursor: pointer !important;
}

.shareables-snapshots .snapshotsImage {
  height: 64px;
  width: 64px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.shareables-snapshots .snapshotsImage img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.shareables-snapshots .snapshots-content {
  margin-left: 15px;
  width: 72%;
}

.shareables-snapshots .snapshots-content h2 {
  font-size: 16px;
  color: #333;
  line-height: 30px;
}

.shareables-snapshots .snapshots-content p.category-name {
  color: var(--grey);
}

.shareablesPreview_Modal .tabbed-modal-wrapper .tab-buttons button.tab-button {
  font-size: 16px;
  font-family: 'HelveticaBold';
  color: var(--grey);
  margin-right: 20px;
}

.shareablesPreview_Modal
  .tabbed-modal-wrapper
  .tab-buttons
  button.tab-button.active {
  color: var(--gold);
}

.shareablesPreview_Modal .tabbed-modal-wrapper .tab-buttons {
  margin-bottom: 10px;
}

.shareablesPreview_Modal
  .tabbed-modal-wrapper
  .tab-content
  .content-wapper
  .shareablesEvent-Image {
  height: 150px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.shareablesPreview_Modal
  .tabbed-modal-wrapper
  .tab-content
  .content-wapper
  .shareablesEvent-Image
  img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.shareablesPreview_Modal
  .tabbed-modal-wrapper
  .tab-content
  .content-wapper
  .shareables-eventContent
  p.date-time {
  color: var(--grey);
}

.shareablesPreview_Modal
  .tabbed-modal-wrapper
  .tab-content
  .content-wapper
  .shareables-eventContent
  p.location {
  color: var(--grey);
}

.shareables-preview-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.share-links-modal .custom-modal-content {
  padding: 10px 25px 0px !important;
}

.social-links-modal.camera-options-list ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: scroll;
}

.social-links-modal.camera-options-list ul li {
  padding: 15px 0px;
  font-size: 16px;
  margin: 0;
  line-height: 1.3em;
  color: #333333;
  border-bottom: none !important;
  list-style: none;
  display: flex;
  align-items: center;
}

.social-links-modal.camera-options-list ul li i {
  font-size: 22px !important;
  margin-right: 0px !important;
}

.social-links-modal.camera-options-list ul li button.react-share__ShareButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  background: #eae6df !important;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin-right: 10px;
  color: white !important;
}

.social-links-modal.camera-options-list ul li:last-child button.react-share__ShareButton{margin-right: 0px;}

.social-links-modal.camera-options-list
  ul
  li
  button.react-share__ShareButton[aria-label='facebook'] {
  color: #1877f2 !important;
}

.social-links-modal.camera-options-list
  ul
  li
  button.react-share__ShareButton[aria-label='whatsapp'] {
  color: #25d366 !important;
}

.social-links-modal.camera-options-list
  ul
  li
  button.react-share__ShareButton[aria-label='email'] {
  color: #1877f2 !important;
}

.social-links-modal.camera-options-list
  ul
  li
  button.react-share__ShareButton[aria-label='twitter'] {
  color: #1da1f2 !important;
}

.social-links-modal.camera-options-list
  ul
  li
  button.react-share__ShareButton[aria-label='linkedin'] {
  color: #0a66c2 !important;
}

.social-links-modal.camera-options-list ul::-webkit-scrollbar {
  height: 8px;
  background-color: transparent !important;
}

.social-links-modal.camera-options-list ul::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  color: #ffffff;
  border-radius: 6px;
}

p.shareable-profile-desc {
  color: #333;
  font-size: 14px;
}

.panel-primary
  .sliding-panel-wrapper
  .sliding-panel-content
  .availability-wrapper
  .availability-days
  span {
  padding: 14px 10px !important;
  width: 14% !important;
  min-width: 14% !important;
  max-width: 14% !important;
}

.panel-primary .availability-modal .new-session-modal .availability-days {
  max-width: 70% !important;
  margin: 25px auto !important;
}

.panel-primary .availability-modal .new-session-modal .availability-days span {
  padding: 14px 10px !important;
  width: 14% !important;
  min-width: 14% !important;
}

.chat-camera-sheet .emoji-list .add-emoji {
  margin-right: 15px !important;
  height: 22px;
  width: 22px;
}

.gallery-imgs.my-snapshots .row [class^='col-'] {
  margin-bottom: 10px !important;
}

.no-results-wrapper.Builders-Appointment-NoRecord .no-results-content h2,
.no-results-wrapper.Builders-GroupChat-NoRecord .no-results-content h2,
.no-results-wrapper.Builders-NoRecord .no-results-content h2 {
  line-height: 20px;
}

.history-events .persons-img-list .img-item {
  margin: 0px 15px 10px 0px !important;
}

.history-events .persons-img-list {
  flex-wrap: wrap;
}

.history-events .truncate_2_lines {
  width: 90%;
}

/* -------------Appointment-Call-Below--------------- */
.RemainingCallTime {
  color: #fff;
  font-family: 'Helvetica';
  background: var(--grey);
  width: max-content;
  padding: 15px;
  border-radius: 8px;
}

.AppointmentCall.session-videos-list {
  position: relative;
}

.AppointmentCall .RemainingCallTime {
  z-index: 2;
  position: absolute;
  left: 30px;
  top: 30px;
}

.AppointmentCall .session-videos-slider .Video-Blocks {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.AppointmentCall .session-videos-slider .Video-Blocks .Participant-Video {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.AppointmentCall .session-videos-slider .Video-Blocks .My-Video {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 2;
}

.AppointmentCall
  .session-videos-slider
  .Video-Blocks
  .Participant-Video
  .video-items {
  position: inherit !important;
  height: 100%;
  width: 100%;
}

.AppointmentCall .session-videos-slider .video-action-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0 25px 0;
  position: absolute;
  z-index: 999;
  right: 30px;
  bottom: 50px;
}

.AppointmentCall .session-videos-slider .video-action-icons .icon-item {
  width: 60px !important;
  height: 60px !important;
}

.panel-secondary.AppointmentCall-Panel {
  padding: 25px 15px 25px !important;
}

/* -------------Opportunity-Call-Below--------------- */
.panel-secondary.OpportunityCall-Panel {
  padding: 25px 15px 25px !important;
}

.OpportunityCall.session-videos-list {
  position: relative;
}

.OpportunityCall.session-videos-list {
  position: relative;
}

.OpportunityCall.session-videos-list .row .col-md-12 {
  height: 100%;
  overflow: hidden;
}

.OpportunityCall .Opportunity-Container {
  height: 100%;
  width: 100%;
  position: absolute;
}

.OpportunityCall .Opportunity-Container .session-video {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.OpportunityCall .Opportunity-Container .session-video .video-item {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  max-height: 100% !important;
}

.OpportunityCall .Opportunity-Container .session-video .video-item video {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover !important;
}

.OpportunityCall .Opportunity-Container .CallActions {
  position: absolute;
  bottom: 0;
  z-index: 2;
  padding: 0px 30px 20px;
}

/* .OpportunityCall .Opportunity-Container .CallActions .session-videos-slider {
  height: 150px;
  margin: 0;
} */

.OpportunityCall
  .Opportunity-Container
  .CallActions
  .session-videos-slider
  .swiper
  .swiper-slide
  .video-items {
  height: 108px;
  width: 108px;
  box-sizing: border-box !important;
}

/* Adjust the width and margin for swiper-slide */
.OpportunityCall
  .Opportunity-Container
  .CallActions
  .session-videos-slider
  .swiper
  .swiper-slide {
  width: 108px !important;
  /* Adjust this width as needed */
  margin-right: 10px !important;
  /* Adjust this margin as needed */
}

.OpportunityCall
  .Opportunity-Container
  .CallActions
  .video-action-icons
  .icon-item {
  width: 50px;
  height: 50px;
}

/*---------------------------------*/

.custom-spinner div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  background-color: transparent !important;
}

.custom-spinner svg {
  height: 40px;
  width: 40px;
}

/*--------------- New Home Pages changes start -------------*/

.loginBtn.mobile-hide a.global-button {
  color: #4e4a49 !important;
  position: relative;
  text-decoration: none;
  font-size: 19px !important;
  font-weight: 600 !important;
  background-color: transparent !important;
  padding: 7px 0px !important;
  margin: 0px 15px !important;
  filter: none !important;
}

.loginBtn.mobile-hide a.global-button::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #4e4a49;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.loginBtn.mobile-hide a.global-button:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.head-section .logo {
  height: 56px;
}

/*--------------- New Home Pages changes end -------------*/

.sidebar-navigation ul li .menu-text a i.icon-circles:before {
  color: inherit !important;
}

/* -------------------- side-menu dropdown changes start ------------------ */
.sidebar-menu-wrapper.open .userType p,
.sidebar-menu-wrapper.open .userType p.single-line-text {
  display: -webkit-box !important;
}

.sidebar-menu-wrapper.open .userType span {
  display: flex !important;
}

.sidebar-menu-wrapper.open .userType p {
  width: 75%;
}

.sidebar-menu-wrapper .userType p,
.sidebar-menu-wrapper .userType p.single-line-text,
.sidebar-menu-wrapper .userType span {
  display: none !important;
}

.sidebar-menu-wrapper .userType {
  justify-content: center;
  background: transparent;
  margin: 0;
  cursor: pointer;
  border: none !important;
  outline: none !important;
  position: relative;
  width: -webkit-fill-available;
  /* applying position to overlap dropdown on navigation items */
}

.sidebar-menu-wrapper .userType .menu-button {
  padding: 20px 20px 10px !important;
}

.sidebar-menu-wrapper.open .userType .menu-button {
  /* background: #403e36; */
  border-radius: 5px;

  border: 1px solid #767676;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px !important;
  cursor: pointer;
  height: 38px;
  color: #fff;

  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sidebar-menu-wrapper.open .userType .menu-button .type {
  width: 82%;
}

/* .sidebar-menu-wrapper.open .userType ul.dropdown:focus-within {
  opacity: 1;
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
  visibility: visible;
} */

.sidebar-menu-wrapper .userType svg {
  width: 20px;
}

.userType p {
  margin: 0;
  color: white;
  font-family: 'Inter';
  font-size: 13px;
  padding-left: 8px;
}

.userType span {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'HelveticaNeue';
  background: #848174;
  border-radius: 50px;
  font-size: 11px;
  line-height: 13px;
}

.sidebar-menu-wrapper.open .userType > .dropdown-wrapper {
  transform: translate(0, 4px);
  opacity: 1;
  visibility: visible;
  height: max-content;
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  /* applying position to overlap dropdown on navigation items */
  z-index: 999999;
  width: 100%;
}

.sidebar-menu-wrapper .userType > .dropdown-wrapper {
  transform: none;
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.userType .dropdown {
  background: #ffffff;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 15px;
  text-align: left;
  transition: 0.3s ease;
  z-index: 99999999 !important;
  padding: 2px 7px 5px 15px;
}

.userType .dropdown::-webkit-scrollbar {
  height: 4px;
  width: 8px;
  background-color: transparent !important;
  position: relative;
  right: 5px;
  top: 5px;
}

.userType .dropdown::-webkit-scrollbar-thumb {
  background-color: var(--borders);
  color: #ffffff;
  border-radius: 6px;
}

.userType ul.dropdown li {
  padding-bottom: 6px;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 14px;
}

.userType ul.dropdown li:last-child {
  margin-bottom: 0px !important;
}

.userType ul.dropdown li p {
  font-family: 'Inter';
  color: black;
  padding-left: 0px !important;
}

.userType .dropdown-wrapper a.publicLink {
  padding: 10px 0px;
}

/* -------------------- side-menu dropdown changes end ------------------ */

.sidebar-menu-wrapper.open .navigation-items {
  margin-top: 15px !important;
}

.list-title-icon .remove-icon.toClose {
  color: var(--gold);
  font-size: 18px !important;
}

.ScheduleScreen_Style .invite-page-search {
  padding-right: 25px;
}

.chatActionSheet ul li.emojies-list {
  border-bottom: none !important;
}

.chatActionSheet ul li i,
.chatActionSheet ul li img {
  height: 25px !important;
  width: 25px !important;
}

.chatActionSheet ul li.shareables-list img {
  margin-right: 10px;
}

.chatActionSheet ul li.snapshot-list-data {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.chatActionSheet ul li.snapshot-list-data .snapshots {
  height: 86px;
  width: 86px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}

.chatActionSheet ul li.snapshot-list-data .snapshots.search {
  background-color: var(--grey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 22px;
}

.chatActionSheet ul li.snapshot-list-data .snapshots.search i {
  margin: 5px 0 0 !important;
}

.chatActionSheet ul li.snapshot-list-data .snapshots img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100% !important;
  width: 100% !important;
  overflow: hidden;
  object-fit: cover;
}

.snapshots-listing .gallery-imgs .row [class^='col-'] {
  margin-bottom: 10px;
  height: 108px;
}

.conferenceSession .stage-list-item .stage-person-img .emoji-icon {
  bottom: 2px !important;
  left: -2px !important;
  top: inherit !important;
}

.conferenceSession .stage-list-item .stage-person-img .emoji-icon img {
  min-width: 100% !important;
}

.new-workspace p {
  font-size: 15px !important;
}

.new-workspace p .count {
  height: 26px;
  width: 26px;
  border-radius: 100%;
  background: var(--grey);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Helvetica';
  color: white;
  font-size: 13px !important;
  margin-left: 10px;
}

.newWorkspace-Modal.custom-modal .close {
  right: 15px !important;
}

.newWorkspace-Modal .custom-modal-content {
  padding: 25px 15px 25px 25px !important;
}

.newWorkspace-Modal .filter-wrapper,
.shareables-workspace-modal .filter-wrapper {
  overflow: hidden !important;
  display: flex;
}

.newWorkspace-Modal .filter-wrapper .filter-category-list,
.shareables-workspace-modal .filter-wrapper .filter-category-list {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.newWorkspace-Modal .filter-wrapper .filter-category-list ul,
.shareables-workspace-modal .filter-wrapper .filter-category-list ul {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
}

.newWorkspace-Modal
  .filter-wrapper
  .filter-category-list
  ul::-webkit-scrollbar {
  background: transparent;
  width: 6px;
  position: absolute;
  z-index: 99;
}

.newWorkspace-Modal .filter-category-list ul::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 99;
  position: absolute;
}

.unSubscribe {
  font-family: 'HelveticaNeueMedium' !important;
  font-size: 15px !important;
}

.custom-dropdown {
  background-color: #fff;
  border-radius: 30px;
  padding: 15px;
}

.custom-dropdown p {
  width: 92%;
}

.single-line-text {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.shareables-workspace-modal.custom-modal {
  z-index: 99999999999 !important;
}

/* .shareables-workspace-modal .close {
  position: absolute;
  right: 15px;
  top: 15px;
} */

.shareables-workspace-modal .custom-modal-content {
  padding: 25px 15px 25px 25px !important;
}

.shareables-workspace-modal .filter-wrapper {
  overflow: hidden !important;
}

.shareables-workspace-modal .filter-wrapper .filter-category-list {
  height: 100%;
}

.shareables-workspace-modal .filter-wrapper .filter-category-list ul {
  height: 82%;
  overflow-y: auto;
  padding-right: 10px;
}

.shareables-workspace-modal
  .filter-wrapper
  .filter-category-list
  ul::-webkit-scrollbar {
  background: transparent;
  width: 6px;
  position: absolute;
  z-index: 99;
}

.shareables-workspace-modal .filter-category-list ul::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 99;
  position: absolute;
}

.shareables-workspace-modal .filter-category-list .btn,
.newWorkspace-Modal .filter-category-list .btn {
  margin: 10px 0px 0px !important;
  max-width: 150px !important;
  line-height: 18px;
}

.ToggleRightArrow {
  margin-right: -90px;
  background: var(--grey);
  color: white !important;
  height: 50px;
  width: 100px;
  font-size: 24px !important;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  transition: 0.4s all 0s ease;
  cursor: pointer !important;
}

.ToggleRightArrow:hover {
  margin-right: -35px !important;
}

.ToggleRightArrow i:nth-child(2),
.ToggleRightArrow i:nth-child(3) {
  display: none;
}

.ToggleRightArrow:hover i:nth-child(2),
.ToggleRightArrow:hover i:nth-child(3) {
  display: block !important;
}

.faq-container .faq-box .accordion .faq-title {
  text-align: left;
  max-width: 95%;
}

.CropImageModal .new-session-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100% !important;
}

.CropImageModal .new-session-modal .ReactCrop {
  flex: 1;
}

.CropImageModal .new-session-modal .ReactCrop .ReactCrop__child-wrapper img {
  height: 100% !important;
}

.CropImageModal .new-session-modal .ReactCrop .ReactCrop__child-wrapper {
  height: 100%;
}

.widgetsContainer .color-pallet {
  flex-direction: column !important;
}

.Settings-Integration .row .add-icon {
  font-size: 20px;
  color: var(--gold);
}

.Settings-Integration .integration-list .card {
  border-radius: 8px !important;
  flex-direction: row !important;
}

.Settings-Integration .row .add-icon.trash-icon {
  color: #272727 !important;
  font-size: 18px !important;
}

.custom-datepicker-field .rmdp-container .rmdp-ep-arrow {
  display: none !important;
}

.custom-datepicker-field .rmdp-container .rmdp-wrapper {
  position: relative !important;
  left: 5px !important;
}

.widget-page-wrapper {
  overflow-y: scroll !important;
  height: 100vh;
}

.widget-page-wrapper::-webkit-scrollbar {
  height: 4px;
  width: 5px;
  background-color: transparent !important;
  position: relative;
  right: 5px;
  top: 5px;
}

.widget-page-wrapper::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  color: #ffffff;
  border-radius: 6px;
}

/* --------  Consult Changes Start  --------- */

.Consult-Page .profile-gallery-wrapper .gallery-imgs .gallery-item {
  height: 108px;
}

.Consult-Page .profile-gallery-wrapper .gallery-imgs .gallery-item img {
  height: 100% !important;
}

/* --------  Consult Changes End  --------- */

.sessions-list-wrapper .discover-builders-wrapper .swiper .swiper-wrapper {
  margin: 0px 40px !important;
}

.sessions-list-wrapper.slider-screen-main .discover-builders-wrapper .swiper .swiper-wrapper{
  margin: 0px!important;
}

.ScheduleScreen .sidebar-heading-wrapper .discover-builders-wrapper,
.sessions-list-wrapper .discover-builders-wrapper {
  padding: 15px 0px !important;
}

.new-refferal-list.to-me .refferal-content span.refferal-auther {
  position: relative;
  top: 6px;
  margin-bottom: 0 !important;
}

.new-refferal-list.to-me .refferal-content span.refferal-date {
  padding-top: 14px;
}

.new-refferal-list.to-me .refferal-content .refferal-like-unlike {
  margin: 15px 0 0 !important;
}

.icon.sideBar-GPTicon svg {
  width: 15px;
  margin: -3px 15px 0 6px;
  text-align: center;
}

.BuildGPT-Page {
  height: 100%;
  width: 100%;
}

.BuildGPT-Page .gpt-notFound {
  text-align: center;
  width: 90%;
  margin: 60px auto 45px;
}

.BuildGPT-Page .gpt-notFound img {
  margin: auto;
}

.BuildGPT-Page .gpt-notFound p {
  font-family: 'HelveticaNeue';
  margin-top: 1.3rem;
  color: #272727;
  font-size: 15px;
}

.custom-InputDate {
  background: #fff;
  flex: 1 1;
  text-align: center;
  padding: 8px;
  border-radius: 25px;
  margin: 0 15px;
}

.pending-payout-page .date-wrapper {
  position: relative;
}

.pending-payout-page .date-wrapper .selected-date span.form-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pending-payout-page .date-wrapper .selected-date .datePicker-dropdown {
  position: absolute;
  top: 44px;
  z-index: 9999999;
}

.location-field.form-control {
  /* for create snapshot camera icon overlap to location dropdown issue. */
  /* z-index: 99999 !important; */
  position: relative;
  /* padding-right: 35px; */
}

.location-field.form-control .css-26l3qy-menu {
  overflow: hidden !important;
  cursor: pointer !important;
  padding: 0 !important;
  /* max-width: 500px; */
  width: 101.3%;
  /* width: 490px; */
  /* width: 470px; */
  left: -5px;
  border-radius: 8px;
}
.create-ticket-form .location-field.form-control .css-26l3qy-menu,
.location-box .location-field.form-control .css-26l3qy-menu{
  max-width: none;
  min-width: auto;
  padding-right: 0px;
  margin-right: 0px;
  width: 101.3%;
  flex: 1;
}
.css-26l3qy-menu div{
  width: 100%;
}
.css-26l3qy-menu .css-11unzgr .css-9gakcf-option{background: #52bd41;}
.css-26l3qy-menu div > div{background: #fff;}
.css-26l3qy-menu div > div:hover{
  background: #52bd41;
  color:#fff;
}

.location-field.form-control .css-26l3qy-menu div {
  cursor: pointer !important;
  padding: 0 !important;
}

.location-field.form-control .css-26l3qy-menu div > div {
  padding: 8px 15px !important;
}
.prefs-inner-content-wrapper #googlecomplete .css-26l3qy-menu{width: 102%!important;}
.css-yk16xz-control:hover {
  border-color: transparent!important;
}
.location-field.form-control .css-1wa3eu0-placeholder{
  position: absolute;/*Position static*/
  -webkit-transform: none;
  transform: none;
  margin-left: 0px;
  margin-right: 0px;
  top: 10px;
  bottom: auto;
}
.panel-primary .location-field.form-control .css-1wa3eu0-placeholder{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 260px;
}
/* .location-field.form-control .css-1g6gooi{display: none;} */
.session-videos#ListviewContent {
  height: 100%;
  position: relative;
  z-index: 999999;
}

.session-videos#ListviewContent .session-videos-list.OpportunityCall {
  height: 95% !important;
}

.send-message {
  transform: rotate(45deg);
}

.container-fromChat-Shareables {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #eaeaea;
  padding: 15px;
  background: #f7f6f2;
}

.network .profile-img img {
  width: 48px !important;
  height: 48px !important;
  border-radius: 50%;
  object-fit: cover;
}

.group-chat .message-user-item .user-img img {
  width: 56px !important;
  height: 56px !important;
}

.Consult-Page .timing-wrapper .slick-slide.slick-current {
  height: auto !important;
}

.Preview-Modal .custom-modal-content {
  flex-direction: column !important;
  align-items: center !important;
  min-height: 60% !important;
  /* max-height: 100% !important; */
  border-radius: 20px !important;
}

.Preview-Modal .custom-modal-content .card-container {
  width: 100%;
}

.Preview-Modal .custom-modal-content .card-container .padd-with-borderContaier {
  border-bottom: none !important;
}

.reply-wrapper.bottom-reply .msg-container .container-fromChat-Shareables {
  overflow: hidden;
  margin-top: 16px;
}

.appointmentConsult {
  display: flex;
  align-items: center !important;
  margin-bottom: 14px !important;
}

.appointmentConsult .time-slots {
  margin: 0 !important;
  padding-bottom:9px;
}

.appointmentConsult .consult-appointment-cross {
  padding: 0 !important;
  background-color: #fff;
  border-radius: 100% !important;
  height: 25px !important;
  width: 25px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-top: 1px !important;
  margin: 0px 0px 0px 10px !important;
}

.consult-page
  .calender-wrapper
  .react-calendar
  .react-calendar__navigation__label {
  color: #68b245;
  font-size: 12px;
}

.Consult-Page .time-item {
  line-height: 16px;
  padding-top: 10px;
}

.Consult-Page .calender-wrapper .react-calendar__month-view__days {
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.Consult-Page
  .calender-wrapper
  .react-calendar__month-view__days
  .react-calendar__tile {
  border-radius: 100%;
  height: 28px;
  width: 28px;
  max-width: 28px !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 10px !important;
  font-size: 12px;
}

.consult-page .calender-wrapper {
  justify-content: flex-start !important;
}

.Qr_Link_Profile .row .card.profile-block-wrapper .profile-img-wrapper {
  align-items: center;
}

.reply-wrapper.bottom-reply {
  z-index: 9 !important;
}

.message-other-users .watchers-imgs img {
  width: 40px!important;
  height: 40px;
  max-width: 40px;
  display: inline-block !important;
  border: 2px solid #ededed;
  border-radius: 50%;
  margin: 0 8px 0 0px;
  background-color: #ededed;
  cursor: pointer;
  object-fit: cover;
}

.form-label-title {
  color: #272727;
  font-family: 'Inter';
  font-size: 15px;
}

.custom-modal.recursion-modal {
  padding: 15px 25px 28px !important;
}

.custom-modal.recursion-modal .custom-modal-content {
  padding: 35px 25px 28px !important;
  margin-top: 30px;
}

.recursion-modal form {
  display: flex;
  flex-direction: column;
}

.recursion-modal form .ticket-dates-wrapper {
  flex: 1;
  padding-right: 15px;
}

.recursion-modal .meetup_date_repeat form .ticket-dates-wrapper{padding-right: 0px;}

.action-sliders .slick-list .slick-track,
.watchers-slider .slick-list .slick-track {
  display: flex;
  gap: 8px;
  margin-left: 0 !important;
}

.action-sliders .slick-slide:first-child {
  margin-left: -6px;
}

.slick-slider.action-sliders .slick-dots {
  max-width: 100% !important;
}

.slick-slider.watchers-slider .slick-prev {
  left: 0px !important;
}

.slick-slider.watchers-slider .slick-next {
  right: 0px !important;
}

.slick-slider.watchers-slider .slick-list {
  transform: translate3d(0, 0, 0) !important;
  /* width : 100%; */
  margin: auto;
}

.slick-slider.watchers-slider.addwidth .slick-list {
  width: 90%;
}

.slick-slider.watchers-slider .slick-arrow::before {
  color: #b1801a !important;
}

.create-workspace .ScheduleScreen_Style .invite-page-search {
  padding-right: 0px !important;
}

.create-workspace
  .ScheduleScreen_Style
  .invite-persons-wrapper
  .invite-persons-list,
.create-workspace
  .ScheduleScreen_Style
  .invite-persons-wrapper
  .invite-persons-list
  .container {
  padding: 0 !important;
}

.editProfile .filter-btn-wrapper .btn-dark.btn,
.filter-btn-wrapper .btn {
  line-height: 18px;
}

/* CHAT CHANGES ON RIGHT PANEL DURING SESSION VIDEO (START) */

.session-right-panel .sliding-panel-wrapper {
  padding: 25px 20px !important;
}

.session-right-panel .messages-wrapper {
  margin-top: 14px;
}

.session-right-panel
  .messages-wrapper
  .sidebar-heading-wrapper.fix-width-forms {
  padding-right: 0px !important;
}

.session-right-panel
  .messages-wrapper
  .reply-box-wrapper
  > div.scrollBar
  > div:first-child {
  margin-right: 0px !important;
}

.session-right-panel
  .messages-wrapper
  .messages
  .messages-list
  .message-item
  .msg-container {
  width: 95% !important;
}

.session-right-panel .messages-wrapper .messages .messages-list {
  padding-right: 20px;
}

/* CHAT CHANGES ON RIGHT PANEL DURING SESSION VIDEO (END) */

.session-videos-grid.flex-grid-layout .video-item {
  /* for session videos view adjustment */
  flex-basis: 30% !important;
  margin: 0 !important;
}

.session-videos-grid.flex-grid-layout {
  /* for session videos view adjustment */
  justify-content: flex-start !important;
  padding: 2px 0px !important;
  top: 0% !important;
  margin: 0 !important;
  gap: 15px;
}

.stage-audience-wrapper.conferenceSession {
  /* for scroll overlap content in session-detail screen */
  padding-right: 20px !important;
}

.image-preview-modal .chat-img-preview {
  width: 100%;
}

.image-preview-modal .chat-img-preview img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.remove-selected-actions {
  color: var(--grey);
  font-size: 20px;
}

.edit_Action .profile-description,
.truncate_2_lines.actions-title {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

#sessionPage
  .sliding-panel-content
  .create-ticket-form.fix-width-forms
  .invite-page-search {
  padding-right: 0px !important;
}

#sessionPage
  .sliding-panel-content
  .create-ticket-form.fix-width-forms
  .invite-persons-list,
#sessionPage
  .sliding-panel-content
  .create-ticket-form.fix-width-forms
  .invite-persons-list
  .container {
  padding: 0px !important;
}

#sessionPage
  .create-ticket-form
  .invite-persons-list
  .row
  .col-xl-3
  .stage-list-item
  .stage-person-name {
  margin: 5px auto 0px !important;
  padding-left: 0px !important;
}

.create-circle-panel .ScheduleScreen_Style .invite-page-search {
  padding-right: 0px !important;
}

.serach-filter-wrapper.create-group .create-ticket-tabs .tab-select {
  padding: 0 !important;
}

.serach-filter-wrapper.create-group
  .create-ticket-tabs
  .tab-select
  .select-wrapper
  .form-control {
  text-align: left;
}

.profile-notes-screen .fix-width-forms {
  /* padding-right: 0px !important; */
  margin-top: 0px;
  padding-top: 10px;
}

.profile-notes-screen.profile-notes-new-screen .fix-width-forms.primary_panel_content{
  padding-right: 0px!important;
  height: 100%;
}

/* .profileNotesSheetholder .profile-notes-screen .fix-width-forms{padding-right: 20px!important;} */

.messages-header .search-tabs-icons .icon.threeDots {
  width: fit-content !important;
  margin-left: 20px !important;
}

.notes-view p,
.MessagesNoteSheet .card-container .chatMsg div:first-child {
  word-break: break-word;
  width: 100%;
}

#sessionPage
  .invite-page-wrapper
  .ScheduleScreen_Style
  .invite-persons-wrapper {
  padding-right: 25px;
}

#sessionPage
  .invite-page-wrapper
  .ScheduleScreen_Style
  .invite-persons-wrapper
  .invite-persons-list,
#sessionPage
  .invite-page-wrapper
  .ScheduleScreen_Style
  .invite-persons-wrapper
  .invite-persons-list
  .container,
#sessionPage
  .invite-page-wrapper
  .ScheduleScreen_Style
  .invite-persons-wrapper
  .invite-persons-list
  .container
  .row
  .col-xl-3.col-md-4 {
  padding: 0 !important;
}

#sessionPage .sliding-panel-content .messages-wrapper.fix-width-forms {
  padding-right: 0px !important;
}

#sessionPage
  .sliding-panel-content
  .messages-wrapper.fix-width-forms
  .messages-profile-heading.with-panel {
  padding-bottom: 10px !important;
}

.create-sessionEvents
  .create-ticket-form
  .ScheduleScreen_Style
  .invite-persons-list,
.create-sessionEvents
  .create-ticket-form
  .ScheduleScreen_Style
  .invite-persons-list
  .container,
.create-sessionEvents
  .create-ticket-form
  .ScheduleScreen_Style
  .invite-page-search {
  padding: 0 !important;
}

.sessioneventFilter .custom-modal-content {
  padding: 35px 25px 20px !important; 
}
.sessioneventFilteration .custom-modal-content{
  padding: 35px 25px 35px !important;
}
.custom-modal-content {
  box-shadow: 0px 1px 4.01px 0px #00000040;
  -webkit-box-shadow: 0px 1px 4.01px 0px #00000040;
}

/* .invite-btns{
  background-color: #f7f6f2;
  background-color: rgb(247, 246, 242, 0.9);
  bottom: 0px;
  opacity: 0.7;
  padding-top: 0px;
  position: absolute;
  width: 550px;
  padding-bottom: 0px;
} */
.invite-btns-my-refferals{
  position: relative;
  width: 100%;
}
/* .opac-btn-wrapper{
  position: absolute;
  bottom: -31px;
  width: 100%;
  text-align: center;
  background: rgb(247, 246, 242, 0.9);
  padding-top: 0;
  padding-bottom: 0px;
} */
.btn-wrapper.opac-btn-wrapper .btn-wrapper .btn{margin: 0 auto 10px;}
.create_company_in_app .slider-component-content .slider-content{margin-top: 30px;}
.sessioneventFilter .custom-modal-content .filter-btn-wrapper {
  padding-top: 24px !important;
}

/*  HOME DiSCOVER USERS LIST CHANGES START  */

.discover-builders-slider .onlyBuildersSlider.swiper .swiper-wrapper {
  margin: 0px !important;
}

.discover-builders-slider .onlyBuildersSlider.swiper .swiper-wrapper {
  margin: 0px 20px !important;
}

.discover-builders-slider.discover-custom-builders-slider .onlyBuildersSlider.swiper .swiper-wrapper{margin:0px!important}

.discover-builders-slider .addwidthDiscoverBuilders.swiper,.assigne_builder_slider .swiper, .wacthes_builder_slider .swiper {
  /* width: calc(100% - 85px); */
  margin: 0px;/*auto*/
  display: flex;
  overflow: inherit;
  justify-content: center;
  width: 100%;
}

.assigne_builder_slider .onlyBuildersSlider.swiper , .wacthes_builder_slider .onlyBuildersSlider.swiper{
  margin: 0px !important;
}

.discover-builders-slider .addwidthDiscoverBuilders.swiper .swiper-button-prev {
  left: 0px !important; /*left: -43px !important;*/
  right: auto;
}

.discover-builders-slider .addwidthDiscoverBuilders.swiper .swiper-button-next {
  right: 0px !important;/*right: -43px !important;*/
  left: auto;
}

.create-ticket-form .discover-builders-wrapper {
  padding: 15px 0;
  overflow: hidden;
}

.create-ticket-form .discover-builders-wrapper .discover-builders {
  overflow: hidden;
}

.create-ticket-form .discover-builders-slider .swiper-button-prev {
  left: -43px !important;
}

.create-ticket-form .discover-builders-slider  .swiper-button-next {
  right: -43px !important;
}

.discover-builders-wrapper.home-users-listing {
  overflow: hidden;
}

/*  HOME DiSCOVER USERS LIST CHANGES END  */

/* .session-happening-upcoming-listing {
  padding-right: 15px;
} */

.location-field.form-control
  .css-2b097c-container
  .css-yk16xz-control
  .css-1okebmr-indicatorSeparator {
  display: none;
}

.custom-modal.cancle-event-modal .custom-modal-content {
  max-width: 450px;
}

.add-feedback .send-refferal-form-wrapper form {
  display: flex;
  flex-direction: column;
}

.add-feedback .send-refferal-form-wrapper form .form-fields {
  flex: 1;
}

.ScheduleScreen .sidebar-heading-wrapper .swiper .swiper-wrapper {
  margin: 0 !important;
}

.messages-wrapper .scrollbaar .messages {
  padding-right: 17px;
}

.notify-actions {
  padding-right: 10px;
  height: 100%;
  width: 100%;
}

.appointment-header .search-tabs-icons .icon:last-child {
  width: -webkit-fill-available;
  margin-left: 20px;
}

.cutsom-control-menu {
  position: relative;
}

.cutsom-control-menu .drop-icon {
  position: absolute;
  right: 20px;
  top: 13px;
  color: #a3a198;
}

.slct-catgris-fieldset .cutsom-control-menu .drop-icon{
  right: 10px;
}

.cutsom-control-menu span.drop-icon.my-custom-drop-icon-holder{
  top: 18px;
  right: 15px;
}

.cutsom-control-menu .form-control {
  padding-right: 40px !important;
}

.general-input-container .cutsom-control-menu .form-control{
  padding-right: 30px!important;
}

.slct-catgris-fieldset .cutsom-control-menu .form-control {
  padding-right: 15px !important;
}

.slct-catgris-fieldset .cutsom-control-menu .form-control.truncate{padding-left: 10px;}

.fix-width-forms.opportunity-respond {
  height: 100%;
}

.opportunity-respond .events-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.opportunity-respond .events-wrapper .opportunies-content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.opportunity-respond
  .events-wrapper
  .opportunies-content-wrapper
  .content-wraps {
  height: 100%;
  width: 100%;
}

.message-form textarea::-webkit-scrollbar-corner {
  background-color: red;
}
.messages-users-wrapper .refferal-list-wrapper > div {
  height: 100%;
}

.message-form textarea::-webkit-scrollbar {
  background-color: white;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.message-form textarea::-webkit-scrollbar-thumb {
  background-color: #c6c5c2;
  border-radius: 25px;
  border: 5px solid transparent;
  background-clip: padding-box;
}

.emoji-icons-item.with-counts {
  width: fit-content;
  padding: 0px 10px;
}

.fix-width-forms.add-contacts {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fix-width-forms.add-contacts .send-refferal-form-wrapper {
  flex: 1;
}

.session-events-creation-modals.custom-modal .close,
.custom-modal.recursion-modal .close,
.custom-modal .close {
  right: 15px !important;
}

.create-sessionEvents .create-ticket-tabs .tab-select,   .create_tranformation .create-ticket-tabs .tab-select, .refer_outside .create-ticket-tabs .tab-select{
  width: 100%;
  padding: 0 !important;
}

.create-sessionEvents .create-ticket-tabs .tab-select .select-wrapper select, .create_tranformation .create-ticket-tabs .tab-select .select-wrapper select ,.refer_outside .create-ticket-tabs .tab-select .select-wrapper select {
  text-align: left;
  width: 100%;
}

/* ---------------------Customize Toast Message Start---------------------------- */

.Toastify .Toastify__toast-container .Toastify__toast {
  width: 90%;
  margin: 10px auto 0;
  border-radius: 12px;
}

.Toastify .Toastify__toast-container .Toastify__toast .Toastify__toast-body{
  padding: 0 6px;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* ---------------------Customize Toast Message End------------------------------ */

.HistoryPage .sliding-tabs .slick-slider .slick-slide {
  width: auto !important;
  margin-right: 50px;
}

.HistoryPage .sliding-tabs .slick-slider .slick-slide:last-child {
  margin-right: 0;
}

.profile-view-parent .sliding-panel-wrapper .empty {
  height: 100%;
  width: 100%;
}

.newSessionEventCallBtn {
  background: var(--gold);
  color: white;
  padding: 10px 15px;
  border-radius: 30px;
  z-index: 99999 !important;
}

/* .filter-modal.from-otherProfile-Snapshot.custom-modal {
  padding: 15px 0px 15px 25px;
} */

.filter-modal.from-otherProfile-Snapshot.custom-modal .custom-modal-content {
  padding: 35px 0px 35px 25px;
}

.filter-modal.from-otherProfile-Snapshot.custom-modal
  .custom-modal-content
  .filter-wrapper
  .filter-category-list {
  padding-right: 25px;
}

.action-listing.followed-up .followed-date,
.action-listing.followed-up .followed-date p {
  color: var(--grey);
}

.refferal-list-wrapper
  .refferals-list-item.card
  .referrals-dataa
  .read-more-read-less {
  word-break: break-word;
}

.btn-success {
  background-color: #52bd41;
  font-weight: bold;

  color: #fff;
  width: 230px;
  height: 44px;
  flex-shrink: 0;
  font-family: var(--int);
  border-radius: 3px;
  /* border-color: var(--primary); */
}
.appointment_milestone {
  width: 347px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 6px;
  background: linear-gradient(
    260deg,
    #4082be 18.99%,
    #2c9299 40.5%,
    #0e7d96 62.01%
  );
}
.appointment_milestone p {
  color: #fff;
  font-family: var(--int);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.appointment_milestone h1 {
  color: #fff;

  font-family: var(--int);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 19px;
}
.min_background {
  width: 88px;
  height: 28px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #52bd41;
  padding-top: 5px;
  color: #fff;
  font-size: 13px !important;

  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 6px;
}
.min_background span {
  color: #fff;
  text-align: right;
  font-family: var(--int);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* payement */

.payment-card {
  padding: 8px;
  border-radius: 0px;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}
.payment-card-subCard {
  /* margin: 10px; */
  padding: 22px 8px;
  border-radius: 0px;
  background: #eaeaea;
  border: 1px solid #eaeaea;
  position: relative;
  border-radius: 2px;
}

.payment-cart-subCard {
  /* margin: 10px; */
  padding: 16px 8px;
  border-radius: 0px;
  background: #f5f9f0;
  /* border: 1px solid #F5F9F0; */
  position: relative;
  border-radius: 2px;
}
.payment-card .message-user-item {
  padding: 15px 0 15px 20px;
  border-bottom: none;
  display: flex;
  position: relative;
}

.payment-item {
  position: relative;
}

.payment-user-item {
  padding: 10px 0 10px 5px;
  /* border-bottom: 1px solid #d5d5d5; */
  display: flex;
  position: relative;
}

.user-item-card {
  padding: 15px 0 15px 20px;
  border-bottom: 1px solid #d5d5d5;
  display: flex;
  position: relative;
}

.payment-user-item .invoice-img {
  margin-right: 17px;
  width: 65px;
  height: 65px;
  object-fit: cover;
  border-radius: 12%;
  border: 3px solid transparent;
  background: #eaeaea;
}

.payment-user-item .cart-img {
  margin-right: 17px;
  width: 94px;
  height: 94px;
  object-fit: cover;
  border-radius: 12%;
  border: 3px solid transparent;
  background: #eaeaea;
}

.contact-card-subCard {
  /* margin: 10px; */
  padding: 5px 5px;
  border-radius: 0px;
  background: #f5f9f0;
  border: 1px solid #f5f9f0;
  position: relative;
  border-radius: 2px;
  height: auto;
  width: auto;
}
.contacts-item-icon {
  /* padding: 10px 0 10px 5px; */
  /* border-bottom: 1px solid #d5d5d5; */

  /* position: absolute; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}
.contacts-item-icon .contacts-icon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  /* border: 3px solid transparent; */
  background: #262626;
  /* flex:1; */
  margin-left: 8px;
  margin-right: 3px;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px !important;
  padding-top: 10px;
}
.buy-btn-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px; /* Adjust the left position as needed */
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.buy-btn-wrapper > h3 {
  margin-right: 18px;
}

.btn-pending-signature {
  padding: 5px 10px;
  border: 1px solid #bfbbbb;
  border-radius: 2.579px;
  font-weight: 400;
}
.member_box {
  width: 109.73px;
  height: 71px;
  top: 153px;
  left: 17px;
  border-radius: 4.61px;
}
.member_box h2 {
  width: 29px;
  height: 13px;
  top: 163px;
  left: 57px;
  font-family: var(--int);
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
}
.member_box p {
  width: 57px;
  height: 18px;
  top: 182px;
  left: 43px;
  border: 2px solid #52bd41;
}

.card_member {
  display: flex;
  /* flex-direction: column; */
  align-items: center; /* Optional: Center the cards horizontally */
  gap: 10px;
}
.card_member span {
  font-family: Helvetica;
  font-size: 10px;
  font-weight: 300;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
}
.card_member h2 {
  font-family: var(--int);
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
}

.appointment_icon {
  /* display: flex;
  justify-content: space-between;
  gap: 10px; */
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  /* column-gap: 10px; */
  padding: 0 10px;
}

.appointment_icon1 {
  width: 34px;
  height: 25px;
  top: 621px;
  left: 68px;
  border-radius: 2.58px;
  background: #262626;
  flex: 1;
  margin: 8px;
}

.appointment_icon2 {
  width: 34px;
  height: 25px;
  width: auto;
  height: auto;
  top: 621px;
  left: 68px;
  border-radius: 2.58px;
  background: #262626;
  flex: 1;
  margin: 8px;
  display: flex; /* Use flexbox for layout */
  align-items: center;
}

.appointment_icon1_image {
  max-width: 22px !important;
  height: 21px;
}

.appointment_icon2 > span {
  color: #fff;

  text-align: right;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 5px;
  margin-left: 4px;
}

/* workspace */

.dragable {
  top: auto !important;
  left: auto !important;
}
.edit-opportunity-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.edit-opportunity-modal .filter-headings h2 {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}
.edit-opportunity-modal .btn.btn-dark {
  width: max-content;
  padding: 13px 45px;
  margin: 19px auto 0;
}

span.text {
  /* position: absolute; */
  /* width: 100%; */
  margin: 0 8px 0 10px;
  font-size: 13px;
  color: var(--green);
  cursor: pointer;
  padding-left: 165px;
}
.login_text {
  color: #fff;
  text-align: center;
  font-family: Dunbar Tall;
  font-size: 30.53px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.221px;
}
.p_text {
  width: 496.236px;
  height: 26px;
  transform: rotate(0.105deg);
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 19.2px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.placeholder_N {
  /* color: #fff !important; */
}
.background-black {
  border-radius: 8.023px !important;
  background: #272727 !important;

  box-shadow: 0px 1.003px 4.012px 0px rgba(0, 0, 0, 0.25) !important;
  width: 60%;
  height: auto;
  flex-shrink: 0;
  color: #fff;

  border: none !important;

  /* font-family: Inter; */
  font-size: 15.056px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.background-black:hover {
  color: #fff;
}
.new-btn_login {
  border-radius: 8.023px !important;
  background: #272727 !important;

  box-shadow: 0px 1.003px 4.012px 0px rgba(0, 0, 0, 0.25) !important;
  width: 60%;
  height: auto;
  flex-shrink: 0;
  color: #fff;

  border: none !important;

  /* font-family: Inter; */
  font-size: 15.056px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* .new-btn_login:hover {
  color: #68b245 !important;
} */
.new-btn_customer {
  border-radius: 7.651px !important;
  border: 0.954px solid #d9d9d9 !important;
  background: linear-gradient(180deg, #fbfffb 0%, #fbfdff 100%) !important;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
  /* color: black; */
  width: 60%;
  height: auto;
  flex-shrink: 0;

  color: #272727 !important;
  font-family: Inter;
  font-size: 15.056px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.next_button {
  border-radius: 8.023px;
  background: #52bd41;
  box-shadow: 0px 1.00293px 4.01173px 0px rgba(0, 0, 0, 0.25);
  width: 215px;
  height: 44px;
  margin-left: 42px;
}

.sidebar-menu-wrapper.open .sidebar-navigation ul li img {
  margin-right: 10px;
  font-size: 15px;
  width: 25px;
  color: #c1bdad;
  display: inline-block;
  text-align: center;
  height: 14px;
}

/* Roi */
.consulting-main .message-user-item {
  /* border-bottom: none !important; */
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e0e0e0 !important;
}
.container_listing {
  width: 316px;
  height: 99px;
  background: #f5f9f0;
  margin-top: 19px;
}

.messages-users-wrapper
  .custom-scroll
  div[style='position: absolute; inset: 0px; overflow: scroll; margin-right: -17px; margin-bottom: -17px;'] {
  /* margin-right: 8px !important; */
}
.messages-users-wrapper .custom-scroll .message-user-item {
  margin-left: 0;
}
.max_height .custom-modal-content {
  max-height: 100%;
}
.custom_model_bg_black .custom-modal-content {
  border-radius: 11.213px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.72) 0%,
    #000 100%
  ) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  max-height: 100%;
}
.max_height .custom-modal-content {
  max-height: 100%;
}
.no_style {
  max-height: 100%;
  list-style: inherit;
}
.comany_title {
  margin-top: 13px;
}
.model_img {
  display: inline;
  padding-bottom: 9px;
}

.consults-list {
  display: flex;
}

.consult-item {
  margin-right: -5px; /* Adjust the negative margin as needed */
}
.panel_height .panel-primary {
  width: 40%;
  padding: 0px;
}
.sidebar-modal_zero_padding {
  padding: 0px;
}

.form-control-with-icon {
  position: relative;
}

create-ticket-form .fieldset .form-control-with-icon {
  position: relative;
}

create-ticket-form .fieldset .form-control-with-icon.input-icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px; /* Adjust the left position as needed */
}
.input-icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 260px; /* Adjust the left position as needed */
}
.create-ticket-form .input-icon{
  left: auto;
  right: 45px;
}
.reward_balance {
  width: 381px;
  height: 120px;
  border-radius: 6px;
  background: linear-gradient(
    260deg,
    #4082be 18.99%,
    #2c9299 40.5%,
    #0e7d96 62.01%
  );
  margin-bottom: 32px;
}
.shift_button.btn.btn-primary :hover {
  color: #52bd41;
}
.add-emoji {
  padding-left: 0px;
}
.camera-options-list.w-100.chatActionSheet .add-emoji{padding-right: 7px!important;}
.person-search-wrapper .search-form button.btn.btn-primary:active {
  background-color: transparent !important;
  border-color: transparent !important;
}
.search-tabs-wrapper .search-form .btn:active {
  background-color: transparent !important;
  border-color: transparent !important;
  outline: none;
  border: none;
  color:var(--lightgray);
}

.icon-map-pin:focus{outline: none;}
.search-form .btn:active {
  background-color: transparent !important;
  border-color: transparent !important;
}
.property_card {
  width: 350px;
  height: 122px;
  border-radius: 5.015px;
  border: 1.003px solid #f6f5f5;
  background: #fff;
}
.property_card_img {
  width: 94.276px;
  height: 94.276px;
  flex-shrink: 0;
  border-radius: 5.015px;
}
.shareable_title {
  color: #000;
  font-family: Inter;
  font-size: 19.05px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.add_border {
  width: 1114px;
  height: 9px;
  top: -6978px;
  left: 18866px;
  background: #f7f6f2;
}

.discover-item .img-wrapper.business img {
  width: 100%;
  height: 100%;
  border-radius: 15%;
  object-fit: cover;
}

/* mobile Model */

#bottom-bar .mobile {
  background: #fff;
  /* border-top-left-radius: 30px; */
  border-radius: 30px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.12);
  /* touch-action: none; */
  width: 100%;
}
#bottom-bar .mobile .action {
  align-items: flex-end;
  display: flex;
}
#bottom-bar .mobile .action .close {
  margin: 19px 15px;
}
#bottom-bar .mobile .action .close img {
  max-width: none;
}

#bottom-bar .mobile .swipe-up {
  padding-left: 0;
  padding-right: 42px;

  /* width: auto; */
  width: -webkit-fill-available;
  height: 73px;
  top: 655px;
  border-radius: 20px, 20px, 0px, 0px;
  background: #000000;

}

#bottom-bar .mobile .step-1 {
  max-height: calc(100vh - 75px);
  overflow: visible;
  padding-top: 0;
}
#bottom-bar .mobile .step {
  padding: 10px 10px 0;
}
#bottom-bar .mobile .step-1 .img-w {
  margin: -27px 0 24px;
  position: relative;
}
#bottom-bar .mobile .step-1 .title {
  margin-bottom: 12px;
}
#bottom-bar .mobile .step-1 .text-list {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7142857143;
  margin-bottom: 24px;
}
#bottom-bar .mobile .step-1 .text-list .item {
  margin-bottom: 8px;
  padding: 0 0 0 16px;
  position: relative;
}

#bottom-bar .mobile .step-1 .text-list .item strong {
  color:#000 !important;
  font-weight: 700;
}
#bottom-bar .mobile .btn {
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.2857142857;
  padding-bottom: 15px;
  padding-top: 16px;
  width: 100%;
}
#bottom-bar .mobile .btn .bttn_ww {
  padding: 13px 25px;
  background-color: var(--primary) !important;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--int) !important;
  border-radius: 8.023px;
  border-color: var(--primary) !important;

}


#bottom-bar .mobile .close-btn{
  margin-top: 18px;
padding: 25px 0;
background-color: #000000;
text-align: center;
color: #FFFFFF;

}

.green{
 color:  #52bd41;
}

.react-calendar .highlighted {
  background-color: #87ceeb38;
  color: #0f5ad9;
  border-radius: 50%; 
  font-weight: 600;
  /* height:15%; */
  
}
.add_group_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  padding-bottom: 8px;
  /* height:15%; */
  
}
.overLay_menu {
  padding: 51px;
  background-color: rgb(0 0 0 / 11%);
  z-index: 222;
  position: fixed;
  top: 469px;
  width: 26.5%;
  
}
.message-form2{
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid #d1d0d0;
  margin: auto 0 0 0;
  padding: 15px 0 0px 0;
  width: 100%;
  position: relative;
  /* background: #f7f6f2; */
  top: 139px;
  
}
.cross_icon{
  font-size: 28px;
    color: #a39f8d;
  
}
.profile-gallery-wrapper.menus_gallery.profile-gallery {
  overflow-x: hidden;
  min-height: 370px;
}
.profile-gallery-wrapper.menus_gallery.my-menus-gallery.profile-gallery{overflow-x: visible;}

.page-content-wrapper{
  overflow: hidden !important;
}

.multi-line-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 70px; /* Allows responsiveness */           
}
.multi-line-without-img-ellipses{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
}
.flex-grow.overflow-x-auto {
  overflow-x: hidden !important;
}
.flex-grow.overflow-x-auto .slick-slide div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-grow.overflow-x-auto .slick-slide {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-grow.overflow-x-auto .slick-slide div .inline-block.px-2 {
  padding: 0px !important;
}

.panel-primary .template-img{
  margin-right: 13px;

}

.disable{
  cursor: not-allowed !important;
}

.appointment_schedule_activities .disabled-Class{font-size: 18px;}
.css-yk16xz-control input:focus{
  outline: none!important;
  border: none!important;
}
.css-1hwfws3{
  outline: none;
  border: none;
}
.css-2b097c-container:focus{
  outline: none;
  border: none;
}
.add_location.user_location .css-1pahdxg-control{
  border-color: #b3b3b3;
  box-shadow: none;
  outline: none;
}
.add_location.user_location .css-1pahdxg-control:focus{
  border-color: #b3b3b3;
  box-shadow: none;
  outline: none;
}
.action-company-name-txt{
  text-overflow: ellipsis;
  overflow: hidden;
}

.adjustCalendar {
  position: absolute;
    left: 0px;
    top: 44px;
    width: 97%;
}

.parent-container {
  position: relative; 
}


/* Center the entire screen content vertically and horizontally */
.call-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f4f6; /* Light background color */
  width: 100%;
}

.phone-number {
  font-size: 27px;
  font-weight: bold;
  color: #333; /* Dark text color */
  margin-bottom: 10px;
  text-align: center;
}

.status-text {
  color: #666; /* Light grey for the calling status */
  font-size: 1rem;
  margin-bottom: 30px;
}

/* Flexbox container for the control buttons */
.controls {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  margin-top: 20%;
}

/* Styling for control buttons */
.control-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #e0e0e0; /* Light grey background */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover effect on control buttons */
.control-button:hover {
  background-color: #d0d0d0;
}

/* Specific color for the microphone icon */
.control-button:first-child svg {
  fill: green;
}

/* Specific color for the speaker icon */
.control-button:nth-child(2) svg {
  fill: black;
}

/* Specific color for the play icon */
.control-button:nth-child(3) svg {
  fill: black;
}

/* Styling for the end call button */
.end-call-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff4d4d; /* Red background color */
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10%;
}

/* Hover effect on end call button */
.end-call-button:hover {
  background-color: #e60000;
}

/* SVG color for the end call button */
.end-call-button svg {
  fill: white;
}

.phone-number-listing {
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 290px;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
}

.phone-number-listing ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.phone-number-listing li {
  padding: 8px;
  cursor: pointer;
}

.phone-number-listing li:hover {
  background-color: #f0f0f0;
}

.phone-number-listing::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.phone-number-listing::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track */
  border-radius: 5px;
}

.phone-number-listing::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 5px;
  border: 2px solid #f1f1f1; /* Adds padding around the thumb */
}

.phone-number-listing::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darkens on hover */
}

.three-buttons ul {

  display: flex;
}
.three-buttons ul li{

  flex: 1;
}

.audio-player{
  display: flex;
  align-items: center;
  margin: 10px;
}


.accept-call-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #34c759; /* Red background color */
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10%;
}

/* Hover effect on end call button */
.accept-call-button:hover {
  background-color:  green;
}

/* SVG color for the end call button */
.accept-call-button svg {
  fill: white;
}


.noRecordImgHeight .no-results-wrapper .img-bg-wrapper{
  height: 100px;
  width: 100px;
}

.team_schedule_horizontal_line{
  height: 2px;
  background-color: #e9e9e9;
  width: -webkit-fill-available;
  position: absolute;
}
.team_schedule_vertical_line{
  width: 2px;
  background-color: #e9e9e9;
  height: 21px;
  margin-left: 10px;
  position: absolute;
  left: 60px;
}

.height-input input {
  height: 50px;
}

.billing-detail {
  background-color: white;
  padding: 12px;
  border-radius: 3px;
}
.businnes_profile_sqaure {
  margin: 0 15px 0 0;
}
.businnes_profile_sqaure img {
  width: 70px;
  min-width: 70px;
  height: 70px;
  margin: 0;
  object-fit: cover;
  border-radius: 15%;
}