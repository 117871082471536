:root {
  --dunbar: 'Inter';
  --ave: 'Inter';
  --avenext: 'Inter';
  --fontawesome: 'Font Awesome 5 Free';

  --primary: #28456f;
  --secondary: #788dac;
  --orange: #d44620;
  --redTitle: #811d2e;
  --text: #3f3f3f;
  --white: #ffffff;
  --black: #000000;
  --darkLight: #171717;
  --light: #f5f5f5;
  --light2: #eeeeee;
  --danger: #da1f1a;
  --borders: #272727;
  --green: #3cb371;
  --warning: #dab028;

  --jd-color-background-default: #ffffff;
}

.select-wrapper {
  position: relative;
}

.select-wrapper:after {
  content: '\f078';
  font-family: var(--fontawesome);
  font-weight: 900;
  color: #000;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  line-height: 1em;
}

.form-group {
  margin: 0 0 10px;
}

.modal-form-wrapper label,
.form-group label {
  display: inline-block;
  margin-bottom: 3px;
  font-size: 13px;
  font-family: var(--ave);
  font-weight: 500;
}

.errorMsg {
  font-size: 12px;
  margin: 5px 0 0;
  font-weight: 500;
  color: var(--danger);
  display: inline-block;
}

.form-control,
input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  width: 100%;
  outline: none;
  border-color: transparent;
  padding: 10px 15px;
  border-radius: 5px;
  font-family: var(--ave);
  font-size: 14px;
  color: var(--black);
  border: 1px solid #e0e0e0;
}

.form-check-input:focus,
.form-control:hover,
.form-control:focus,
input[type='text']:hover,
input[type='email']:hover,
input[type='password']:hover,
textarea:hover {
  box-shadow: none !important;
  outline: none;
  border-color: #e0e0e0;
}

.input[type='submit'],
.input[type='button'],
.btn {
  padding: 10px 30px;
  background-color: #e0e0e0;
  color: #b2b2b2;
  font-size: 14px;
  font-family: var(--dunbar);
  border-radius: 5px;
  border-color: #e0e0e0;
}

.input[type='submit']:focus,
.input[type='button']:focus,
.input[type='submit']:hover,
.input[type='button']:hover,
.btn:active,
.btn:focus,
.btn:hover {
  background-color: transparent;
  color: #b2b2b2;
  border-color: #e0e0e0;
  box-shadow: none !important;
}

.btn.btn-small {
  padding: 10px 15px;
}

.btn.btn-smaller {
  font-size: 12px;
  padding: 8px 10px;
}

.btn.btn-small-xs {
  padding: 5px 8px 6px;
  line-height: 1;
  font-size: 10px;
}

.btn.btn-dark {
  border-color: var(--primary);
  background-color: #000;
  color: var(--primary);
}

.btn.btn-dark:hover,
.btn.btn-dark:focus {
  background-color: transparent;
  color: #000;
}

.btn.black-hover:focus,
.btn.black-hover:hover {
  color: #000;
}

.btn.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.form-control.error {
  border-color: var(--danger);
}

.info-text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--secondary);
  line-height: 1;
  display: inline-block;
}

.info-text.error {
  color: var(--danger);
}

.info-text a {
  color: var(--light);
  text-decoration: underline;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon .form-control {
  padding-left: 30px;
}

.inputWithIcon i {
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.select-box {
  position: relative;
}

.select-box:after {
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  height: 10px;
  content: '';
  background-color: #000;
}

.alert {
  font-size: 15px;
}

.alert .btn-close {
  font-size: 11px;
  opacity: 1;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.as-link {
  cursor: pointer;
}

.no-margin {
  margin: 0;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

html {
  background: transparent;
}

body {
  font-family: var(--ave);
  font-size: 16px;
  line-height: 1.2em;
  color: var(--black);
  margin: 0;
  padding: 0;
  background: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--dunbar);
}

.mobile-only {
  display: none !important;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.gradient {
  background: var(--primary);
  background: linear-gradient(
    300deg,
    rgba(40, 69, 111, 1) 0%,
    rgba(76, 100, 136, 1) 100%
  );
}

.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  z-index: 10;
}

.loader-logo {
  position: relative;
}

.loader-logo img {
  max-width: 80px;
  margin-bottom: 10px;
}

.loader-logo:after {
  width: 50px;
  height: 50px;
  content: '';
  background-image: url('../../images/loader.gif');
  background-size: 100% 100%;
  position: absolute;
  top: 100%;
  left: 50%;

  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.app-container {
  min-height: 100vh;
  background: var(--white);
}

.auth-page {
  width: 100%;
  height: 100vh;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.auth-page::before {
  background-image: url('../../images/logo.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100%;
  position: absolute;
  top: -100px;
  left: -25%;
  width: 80%;
  content: '';
  opacity: 0.3;
  z-index: 1;
  transform: rotate(21deg);
}

.auth-page-logo {
  margin: 35px;
  text-align: center;
  z-index: 3;
}

.auth-page-logo img {
  max-width: 130px;
}

.auth-page-temp .heading-area {
  text-align: center;
  position: relative;
  z-index: 3;
  margin: 0 auto 30px;
  width: 600px;
  max-width: 80%;
}

.auth-page-temp .heading-area h2 {
  color: #fff;
  font-size: 30px;
  line-height: 1.3em;
  margin: 0 0 15px;
}

.auth-page-temp .heading-area p {
  color: #fff;
  font-size: 15px;
  line-height: 1.5em;
  margin: 0 auto 5px;
  max-width: 80%;
}

.auth-page-temp .auth-page-container {
  margin: 0 auto;
}

.signup-links {
  font-size: 14px;
  line-height: 1.2em;
  color: #fff;
  margin: 25px 0 0;
}

.signup-links a {
  color: var(--primary);
  text-decoration: none;
}

.form-wrapper {
  max-width: 380px;
  width: 80%;
  padding: 55px;
  background: #fff;
  border-radius: 10px;
  z-index: 2;
}

.form-wrapper .logo {
  width: 80px;
  height: 80px;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px;
}

.form-wrapper .logo img {
  max-width: 80%;
}

.form-wrapper h2 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin: 0 0 30px;
}

.form-wrapper .btn {
  max-width: 75%;
  margin: 0 auto;
}

.remember-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 0px;
}

.remember-wrapper .form-check {
  margin: 0;
}

.remember-wrapper input[type='checkbox']:focus,
.remember-wrapper input[type='checkbox']:hover,
.remember-wrapper input[type='checkbox'] {
  font-size: 14px;
  box-shadow: none;
  outline: none;
  border-color: #e0e0e0;
}

.remember-wrapper input[type='checkbox']:checked {
  border-color: var(--primary);
  background-color: var(--primary);
}

.remember-wrapper label,
.forget-link,
.forget-link a {
  color: var(--primary);
  font-size: 12px;
  text-decoration: none;
  margin: 0;
  cursor: pointer;
}

/* Admin Sidebar */
.sidebar-menu-wrapper {
  width: 60px;
  height: 100vh;
  /* border-right: 1px solid var(--borders); */
  background-color: var(--darkLight);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.sidebar-menu-wrapper.open {
  width: 250px;
}

.hamberger {
  width: 60px;
  height: 60px;
  border-right: 1px solid var(--borders);
  background-color: var(--darkLight);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;

  --webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);

  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.admin-wrapper.open .hamberger {
  border-left: 1px solid var(--borders);
}

.admin-wrapper:not(.open) .hamberger {
  border-color: var(--borders);
  background-color: var(--darkLight);

  --webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
}

.admin-wrapper .hamberger svg {
  color: #fff;
}

.admin-content {
  position: relative;
  z-index: 5;
}

.profile-image img {
  max-width: 100%;
}

.profile-wrapper {
  padding: 14px 15px 13px;
  background: var(--darkLight);
  /* background: linear-gradient(165deg, rgba(23, 23, 23, 1) 0%, rgba(0, 0, 0, 1) 61%); */
  opacity: 0;
  display: none;
  border-bottom: 1px solid #272727;

  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.profile-wrapper span.closeIcon {
  display: none;
  cursor: pointer;
}

.sidebar-menu-wrapper.open .profile-wrapper {
  display: inline-block;
  opacity: 1;
}

.app-logo-wrapper {
  display: flex;
  align-items: center;
}

.app-logo-wrapper .logo {
  width: 100%;
  margin-right: 10px;
}

.app-logo-wrapper .logo img {
  width: 130px;
  max-width: 100%;
}

.app-logo-wrapper .app-name {
  font-size: 14px;
  color: var(--white);
  line-height: 1.4em;
  text-transform: uppercase;
}

.user-profile-wrapper {
  text-align: center;
  margin: 37px 0 0;
}

.user-name {
  margin: 0 0 35px;
}

.user-name h2 {
  font-size: 16px;
  color: var(--white);
  margin: 0 0 10px;
  line-height: 1.1em;
}

.user-name h3 {
  font-size: 14px;
  color: var(--white);
  margin: 0;
  line-height: 1.1em;
}

.profile-image {
  margin-bottom: -65px;
}

.profile-image img {
  width: 100px;
  height: 100px;
  padding: 8px;
  border-radius: 50%;
  background-color: var(--white);
  object-fit: cover;
}

.sidebar-navigation {
  margin: 0px 0 25px 0px;
}

.sidebar-menu-wrapper:not(.open) .sidebar-navigation {
  margin-top: 15px;
}

.sidebar-navigation ul {
  margin: 0;
  padding: 0;
}

.sidebar-navigation ul li {
  margin: 0;
  padding: 0 15px 0 0;
  list-style: none;
  align-items: center;
  color: var(--primary);
}

.sidebar-navigation ul li.removedot ::after {
  list-style: none !important;
  display: none;
}

.sidebar-navigation ul li.check-message ::after {
  list-style: none !important;
  display: none;
}

.sidebar-navigation ul li span.makeDot {
  width: 5;
  height: 5;
  background-color: '#C48E1D';
  border-radius: 50;
  position: 'absolute';
  top: '50%';
  left: -15;
}

.sidebar-navigation ul li.withPadding {
  padding: 10px 15px;
}

.sidebar-navigation ul li .icon {
  width: 20px;
  font-size: 15px;
  margin: 0;
  color: #c1bdad;
}

.sidebar-menu-wrapper.open .sidebar-navigation ul li .icon {
  margin-right: 10px;
  font-size: 15px;
  width: 25px;
  color: #c1bdad;
  display: inline-block;
  text-align: center;
}

.sidebar-menu-wrapper.open .sidebar-navigation ul li .icon.icon-builders {
  font-size: 13px;
}

.sidebar-menu-wrapper .sidebar-navigation ul li.active .icon {
  color: #68B245;
  filter: brightness(100);
}

.sidebar-navigation ul li a {
  color: #fff;
  text-decoration: none;
  padding: 10px 0;
  display: inline-block;
  width: 100%;
  position: relative;
}

.sidebar-navigation ul li a:after {
  display: inline-block;
  content: '';
  width: 5px;
  height: 5px;
  background-color: var(--gold);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -15px;
  opacity: 0;

  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sidebar-navigation ul li.message_active a:after {
  opacity: 1;
}

.sidebar-navigation ul li li {
  margin: 0 0 0 10px;
  padding: 0;
  text-transform: initial;
}

.sidebar-navigation ul li li a {
  color: var(--white);
}

.sidebar-navigation ul li.active > .menu-text > a,
.sidebar-navigation ul li a.active,
.sidebar-navigation ul li.active > a {
  color: var(--white);
}

.sidebar-navigation ul li.active span > a:after {
  opacity: 1;
}

.sidebar-menu-wrapper:not(.open) .menu-label {
  display: none;
}

.sidebar-menu-wrapper:not(.open) .sidebar-navigation ul li li {
  margin: 0;
  text-align: center;
}

.sidebar-menu-wrapper:not(.open) .sidebar-navigation ul li a {
  text-align: center;
}

/* .sidebar-menu-wrapper:not(.open)
  .sidebar-navigation
  ul:not(.sub-menu)
  > li:first-child {
  border-top: 1px solid #171717;
} */

.sidebar-menu-wrapper:not(.open) .sidebar-navigation ul:not(.sub-menu) > li {
  /* border-bottom: 1px solid #171717; */
  padding-left: 15px;
}

.sidebar-menu-wrapper:not(.open)
  .sidebar-navigation
  ul:not(.sub-menu)
  > li
  a:after {
  left: -8px;
}

.sidebar-menu-wrapper:not(.open) .footer-menu-popup,
.sidebar-menu-wrapper:not(.open) .footer-icons {
  display: none;
}

.sidebar-menu-wrapper.open .sidebar-navigation ul li li .icon {
  display: inline-block;
}

.menu-text {
  display: flex;
  align-items: center;
}

.sidebar-navigation .menu-text{display: inline-flex;}

.sidebar-menu-wrapper:not(.open) .menu-text {
  justify-content: center;
}

.sidebar-navigation ul.sub-menu {
  display: none;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 15px;
}

.sidebar-navigation ul.sub-menu li:last-child a {
  padding-bottom: 0;
}

/* .sidebar-navigation ul li.active .sub-menu {
    display: inline-block;    
} */
.submenu-icon {
  font-family: 'Font Awesome 5 Free';
  position: relative;
  font-size: 15px;
  color: #4a4a4a;
  font-weight: 900;
  margin-left: auto;
  margin-right: 10px;
}

.submenu-icon::before {
  content: '\f054';
}

/* .sidebar-navigation ul li.active .submenu-icon::before */
.sidebar-navigation ul li.open .submenu-icon::before {
  content: '\f078';
}

.sidebar-menu-wrapper:not(.open) .submenu-icon {
  display: none;
}

.sidebar-navigation ul li.open .sub-menu {
  display: inline-block;
}

.sidebar-footer {
  margin-top: auto;
  padding: 15px 15px;
  border-top: 1px solid var(--borders);
}

.sidebar-menu-wrapper:not(.open) .sidebar-footer {
  text-align: center;
}

.copyright-text,
.sidebar-footer .menu-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.sidebar-footer .menu-text {
  margin: 0;
}

.sidebar-menu-wrapper.open .sidebar-footer .menu-text {
  flex-direction: row;
}

.menu-label {
  display: none;
}

.sidebar-menu-wrapper.open .menu-label {
  display: inline-block;
}

.sidebar-navigation li .menu-label {
  font-weight: 500;
  font-family: var(--ave);
  color: #949494;
}

.sidebar-navigation li.active .menu-label {
  font-weight: 600;
  color: #68B245;
}

.sidebar-navigation li.unread .menu-label {
  font-weight: 300 !important;
  color: #c1bdad;
}

.sidebar-footer .menu-text .icon {
  margin-right: 10px;
}

.sidebar-footer .menu-text,
.sidebar-footer .menu-text a {
  color: var(--primary);
  text-decoration: none;
}

.sidebar-menu-wrapper:not(.open) .sidebar-footer .as-link {
  margin-bottom: 10px;
}

.copyright-text {
  margin: 10px 0 0;
}

.copyright-text p {
  font-size: 12px;
  color: var(--white);
  margin: 0;
  opacity: 0.6;
}

.copyright-text img {
  max-width: 30px;
}

.sidebar-menu-wrapper:not(.open) .sidebar-footer .menu-text {
  margin-bottom: 0;
  flex-direction: column;
}

.sidebar-menu-wrapper:not(.open) .sidebar-footer .menu-text .icon {
  margin: 0;
}

.sidebar-menu-wrapper:not(.open) .copyright-text {
  display: none;
  flex-direction: row;
}

.admin-wrapper {
  background-color: var(--black);
  min-height: 100vh;
  padding: 0 0 0 60px;

  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.admin-wrapper.no-sidebar {
  padding: 0;
}

.admin-wrapper.open {
  padding: 0 0 0 249px;
}

.admin-header {
  min-height: 61px;
  padding: 10px;
  border-bottom: 1px solid var(--borders);
}

.admin-header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.admin-header .app-logo-wrapper .app-name {
  color: var(--redTitle);
}

.admin-wrapper.open .admin-header .app-logo-wrapper {
  display: none;
}

.admin-header .user-dropdown:hover,
.admin-header .user-dropdown:focus,
.admin-header .user-dropdown {
  background-color: transparent !important;
  border: none;
  font-size: 13px;
  color: #fff !important;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-family: var(--ave);
  font-weight: 500;
}

.dropdown-toggle::after {
  margin-left: 8px;
}

.user-dropdown img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.user-dropdown-wrapper {
  margin-left: auto;
}

.user-dropdown-wrapper .dropdown-menu {
  border: 1px solid var(--borders);
  padding: 5px 0;
}

.user-dropdown-wrapper .dropdown-menu a {
  padding: 6px 10px;
  font-size: 14px;
  color: var(--text);
}

.admin-body {
  position: relative;
}

/* .admin-body:after {
    content: "";
    height: 142px;
    width: 100%;
    background-color: var(--darkLight);
    position: absolute;
    top: 0;
    left: 0;
} */

.admin-body > .container {
  position: relative;
  z-index: 2;
  padding: 0 30px 30px;
}

/* Body Card */
.body-card {
  padding: 0;
  border-radius: 0;
  /* background: #000; */
  /* border: 1px solid var(--borders); */
}

.breadcrumb {
  margin: 25px 0 20px;
  display: inline-block;
}

.breadcrumb ul {
  margin: 0;
  padding: 0;
}

.breadcrumb ul li {
  list-style: none;
  display: inline-block;
}

.breadcrumb ul li span {
  display: flex;
  align-items: center;
}

.breadcrumb ul li span:after {
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  color: #fff;
  font-weight: 900;
  font-size: 11px;
  margin: 0 8px;
}

.breadcrumb ul li:last-child span:after {
  content: '';
}

.breadcrumb ul li,
.breadcrumb ul li a:hover,
.breadcrumb ul li a:focus,
.breadcrumb ul li a {
  text-decoration: none;
  color: var(--white);
  font-size: 14px;
}

.breadcrumb ul li .icon {
  font-size: 18px;
}

.body-card .heading {
  /* border-bottom: 1px solid var(--borders); */
  padding: 15px 0px 15px 0px;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.body-card .heading h2 {
  font-size: 22px;
  text-transform: uppercase;
  color: var(--white);
  font-family: var(--ave);
  margin: 0 0;
  line-height: 1.2em;
}

.body-card .body-content {
  padding: 15px;
}

.select-dropdown {
  position: relative;
}

.select-dropdown:after {
  content: '\f078';
  width: 30px;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  font-family: var(--fontawesome);
  font-weight: 900;
  color: var(--text);
  z-index: 1;
  justify-content: center;
}

.search-filter {
  align-items: center;
  display: flex;
  margin: 0 0 15px;
}

.search-filter .select-dropdown select {
  padding: 8px 8px;
}

.search-filter .select-dropdown {
  margin-right: 15px;
}

/* Service Page */
.services-wrapper {
  margin: 70px 0 30px;
}

.service-item {
  margin-bottom: 30px;
  text-align: center;
}

.no-margin-service .service-item {
  margin-bottom: 0;
}

.service-item h2 {
  font-size: 14px;
  color: var(--primary);
  font-family: var(--ave);
  text-align: center;
}

.hexa-content-wrapper {
  width: 100%;
  height: 150px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 0 25px;
}

.hexa-content-wrapper i {
  font-size: 45px;
  color: var(--text);
  position: relative;
  z-index: 3;
}

.hexagon {
  top: 50%;
  left: 50%;
  position: absolute;
  margin: 0 auto;
  background-color: var(--white);
  border-radius: 10px;
  width: 130px;
  height: 80px;
  box-sizing: border-box;
  transition: all 1s;
  border: 0 solid transparent;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);

  -webkit-transform: translate(-50%, -50%) rotate(60deg);
  -moz-transform: translate(-50%, -50%) rotate(60deg);
  transform: translate(-50%, -50%) rotate(60deg);
}

.hexagon:before,
.hexagon:after {
  content: '';
  border: inherit;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  border-radius: inherit;
  height: 100%;
  width: 100%;
}

.hexagon:before {
  transform: rotate(60deg);
}

.hexagon:after {
  transform: rotate(-60deg);
}

/* Modal */
.modal-footer,
.modal-header {
  border: none;
}

.modal-header .modal-title {
  font-size: 25px;
  line-height: 1.2em;
  margin: 0;
  color: var(--gold);
}

.modal-header .close {
  font-size: 25px;
  color: #000;
  opacity: 1;
}

.modal .modal-dialog .modal-content {
  border-radius: 20px;
  border: none;
  background-color: #f7f6f2;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  padding: 15px 20px;
}

.modal.small .modal-dialog {
  max-width: 450px;
}

.modal.medium .modal-dialog {
  max-width: 650px;
}

.modal.large .modal-dialog {
  max-width: 960px;
}

.modal .modal-dialog .modal-content .form-control {
  background-color: #fff;
  border-color: #fff;
  color: #000;
  width: 100%;
}

.modal form .react-tel-input .form-control {
  padding-left: 45px;
}

.modal form .react-tel-input .selected-flag {
  padding-left: 13px;
}

.modal-headings {
  margin: -17px 0 20px;
}

.modal-headings h2 {
  color: #fff;
  font-size: 18px;
  margin: 0 0 5px;
  line-height: 1.5em;
}

.modal-headings p {
  line-height: 1.5em;
  color: #fff;
  margin: 0 0 10px;
  font-size: 14px;
}

.modal .alert {
  margin-bottom: 25px;
}

.modal-form-wrapper .btn i {
  margin-right: 5px;
}

.device-info table.info-table {
  width: 100%;
}

.device-info table.info-table tr th,
.device-info table.info-table tr td {
  padding: 5px 5px;
}

.device-info table.info-table tr th {
  font-size: 14px;
  color: #858585;
  font-weight: 600;
  vertical-align: top;
  width: 50%;
}

.device-info table.info-table tr td {
  font-size: 14px;
  color: #858585;
}

.btns-table td {
  text-align: right;
}

.btns-table .btn {
  width: 100%;
  border-color: var(--borders);
  background: #f3f1f2;
  color: #aca6a6;
  font-family: var(--ave);
}

.btns-table .btn:hover,
.btns-table .btn:focus {
  background-color: transparent;
}

.btns-table .btn.btn.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}


/* .btn.btn.btn-primary:hover,
 .btn.btn.btn-primary:focus {
  background-color: var(--primary);
  border-color: var(--primary);

} */
.btns-table .btn.btn.btn-primary:hover,
.btns-table .btn.btn.btn-primary:focus {
  background-color: (var(--primary));
  color: var(--primary);
}

.straming-wrapper .thumbnails {
  margin-bottom: 15px;
}

/* Tabs */
.nav-tabs {
  border-color: var(--borders);
}

.nav-tabs .nav-link {
  margin: 0;
  background-color: transparent;
  border: none;
  border-top: 2px solid transparent;
  border-radius: 5px 5px 0 0;
  color: #fff;
  padding: 8px 15px;
  font-size: 14px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
}

.nav-tabs .nav-link.active {
  background-color: var(--borders);
  border-color: transparent;
  color: #fff;
}

.tab-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0 10px;
}

.tab-heading h2 {
  font-family: var(--ave);
  font-size: 18px;
  color: #fff;
  margin: 0;
  line-height: 1.2em;
  font-weight: 600;
}

.tab-footer .btn,
.tab-heading .btn {
  padding: 6px 10px 6px;
  font-size: 13px;
  line-height: 1.2;
}

.verticle-tabs {
  display: flex;
}

.verticle-tabs > .nav-tabs {
  max-width: 150px;
  flex: 1;
  display: inline-block;
  border-bottom: transparent;
  border-right: 1px solid var(--borders);
  margin-right: 15px;
  padding: 0px 0 20px;
}

.verticle-tabs > .nav-tabs .nav-item {
  margin-bottom: 0;
  margin-right: -1px;
}

.verticle-tabs .tab-content {
  flex: 1;
  padding: 15px 0 5px 0;
}

.verticle-tabs > .nav-tabs .nav-link {
  border-radius: 5px 0 0 5px;
}

.verticle-tabs > .nav-tabs .nav-link:hover {
  background-color: var(--borders);
  color: #fff;
}

.verticle-tabs > .nav-tabs .nav-item.show .nav-link,
.verticle-tabs > .nav-tabs .nav-link.active {
  color: #fff;
  background-color: var(--borders);
  border-color: var(--borders);
}

.verticle-tabs > .nav-tabs .nav-link {
  width: 100%;
  text-align: left;
  border: 1px solid transparent;
}

.tab-footer {
  margin: 5px 0 0;
}

.tab-content h4 {
  font-size: 16px;
  margin: 15px 0 10px;
  color: #fff;
  border-bottom: 2px solid #fff;
  padding-bottom: 7px;
}

/* CCTV PAGE */
.cctv-map {
  text-align: center;
  margin: 20px 0 30px;
}

.cctv-map > div {
  display: inline-block;
}

.alerts {
  padding: 0 15px;
}

.listing-tables {
  margin: 0px -15px 0;
}

.card-content .listing-tables {
  margin: 0;
}

.status,
.listing-tables .status {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.status:before,
.listing-tables .status:before {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #858585;
  margin-right: 8px;
  display: inline-block;
}

.status.online:before,
.listing-tables .status.online:before {
  background-color: var(--green);
}

.status.offline:before,
.listing-tables .status.offline:before {
  background-color: var(--danger);
}

.listing-tables tr th {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  padding: 12px 10px;
  border-color: var(--borders);
}

.card-content .listing-tables tr th {
  border-color: #fff;
}

.listing-tables .table {
  border: 1px solid var(--borders);
}

.listing-tables .table > :not(:first-child) {
  border-top: 1px solid #e0e0e0;
}

.listing-tables tr td {
  font-size: 12px;
  border-bottom: none;
  color: #fff;
  padding: 12px 10px;
  border-color: var(--borders);
  background-color: transparent;
  vertical-align: middle;
}

.listing-tables tr:nth-child(odd) td {
  background-color: #171717;
}

.card-content .listing-tables tr:nth-child(odd) td {
  background-color: transparent;
}

.listing-tables tr:hover td {
  background-color: var(--borders);
}

.listing-tables .title {
  font-weight: 600;
}

.listing-tables .severity-icon {
  font-size: 14px;
}

.listing-tables .severity-icon.danger {
  color: var(--danger);
}

.listing-tables .severity-icon.success {
  color: var(--green);
}

.listing-tables .severity-icon.warning {
  color: var(--warning);
}

.action-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.actionIcon {
  width: 30px;
  height: 30px;
  background-color: var(--secondary);
  color: var(--white);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 3px;
  padding: 0 0;
  cursor: pointer;
  vertical-align: middle;
}

.actionIcon.danger {
  background-color: var(--danger);
}

.actionIcon.success {
  background-color: var(--green);
}

.pagging-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0px;
}

.pagging-wrapper p {
  font-size: 13px;
  font-weight: 500;
  color: var(--text);
  margin: 0;
  text-transform: uppercase;
}

.filter-options-wrapper .btn,
.pagging-btns .btn {
  padding: 10px 15px;
  line-height: 1.2;
  min-width: 90px;
  font-size: 11px;
  font-weight: 600;
  font-family: var(--avenext);
  text-transform: uppercase;
  background-color: var(--primary);
  border-color: var(--primary);
  color: #000;
}

.pagging-btns .btn.disable {
  background-color: var(--borders);
}

.next-btn {
  margin-left: 10px;
}

/* Filter Options */
.filter-options-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.filter-options-wrapper .btn {
  margin-left: 10px;
}

.filter-options-wrapper .btn i {
  margin-right: 5px;
}

.filter-wrapper .form-group,
.filter-wrapper.heading-search .select-dropdown {
  width: 100%;
}

.filter-wrapper.heading-search .fitlter-icons-btn {
  margin-top: 0;
  width: 100%;
}

.fitlter-btn {
  display: inline-block;
}

.fitlter-icons-btn {
  width: 35px;
  height: 35px;
  background-color: var(--primary);
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
}

.filter-options-wrapper .search-filter {
  margin: 0;
}

.filter-wrapper.inline-search {
  text-align: right;
}

.filter-wrapper.heading-search {
  flex-direction: column;
  min-width: 250px;
  position: absolute;
  z-index: 5;
  top: 50px;
  background: #fff;
  padding: 30px 15px 10px 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  right: 0;
}

.filter-wrapper .form-group {
  margin: 0;
  width: auto;
  display: inline-block;
}

.filter-wrapper.heading-search .form-group {
  width: 100%;
  margin: 0 0 15px 0;
}

.filter-wrapper .select-dropdown {
  width: 160px;
}

.reset-filter {
  font-size: 10px;
  margin: 10px 0 0;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  display: inline-block;
  text-align: center;
}

.filter-options-wrapper .ml-2 {
  margin-left: 10px;
}

.filter-wrapper.inline-search .reset-filter {
  text-align: right;
}

.floor-map-img {
  position: relative;
  text-align: right;
}

.filter-wrapper .close-btn,
.floor-map-img .removeImg {
  font-size: 11px;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--primary);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.wrapper-btn .btn,
.floor-map-img .add-btn {
  width: 120px;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: var(--primary);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  float: right;
  margin: 10px 0 0;
}

.floor-map-img .add-btn i {
  margin-right: 5px;
}

.floor-map-img img {
  max-width: 100%;
  max-height: 100px;
}

.searchForm {
  position: relative;
}

.searchForm .form-control {
  padding: 7px 35px 7px 10px;
}

.searchForm .search-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
}

.device_name {
  color: var(--primary);
  font-weight: 600;
  text-decoration: underline;
}

.stream-video {
  width: 100%;
  height: 450px;
}

.mapping-list-wrapper {
  margin: 20px 0 0;
}

.mapping-list-wrapper .select-dropdown {
  max-width: 150px;
}

.mapping-list-wrapper .wrapper-btn {
  margin: 0 0 0px;
  display: inline-block;
  width: 100%;
}

.wrapper-btn .btn {
  width: auto;
  margin-left: 10px;
}

.wrapper-btn .btn i {
  margin-right: 5px;
  line-height: 1.5em;
}

.mapping-list-wrapper .form-group {
  margin: 0;
}

.mapping-list-wrapper table th {
  border-bottom: 2px solid var(--borders);
  padding: 8px;
  font-size: 14px;
}

.mapping-list-wrapper table td {
  border-bottom: 1px solid var(--borders);
  padding: 8px;
}

.floor-map-wrapper {
  position: relative;
  overflow-x: auto;
}

.floor-map-wrapper .floor-img {
  width: auto;
  display: inline-block;
  position: relative;
}

.floor-map-wrapper .floor-img:after {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.floor-map-wrapper .floor-img img {
  width: 700px;
}

.floor-rooms {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.floor-item {
  position: absolute;
  width: 60px;
  height: 60px;
  font-size: 25px;
  border-radius: 50%;
  top: -40px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  z-index: 1;
  border: 2px solid transparent;
}

.floor-item.highlight {
  /* background-color: rgba(40, 69, 111, 0.9); */
  border-color: #ffd60a;
  background-color: #ffd60a;
}

.floor-item:after {
  content: '';
  border: 25px solid transparent;
  position: absolute;
  border-right-color: var(--text);
  opacity: 0.3;
  top: 10%;
  right: 35%;
  z-index: -1;
}

.floor-item.online {
  color: var(--green);
}

.floor-item.offline {
  color: var(--danger);
}

.room-list-column > p {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary);
}

.room-item {
  padding: 0;
  margin: 0;
  position: relative;
}

.room-item h2 {
  font-family: var(--avenext);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2em;
  margin: 0;
  padding: 10px 0;
  position: relative;
  color: var(--primary);
  cursor: pointer;
}

.room-item h2:after {
  position: absolute;
  top: 50%;
  right: 0px;
  font-family: var(--fontawesome);
  font-weight: 900;
  color: var(--primary);
  transform: translateY(-50%);
  content: '\f0da';

  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.room-item.open h2:after {
  -webkit-transform: translate(-2px, -6px) rotate(90deg);
  -moz-transform: translate(-2px, -6px) rotate(90deg);
  transform: translate(-2px, -6px) rotate(90deg);
}

.room-item.highlight {
  background-color: var(--light2);
}

.room-devices-wrapper {
  margin: 5px 0 0;
  width: 100%;
  display: none;
}

.room-devices-wrapper.open {
  display: inline-block;
}

.room-devices-wrapper ul {
  margin: 0;
  padding: 0;
}

.room-devices-wrapper ul li {
  width: 100%;
  list-style: none;
  padding: 0 0 0 5px;
  margin: 0 0 5px;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.room-devices-wrapper ul li i {
  margin-right: 5px;
  font-size: 12px;
}

.room-devices-wrapper ul li.highlight {
  color: var(--orange);
}

/* .room-devices-wrapper ul li:after {
    position: absolute;
    top: 50%;
    right: 0px;
    font-family: var(--fontawesome);
    font-weight: 400;
    color: var(--borders);
    transform: translateY(-50%);
    content: "\f111";

    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}
.room-devices-wrapper ul li.checked:after {
    font-weight: 900;
    opacity: 1;
    content: "\f058";
    color: var(--green);
} */

.room-devices-wrapper ul li .showHideItem {
  margin-left: 10px;
  cursor: pointer;
}

/* DashBoard */
.card {
  background-color: var(--borders);
}

.card-content {
  padding: 10px;
}

.card-content .card-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-content h3 {
  font-size: 15px;
  color: #fff;
  line-height: 1em;
  margin: 5px 10px 10px 0;
  font-family: var(--avenext);
  font-weight: 600;
}

.card-content .amount {
  font-size: 12px;
  color: #fff;
  line-height: 1.2em;
  font-family: var(--dunbar);
}

.top-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}

.top-card .icon {
  margin-right: 15px;
  font-size: 18px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--text);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-card h2 {
  font-size: 22px;
  color: var(--primary);
  line-height: 1em;
  margin: 0 0 5px;
  font-family: var(--ave);
  font-weight: 900;
}

.top-card p {
  margin: 0;
  font-size: 12px;
  color: #8e8e8e;
  line-height: 1.2em;
  font-family: var(--ave);
}

.admin-body .breadcrumb {
  display: flex;
  align-items: center;
}

.admin-body .breadcrumb span.back-btn {
  color: #fff;
  margin-right: 15px;
  padding: 3px 8px;
  font-size: 12px;
  border-color: #fff;
  cursor: pointer;
}

.admin-body .breadcrumb span.back-btn:hover {
  background-color: transparent;
}

.admin-body .breadcrumb span.back-btn i {
  margin-right: 5px;
}

.info-text.add-new-user-link {
  color: var(--primary);
  font-weight: 500;
  display: inline-block;
  border-bottom: 1px solid var(--primary);
  width: auto;
  margin: 15px 0 0;
  cursor: pointer;
}

.editor-class {
  border: 1px solid var(--borders);
  background-color: #fff;
}

.jodit_theme_default {
  background-color: #fff;
}

.jodit_theme_default .jodit-wysiwyg {
  max-height: 450px;
}
