/*(1920x1080) Full HD Display*/

/* @media (min-width: 1600px) and (max-width: 2560px)  {
    
    .fixed-width-inputs,
    .fixed_width_page {
        max-width: 100%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        width: auto;
        margin-left: 0px;
    }
    .msg-create-group-list-holder .fixed-width-inputs{
        max-width: 100%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        width: auto;
        margin-left: 0px;
    }
} */
@media(min-width:768px) {

    .appointment-desc .show-more,
    .appointment-desc-titles span {
        display: none;
    }

    .ToggleRightArrow {
        display: none !important;
    }

    .slider-3-roles-conatiner .role-row.col-md-2{
        width: 11.666667%;
    }

    .company-info-screen.system_role_screen .slider-3-roles-conatiner .role-row.col-md-2{width: auto;}
}


@media (max-width: 1371px) {
    .capture-with-camera-smart-chat-wrapper{
        height: auto;
      }
      .capture-with-camera-wrapper .video-img, 
      .capture-with-camera-wrapper .video{
        height: auto;
      }
      /* .location-field > div > div{
        max-width: 96.5%;
        padding-right: 10px;
    } */
     .location-field .css-yk16xz-control,
     .location-field .css-1pahdxg-control{
        max-width: 96.9%;
        padding-right: 10px;
     }
    body {
        overflow: hidden;
        height: 100%;
    }
    .floated-panel .panel-primary {
        width: 420px;
    }

    .floated-panel.open .panel-secondary {
        padding-right: 435px;
    }
    .message-form.message-ellipse-form-holder > span{
        left: 0px;
        margin-left: auto;
        margin-right: auto;
        right: 0;
    }
    .profileNotesSheet .message-form.message-ellipse-form-holder{width: 100%;}
    .profileNotesSheet .message-form{justify-content: space-around!important;}
    .profileNotesSheet .message-form .form-icon.send-btn {
        margin: 0 3px 0 0px;
    }
    .message-form.message-smartchat-form .form-icon.send-btn {
        margin-right: 3px;
        margin-left: 3px;
    }
    .custom-modal-content .filter-wrapper.filter-wrapper2{padding-bottom: 0px;}
}

@media (max-width: 1169px) {
    .otherProfileView .sliding-panel-wrapper .sliding-panel-content .fix-width-forms {
        padding-right: 30px !important;
    }
    .site-header {
        padding: 20px 15px;
    }

    .site-header.fixed {
        padding: 20px 15px;
    }

    .hambergar {
        width: 35px;
        height: 35px;
        position: absolute;
        z-index: 3;
        display: inline-block;
        top: 50px;
        right: 20px;
        cursor: pointer;
    }

    .hambergar span {
        width: 100%;
        height: 4px;
        float: right;
        background: var(--primary);
        margin: 0 0 7px;
        border-radius: 3px;
        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
    }

    .hambergar span:last-child {
        margin: 0;
    }

    .navigation-wrapper li {
        width: 100%;
        text-align: center;
        margin: 0;
        border-bottom: 1px solid #ccc;
    }

    .navigation-wrapper .navigation {
        position: fixed;
        padding: 125px 0 0;
        width: 420px;
        max-width: 90%;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        height: 100vh;
        z-index: 2;
        opacity: 0;
        background: rgba(0, 0, 0, 0.9);
        box-shadow: 0 0 20px rgb(0 0 0 / 20%);

        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        transform: translateX(100%);
    }

    .navigation-wrapper.open .navigation {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        transform: translateX(0);
    }

    .navigation ul {
        display: initial;
        width: 100%;
    }

    .navigation ul li {
        width: 100%;
        text-align: center;
        margin: 0;
        border-bottom: 1px solid #ccc;
    }

    .navigation-wrapper li:first-child {
        border-top: 1px solid #ccc;
    }

    .navigation-wrapper li a:not(.btn) {
        width: 100%;
        display: inline-block;
        padding: 20px 10px;
        color: #fff;
        font-size: 16px;
    }

    .page-wrapper.menu-open .page-content-wrapper {
        padding-left: 60px;
    }

}

@media (max-width: 1024px) {
    .capture-with-camera-wrapper .video-img,
    .capture-with-camera-wrapper .video{height: auto;}
    .banner-content h2 {
        font-size: 23px;
    }

    .make-difference-content,
    .about-content {
        width: 80%;
    }

    /* Landing Page */
    .special-content-img img,
    .banner-block .banner-img img {
        max-width: 100%;
        width: 90%;
        max-height: initial;
    }

    .receive-recognition-wrapper .special-content-img img {
        width: 100%;
    }

    .banner-block .banner-content h1 {
        font-size: 35px;
        line-height: 1.4em;
        margin-bottom: 15px;
    }

    .banner-block .banner-content h2,
    .banner-block .banner-content h3 {
        font-size: 25px;
    }

    .banner-block .banner-content h2 {
        font-size: 18px;
    }

    .landing-page .hamberger-1 {
        width: 35px;
        height: 32px;
        z-index: 3;
        display: inline-block;
        position: relative;
        cursor: pointer;
        margin: 0 15px -12px 0;
    }

    .landing-page .hamberger-1 span {
        width: 100%;
        height: 4px;
        float: right;
        background: var(--primary);
        margin: 0 0 8px;
        border-radius: 3px;
        transition: all 0.5s ease 0s;
    }

    .landing-page .hamberger-1 span:last-child {
        margin: 0;
        width: 75%;
    }

    .landing-page .navigation {
        position: fixed;
        width: 420px;
        max-width: 90%;
        right: 0;
        top: 0;

        display: flex;
        height: 100vh;
        background: rgba(0, 0, 0, 0.95);
        z-index: 3;
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
        padding-top: 85px;
        flex-direction: column;
        margin: 0;
        opacity: 0;
        transition: all 0.5s ease 0s;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }

    .landing-page .right-side-wrapper.open .navigation {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    .landing-page .navigation ul {
        -webkit-flex-direction: column;
        flex-direction: column;
    }

    .landing-page .navigation ul li {
        width: 100%;
        margin: 0;
        text-align: center;
        font-size: 16px;
        border-bottom: 1px solid #777;
    }

    .landing-page .navigation ul li a {
        padding: 20px 15px;
        color: #fff;
        display: inline-block;
    }

    .landing-page .navigation .closeIcon {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 25px;
        right: 25px;
        font-size: 35px;
        color: var(--primary);
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .big-blob-shape {
        height: 75%;
    }


    .invite-persons-list .col-md-4,
    .stage-list-wrapper .col-md-4 {
        width: 33.33%;
    }

    .fix-width-forms {
        max-width: auto;
    }
    .setting-subpages .general .general-input-container .input-group .email-title > span{height: auto!important;}
    .calendar-custom-modal-holder{width: 90%;}
    .panel-primary .calendar-custom-modal-holder{width: 90%;}
}

@media (max-width: 960px) {

    .panel-primary {
        width: 380px;
    }

    .fix-width-forms {
        max-width: 100%;
    }
    .filter-btn-wrapper.pt-1.pb-1.create-session-btn.mx-0.bg_transparent.bottom-0{bottom: 7px!important;}

}

@media (max-width: 768px) {
    .message-chat-box-holder .message-item .msg-container{
        width: 100%;
    }
    .fix-width-refferal-form .tab-select select{text-align: left;}
    .fix-width-refferal-form .tab-select{
        padding-left: 0px;
        padding-right: 0px;
    }
    .create-editoppertunity-tabs > .tab-select{
        padding-left: 0px;
        padding-right: 0px;
    }
    .create-editoppertunity-tabs > .tab-select select{text-align: left;}
    .ticket-session-img-btn-holder > div{
        margin-right: 0px!important;
    }
    .fix-width-forms.fix-width-refferal-form {
        padding-bottom: 50px;
    }
    .ticket-session-img-btn-holder{
        flex-direction: column;
        gap: 15px;
    }
    .panel-secondary .create-ticket-tabs {
        margin: 15px 0px 15px 0px;
        width: 100%;
    }
    .panel-secondary .create-ticket-tabs .tab-select select{
        text-align: left;
        width: 100%;
    }
    input.convo-ticket-field.form-control{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .panel-wrapper .panel-primary {
        max-width: inherit;
        flex: 1;
        width: 100%;
    }

    .panel-wrapper:not(.floated-panel) .panel-secondary {
        position: fixed;
        top: 0;
        right: 0;
        width: 450px;
        max-width: 95%;
        height: 100%;
        z-index: 2;
        background-color: #f7f6f2;
        border-radius: 45px 0 0 45px;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        transform: translateX(100%);
    }

    .panel-wrapper.floated-panel .panel-primary {
        position: fixed;
        top: 0;
        right: 0;
        max-width: 450px;
        width: 90%;
        min-height: 100vh;
        background-color: #f7f6f2;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
        z-index: 9;
    }

    .panel-wrapper.floated-panel:not(.open) .panel-primary {
        transform: translateX(100%);
        box-shadow: none;
    }

    .panel-wrapper.open:not(.floated-panel) .panel-secondary {
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
        overflow: hidden;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        transform: translateX(0%);
    }

    .panel-wrapper.floated-panel .panel-secondary {
        width: 100%;
    }

    .floated-panel.open .panel-secondary {
        padding-right: 15px !important;
    }

    .sidebar-menu-wrapper {
        border-radius: 0 25px 25px 0;
    }

    .sidebar-menu-wrapper {
       
        padding:  15px 0 0;
      }
      
      .sidebar-menu-wrapper .user-menu{
        padding: 0 0 20px 0;
      }
      .footer-menu-popup{
      bottom: 80px;
      }
}

@media (max-width: 767px) {

    /* ---------------- */

    .snapshot-imgs-wrapper .snapshot-imgs-item{
        display: inherit;
    }
    .snapshot-imgs-wrapper .snapshot-imgs-item video{
        object-fit: cover;
    }
    .mobile_back_btn{
        display: block;
        position: absolute;
        top: -5px;
        right: 0px;
    }
    .desktop_back_btn{display: none;}
    .setting-subpages .general-custom-style .general-input-container{
        flex-direction: column;
    }

    .setting-subpages .general-custom-style .general-input-container .input-group{
        margin-bottom: 15px;
    }

    .setting-subpages .general-custom-style{
        margin-left: 4px;
        margin-right: 4px;
        padding-bottom: 50px;
    }

    .setting-subpages .roi_custom_template_dropdown{
        width: 97%;
    }

    .setting-subpages .general-custom-style .general_top_custom_style{margin-right: 0px;}

    .setting-subpages .general-custom-style .general_top_custom_style > h1{
        flex: 1;
        margin-right: auto;
        font-size: 15px!important;
    }

    .general-input-billing-email-contain{width: 100%!important;}
    
    .setting-subpages .general-custom-style .general_top_custom_style .location-switch-area span{
        font-size: 14px;
    }

    .messages-header.messages-roi-custom-header{
        flex-direction: column;
        height: auto;
        align-items: start;
    }

    .messages-header.messages-roi-custom-header .setting_btn > button{
        margin-left: 0px;
        margin-right: 10px;
    }

    .messages-header.messages-roi-custom-header .LabelTabs{margin-bottom: 15px;}

    .panel-secondary .create-ticket-tabs {
        margin: 15px auto;
        width: 90%!important;
    }
    .create-ticket-tabs.create-business-ticket-tabs .tab-select{
        padding-left: 0px;
        padding-right: 0px;
    }
    .create-ticket-tabs.create-business-ticket-tabs .tab-select .select-wrapper .form-control{
        text-align: left;
    }
    .panel-wrapper .panel-handle {
        width: 45px;
        height: 45px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        background: var(--dark);
        color: var(--lightgray);
        font-size: 24px;
        transform: translateX(-46px);
        position: absolute;
        top: 150px;
        left: 0;
        /* right: 0; */
    }

    .make-difference-content,
    .about-content,
    .banner-content {
        width: 90%;
    }

    .banner-block .banner-img img {
        max-width: 100%;
    }

    .banner-content h2 {
        font-size: 19px;
    }

    .banner-content h3 {
        font-size: 14px;
    }

    .headings h2,
    .about-content h2 {
        font-size: 19px;
    }

    .about-content p {
        font-size: 16px;
    }

    .site-header .social-icons {
        position: absolute;
        top: 42%;
    }

    .mobile-content {
        text-align: center;
        margin-bottom: 25px;
    }

    .mobile-content h2,
    .mobile-content h3 {
        font-size: 20px;
    }

    .mobile-images .app-store-links {
        width: 150px;
    }

    .make-difference-content h2 {
        font-size: 20px;
    }

    .make-difference-content p {
        font-size: 18px;
    }

    .footer-wrapper {
        text-align: center;
    }

    .mobile-footer-icons {
        margin: 10px 0 10px;
    }

    .footer-links {
        margin: 10px 0 10px;
        text-align: center;
    }

    .back-to-top {
        bottom: 45px;
        right: 25px;
    }

    .our-process-wrapepr {
        padding: 40px 0 40px 0;
    }

    .process-item {
        margin-bottom: 30px;
    }

    .process-wrap::after {
        display: none;
    }

    /* Landing Page */
    .landing-page .hamberger-1 {
        margin: 0 5px -10px 0;
    }

    .banner-block .banner-content {
        width: 100%;
        text-align: center;
    }

    .banner-block {
        padding: 90px 0 50px;
    }

    .banner-block .banner-img {
        margin-top: 40px;
        margin-bottom: 25px;
    }

    .landing-page .banner-block:after {
        bottom: -41%;
    }

    .about-wrapper {
        padding: 70px 0 50px;
    }

    .about-wrapper h2,
    .about-wrapper h4 {
        font-size: 25px;
        max-width: 100%;
    }

    .about-wrapper p {
        font-size: 18px;
    }

    .about-wrapper.bottom-part {
        padding: 50px 0;
    }

    .about-wrapper.bottom-part h4 {
        font-size: 28px;
    }

    .about-wrapper.bottom-part h2 {
        font-size: 20px;
    }

    .special-content-wrapper {
        padding: 60px 0 10px;
        text-align: center;
        position: relative;
        z-index: 1;
    }

    .special-content-img {
        margin: 0 auto 25px;
        max-width: 100%;
    }

    .special-content h2,
    .special-content p {
        max-width: 100%;
        font-size: 28px;
    }

    .special-content p {
        font-size: 20px;
    }

    .special-content-logo-imgs ul {
        justify-content: center;
    }

    .how-it-works-wrapper .special-content {
        margin: 0 auto 35px;
    }

    .how-it-works-item {
        margin-bottom: 55px;
    }

    .big-blob-shape {
        height: 25%;
    }

    .faq-wrapper {
        margin: 40px 0 0;
    }

    .faq {
        max-width: 100%;
    }

    .accordion-button:focus,
    .accordion-button:not(.collapsed),
    .accordion-button {
        font-size: 16px;
    }

    .accordion-body {
        font-size: 14px;
    }

    .faq-extra-content {
        margin: 30px 0 0;
    }

    .footer-wrapper .copyright {
        margin: 0 0 5px;
    }

    .footer-wrapper .copyright p {
        text-align: center;
    }

    .footer-wrapper .social-icons {
        justify-content: center;
    }

    .backToTop {
        bottom: 15px;
        right: 15px;
    }

    .upcoming-integrations-wrapper {
        padding-bottom: 60px;
    }

    .special-content-logo-imgs {
        flex-direction: column;
    }

    .special-content-logo-imgs .social-icons-2 {
        margin-top: 15px;
    }

    .get-paid-wrapper {
        padding-top: 0;
    }

    .get-paid-wrapper .special-content-img {
        margin-bottom: 55px;
    }

    .upcoming-integrations-wrapper .logo-slider .item img {
        margin: 0 auto;
        max-width: 90%;
    }

    .emoji-list-wrapper .col-md-2 {
        width: 20%;
    }

    .stage-list-wrapper .col-md-3 {
        width: auto;
    }

    .session-videos-list {
        flex-direction: column-reverse;
    }

    .session-videos-slider {
        margin-left: 0;
        width: 100%;
    }

    .gallery-imgs .col-md-4 {
        width: 33.33%;
        margin-bottom: 15px;
    }

    .appointment-content-wrapper {
        width: 100%;
        padding: 20px 15px;
    }

    .appointment-content-wrapper .container>.row {
        /* flex-direction: column-reverse; */
    }

    .appointment-content-wrapper .appointment-block {
        margin-top: 0px;
        margin-bottom: 15px;
    }

    .timing-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #F6F6F3 ;
        z-index: 4;
        padding: 60px 15px;
    }

    .timing-slider .slick-arrow {
        /* top: unset; */
    }

    .timing-slider .slick-arrow.slick-next {
        bottom: initial;
    }

    .timing-wrapper .remove-time {
        position: absolute;
        top: 20px;
        left: 25px;
        font-size: 25px;
        color: var(--primary);
    }

    .appointment-block {}

    .appointment-block .appointment-img img {
        width: 90px;
        height: 90px;
    }

    .appointment-block .appointment-content {
        padding: 35px 15px 15px;
    }

    .appointment-block .appointment-content h2 {
        font-size: 18px;
    }

    .appointment-extra-content .event-dropdown {
        margin-bottom: 20px;
    }

    .appointment-extra-content .appointment-desc:not(.show-more) p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .appointment-desc-2:not(.show-more) p {
        display: none;
    }

    .appointment-desc-titles {
        display: flex;
        justify-content: space-between;
    }

    .appointment-desc .show-more,
    .appointment-desc-titles span {
        color: var(--primary);
        font-size: 14px;
        cursor: pointer;
    }

    .appointment-desc .show-more {
        margin: 5px 0 0;
        display: inline-block;
    }

    .appointment-extra-content p {
        margin: 0;
        line-height: 1.5em;
    }

    .recursion-modal .ticket-dates-wrapper .row button.exclude-button {
        margin-top: 18px;
    }

    .remove-react-scrollbar-indicator::-webkit-scrollbar-thumb {
        display: none;
    }


    .sidebar_ul_items{
        padding-bottom: 115px !important;
      }
      .Toastify .Toastify__toast-container .Toastify__toast {
        width: 80%!important;
        margin: 5px auto 0!important;
        border-radius: 12px;
        min-height: 45px!important;
        position: relative!important;
        left: 30px!important;
      }
      
      .Toastify .Toastify__toast-container .Toastify__toast .Toastify__toast-body{
        padding: 0 6px;
        margin-top: 0px;
        margin-bottom: 0px;
      } 
      .new-page-wrapper .navbar_bg.bg_transparent{
        padding: 7px 0;
        bottom: 0px;
      }
      .prefs-inner-content-wrapper .checkbox-list li .check-icon{
        right: -40px;
      }
      .roi-form-dropdown.lead_button_dropdown {
        flex: 0 0 35%;
    }
    .purchase-filter-ticket-btn-container{flex-direction: column;}
    
    .purchase-filter-ticket-btn-wrapper .ticket-free-wrapper {
        width: 62%;
        flex: 0 0 auto;
        margin-top: 10px;
    }
    .purchase-filter-ticket-btn-wrapper .select-wrapper{margin-left: 0px;}
    .refferal-list-wrapper .custom-refferal-vertical-scrollbar > div:nth-child(3),
    .refferal-list-wrapper .custom-refferal-vertical-scrollbar > div:nth-child(2){
        display: none!important;
        height: 0px!important;
        opacity: 0!important;
    }
    .custom-refferal-vertical-scrollbar {
        overflow-y: hidden!important; /* Hide the vertical scrollbar */
    }
    
    /* For WebKit browsers (Chrome, Safari) */
    .custom-refferal-vertical-scrollbar::-webkit-scrollbar {
        display: none!important; /* Hide scrollbar */
    }
    
    /* For Firefox */
    .custom-refferal-vertical-scrollbar {
        scrollbar-width: none!important; /* Hide scrollbar in Firefox */
    }
    
    /* IE/Edge */
    .custom-refferal-vertical-scrollbar {
        -ms-overflow-style: none!important; /* Hide scrollbar in IE/Edge */
    }
    .availability-times .row .col-md-5 > div{margin-bottom: 15px;}
    .availability-days span{padding: 10px;}
    .availability-days span.active{
        border-radius: 50%;            /* Ensures the element is circular */
        color: #fff;
        display: inline-flex;      /* Use inline-flex for better control */
        justify-content: center;   /* Center the text inside the circle */
        align-items: center;
        padding: 10px;        /* Padding for circular shape */
        text-align: center;        /* Ensure text is centered */
        flex-shrink: 0;            /* Prevent shrinking when space is limited */
        box-sizing: content-box;   /* Padding included in size calculation */
    }
    .availability-times .add-time-holder{height: auto;}
    .modal-custom-calendar-days {
        padding-left: 5px;
    }
    .calendar-custom-modal-holder{
        padding:15px 10px;
    }
}

@media (max-width: 567px) {
    .serach-filter-wrapper.create-group .messages-users-list .create-ticket-form .ticket-img-wrapper {
        margin: 0;
    }
    .slider-content .cutsom-control-menu .form-control {
        padding-right: 15px !important;
    }
    .slider-content .cutsom-control-menu .drop-icon{right: 15px;}
    .slider-content{font-family: 'inter'!important;}
    .capture-with-camera-smart-chat-wrapper{
        /* height: 220px; */
      }
    .capture-with-camera-imgs-holder{height: 75%!important;}
    .location-field > div > div {
        max-width: 95%;
        padding-right: 10px;
    }
}

@media (max-width: 479px) {
    .events-time-item.as-link.active .day {
        font-size: 12px !important;
    }

    .events-time-item.as-link.active .date {
        font-size: 14px !important;
    }

    .events-time-item.as-link.active .month-year {
        font-size: 12px !important;
    }

    .events-time-item.as-link.active .time {
        font-size: 10px !important;
    }

    .events-headings .category-name.date {
        word-break: break-word;
    }

    .events-wrapper .events-icons-wrapper {
        padding-right: 20px;
    }

    .sliding-tabs .btn,
    .tabs-style-2 ul li .btn,
    .nav-tabs li .nav-link {
        font-size: 15px !important;
    }

    .settign-integ-btns {
        flex-direction: column !important;
    }

    .settign-integ-btns .btn {
        margin-top: 12px;
    }

    .events-icon.dark {
        flex-direction: column !important;
        align-items: flex-start !important;
    }

    .events-icon.dark .profile-share-icon {
        margin-top: 10px;
    }

    .events-icon.dark .profile-share-icon,
    .events-icon.dark .profile-share-icon .as-link:first-child {
        margin-left: 0px !important;
    }

    .ToggleRightArrow {
        position: absolute;
        right: 40px;
        top: 55px;
        height: 35px !important;
        width: 60px !important;
    }

    .ToggleRightArrow i {
        font-size: 20px !important;
    }

    span.ToggleRightArrow:hover {
        position: absolute;
        right: -15px;
    }

    span.ToggleRightArrow:hover i:nth-child(2),
    span.ToggleRightArrow:hover i:nth-child(3) {
        display: none !important;
    }

    .session-success-modal .new-session-modal h3 {
        font-size: 15px !important;
    }

    .session-success-modal .new-session-modal p,
    .session-success-modal .new-session-modal button,
    .session-success-modal .new-session-modal .copy-link-text {
        font-size: 14px !important;
    }

    .new-session-modal-wrapper.createSessionModal.session-success-modal .new-session-modal {
        padding: 0px 15px !important;
    }

    .profile-gallery-wrapper .gallery-imgs.my-snapshots .gallery-item {
        height: 76px !important;
        min-height: 100% !important;
        width: 76px !important;
        border-radius: 12px !important;
    }

    .custom-modal-content .filter-wrapper::-webkit-scrollbar-thumb,
    .remove-react-scrollbar-indicator div:last-child>div {
        background-color: transparent !important;
    }
    .page-content-wrapper .create_company_in_app .slider-component-content .slider-content {
        margin-top: 17px;
        position: relative;
        z-index: 9;
    }
    .new-page-wrapper .slider-component-content .slider-content-inputs .tell-us {
        font-size: 17px !important;
    }
    .new-page-wrapper .navbar_bg{
        z-index: 9;
        /* background: transparent; */
    }
    .search-tabs-wrapper .search-form .search-input-location .phone-messages-call-input-contain .form-control{width: 96%;}
}