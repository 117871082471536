/* Fonts */

@font-face {
    font-family: 'DunbarTall'; /* Replace with the actual font family name */
    src: url('../fonts/Dunbar/DunbarText-Bold.eot') format('eot'),
     url('../fonts/Dunbar/DunbarText-Bold.woff2') format('woff2'),
     url('../fonts/Dunbar/DunbarText-Bold.woff') format('woff'),
     url('../fonts/Dunbar/DunbarText-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

