@media (max-width: 1025px) {
    .admin-wrapper {
        padding: 0;
    }

    .admin-wrapper.open {
        padding: 0;
    }

    .sidebar-menu-wrapper.open {
        z-index: 9999;
    }

    .sidebar-menu-wrapper:not(.open) {
        /* transform: translateX(-100%); */
    }

    .page-wrapper.menu-open .page-content-wrapper,
    .page-wrapper:not(.menu-open) .page-content-wrapper {
        /* padding-left: 0px !important; */
    }

    /* .sidebar-menu-wrapper:not(.open) .logo-wrapper .bars-icon {
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--dark);

        -webkit-transform: translateX(50px);
        -moz-transform: translateX(50px);
        transform: translateX(50px);
    } */

    .admin-header {
        padding: 10px 10px 10px 70px;
    }

    .admin-wrapper:not(.open) .hamberger {
        --webkit-transform: translateX(0);
        transform: translateX(0);
    }

    .profile-wrapper span.closeIcon {
        display: inline-block;
        position: absolute;
        right: 13px;
        top: 23px;
        z-index: 4;
        color: #fff;
        font-size: 20px;
        font-weight: 900;
    }

    .modal.large .modal-dialog {
        max-width: 95%;
    }

    .listing-tables {
        overflow-x: auto;
    }

}

@media (max-width: 767px) {
    .react-scrollbar > div:first-child {
        margin-right: 0px !important;
        margin-bottom: 0px !important;
    }
    .app-logo-wrapper .app-name {
        font-size: 11px;
    }

    .admin-body>.container {
        padding: 0 15px;
    }

    /* .services-wrapper .col-12 {
        flex: 0 0 auto;
        width: 50%;
    } */
    .services-wrapper .col-12:last-child,
    .services-wrapper .col-12:first-child {
        display: none;
    }

    .device-info .row {
        flex-direction: column;
    }

    .info-table {
        margin-bottom: 15px;
    }

    .straming-wrapper .row {}

    .cctv-map img {
        max-width: 100%;
    }

    .listing-tables {
        overflow-x: auto;
    }

    .auth-page .form-wrapper {
        width: 85%;
    }
}

@media (max-width: 479px) {
    .panel-wrapper:not(.floated-panel) .panel-secondary {
        width: calc(100% - 60px) !important;
        max-width: 100% !important;
        border-radius: 0 !important;
        box-shadow: none !important;
    }
}