:root {
  --ave: 'Inter';
  --avenext: 'Inter';
  --helveNeue: 'Inter';
  --int: 'Inter';
  --fontawsome: 'Font Awesome 5 Free';
  --dunbarTall: 'Dunbar Tall';
  --icomoon: 'icomoon';
  --primary: #52bd41;
  --gold: #52bd41;
  --white: #020101;
  --black: #000000;
  --light: #a0a0a0;
  --dark: #272727;
  --danger: #ba2323;
  --borders: #e0e0e0;
  --lightgray: #a39f8d;
  --grey: #9b9787;
  --red: #c14545;
  --green: #52bd41;
}

/* tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* import css */
@import url('../fonts/font.css');

html {
  background: transparent;
}

body {
  font-family: var(--int);
  font-size: 15px;
  line-height: 1.2em;
  color: var(--dark);
  margin: 0;
  padding: 0;
  background: transparent;
  /* overflow: hidden; */
  /* overflow-y: hidden; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--helveNeue);
  font-weight: 600;
}

.p-reg {
  font-family: var(--int);
  color: var(--black);
  font-size: 14px;
}

.btn.btn-primary:focus {
  background-color: (var(--primary));
  /* color: #fff; */
  /* border-color: var(--primary); */
  background: var(--primary);
  outline: none;
}

.btn.btn-primary:focus-visible {
  background-color: (var(--primary));
  /* color: #fff; */
  /* border-color: var(--primary); */
  background: var(--primary);
  outline: none;
}

.btn.btn-primary:focus-within {
  background-color: (var(--primary));
  /* color: #fff; */
  /* border-color: var(--primary); */
  background: var(--primary);
  outline: none;
}

.icon-filter-icon:focus-visible,
.icon-filter-icon:focus,
.icon-filter-icon:focus-within {
  outline: none;
  color: #262626;
}

.roi-search-form .btn.btn-primary.active .icon-filter-icon:focus {
  color: #262626;
}
.roi-search-form .btn.btn-primary .btn-check:checked + .btn,
.roi-search-form .btn.btn-primary .btn.active,
.roi-search-form .btn.btn-primary .btn.show,
.roi-search-form .btn.btn-primary .btn:first-child:active,
.roi-search-form .btn.btn-primary :not(.btn-check) + .btn:active {
  color: #262626 !important;
}

.form-check-input:checked[type='radio'] {
  background-image: none;
}

.search-tabs-wrapper .search-form .btn:focus {
  background: transparent;
  border: none;
  outline: none;
}

.search-tabs-wrapper .search-form .btn:hover {
  background: transparent;
  border: none;
  outline: none;
}

.btn.btn-primary:has(i):focus {
  background: transparent;
  border: none;
  outline: none;
}

.btn.btn-primary:has(i):focus-visible {
  background: transparent;
  border: none;
  outline: none;
}

.btn.btn-primary:has(i):focus-within {
  background: transparent;
  border: none;
  outline: none;
}

.category-btns-wrapper-slider .btn.btn-primary:focus {
  background: transparent;
  color: #000;
}

.category-btns-wrapper-slider .btn.btn-primary:focus.active {
  background: #272727;
  color: #ffffff;
}

.category-btns-wrapper-slider .btn.btn-primary:hover {
  color: #ffffff;
}

.border-radius-25 {
  border-radius: 25px;
}

.select-wrapper {
  position: relative;
}

.select-wrapper:after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: var(--lightgray);
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  line-height: 1em;
  z-index: 2;
}
.select-wrapper.customized-select-wrapper.dropdown::after {
  right: 10px;
}
.customized-slider2-inner-container .select-wrapper::after {
  right: 8px;
}
.custom-scheduler-dropdown .select-wrapper::after {
  right: 8px;
}
/* .menus-dropdowns.roles_dropdown .select-wrapper.dropdown button{max-width: 180px;} */
.snapshot-slect-wrapper.select-wrapper::after {
  right: 15px;
}
.select-wrapper .dropdown button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.select-wrapper button,
.select-deal-wrapper .dropdown button,
input[type='text'].form-control {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* max-width: 180px; */
}
.select-deal-wrapper::after {
  right: 10px;
}
.select-wrapper.customized-select-wrapper button {
  padding-right: 15px;
}
.profile-gallery-wrapper.menus_gallery.profile-gallery .select-wrapper::after {
  right: 10px;
}
.payment-select-frequency-fields .select-wrapper::after {
  right: 15px;
}
.profile-gallery-wrapper.menus_gallery.my-menus-gallery .select-wrapper::after {
  right: 10px;
  cursor: pointer;
}

.create-ticket-form.create-action-ticket-form {
  padding-bottom: 60px;
}
.create-ticket-form.create-action-ticket-form .select-wrapper::after {
  right: 15px;
}
.create-ticket-form.create-action-ticket-form .rc-time-picker::after {
  right: 16px;
}
/* .create-ticket-form.create-action-ticket-form input[type="date"]::-webkit-calendar-picker-indicator {

  z-index: 5;
  opacity: 0;
} */
.create-ticket-form.create-action-ticket-form .custom-date-picker {
  position: relative;
}
.create-ticket-form.create-action-ticket-form .custom-date-picker::after {
  /* content: '\f073'; 
  font-family: 'Font Awesome 5 Free';  */
  font-weight: 900; /* FontAwesome icons are bold */
  font-size: 15px;
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: 12px;
  transform: translateY(-50%);
  /* pointer-events: none; */
  cursor: pointer;
  /* background-image:url('../images/calendar-updated-icon.png'); */
  background-size: contain;
  content: '';
  width: 14px;
  height: 17px;
  background-position: center center;
}
.custom-suggestions.invoices_suggestion > li {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.text-limit-paragraph {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 760px;
}
.catalog_product_item .text-limit-paragraph {
  max-width: 500px;
}
.remove_deal .cross_icon {
  font-size: 22px;
}
.upload-custom-slct-wrapp {
  position: relative;
  margin-left: 3px;
  margin-right: 2px;
}
.upload-custom-slct-wrapp::after {
  right: 10px;
}
.select-wrapper select.form-select,
.select-wrapper select {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  --selectHoverCol: #999;
  --selectedCol: red;
}

.select-wrapper.roi-custom-select-wrapper .dropdown button {
  width: 100%;
}
.customized-search-filter-wrapper .ScheduleScreen_Style {
  width: 98%;
}
.form-group {
  margin: 0 0 15px;
}

.form-group label {
  display: inline-block;
  margin-bottom: 7px;
  font-size: 12px;
  font-family: var(--ave);
  font-weight: 500;
  color: #fff;
}

.custom-datepicker-field .rmdp-input,
form select,
.form-control,
input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  width: 100%;
  height: auto;
  outline: none;
  padding: 10px 15px;
  border-radius: 8px;
  font-family: var(--ave);
  font-size: 14px;
  box-shadow: none;
  background-color: #fff;
  border: 1px solid var(--borders);
  color: var(--dark);
  font-weight: 400;
}

.form-control:hover,
.form-control:focus,
input[type='text']:hover,
input[type='text']:focus,
input[type='email']:hover,
input[type='email']:focus,
input[type='password']:hover,
input[type='password']:focus,
textarea:hover,
textarea:focus {
  border-color: var(--borders);
}

select.form-control {
  -webkit-appearance: initial;
  -moz-appearance: initial;
  appearance: initial;

  border-color: var(--borders);
}

.form-control:hover,
.form-control:focus,
input[type='text']:hover,
input[type='email']:hover,
input[type='password']:hover,
textarea:hover {
  box-shadow: none !important;
  background-color: #fff;
  border-color: var(--borders);
  color: #000;
}

.form-check.form-switch input {
  cursor: pointer;
}

.form-check.form-switch label {
  margin-top: 2px;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--green);
  border-color: var(--green);
}

.form-switch .form-check-input:focus {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');
}

.form-switch .form-check-input:focus:checked {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
}

/* form .inputWithIcon .form-control .input[type='submit'],
input[type='button'],
.btn {
  padding: 13px 25px;
  background-color: var(--primary);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--helveNeue);
  border-radius: 25px;
  border-color: var(--primary);
} */

form .inputWithIcon .form-control .input[type='submit'],
input[type='button'],
.btn {
  padding: 13px 25px;
  background-color: var(--primary);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--int);
  border-radius: 8.023px;
  border-color: var(--primary);
}

.sidebar_ul_items {
  padding-bottom: 78px !important;
}

form .inputWithIcon .form-control .input[type='submit'],
input[type='button'],
.btn.new-btn {
  padding: 8px 27px;
  background-color: var(--primary);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--int);
  border-radius: 8.023px;
  border-color: var(--primary);
}

input[type='submit']:focus,
input[type='button']:focus,
input[type='submit']:hover,
input[type='button']:hover,
.btn:active,
.btn:focus,
.btn:hover {
  background-color: transparent;
  color: var(--primary);
  border-color: transparent;
  box-shadow: none !important;
}

.btn.btn-small {
  padding: 10px 15px;
}

.btn.btn-dark {
  border-color: none;
  /* background-color: var(--dark); */
  background-color: var(--primary) !important;
  color: #fff;
}

.btn.btn-green {
  border-color: var(--green);
  background-color: var(--green);
  color: #fff;
}

.btn.btn-dark:hover,
.btn.btn-dark:focus {
  /* background-color: #333; */
  background-color: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.btn.black-hover:focus,
.btn.black-hover:focus,
.btn.black-hover:hover {
  color: var(--dark);
}

.btn.btn-danger {
  border-color: #000;
  color: #fff;
  background-color: var(--danger);
}

.btn.btn-outline {
  border-color: var(--primary);
  color: #fff;
  background-color: transparent;
}

.btn.btn-dark-outline {
  border-color: var(--dark);
  color: var(--dark);
  background-color: transparent;
}

.btn.btn-dark-outline:hover {
  background-color: var(--dark);
  color: #fff;
}

.btn.btn-outline:hover {
  background-color: var(--primary);
  color: #000;
}

.custom-crop-modal-btn {
  margin-top: 35px;
}

.form-control.error {
  border-color: var(--danger);
}

.info-text {
  width: 100%;
  display: inline-block;
  margin-top: 8px;
  font-size: 12px;
  color: var(--light);
  line-height: 1.3em;
  word-break: break-all;
}

.info-text b {
  color: var(--gold);
  font-weight: 400;
}

.info-text.error {
  color: var(--danger);
}

.info-text a {
  color: var(--primary);
  text-decoration: underline;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon .form-control {
  padding-left: 30px;
}

.inputWithIcon i {
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.inputWithIcon.right-side .form-control {
  padding-left: 15px;
  padding-right: 30px;
}

.inputWithIcon.right-side i {
  right: 15px;
  left: inherit;
}

.select-box {
  position: relative;
}

.select-box:after {
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  height: 10px;
  content: '';
  background-color: #000;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.alert-dismissible .btn-close {
  font-size: 12px;
}

.alert p {
  font-size: 12px;
  margin: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.as-link {
  cursor: pointer;
}

.primary-color {
  color: var(--gold);
}

.no-margin {
  margin: 0;
}

.fieldset {
  margin: 0 0 15px;
}

.fieldset.send-refferal-fieldset {
  margin: 0 0 11px;
}

.send-refferal-feedback-holder > .fieldset {
  margin: 0 0 10px;
}

form label {
  font-size: 14px;
  color: var(--light);
  margin: 0 0 8px;
  display: inline-block;
  width: 100%;
  line-height: 1.2em;
}

.mobile-only {
  display: none !important;
}

.page-wrapper {
  background-color: #f7f6f2;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #000;
  z-index: 9999;
}

.loader-logo {
  position: relative;
}

.loader-logo img {
  max-width: 130px;
}

.loader-logo:after {
  width: 45px;
  height: 45px;
  content: '';
  background-image: url('../images/loader.gif');
  background-size: 100% 100%;
  position: absolute;
  top: 110%;
  left: 50%;

  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.footer-wrapper img {
  max-width: 150px;
}

.footer-wrapper {
  width: 100%;
  background: #1d1c1e;
  padding: 15px 0;
}

.footer-wrapper .row {
  align-items: center;
}

.footer-wrapper .footer-links {
  text-align: right;
}

.footer-wrapper .footer-text,
.footer-wrapper .footer-links a {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
}

.appointment-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  flex: 1;
  min-height: 100vh;
}

.appointment-content-wrapper {
  width: 90%;
  max-width: 1024px;
  padding: 45px 30px;
  border-radius: 50px;
  /* background-color: #fff; */
  background-color: none;
  margin: 25px auto 80px;
  flex: 1;
}

.appointment-block {
  text-align: center;
}

.appointment-img {
  width: auto;
  margin: 0 auto -35px;
  position: relative;
  z-index: 3;
  display: inline-block;
}

.appointment-img .profile-share {
  width: 35px;
  height: 35px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #efecec;
  color: var(--primary);
  font-size: 16px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.appointment-img img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid #fff;
}

.appointment-content {
  background: #f7f6f2;
  border: 4px solid #f7f6f2;
  border-radius: 15px;
  padding: 35px 15px 20px;
  position: relative;
  z-index: 1;
  max-width: 93%;
  margin: 0 auto;
}

.appointment-content h2 {
  margin: 0;
  font-size: 20px;
  color: #393939;
  line-height: 1.5em;
  font-weight: 900;
}

.appointment-content p {
  font-size: 13px;
  color: #919191;
  margin: 0 0 5px;
}

.appointment-extra-content {
  margin: -45px 0 0;
  border: 1px solid #dedddd;
  padding: 60px 15px 15px;
  border-radius: 25px;
  line-height: 1.3em;
  text-align: left;
}

.appointment-extra-content p {
  font-size: 14px;
  text-align: left;
  color: #272727;
}

.appointment-extra-content h2 {
  font-size: 20px;
  color: #272727;
  margin: 0 0 5px;
  line-height: 1.3em;
}

.appointment-extra-content h3 {
  font-size: 16px;
  color: #272727;
  margin: 0;
  line-height: 1.3em;
}

.appointment-extra-content h4 {
  font-size: 14px;
  color: #848175;
  margin: 0 0 5px;
  line-height: 1.3em;
}

.appointment-extra-content .event-dropdown {
  margin: 15px 0 30px;
}

.event-info {
  margin: 15px 0 15px;
}

.event-info ul {
  margin: 0;
  padding: 0;
}

.event-info ul li {
  list-style: none;
  margin: 0 0 10px 0;
  text-align: left;
  color: #272727;
  font-size: 14px;
}

.event-info ul li i {
  margin: 0 8px 0 0;
  font-size: 16px;
  min-width: 15px;
  text-align: center;
}

.appointment-extra-content .event-btn {
  margin: 15px 0 10px;
}

.tabs-wrappper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px 0;
}

.event-tabs {
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 25px;
  overflow: hidden;
  width: 350px;
}

.event-tabs ul {
  margin: 0;
  padding: 0;
}

.event-tabs ul li {
  list-style: none;
  display: inline-block;
  width: 33.33%;
}

.event-tabs ul li .btn {
  padding: 8px 15px;
  border-radius: 0;
  background-color: #edebe5;
  border: none;
  color: #272727;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
}

.event-tabs ul li .btn.active,
.event-tabs ul li .btn:focus,
.event-tabs ul li .btn:hover {
  background-color: var(--primary);
  color: #fff;
}

.event-tabs .row {
  margin: 0;
}

.event-tabs .col {
  margin: 0;
  padding: 0;
}

.event-dropdown select {
  padding: 8px 15px;
  background-color: #fff;
  border-radius: 25px;
  border: 1px solid #efecec;
  color: #272727;
}

.market_home_page.primary_panel_content .event-tabs ul {
  margin-top: 2px;
}

.heading-links {
  text-align: right;
  margin: 0 0 15px;
}

.heading-links a {
  color: var(--primary);
  text-decoration: none;
}

/* Calender Block */
.calender-wrapper .current-time {
  font-size: 14px;
  color: var(--primary);
  display: inline-block;
  margin: 15px 0;
  font-weight: 500;
}

.timing-wrapper {
  border-radius: 5px;
  background: #f6f6f6;
  text-align: center;
  padding: 30px 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.timing-slider {
  flex: 1;
  overflow-y: auto;
  height: 100%;
  display: flex;
  margin: 30px 5px;
}

.time-item {
  padding: 6px 4px;
  background-color: #fff;
  border-radius: 5px;
  display: inline-block;
  /* box-shadow: 0 3px 3px rgb(0 0 0 / 11%); */
  font-size: 11px;
  color: #393939;
  cursor: pointer;
  margin: 0 0 10px;
}

.time-item.active {
  background-color: var(--primary);
  color: #fff;
  flex: 1;
}

.time-item.close-item {
  background-color: #efeeee;
  color: #393939;
  width: 50px;
}

.timing-wrapper .selected-date {
  font-size: 11.779px;
  color: #272727;
  margin: 15px 0 15px;
  display: inline-block;
  border-bottom: 1px solid var(--borders);
  padding-bottom: 10px;
}

.timing-wrapper .selected-date span {
  display: inline-block;
  margin: 5px 0 0;
  font-size: 13px;
}

.timing-wrapper .btn-wrapper {
  margin: 0 0 15px;
}

.timing-wrapper .btn {
  width: 100%;
  padding: 8px 15px;
  font-size: 14px;
  line-height: 1.2em;
}

.calender-wrapper .react-calendar {
  width: 100%;
  border: none;
}

.calender-wrapper .react-calendar__tile:enabled:hover,
.calender-wrapper .react-calendar__tile:enabled:focus,
.calender-wrapper .react-calendar__tile--now {
  background: #f1f5fe;
}

.calender-wrapper .react-calendar__tile--now:enabled:hover,
.calender-wrapper .react-calendar__tile--now:enabled:focus {
  background: #f1f5fe;
}

.calender-wrapper .react-calendar__tile--active:enabled:hover,
.calender-wrapper .react-calendar__tile--active:enabled:focus,
.calender-wrapper
  .react-calendar__tile--active.react-calendar__month-view__days__day--weekend,
.calender-wrapper .react-calendar__tile--active {
  background: #cfeec0;
  color: #000000;
  font-weight: 700;
}

.calender-wrapper .react-calendar__tile {
  border-radius: 25px;
  padding: 15px 10px;
}

.calender-wrapper .react-calendar__navigation__arrow {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #1d1e1c;
  min-width: initial;
  margin: 0 5px;
  border: 1px solid #edebe5;
  color: white;
  transition: all 0.2s ease 0s;
  font-size: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.calender-wrapper .react-calendar__navigation {
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.calender-wrapper .react-calendar__navigation__arrow:enabled:focus,
.calender-wrapper .react-calendar__navigation__arrow:enabled:hover {
  background: #1d1e1c;
  color: #fff;
  /* border-color: var(--primary); */
}

.calender-wrapper .react-calendar__navigation__label {
  width: auto;
  position: absolute;
  background-color: transparent;
  right: 85px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 14px;
  font-family: var(--helveNeue);
}

.calendar_column.appointment_cal_holder
  .calender-wrapper
  .react-calendar__navigation__label {
  right: 79px;
}

.calender-wrapper .react-calendar__navigation__label:enabled:hover {
  background-color: transparent;
}

.calender-wrapper .react-calendar__month-view__days__day--weekend {
  color: #393939;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.timing-slider .slick-slider {
  position: inherit;
}

.timing-slider .slick-arrow {
  width: 115px;
  height: 25px;
  left: 50%;
  top: 55px;
  transform: translateX(-50%);

  background: #ecebe9;
  color: #fff;
  border-radius: 3px;
  /* border: 5px solid #fff; */
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); */
}

.timing-slider .slick-arrow.slick-next {
  top: initial;
  bottom: 10px;
}

.timing-slider .slick-prev:before,
.timing-slider .slick-next:before {
  font-family: var(--fontawsome);
  font-weight: 900;
  font-size: 14px;
  opacity: 1;
  color: #000;
}

.timing-slider .slick-prev:before {
  content: '\f077';
}

.timing-slider .slick-next:before {
  content: '\f078';
}

.appointment-fields-wrapper .btn-wrapper .btn {
  margin: 0 15px 0 0;
  padding: 8px 15px;
  min-width: 100px;
}

.timing-slider .time-item.close-item {
  margin-left: 10px;
}

.headings {
  margin: 0 0 15px;
}

.headings h2 {
  font-size: 20px;
  color: #272727;
  line-height: 1.3em;
  margin: 0 0 5px;
}

/* Profile Page */
.profile-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  flex: 1;
  min-height: 100vh;
}

.profile-content-wrapper {
  width: 90%;
  max-width: 960px;
  padding: 45px 30px;
  border-radius: 50px;
  background-color: #fff;
  margin: 80px auto;
  flex: 1;
}

.card {
  padding: 17px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #eaeaea;
}

.fix-width-forms.edit-business-card-wrapper {
  padding-left: 15px !important;
}

.profile-img-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.profile-img {
  margin: 0 15px 0 0;
}

.profile-img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;

  object-fit: cover;
}

.profile-name {
  flex: 1;
  padding-top: 14px;
}

.profile-name-headings {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-name-headings.with-panel {
  padding-right: 45px;
}

.profile-name h2 {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--avenext);
  margin: 0 0 0;
  line-height: 1.2em;
  color: var(--dark);
  word-break: break-all;
}

.profile-name h3 {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--avenext);
  margin: 0 0 0;
  line-height: 1.2em;
  color: var(--lightgray);
}

.upload-user-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.upload-user-img img {
  margin-right: 15px;
  object-fit: cover;
}
.upload-edit-business-profile-img.upload-user-img .icon {
  right: -2px;
  left: auto;
  cursor: pointer !important;
  /* z-index: 9999999 !important; */
}
.upload-edit-business-profile-img {
  cursor: default;
}
.upload-edit-business-profile-img.upload-user-img input[type='file'] {
  width: 30px;
  height: 30px;
  left: auto;
  right: -3px;
  bottom: -47px;
  top: auto;
  cursor: pointer;
}
.upload-edit-user-img {
  overflow: hidden;
}
.upload-edit-user-img img {
  margin-right: 0px;
  overflow: hidden;
}

.upload-user-img .icon {
  position: absolute;
  bottom: -3px;
  left: -2px;
  color: var(--gold);
  background: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
}

.profile-img.upload-user-img.removeCursor.upload-edit-user-img .icon {
  bottom: 0px;
}

.upload-edit-user-img .icon {
  left: auto;
  right: 2px;
}

.upload-user-img input[type='file'] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.upload-user-img input[type='file']::-webkit-file-upload-button,
.upload-user-img input[type='file'] {
  /* chromes and blink button */
  cursor: pointer;
}
.upload-user-img input[type='file'] .events-icon,
.social-icons {
  margin: 13px 0 0;
}

.events-icon {
  display: flex;
  align-items: center;
}

.profile-share-icon {
  margin-left: auto;
}

.profile-name .btns-wrapper .btn {
  padding: 8px 20px;
  font-size: 13px;
}

.social-icons ul {
  margin: 0;
  padding: 0;
}

.social-icons ul li {
  display: inline-block;
  list-style: none;
  margin: 0 10px 0 0;
}

.social-icons ul li a {
  text-decoration: none;
}

.social-icons ul li i {
  font-size: 20px;
  text-decoration: none;
  color: var(--green);
}

.icon_color {
  color: var(--gold) !important;
}
.profile-content-social-infos .social-icons ul li:nth-child(4),
.profile-content-social-infos .social-icons ul li:last-child {
  position: relative;
  left: -3px;
}
.profile-share-icon span,
.profile-share-icon a {
  font-size: 18px;
  text-decoration: none;
  color: var(--lightgray);
  margin-left: 15px;
  cursor: pointer;
}

.profile-content-infos {
  margin: 7px 0 0 0;
}

.profile-content-infos .skills {
  font-size: 14px;
  color: var(--dark);
  margin: 0 0 10px;
}

.profile-content-infos .other-infos {
  display: flex;
  align-items: center;
}

.events-icon ul {
  margin: 0;
  padding: 0;
}

.events-icon ul li {
  margin: 0 10px 0 0;
  display: inline-block;
  list-style: none;
  font-size: 18px;
  color: var(--primary);
  vertical-align: middle;
}

.events-icon ul li a {
  color: var(--gold);
  text-decoration: none;
}

.events-icon ul li .event-icon-text {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  font-family: var(--ave);
  vertical-align: middle;
  color: #000;
}

.events-icon ul li .icon-applaud {
  font-size: 22px;
}

.profile-content-infos .profile-address {
  font-size: 13px;
  color: var(--lightgray);
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
}

.profile-content-infos .profile-address i {
  margin: -5px 5px 0 0;
  font-size: 15px;
}

.profile-content-infos .profile-address .icon-aroplane {
  font-size: 20px;
}

.profile-gallery-wrapper .event-tabs {
  width: 100%;
  display: inline-block;
}

.profile-gallery-wrapper .event-tabs ul li .btn.active,
.profile-gallery-wrapper .event-tabs ul li .btn:focus,
.profile-gallery-wrapper .event-tabs ul li .btn:hover {
  /* background-color: var(--gold); */
}

.gift-details-wrapper .events-desc-wrapper:not(.show-app) div,
.profile-description:not(.show-all) {
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.profile-description p,
.profile-description {
  color: var(--dark);
  font-size: 15px;
  line-height: 1.3em;
  margin: 25px 0 5px;
  white-space: normal;
  word-break: break-all;
}

.profile-description p {
  margin: 0 0 15px;
}

.serach-filter-wrapper.edit_Action .profile-description {
  margin: 0px;
}

.fieldset-invite-wrap {
  margin-bottom: 0px;
}

.primary_panel_wraper.oppertunities-panel-list-wrap {
  padding-right: 10px;
}

.refferals-list.action-listing.p-0.bg-transparent {
  margin-bottom: 5px;
}

.show-more-link {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  color: var(--green);
  margin: 0;
}

.donation-block-wrapper {
  margin: 15px 0 0;
}

.donation-block-wrapper p {
  color: #3b3b3b;
  font-size: 14px;
  line-height: 1.3em;
  margin: 0 0 15px;
}

.donation-block-wrapper .numbers-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.donation-block-wrapper form .form-group {
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0;
}

.donation-block-wrapper form .form-group label {
  font-size: 14px;
  color: #3b3b3b;
  margin: 0 0;
}

.donation-block-wrapper form input[type='number'] {
  width: 70px;
  margin: 0 15px 0 0;
}

.donation-block-wrapper .donation-amount {
  font-size: 16px;
  font-weight: 600;
  color: #3b3b3b;
  text-align: center;
  margin: 10px 0 0;
  display: inline-block;
  width: 100%;
}

.donation-block-wrapper .payment-btns {
  margin: 5px 0 0;
}

.donation-block-wrapper .payment-btns .btn {
  width: 100%;
  background-color: transparent;
  border-color: #c6c6c6;
  margin: 15px 0 0;
  font-size: 14px;
  color: #3b3b3b;
  border-radius: 25px;
}

.donation-block-wrapper .payment-btns .btn.apple-pay {
  color: #000000;
}

.donation-block-wrapper .payment-btns .btn:hover {
  background-color: #c6c6c6;
}

.donation-block-wrapper .payment-btns .btn i {
  margin: 0 5px 0 0;
  font-size: 18px;
}

.numbers-field .coffee-cup img {
  width: 50px;
}

.numbers-field .multiply {
  width: 15px;
  margin: 0 0 0 10px;
  font-weight: 600;
}

.numbers-field .numbers {
  flex: 1;
  display: flex;
}

.numbers-field .numbers span {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c6c6c6;
  border-radius: 50%;
  margin: 0 8px;
  cursor: pointer;
}

.numbers-field .numbers span.active {
  background-color: var(--gold);
  color: #fff;
  border-color: var(--gold);
}

.img-gallery-wrapper {
  width: 100%;
  margin: 20px 0 0;
  padding: 0 15px;
}

.gallery-imgs .gallery-item {
  margin: 0 0 15px;
  position: relative;
  border-radius: 21px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
}

.gallery-imgs .gallery-snapshot-row-holder.row .gallery-item {
  width: 115px;
}

.Business_company_profile_holder
  .gallery-imgs
  .gallery-snapshot-row-holder.row
  .gallery-item {
  width: 150px;
  height: 130px !important;
}

.gallery-imgs .gallery-snapshot-row-holder.row .gallery-item img {
  position: static;
  max-width: 100%;
}

.gallery-imgs .gallery-item.active {
  border-color: var(--primary);
}

.gallery-imgs .gallery-item:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.16);
  z-index: 1;
  opacity: 0;

  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.zoom-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: 0;

  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.gallery-imgs .gallery-item:hover:after {
  opacity: 1;
}

.gallery-imgs .gallery-item:hover .zoom-icon {
  opacity: 1;
}

.gallery-imgs img {
  width: 100%;
}

/* Sidebar */
.sidebar-menu-wrapper {
  background-color: var(--dark);
  padding: 15px 0;
}

/* .sidebar-menu-wrapper.open {
  padding: 15px 25px 15px 25px;
} */

.padding.open {
  padding: 0px 25px 0px 25px;
}

.sidebar-menu-wrapper .logo-wrapper {
  margin: 10px 0 15px;
}

.sidebar-menu-wrapper:not(.open) .logo-wrapper {
  margin: 0;
  justify-content: center;
}

.sidebar-menu-wrapper .logo-wrapper img {
  max-width: 105px;
}

.sidebar-menu-wrapper .logo-wrapper {
  display: flex;
  align-items: center;
}

.sidebar-menu-wrapper .logo-wrapper .bars-icon {
  margin-left: auto;
  font-size: 22px;
  color: #9b9787;
  cursor: pointer;
}

.sidebar-menu-wrapper:not(.open) .logo-wrapper .bars-icon {
  color: #9b9787;
  margin-left: 0;
  margin-top: 10px;
}

.sidebar-menu-wrapper .sidebar-navigation {
  flex: 1;
}

.sidebar-menu-wrapper .user-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.sidebar-menu-wrapper .user-img {
  margin: 0 15px 0 0;
}

.sidebar-menu-wrapper .user-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.sidebar-menu-wrapper .user-name {
  flex: 1;
  margin: 0;
}

.sidebar-menu-wrapper .user-name h3 {
  font-size: 15px;
  font-weight: 300;
  color: #9b9787;
  line-height: 1.2em;
  margin: 0 0 3px;
}

.sidebar-menu-wrapper .user-name h4 {
  font-size: 13px;
  color: #7c7c7c;
  line-height: 1.2em;
  margin: 0 0 0;
}

.sidebar-menu-wrapper .user-menu a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logout-link {
  font-size: 12px;
  color: #9b9787;
}

.logout-link a {
  color: #9b9787;
  outline: none;
}

.sidebar-menu-wrapper .footer-icons {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.sidebar-menu-wrapper .footer-icons .icon {
  margin-left: 15px;
  font-size: 25px;
  color: #9b9787;
}

.sidebar-menu-wrapper:not(.open) .user-menu {
  text-align: center;
  justify-content: center;
}

.sidebar-menu-wrapper:not(.open) .user-menu .logout-link,
.sidebar-menu-wrapper:not(.open) .user-menu .user-name {
  display: none;
}

.sidebar-menu-wrapper:not(.open) .user-img {
  margin: 0;
}

.footer-menu-popup {
  position: absolute;
  bottom: 75px;
  padding: 25px 15px;
  background: #3e3e3e;
  border-radius: 25px;
  width: 80%;
  left: 25px;

  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;

  /* -webkit-transition: all 0.5s 0s ease-in;
    -moz-transition: all 0.5s 0s ease-in;
    transition: all 0.5s 0s ease-in; */
}

.footer-menu-popup ul {
  margin: 0;
  padding: 0;
}

.footer-menu-popup ul li {
  list-style: none;
  margin: 0 0 15px;
}

.footer-menu-popup ul li:last-child {
  margin: 0;
}

.footer-menu-popup ul li a {
  font-family: var(--ave);
  color: #949494;
  font-size: 15px;

  display: flex;
  align-items: center;
}

.footer-menu-popup ul li a span {
  font-size: 15px;
  width: 25px;
  margin: -3px 10px 0 0;
  text-align: center;
}

/* Page Content Wrapper */
.page-content-wrapper {
  padding-left: 60px;

  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.page-wrapper.menu-open .page-content-wrapper {
  padding-left: 250px;
}

.page-content-wrapper .container {
  margin-left: 0;
  position: relative;
}

.content-sidebar {
  border-right: 1px solid var(--borders);
  min-height: 100vh;
  padding: 25px 25px 25px 10px;
  position: relative;
  height: 100%;
}

.sessions-content-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sessions-list-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.sessions-content-wrapper .event-tabs .col {
  padding: 0;
}

.dark-tabs.event-tabs {
  background-color: #edebe5;
}

.dark-tabs.event-tabs .btn {
  width: 100%;
  background-color: transparent;
  border-color: transparent;
  color: var(--dark);
}

.dark-tabs.event-tabs .btn.active {
  background-color: var(--dark);
  color: #fff;
}

.session-wrapper {
  margin: 10px 0 25px 0;
}

.session-item {
  margin: 0 0 15px;
}

.session-heading {
  margin: 0 0 5px;
}

.session-heading h2 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 5px;
  line-height: 1.3em;
  color: #333333;
}
.membership-profile-notes-block .session-heading h2 {
  margin-bottom: 5px;
}
.membership-profile-notes-block .session-address-wrapper {
  justify-content: flex-end;
}
.membership-profile-notes-block .session-address-wrapper span {
  margin: 0 5px;
  font-size: 18px;
}
.message-notes-full-width-hold {
  width: 98%;
}
.profileNotesSheetholder .message-notes-full-width-hold {
  width: 100%;
}
.message-notes-full-width-hold .roi-form-dropdown .dropdown-menu.show {
  display: block;
  /* top: 100% !important; */
  left: 0px !important;
  transform: none !important;
  inset: auto !important;
}
.membership-profile-notes-block
  .session-defination-list-holder
  .input-label
  strong {
  margin-right: 5px;
}
.membership-profile-notes-block
  .session-defination-list-holder
  .input-label
  span {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 415px;
  display: block;
  overflow: hidden;
}
.membership-profile-notes-block .session-defination-list-holder .input-label {
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
}
.session-heading .users-text {
  font-size: 14px;
  color: #333333;
  margin: 0 10px 0 0;
}

.session-heading .users-text i {
  margin: 0 0 10px;
  font-size: 16px;
}

.session-heading .category-text {
  color: #333333;
  opacity: 0.41;
}

.event-users-cate .category-text i {
  margin-left: 15px;
}

.bottom-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bottom-icons span {
  cursor: pointer;
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #464646;
  margin-left: 0px;
}

.persons-img-list {
  margin: 0 0 10px;
}

.persons-img-list .img-item {
  position: relative;
  margin: 0 10px 10px 0;
  display: inline-block;
}

.calls-information-list-menu .persons-img-list .img-item {
  margin-right: 12px;
}

.persons-img-list.persons-img-snapshot-holder .img-item {
  margin-bottom: 0px;
}

.persons-img-gallery .persons-img-list .img-item {
  margin-right: 0px;
}
.persons-img-gallery .slick-track {
  width: 100% !important;
}

.persons-img-list .img-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.calls-information-list-menu .persons-img-list .img-item img {
  width: 60px;
  height: 60px;
}

.persons-img-list .img-item span.online-icon {
  position: absolute;
  bottom: -3px;
  right: -2px;
  background: var(--gold);
  border: 3px solid #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.persons-img-list .img-item span.speaker-icon {
  position: absolute;
  top: -4px;
  left: -3px;
  color: var(--gold);
  background: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 11px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.persons-img-list .img-item span.owner-icon {
  position: absolute;
  bottom: -3px;
  right: -2px;
  color: var(--gold);
  background: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 11px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.persons-name-list ul {
  margin: 0;
  padding: 0;
}

.persons-name-list li {
  width: 50%;
  display: inline-block;
  text-align: left;
  padding: 0 0 0 20px;
  margin: 0 0 3px;
  font-size: 14px;
  color: #464646;
  position: relative;
}

.persons-name-list li span {
  font-size: 12px;
  margin-right: 5px;
  position: absolute;
  top: 50%;
  left: 0;

  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.person-address-place {
  margin: 15px 0 0 0;
  font-size: 16px;
  color: #464646;
  display: flex;
  align-items: center;
}

.person-address-place i {
  margin: 0 5px 0 0;
  font-size: 18px;
  color: #000000;
}

/* Events Wrapper + Event Item */
.events-content-wrapper {
  margin: 90px 0 30px 0;
}

.events-item {
  /* max-width: 400px; */
  border: 1px solid #eaeaea;
  background: #fff;
  padding: 15px;
  border-radius: 25px;
  margin: 0 0 20px;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16); */
}

.calls-information-list-menu .events-item {
  border-radius: 5px;
}

.events-item.no-bg {
  background-color: transparent;
  border: none;
  box-shadow: none;
  max-width: 100%;
}

.widgets-wrapper .events-item {
  max-width: 100%;
}

.event-img {
  margin: 0 0 15px;
}

.event-img img {
  width: 100%;
  border-radius: 15px;
  max-height: 200px;
  object-fit: cover;
}

.events-item .session-heading {
  display: flex;
  align-items: flex-end;
  margin: 0 0 10px;
}

.call-recording-holder .persons-img-list {
  flex: 1;
}

.calls-information-list-menu .events-item .session-heading {
  margin-bottom: 0px;
}

.events-item .session-heading.twillo-session-heading {
  display: block;
  margin-bottom: 0px;
}

.twillo-call-list-holder {
  flex: 1;
}

.event-titles {
  flex: 1;
}

.event-titles h2 {
  font-size: 17px;
  color: #333333;
  margin: 0 0 8px;
  line-height: 1.3em;
}

.event-titles .event-date {
  margin: 0 0 8px;
}

.event-share {
  width: 30px;
  color: var(--gold);
  font-size: 20px;
  margin: 0 0 10px 10px;
  text-align: right;
}

.events-place-like {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.events-place-like .address {
  font-size: 16px;
  color: #464646;
  display: -webkit-box; /* Enables line clamping */
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical; /* Required for line clamp */
  overflow: hidden; /* Hides overflow text */
  text-overflow: ellipsis; /* Shows the '...' */
  white-space: normal; /* Prevent conflicts */
  max-width: 100%; /* Ensures the container doesn't stretch infinitely */
}

.session-events-item-listing .events-place-like .address {
  flex: 0 0 auto;
}

.events-place-like .address i {
  font-size: 18px;
  color: #000000;
  margin: 0 8px 0 0;
}

.events-place-like .event-amount {
  font-size: 16px;
  color: #464646;
  margin: 0 5px;
}

.events-place-like .event-heart {
  font-size: 20px;
  color: var(--gold);
  margin: 0 5px;
}

.session-wrapper .events-item .session-heading .event-titles h2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.edit-opportunity-slide-modal .filter-wrapper.filter-pad-bottom-wrap {
  margin-bottom: 0px;
}
/* .slider-component.create_company_form .custom-modal-content{padding-bottom: 0px;} */
/* Session Details */
.sessions-details-content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sessions-details-content-wrapper .session-content {
  margin: 0 0 25px;
}

.sessions-details-content-wrapper .session-content h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 5px;
  line-height: 1.3em;
}

.session-content .auther-name {
  font-size: 14px;
  color: #a5a5a5;
  margin: 0 0 8px;
  display: flex;
  position: relative;
  align-items: center;
}

.session-content .auther-name .info-icon {
  font-size: 18px;
  color: #040504;
  margin-left: 5px;
}

.session-content .user-info {
  display: flex;
  align-items: center;
}

.session-content .user-info .icon-text {
  font-size: 14px;
  color: #363636;
  margin-right: 14px;
  font-family: var(--int) !important;
  display: flex;
  align-items: center;
}

.session-content .user-info .icon-text span {
  padding: 3px 0px 0px 4px;
}

.session-content .user-info .icon-text.videoIcon {
  margin-top: 3px;
}

.session-content .user-info .icon-text.videoIcon span {
  padding: 0px 0px 0px 7px !important;
}

.session-content .user-info .icon-text i {
  margin-right: 3px;
  color: var(--lightgray);
  font-size: 16px;
}

.session-content .add-link {
  margin-left: auto;
  font-size: 14px;
  color: var(--gold);
  cursor: pointer;
}

.session-website-slider {
  margin: 25px 0 20px;
}

.sessions-details-content-wrapper .session-website-slider {
  margin: 5px 0 25px;
}

.session-website-slider .slick-slide {
  padding: 0 10px 0 0;
  margin: 0;
}

.session-website-slider .slick-arrow {
  z-index: 5;
}

.session-website-slider .slick-arrow:before {
  font-family: 'swiper-icons';
  color: #000;
  opacity: 1;
}

.session-website-slider .slick-next {
  right: 15px;
}

.session-website-slider .slick-next:before {
  content: 'next';
}

.session-website-slider .slick-prev {
  left: 5px;
}

.session-website-slider .slick-prev:before {
  content: 'prev';
}

.session-website-slider .person-web-item {
  background: #e8e8e9;
  border-radius: 25px;
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e9;
  margin: 0;
  width: 100%;
}

.person-web-item .person-img {
  margin: 5px -15px 5px 5px;
  position: relative;
  z-index: 2;
}

.person-web-item .person-img img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  object-fit: cover;
}

.person-web-item .person-web-link {
  flex: 1;
  background: #ffffff;
  padding: 13px 10px 13px 20px;
  border-radius: 25px;
  text-align: center;
}

.person-web-item .person-web-link a {
  color: #000000;
  text-decoration: none;
  font-size: 14px;
}

.stage-header {
  display: flex;
  align-items: center;
  margin: 5px 0 15px;
}

.stage-header h3 {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}
.stage-header h2 {
  color: var(--primary);
}

.stage-header .video-icon {
  margin: 0 0 0 auto;
  font-size: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.stage-header .video-icon i {
  font-size: 16px;
  color: var(--lightgray);
  margin-left: 5px;
}

.stage-header .video-icon .active i,
.stage-header .video-icon.active i {
  color: var(--gold);
}

.stage-header-icon {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.stage-list-wrapper {
  padding: 0 15px;
}

.stage-list-item {
  text-align: center;
  margin: 0 0 20px;
}

.stage-list-item p.p-reg.text-gey {
  padding-left: 5px;
}
.stage-person-img {
  position: relative;
  width: 80px;
  height: auto;
  margin: 0 auto;
}

.invite-persons-wrapper .stage-person-img {
  width: auto;
}

.system-roles-invite-holder .stage-person-img {
  display: inline-block;
}

.stage-person-img img {
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid transparent;
  object-fit: cover;
  margin: auto;
}

.system-roles-invite-provider .stage-person-img img {
  border: 0px;
}

.poc-send-icons-new-block span.as-link svg {
  width: 17px;
  height: 17px;
}
.poc-send-icons-new-block .poc_delete > i {
  font-size: 17px;
}
.poc-send-icons-new-block .poc_send_icon > i {
  font-size: 17px;
}
.ScheduleScreen_Style .stage-person-img.business img,
.all_builders
  .stage-list-wrapper
  .stage-list-item
  .stage-person-img.business
  img,
.persons-imgs-wrapper .stage-person-img.business img {
  border-radius: 15%;
}
.stage-person-img.active img {
  border-color: var(--gold);
}

.stage-list-item .stage-person-name {
  margin: 5px 0 0;
}

.stage-list-item .stage-person-name h3 {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  word-break: break-all;

  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.stage-person-img .owner-icon {
  position: absolute;
  bottom: 3px;
  left: 10px;
  color: var(--gold);
  background: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 11px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stage-person-img .online-icon {
  position: absolute;
  bottom: 5px;
  right: 12px;
  background: var(--gold);
  border: 3px solid #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.session-action-btns {
  width: 100%;
  max-width: 320px;
  padding: 13px 10px;
  margin: auto auto 20px;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 -2px 19px rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
  justify-content: center;
}

.session-action-btns .action-icon.file-icon {
  /* margin-right: auto; */
}

.session-action-btns .action-icon {
  margin: 0 10px;
  cursor: pointer;
}

.session-action-btns .action-icon i {
  font-size: 28px;
  color: var(--lightgray);
}

.session-action-btns a {
  color: #1a1a1a;
}

.session-video-header {
  padding: 10px 0;
  margin: 0 0 15px 0;
  text-align: right;
  display: flex;
  align-items: center;
}

.session-video-header .grid-icons {
  margin-left: auto;
  height: 45px;
  display: flex;
  align-items: center;
}

.grid-icons i {
  font-size: 20px;
  color: var(--lightgray);
}

.grid-icons .grid-icon {
  margin: 0 5px;
  cursor: pointer;
}

.grid-icons .expand-icon i {
  font-size: 17px;
}

.grid-icon.active i {
  color: var(--gold);
}

.video-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  height: 110px;
  background: #000;
  margin: 0 auto 10px;
  border-radius: 20px;
  border: 1px solid #e2e2e2;
  color: #e2e2e2;
  font-size: 13px;
  position: relative;
  cursor: pointer;
  /* transition-delay: 1s; */
}

.session-videos-grid {
  /* width: 100%;
    max-height: 85vh; */
}

.session-videos-grid.flex-grid-layout {
  /* display: flex; */
  position: relative;
  top: 50%;
  margin: auto;
  padding: 2px 4.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.session-videos-grid.grid-layout .video-item {
  /* height: 150px;
    float: left; */
}

.session-videos-grid .video-item {
  overflow: hidden;
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.session-videos-grid .video-item:last-child {
  /* margin-bottom: 0; */
}

.session-videos-grid .video-item img,
.session-videos-grid .video-item video {
  width: 100%;
  /* padding: 2px; */
  object-fit: cover;
  height: 100%;
}

.session-videos-grid .video-item.mobile-video video {
  width: auto;
  margin: auto;
  display: block;
}

.session-videos-grid .video-item.portrait-view img,
.session-videos-grid .video-item.portrait-view video {
  width: auto;
}

.video-items {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 225px;
  max-width: 100%;
  overflow: hidden;
  height: 150px;
  background: #ffffff;
  margin: 0 0 10px;
  border-radius: 20px;
  border: 1px solid #e2e2e2;
  color: #e2e2e2;
  font-size: 13px;
  position: relative;
  cursor: pointer;
}

.session-videos {
  display: flex;
  flex: 1;
  max-height: 100%;
}

.session-videos-list {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
}

.session-videos-list .row {
  width: 100%;
  flex: 1;
}

.session-video {
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex: 1;
}

.session-video .video-item {
  border: none;
}

.session-video .video-item video {
  object-fit: none;
  position: initial;
  height: auto;
  object-fit: contain;
}

.session-videos-slider {
  margin: 0 0 15px 15px;
  padding: 0;
}

.session-videos-slider.verticle-slider {
  overflow: hidden;
}

.session-videos-slider .slick-slide {
  padding: 0 5px;
}

.session-videos-slider .video-item {
  margin: 0;
}

.session-videos-list .session-video .video-item {
  height: 450px;
  margin: 0;
}

.session-video #large-video-container {
  height: auto;
  width: 100%;
  max-height: 87vh;
}

.session-videos-slider .video-items {
  margin: 0;
}

.session-videos-list .session-video .video-items {
  height: 450px;
  margin: 0;
}

.session-videos-slider .slider-nav,
.session-videos-slider .slick-arrow {
  z-index: 2;
  min-width: auto;
  width: 30px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.session-videos-slider .swiper-button-next,
.session-videos-slider .swiper-button-prev {
  color: var(--lightgray);
}

.session-videos-slider .slick-arrow:before {
  font-family: var(--fontawsome);
  font-size: 18px;
  color: var(--gold);
  font-weight: 900;
}

.session-videos-slider .slick-prev {
  left: 5px;
}

.session-videos-slider .slick-next {
  right: 5px;
}

.session-videos-slider .slick-prev::before {
  content: '\f053';
}

.session-videos-slider .slick-next::before {
  content: '\f054';
}

.session-videos-slider.verticle-slider .rec-carousel {
  flex-direction: column;
  position: relative;
}

.session-videos-slider.verticle-slider .rec-slider-container {
  flex: 1;
  margin: 0;
}

.session-videos-slider.verticle-slider .rec-carousel-item {
}

.session-videos-slider.verticle-slider .slider-nav {
  width: 100%;
  text-align: center;
  position: absolute;
}

.session-videos-slider.verticle-slider .slider-nav.slide-prev {
  top: -20px;
}

.session-videos-slider.verticle-slider .slider-nav.slide-next {
  bottom: -20px;
}

.opportunities-list-wrapper {
  margin-top: 10px;
}

.opportunity-item {
  margin: 0 0 15px 0;
  /* box-shadow: 0 0 10px rgb(0 0 0 / 16%);
    border: none; */
}

.opportunity-item:last-child {
  /* margin: 0; */
}

.opportunity-item .category,
.opportunity-item h2,
.truncate_2_lines {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.opportunity-item h2 {
  margin: 0 0 5px;
  font-size: 18px;
  line-height: 1.3em;
  cursor: pointer;
  width: 90%;

  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.oppertunities-appointment-profile-wrapper .opportunity-item h2 {
  pointer-events: none;
}

.oppo-share-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;
}

.oppo-share-icon {
  font-size: 18px;
  cursor: pointer;
}

.opportunity-item .category {
  font-size: 15px;
  color: #9b9787;

  display: inline-block;
  width: 90%;
}

.oppo-share-icon i {
  color: var(--gold);
}

.oppo-desc {
  width: 90%;
  margin: 0 0 20px;
}

.oppo-desc p {
  font-size: 15px;
  color: #333333;
  margin: 0 0 10px;
  line-height: 1.3em;
}

.oppo-desc .oppo-desc-content:not(.show-all) {
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.oppo-desc .read-more {
  cursor: pointer;
  color: var(--primary);
  display: inline-block;
  font-size: 15px;
  margin: 0;
}

.opportunity-item .oppo-address {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0;
}

.opportunity-item .reffered-text {
  display: inline-block;
  width: 100%;
  margin: 0 0 8px;
}

.opportunity-item .oppo-address span {
  flex: 1;
}

.opportunity-item .oppo-address .address {
  display: flex;
  margin-right: auto;

  overflow: hidden;

  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.opportunity-item .oppo-address .address i {
  font-size: 18px;
  color: #464646;
  margin: 0 5px 0 0;
}

.opportunity-item .oppo-address .address b {
  margin-left: 10px;
}

.opportunity-item .oppo-address .hearticon {
  margin-left: auto;
  text-align: right;
}

.opportunity-item .injeaction-text {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  color: #464646;
}

.appointment-list-wrapper .injeaction-text {
  justify-content: center;
}

.opportunity-item .injeaction-text b {
  margin: 0 5px;
}

.opportunity-item .injeaction-text i {
  font-size: 18px;
  margin: 0 5px 0 0;
  color: #464646;
}

.opportunity-item .hearticon {
  cursor: pointer;
}

.opportunity-item .hearticon i {
  font-size: 20px;
  color: var(--gold);
  /* margin-left: 15px; */
}

.oppertunities-appointment-profile-wrapper span.hearticon i.fa-solid.fa-phone {
  padding-right: 7px;
  font-size: 18px;
}

.person-search-wrapper {
  margin: 20px 0 20px;
  display: flex;
  flex-direction: column;
}

.person-search-wrapper .search-input {
  flex: 1;
  margin: 0 10px 0 0;
}

.person-search-wrapper > div {
  height: 100%;
  flex: 1;
}

.search-form .btn,
.analytics_dropdown .btn:has(i) {
  background-color: transparent;
  border-color: transparent;
  border-radius: 10px;
  color: var(--lightgray);
  font-size: 22px;
  padding: 5px 5px 5px 15px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
}
.search-form .btn:has(i):focus {
  color: var(--lightgray);
  background: transparent;
  border: none;
}

.search-form .btn.active {
  color: var(--primary);
}

.search-form .btn.active .icon-map-pin {
  color: var(--green);
}

.person-search-wrapper .message-user-item .user-content {
  width: auto;
  flex: 1;
}

.category-btns-wrapper {
  margin: 25px 0 10px 0;
  display: inline-block;
  width: 100%;
}

.persons-imgs-wrapper {
  position: relative;
  flex: 1;
}

.category-btns-wrapper-slider .btn,
.category-btns-wrapper .btn {
  background-color: transparent;
  border: 1px solid #afafaf;
  border-radius: 25px;
  color: #9b9787;
  margin: 0 10px 10px 0;
  padding: 10px 20px;
  vertical-align: top;
  font-family: var(--int);
}

.category-btns-wrapper .btn:last-child {
  margin: 0;
}

.category-btns-wrapper-slider .btn.active,
.category-btns-wrapper-slider .btn:hover,
.category-btns-wrapper .btn.active,
/* .category-btns-wrapper .btn:focus, */
.category-btns-wrapper .btn:hover {
  background-color: #e8dfcd;
  color: black;
  border-color: #e8dfcd;
}

.invited-by-wrapper {
  display: flex;
  align-items: center;
  margin: 25px 0 0;
}

.invited-by-wrapper .user-img {
  margin: 0 15px 0 0;
}

.invited-by-wrapper .user-img img {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #dedede;
  object-fit: cover;
}

.invited-by-wrapper .user-info span {
  display: inline-block;
  width: 100%;
  margin: 0 0 3px;
}

.invited-by-wrapper .user-info span {
  font-size: 14px;
  color: var(--lightgray);
  line-height: 1.3em;
}

.invited-by-wrapper .user-info span.info-name b {
  color: var(--lightgray);
  font-weight: 400;
}

.session-profile-wrapper {
  padding: 50px 0 30px;
  margin-left: -10px;
}

.session-profile-wrapper.profile-modal {
  margin: 0;
  padding: 0;
}

.session-profile-wrapper .profile-block-wrapper {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}

.tabs-style-2,
.session-profile-wrapper .event-tabs {
  border: none;
  border-radius: 0;
  background-color: transparent;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-tabs {
  border: none;
}

.sliding-tabs .btn,
.nav-tabs .nav-link,
.tabs-style-2 ul li .btn {
  padding: 0 !important;
  background-color: transparent !important;
  color: var(--grey);
  font-family: var(--int);
  border: none;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sliding-tabs .btn:hover,
.sliding-tabs .btn:focus,
.sliding-tabs .btn.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link.active,
.tabs-style-2 ul li .btn:hover,
.tabs-style-2 ul li .btn:focus,
.tabs-style-2 ul li .btn.active {
  background-color: transparent !important;
  color: var(--green);
  font-family: var(--int);
}

.sliding-tabs .btn:not(.active):hover,
.tabs-style-2 ul li .btn:not(.active):hover {
  font-weight: 700;
}

.nav-tabs li,
.tabs-style-2 ul li {
  width: auto;
  margin: 0 10px 0 0;
}

.session-profile-wrapper .custom-tabs ul li .btn {
  padding: 12px 20px;
}

.session-profile-wrapper .event-tabs .add-icon {
  color: var(--gold);
  font-size: 17px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Messages Page */
.messages-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.messages-header .event-tabs {
  width: 325px;
}

.messages-header .add-icon {
  color: var(--gold);
  font-size: 18px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.messages-users-wrapper .search-input {
  margin-right: 0;
}

.messages-users-list {
  margin: 10px 0 0 0;
  height: 100%;
  width: 100%;
}

.message-user-item {
  padding: 15px 0 15px 20px;
  border-bottom: 1px solid #d5d5d5;
  display: flex;
  position: relative;
}

.custom-scroll .message-user-item {
  margin-left: 25px;
}

.message-user-item:last-child {
  border: none;
}

.message-user-item.new-message:before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: var(--gold);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0px;

  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* .message-user-item.active:after {
    content: "";
    width: 4px;
    height: 8px;
    background-color: var(--gold);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 0px;

    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
} */

.message-user-item .user-img {
  margin: 0 15px 0 0;
  position: relative;
  border-radius: 50%;
}

.message-user-item .user-content {
  width: 45%;
}

.share-image-count {
  position: relative;
}

.share-image-count span,
.message-user-item .user-img > span {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
  width: 25px;
  height: 25px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  font-size: 15px;
  color: var(--gold);
}

.share-image-count span {
  bottom: -5px;
  right: -5px;
}

.message-user-item .user-img img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid transparent;
}

.message-user-item.active .user-img img {
  border-color: var(--primary);
}

.user-content h2 {
  font-size: 16px;
  margin: 0 0 5px;
  line-height: 1.3em;
  color: #333333;
}

.user-content p {
  font-size: 14px;
  margin: 0 0 8px;
  line-height: 1.3em;
  color: #333333;
}

.message-date-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service-info {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message-date-info .message-date {
  font-size: 12px;
  color: #818181;
}

.message-other-users {
  display: flex;
  align-items: center;
}

.message-other-users .users-imgs img {
  width: 25px;
  height: 25px;
  max-width: 25px;
  display: inline-block !important;
  border: 2px solid #ededed;
  border-radius: 50%;
  margin: 0 0 0 -10px;
  background-color: #ededed;
  cursor: pointer;
  object-fit: cover;
}

.message-other-users {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.message-other-users .profile-description {
  margin-top: 0px;
  margin-bottom: 0px;
}
.message-other-users .users-count {
  font-size: 14px;
  color: #333333;
  margin: 0 15px 0 0;
  font-weight: 500;
}

.message-form {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid #d1d0d0;
  margin: auto 0 0 0;
  padding: 15px 0 0px 0;

  width: 100%;
  position: relative;
  background: #f7f6f2;
}

.message-form.message-smartchat-form {
  padding-bottom: 15px;
}

.message-form .form-control {
  background-color: #fff;
  border: 2px solid #f2f2f2;
  color: #6b6b6b;
  border-radius: 25px;
}

.message-form textarea.form-control {
  max-height: 160px;
  resize: none;
}

.message-form .form-icon {
  color: var(--lightgray);
  font-size: 22px;
  margin-right: 15px;
  width: 35px;
  text-align: center;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.message-form .smartlivechat-text-yellow-holder {
  padding-left: 15px;
  margin-right: 15px;
}

.messages-wrapper .form-icon {
  color: var(--green);
}

.message-smartchat-form .form-icon {
  margin-right: 0px;
}

.message-form.message-smartchat-form .form-icon.send-btn {
  margin-right: 8px;
  margin-left: 8px;
}

.message-form .form-icon.send-btn {
  margin: 0 7px 0 5px;
}

.landing-chat-option-holder .message-form .form-icon.send-btn {
  margin: 0 7px 0 5px;
}

.message-form .form-icon.send-btn i {
  font-size: 25px;
}

.message-form .form-input {
  flex: 1;
}

.message-form .msg-img {
  position: relative;
  margin-right: 15px;
}

.message-form .msg-img img {
  width: 75px;
  object-fit: contain;
}

.message-form .msg-img i {
  background-color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -3px;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  cursor: pointer;
}

.messages-main-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.messages-wrapper {
  padding: 0px 0 0px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
}

.messages-wrapper .messages {
  padding-left: 5px;
  padding-top: 20px;
}

.reply-box-wrapper {
  display: flex;
  flex: 1;
}

.create-session-links {
  text-align: center;
  font-size: 16px;
  color: var(--gold);
  margin: 15px 0 20px;
}

.messages-user-header {
  margin: 0 0 15px;
  display: flex;
  align-items: center;
}

.messages-user-header .user-img {
  margin: 0 20px 0 0;
}

.messages-user-header .user-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.messages-user-header .user-details h2 {
  font-size: 18px;
  margin: 0 0 5px;
  color: var(--dark);
  line-height: 1.2em;
}

.messages-user-header .user-details .user-friends {
  font-size: 14px;
  color: var(--gold);
}

.messages-header-icons {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.landing-chat-option-holder .messages-header-icons {
  margin-bottom: 0px !important;
}

.chat-option.landing-chat-option-holder .fix-width-forms {
  padding-right: 0px !important;
}

.messages-header-icons span {
  margin-left: 15px;
  font-size: 15px;
  color: var(--lightgray);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ganjaaaa .messages-header-icons span i {
  font-size: 23px;
  /* margin-right: 5px; */
}
.messages-header-icons span {
  color: #fff !important;
}
.messages-header-icons span i.icon-logout.our-custom-logout-icon {
  color: #db2d2d;
  font-size: 23px;
}
.messages-header-icons .poll-svg-img svg {
  width: 23px;
  height: 23px;
}
.ganjaaaa {
  /* background-color: black; */
  /* min-height: 85px; */
  z-index: 5;
  position: relative;
  /* padding: 10px; */
  display: flex;
  align-items: flex-start;
  /* padding-top: 22px; */
  line-height: normal;
}
.ganjaaaa2 {
  border-top-left-radius: 25px;
  z-index: 10;
  position: relative;
  top: -23px;
  background-color: #f7f6f2;
  border-top-right-radius: 25px;
  padding: 20px 15px 10px 0px;
}
.ganjaaaa > .new-sms-header-icons {
  margin-bottom: 0px;
}
.profile-links-btns.success-decline-btn-block {
  text-align: left;
  margin-bottom: 15px;
  margin-top: 0px;
}

.profile-links-btns.success-decline-btn-block .success-btn-class,
.profile-links-btns.success-decline-btn-block .decline-btn-class {
  margin-right: 10px;
  background: transparent;
  border-radius: 8px;
  font-size: 14px;
  font-family: var(--helveNeue);
  color: var(--lightgray);
  border-color: #e0e0e0;
  padding: 10px 12px;
}
.profile-links-btns.success-decline-btn-block .chatroom-status-disable-btn {
  pointer-events: none;
  margin-right: 10px;
  background: transparent;
  border-radius: 8px;
  font-size: 14px;
  font-family: var(--helveNeue);
  color: var(--lightgray);
  border-color: #e0e0e0;
  padding: 10px 12px;
}
span.info-icons.our-custom-info-icons {
  display: inline-block;
  vertical-align: text-top;
  font-size: 20px;
  line-height: 20px;
}
.profile-links-btns.success-decline-btn-block .success-btn-class {
  margin-right: 0px;
}
.our-custom-info-icons .icon-info-down .path1:before {
  content: '\e903';
  color: var(--gold);
}
.our-custom-info-icons .icon-info-down .path2:before {
  color: #fff;
}
.our-custom-info-icons .icon-info-down .path3:before {
  color: #fff;
}
.messages-header-icons span.icon.custom-plus-icon {
  margin-left: 0px;
}
.messages-header-icons span.icon.custom-plus-icon i {
  font-size: 22px;
}
.new-sms-header-icons {
  margin-left: 0px;
  margin-bottom: 35px;
}

.messages-wrapper .messages {
  margin-top: auto;
  flex: 1;
}

.message-day-saparator {
  font-size: 13px;
  text-align: center;
  color: #9f9f9f;
  margin: 0 0 5px;
}

.message-item {
  margin: 0 0 15px;
  display: flex;
  flex-direction: column;
}

.new-msg-item {
  padding-left: 55px;
}

.new-msg-item .msg-usr-img img {
  min-width: 45px;
  width: 45px;
  height: 45px;
}

.message-item.reply-message {
  align-items: end;
}

.message-item .msg-container {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%);
  padding: 15px 15px;
  border-radius: 20px;
  font-size: 15px;
  color: var(--dark);
  width: 60%;
  margin: 0 0 10px;
}

.message-item .msg-container p {
  font-size: 15px;
  margin: 0 0 7px 0;
  word-break: break-word;
}

.message-item.reply-message .msg-container {
  border-radius: 20px;
  /* background-color: var(--dark);
    color: #ffffff; */
}

.reply-message .msg-content-left-text {
  /* color: #fff; */
}
.show-on-public.show-on-public-profile-holder {
  padding-left: 0px !important;
  padding-right: 0px;
}
.show-on-public.show-on-public-profile-holder > div {
  justify-content: flex-end !important;
}

.show-on-public.show-on-public-profile-holder .form-switch .form-check-input {
  margin-left: -2em;
}

.show-on-public.show-on-public-profile-holder p {
  padding-right: 40px !important;
}

.msg-heading {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.msg-heading .show .btn,
.msg-heading .btn:hover,
.msg-heading .btn:focus,
.msg-heading .btn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  padding: 0;
}
.card-container.messages-card-note-container {
  margin: 0 15px 15px;
  border-radius: 8px;
}
.profileNotesSheetholder .card-container.messages-card-note-container {
  margin-left: 0px;
  margin-right: 0px;
}
.profileNotesSheetholder
  .profile-notes-new-screen
  .card-container.messages-card-note-container {
  margin-right: 20px;
}
.profileNotesSheetholder
  .profile-notes-new-screen
  .profile-notes-listing-container
  .card-container.messages-card-note-container {
  margin-right: 0px;
}
.message-form.message-ellipse-form-holder > span {
  position: relative;
  left: 30px;
  font-size: 20px;
  color: var(--gold);
}
.message-form.message-ellipse-form-holder > span i {
  font-size: 20px;
}
.messages-notes-field-form-block {
  padding: 0 15px 5px;
}
.messages-header-icons.new-sms-header-icons span:first-child {
  margin-left: 0px;
}
.message-adjust-img-container > img {
  width: 100%;
  object-fit: cover;
  height: auto;
  max-width: 425px;
  max-height: 250px;
  object-position: top;
}
.message-adjust-profile-desc {
  margin-top: 0px;
  margin-bottom: 0px;
}
.message-profile-notes-form .profile-notes-ellipse-iconn {
  font-size: 20px;
  color: var(--gold);
  margin-right: 10px;
}
.message-form.fix-width-forms.message-profile-notes-form {
  padding-bottom: 15px;
}
.profile-notes-cross-icon-holder i {
  font-size: 15px;
  line-height: 15px;
}
.message-form.fix-width-forms.message-profile-notes-form
  .form-icon
  .icon-play-icon {
  color: var(--gold);
}
.reply-wrapper {
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 16%);
  margin: 0;
  position: absolute;
  bottom: 109%;
  width: 100%;
  background-color: #f7f6f2;
}

.reply-wrapper.user-name-list {
  padding-left: 0;
  padding-right: 0;
}

.reply-wrapper.user-name-list .user-item {
  cursor: pointer;
  font-size: 15px;
  padding: 8px 15px;
}

.reply-wrapper.user-name-list .user-item:hover {
  background: var(--light);
  color: #fff;
}

.bottom-reply .msg-container {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  white-space: normal;
}

.messages-wrapper .reply-wrapper .close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: var(--lightgray);
  font-size: 18px;
}

.msg-usr {
  display: flex;
  align-items: center;
}

.msg-usr-img {
  margin: 0 10px 0 0;
}

.message-item.new-msg-item .msg-usr-img img {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.msg-usr-img img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.msg-usr-details h2 {
  font-size: 13px;
  margin: 0 0 0px 0;
  line-height: 1.3em;
  color: var(--dark);
}

.msg-usr-details span.msg-date {
  font-size: 12px;
  margin: 0;
  display: inline-block;
  color: #9f9f9f;
}

.message-item .msg-img img {
  max-width: 100px;
}

.message-item .msg-img video {
  max-width: 100%;
  height: auto;
}

.message-item .msg-img {
  width: auto;
  display: inline-block;
  margin: 10px 10px 0 0;
}

.message-item .quoted-msg {
  margin: 10px 0 0;
}

.message-item .quoted-msg .msg-container {
  width: 100%;
  background-color: #f7f6f2;
  border-radius: 10px;
  box-shadow: none;
}

.message-item.private-msg .msg-container,
.message-item .private-msg .msg-container {
  background-color: #6e685a;
}

.message-item.private-msg .msg-container .quoted-msg .msg-container {
  background-color: #f7f6f2;
}

.message-item.private-msg .msg-container .quoted-msg .msg-container p {
  color: var(--dark);
}

.message-item.private-msg
  .emoji-icons-wrapper
  .emoji-icons-item.add-new-reaction,
.message-item.private-msg .msg-user-replies-wrapper .reaction-count {
  color: #fff;
}

.private-msg .msg-container p {
  color: #fff;
}

.msg-reactions,
.msg-content {
  display: flex;
  justify-content: space-between;
}

.msg-content .icons {
  font-size: 18px;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  color: var(--lightgray);
}

.msg-content .replies-count {
  color: var(--gold);
}

.msg-content-left {
  margin-right: 5px;
  color: var(--dark);
  line-height: 1.4em;
  width: 90%;
  word-wrap: break-word;
}

.msg-content-left-text {
  width: 100%;
  display: inline-block;
  margin: 0;
  color: var(--dark);
}

.new-msg-item .msg-content-left {
  margin: 0 0 0 -75px;
  width: auto;
}

.new-msg-item .msg-content-right {
  flex: 1;
  margin: 0 0 0 25px;
}

.msg-content-right h2 {
  font-size: 14px;
  font-family: var(--helveNeue);
  color: var(--lightgray);
  margin: 0 0 5px;
}

.msg-content-right h2 span.msg-date {
  color: var(--lightgray);
  font-size: 14px;
}

.msg-content-right p {
  word-break: break-all;
}

.msg-reactions {
  margin: 15px 0 0;
  align-items: center;
}

.msg-reactions span {
  font-size: 14px;
}

.msg-reactions .reaction-count {
  margin-left: 5px;
}

.emoji-icons-wrapper {
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
}

.emoji-icons-live-chat-wrapper {
  align-items: center;
}

.emoji-icons-live-chat-wrapper .dot-options {
  padding-top: 2px;
}

.emoji-icons-wrapper .emoji-icons-item,
.msg-smart-reply-list-reactions .emoji-icons-item {
  border: 1px solid #f7f6f2;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  vertical-align: top;
  cursor: pointer;
}

.msg-smart-reply-list-reactions .emoji-icons-item {
  border: 1px solid #f7f6f2;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  vertical-align: top;
  cursor: pointer;
  background: #fff;
  font-size: 20px;
  color: var(--dark);
}

.chat-option.landing-chat-option-holder .emoji-icons-wrapper .emoji-icons-item {
  width: 23px;
  height: 23px;
}

.emoji-icons-wrapper .emoji-icons-item img {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.emoji-icons-wrapper .emoji-icons .reaction-count {
  font-size: 12px;
  margin-left: 10px;
}

.emoji-icons-wrapper .emoji-icons-item.add-new-reaction {
  font-size: 20px;
  color: var(--dark);
}

.chat-option.landing-chat-option-holder
  .emoji-icons-wrapper
  .emoji-icons-item.add-new-reaction {
  font-size: 13px;
  margin-right: 0px;
}

.dot-options {
  width: 25px;
  height: 25px;
  display: flex;
  margin: 0.2rem 0 0;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--lightgray);
}

.primary_panel_content.live_chat_box .refferal-list-wrapper .scrollbaar > div {
  padding-bottom: 0px;
}

.message-item.mb-0.new-msg-item.reply-message:last-child
  .msg-container.login_user_msg {
  margin-bottom: 0px;
}

.msg-user-replies-wrapper {
  margin: 0;
  display: flex;
  align-items: center;
}

.msg-user-replies-wrapper .person-img {
  display: inline-block;
  vertical-align: top;
  margin: 0 5px 0 0;
  cursor: pointer;
}

.msg-user-replies-wrapper .person-img img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.msg-user-replies-wrapper .reaction-count {
  font-size: 14px;
  color: var(--gold);
  margin-left: 5px;
}

.new-msg-item .quoted-msg {
  margin: 0px 0 15px 0;
}

.new-msg-item .quoted-msg .msg-date {
  color: var(--lightgray);
  font-size: 14px;
}

.reply-for-wrapper {
  background: #808080;
  padding: 8px;
  border-radius: 15px;
  margin: 10px 0 0;
}

/* Create Widget Page */
.widget-page-wrapper {
  padding-top: 25px;
  padding-bottom: 25px;
  min-height: 100vh;
  background-color: transparent;
}

.widget-content {
  padding-left: 10px;
}

/* .widget-page-wrapper .event-tabs {
    text-align: left;
    width: auto;
} */
/* .preferences_custom_events_tabs_slider .slick-track{display: flex;}
 .preferences_custom_events_tabs_slider .slick-slide{
    flex: 0 0 21%;
 } */
.tabs-style-2 {
  margin: 0 0 15px;
  width: auto;
}

.messages-header .event-tabs.tabs-style-2 {
  margin-bottom: 2px;
}

.refferal-tabs-custom-style {
  margin-bottom: 0px;
}

.tabs-style-2 ul li,
.nav-tabs li {
  margin: 0 20px 0 0;
}

.sliding-tabs .btn,
.tabs-style-2 ul li .btn,
.nav-tabs li .nav-link {
  font-size: 17px;
  /* font-family: var(--helveNeue); */
}

.widget-content .events-content-wrapper,
.widget-content .appointment-content-wrapper {
  margin: 30px 0 30px;
}

.widget-content .events-item {
  max-width: 100%;
}

.color-pallet {
  display: flex;
  justify-content: space-between;
  margin: 0 0 5px;
}

.widget-content .copy-btn {
  margin: 0 0 25px;
}

.widget-content .copy-btn .btn {
  background: #000;
  color: #fff;
  border-color: #000000;
  margin: 5px 0 0;
}

.widget-content .copy-btn .btn:hover,
.widget-content .copy-btn .btn:focus {
  background-color: transparent;
  color: #000;
}

.widget-content .copy-btn .url {
  font-size: 13px;
  color: var(--dark);
  word-wrap: break-word;
  width: 100%;
  display: inline-block;
  margin: 10px 0 8px;
  padding: 7px 13px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
  background: #fff;
  line-height: 1.3em;
}

.color-list ul {
  margin: 0;
  padding: 0;
}

.color-list ul li {
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 0;
}

.color-list .color-item {
  width: 25px;
  height: 25px;
  display: inline-block;
  border: 2px solid #bbb;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
}

.color-list .color-item.active {
  box-shadow: 2px 4px 1px rgba(0, 0, 0, 0.16);
  border-color: #fff;
}

.participant-grid-item__video {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* .session-video .participant-grid-item__video, */
.session-videos-grid .participant-grid-item__video {
  position: relative;
  width: 100%;
  height: auto;
}

.speakingperson {
  outline: 4px solid #c48e1d;

  border: none !important;
  transition: outline 0.3s ease-in-out;
}

span.spotify-btn {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  color: var(--black);
}

/* Spotify Page */
.spotify-content-wrapper.playing {
  padding-bottom: 80px;
}

.list-heading {
  margin: 0 0 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--borders);
}

.list-heading h2 {
  font-size: 18px;
  margin: 0;
}

.search-form {
  position: relative;
}

.search-form .form-control {
  border-radius: 8px;
  padding-left: 18px;
}

.spotify-search .search-input {
  margin: 0;
}

.spotify-search .spotify-btn {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 5px;
  top: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.spotify-search .spotify-btn i {
  font-size: 16px;
  color: #333;
  opacity: 0.41;
}

.spotify-item {
  flex-direction: row;
  margin: 0 0 15px;
  padding: 12px;
}

.spotify-item.active {
  border-color: var(--gold);
}

.spotify-item .album-img {
  width: 70px;
  margin-right: 15px;
  cursor: pointer;
}

.spotify-item .album-img img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 20px;
}

.album-desc {
  cursor: pointer;
}

.album-desc h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  line-height: 1.3em;
  color: #333333;
}

.album-desc h3 {
  font-size: 12px;
  font-family: var(--avenext);
  color: #333333;
  opacity: 0.41;
  margin: 3px 0;
}

.album-desc .length {
  font-size: 11px;
  color: #333333;
  display: inline-block;
  margin: 0;
}

.spotify-item .album-play-btn {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.spotify-item .album-play-btn span {
  cursor: pointer;
}

.spotify-item .album-play-btn i {
  font-size: 17px;
  color: var(--gold);
  margin-left: 10px;
}

.no-results {
  margin: 0 0 0;
  padding: 15px;
  text-align: center;
}

.no-results p {
  font-size: 15px;
  color: #333;
  line-height: 1.5em;
  margin: 0;
}

.spotify-player {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 54px;
  background: #fff;
  border-top: 1px solid var(--borders);
}

.spotify-content-wrapper .spotify-player {
  padding-left: 60px;
}

.spotify-content-wrapper.menu-open .spotify-player {
  padding-left: 250px;
}

.spotify-player ._ContentRSWP {
  padding-right: 45px;
}

.spotify-player .close-btn {
  position: absolute;
  top: 50%;
  right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #333333;
  cursor: pointer;
  z-index: 5;

  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Modal */
.modal .modal-dialog .modal-content {
  background-color: #000;
  padding: 0;
  box-shadow: none;
}

.modal .modal-header {
  padding: 1rem 30px;
}

.modal .modal-body {
  border-radius: 20px;
  background-color: #f7f6f2;
  padding: 25px 30px;
}

.modal-header-icons {
  display: flex;
  align-items: center;
}

.modal-header-icons span {
  margin-right: 15px;
  cursor: pointer;
}

.modal-header-icons i {
  font-size: 22px;
  color: var(--gold);
}

.modal-header-btns {
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;
}

.modal-header-btns .btn {
  margin: 0 20px 0 0;
  background-color: transparent;
  border-color: #4e4e4e;
  color: #4e4e4e;
  padding: 8px 15px;
  line-height: 1.5em;
  font-size: 14px;
}

.modal-header-btns .btn:hover {
  background-color: #4e4e4e;
  color: #fff;
}

.modal .close,
.modal-header-btns .close {
  opacity: 1;
  color: var(--gold);
}

.audio-icon.active i {
  color: var(--gold);
}

.modal-header-btns span {
  color: var(--gold);
  margin: 0 10px;
}

.modal-header-btns span.logout-link {
  color: #db2d2d;
}

.modal-logo img {
  max-width: 150px;
}

/* Stage Request Modal  */
.stage-request-wrapper {
  padding: 0 0 25px;
}

.stage-request-item {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  border-bottom: 1px solid var(--borders);
  padding: 15px 0;
}

.stage-request-item:last-child {
  border: none;
}

.stage-request-item .stage-img {
  margin: 0 20px 0 0;
}

.stage-request-item .stage-img img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}

.stage-request-item .stage-name {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--helveNeue);
}

.stage-likes-icons {
  margin-left: auto;
}

.stage-likes-icons span {
  margin-left: 10px;
}

.stage-likes-icons i {
  font-size: 20px;
  color: var(--gold);
}

.notes-profile-heading {
  display: flex;
  align-items: center;
}

.notes-profile-heading .profile-user-img {
  margin-left: auto;
}

.notes-profile-heading .profile-user-img img {
  margin-left: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.profile-title h2 {
  font-size: 18px;
  margin: 0 0 5px;
  color: #333333;
  font-family: var(--helveNeue);
}

.profile-title h3 {
  font-size: 16px;
  margin: 0 0 5px;
  color: #333333;
  font-family: var(--helveNeue);
}

.notes-sub-heading {
  display: flex;
  margin: 5px 0 0;
}

.notes-sub-heading span {
  font-size: 15px;
  color: #333;
  opacity: 0.41;
  font-family: var(--helveNeue);
  font-weight: 500;
  margin: 0 8px 0 0;
}

.notes-sub-heading span:last-child {
  margin-left: auto;
  margin-right: 0;
}

.notes-profile-fields {
  margin: 20px 0 0;
}

.notes-profile-fields .btn {
  width: 100%;
  padding: 15px;
  background-color: var(--gold);
  border-color: var(--gold);
  color: #fff;
  margin: 20px 0 0;
}

.notes-profile-fields .btn:focus,
.notes-profile-fields .btn:hover {
  background-color: transparent;
  border-color: var(--gold);
  color: var(--gold);
}

.dropdown-custom-full-width-menu.dropdown-menu.show {
  width: -webkit-fill-available; /* For WebKit-based browsers (Chrome, Safari) */
  width: -moz-available; /* For Firefox */
  width: fill-available;
  width: 100%; /* Standard property */
}

.dropdown.show .dropdown-menu.show {
  width: -webkit-fill-available; /* For WebKit-based browsers (Chrome, Safari) */
  width: -moz-available; /* For Firefox */
  width: fill-available;
  width: 100%; /* Standard property */
}

/* Messages Modal */

.modal-messages-wrapper .message-form {
  padding: 15px 25px;
  background-color: #fff;
  border: none;
  margin: 0 -30px -25px;
  border-radius: 0 0 25px 25px;
}

.modal-messages-wrapper .message-form .form-control {
  border: 1px solid var(--borders) !important;
  border-radius: 25px;
}

.messages-profile-heading {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin: 0 0 5px;
  /* border-bottom: 1px solid #e2e2e2; */
}

.message-profile-smart-chat-head-block {
  align-items: center;
}

.message-profile-smart-chat-head-block .messages-profile-heading {
  padding-top: 0px; /*padding-top: 15px;*/
  padding-bottom: 7px;
  margin-bottom: 7px;
}

.messages-profile-heading.with-panel {
  padding-right: 45px;
}

.messages-profile-heading .profile-img {
  margin-right: 15px;
}

.messages-profile-heading .profile-img img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.messages-profile-heading .profile-content {
  flex: 1;
  text-align: right;
}

.messages-profile-heading .user-name,
.messages-profile-heading .profile-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.messages-profile-heading .profile-links {
  justify-content: flex-end;
}

.message-user-count {
  margin-right: auto;
}

.profile-links-btns {
  text-align: right;
}

.profile-links-btns .btn {
  margin-left: 10px;
}
.profile-links-btns > .btn {
  margin-left: 15px;
}
.messages-profile-heading .user-name {
  margin: 0 0 10px;
}
.user-name.my-username {
  margin-bottom: 0px;
}
.message-profile-heading-nospace-block {
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid #e2e2e2;
  padding-left: 15px;
}
.messages-wrapper.fix-width-forms.message-chat-box-holder
  .message-profile-heading-nospace-block {
  padding-left: 20px;
}
.profile-head-icons-block span.icon.threeDots {
  font-size: 20px;
  color: var(--lightgray);
  cursor: pointer;
}
.profile-head-icons-block .image-holder svg {
  cursor: pointer;
}
.profile-head-icons-block {
  padding-top: 10px;
}
.message-profile-smart-chat-head-block .profile-head-icons-block {
  padding-top: 0px;
}
.profile-head-icons-block .image-holder {
  display: flex;
}
.profile-head-icons-block .image-holder span.icon.threeDots {
  margin-right: 20px;
}
.profile-head-icons-block .image-holder span.icon.active {
  color: var(--gold);
}
.message-profile-heading-nospace-block .profile-head-icons-block {
  margin-right: 23px;
}
.messages-profile-heading .user-name h2 {
  font-size: 16px;
  font-family: var(--helveNeue);
  color: #333;
  margin: 0;
}

.messages-profile-heading .user-name .btn {
  border: 1px solid #fff;
  background-color: #fff;
  color: #1f1f1f;
  padding: 8px 30px;
}

.messages-profile-heading .user-name .btn:focus,
.messages-profile-heading .user-name .btn:hover {
  background-color: #1f1f1f;
  border-color: #1f1f1f;
  color: #fff;
}

.messages-profile-heading .profile-links span {
  font-size: 14px;
  color: var(--gold);
  font-weight: 500;
}

.profile-links .btn,
.profile-links .btn:focus {
  background-color: transparent;
  padding: 0;
  border: none;
  color: var(--gold);
  font-size: 14px;
  font-weight: 500;
}

.profile-links .btn:focus .profile-links .btn:hover {
  background-color: transparent;
  color: var(--gold);
}

/* Login / Auth Page */
.auth-page-wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  background-position: center;
  flex-direction: column;
}

.auth-page-container {
  position: relative;
  z-index: 3;
  /* margin: 0 0 70px; */
  padding: 55px;
  /* background: #fff; */
  border-radius: 10px;
  max-width: 450px;
  border-radius: 35px;
}

.auth-page-wrapper .custom-modal {
  position: fixed;
}

.auth-page-container.medium {
  width: 100%;
  max-width: 500px;
}

.auth-logo {
  text-align: left;
  margin-bottom: 15px;
}

.register-component2 .auth-logo {
  margin-bottom: 10px;
}

.auth-logo img {
  max-width: 180px;
}

.auth-logo h3 {
  color: var(--dark);
  font-size: 14px;
  margin: 0;
  font-family: var(--ave);
  font-weight: 600;
}

.auth-form form .form-control:focus,
.auth-form form .react-tel-input .form-control,
.auth-form form .form-control {
  width: 100%;
  height: auto;
  max-width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--white);
  border-radius: 0;
  color: var(--white);
  padding: 8px 0 8px 0;
  font-size: 13px !important;
}

.auth-form form .form-control option {
  background-color: transparent;
  color: #000;
}

.auth-form form .react-tel-input .form-control {
  border: 0.954px solid #d9d9d9;
  border-radius: 7.651px;
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  padding-left: 48px;
  width: 100%;
}
.auth-login-custom-flag-form .react-tel-input {
  max-width: 322px;
}

/* .register-component2 .auth-form form .react-tel-input .form-control{width: 100%;} */

.verify-roi-phone .slider-component-content .slider-content {
  margin-top: 35px;
}

form .react-tel-input .form-control {
  padding-left: 48px;
  height: auto;
  width: 100%;
  max-width: 100%;
  line-height: 1.4em;
  border-radius: 25px;
}

.auth-page-container .form-control::placeholder {
  color: #fff;
}

.auth-form .form-group {
  margin-bottom: 15px;
}

.auth-form .form-group .info-text {
  margin: 15px 0 0 0;
}

.auth-form .btn {
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  padding: 13px 25px;
  letter-spacing: normal;
}

.auth-form .btn:focus,
.auth-form .btn:hover {
}

.resend-timing-seonds {
  font-size: 14px;
  color: var(--lightgray);
  flex: 1;
  margin-left: 15px;
  text-align: right;
}

.auth-form form .form-label {
  font-size: 12px;
  line-height: 1.4em;
  color: white;
  font-weight: 600;
  margin: 0 0 5px;
}

.react-tel-input .flag-dropdown {
  position: relative;
  top: -18px;
}

.react-tel-input .country-list {
  margin-top: 20px;
}

form .react-tel-input .flag-dropdown.open .selected-flag,
form .react-tel-input .selected-flag:hover,
form .react-tel-input .selected-flag:focus,
form .react-tel-input .flag-dropdown:hover,
form .react-tel-input .flag-dropdown:focus,
form .react-tel-input .flag-dropdown.open,
form .react-tel-input .flag-dropdown {
  background-color: transparent;
  border: none;
}

.auth-form form .react-tel-input .selected-flag .arrow {
  border-top-color: white;
}

.auth-form form .react-tel-input .flag-dropdown.open .selected-flag .arrow {
  border-bottom-color: var(--dark);
}

form .react-tel-input .selected-flag .arrow {
  border-top-color: #000;
}

form .react-tel-input .flag-dropdown.open .selected-flag .arrow {
  border-bottom-color: #000;
}

.otp-wrapper {
  display: flex;
  justify-content: space-between;
}

.otp-wrapper .form-group {
  margin-right: 15px;
}

.otp-wrapper .form-group:last-child {
  margin-right: 0;
}

.otp-wrapper .form-control {
  text-align: center;
}

.update-number {
  width: auto;
  font-size: 13px;
  display: inline-block;
  margin-top: 15px;
  color: var(--white);
  cursor: pointer;
  line-height: 1.5em;
}

.social-icons {
  display: flex;
}

.social-icons .icons {
  margin: 5px 8px 5px 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  text-align: center;
}

.social-icons.light .icons {
  color: #fff;
}

.social-icons .icons.active {
  color: var(--primary);
}

.nominated-by {
  margin: 0 0 25px;
  align-items: center;
  display: flex;
}

.nominated-by .nominated-by-img {
  margin: 0 15px 0 0;
}

.nominated-by .nominated-by-img img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.nominated-by-content h3 {
  font-size: 13px;
  color: var(--dark);
  margin: 0;
  line-height: 1.3em;
}

.nominated-by-content span.nomination-date {
  font-size: 11px;
  color: var(--dark);
}

.search-tabs-wrapper {
  margin: 0px 0 15px 0;
}

.search-tabs-icons {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* .appointment_schedule_activities .search-tabs-icons{margin-top: 2px;} */
.appointment_schedule_activities
  .search-tabs-icons
  .icon
  i.fas.fa-ellipsis-vertical {
  padding-top: 2px;
}
.schedule-activities-page-calendar
  .search-tabs-icons
  .icon
  i.fas.fa-ellipsis-vertical {
  padding-top: 2px;
}
.search-tabs-icons .icon,
.reply_options .icon {
  font-size: 20px;
  margin-left: 10px;
  color: var(--lightgray);
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 7px;
}
.schedule-activities-page-calendar .search-tabs-icons .icon {
  margin-top: 0px;
}

.schedule-activities-page-calendar .tabs-style-2 {
  margin-bottom: 0px;
}

.search-tabs-icons.search-refferal-tab-icons-holder .icon {
  margin-top: 0px;
}

.search-tabs-icons.customer-tabs-product-icons .icon {
  margin-top: 4px;
}

.search-feedback-tab-icons .icon {
  margin-top: 9px;
}

.search-feedback-tab-icons .icon i {
  color: #fff;
}

.search-createCatalog-tab-icons .icon {
  margin-top: 0px;
}

#unique-group-icon-holder > label {
  margin-bottom: 0px;
}

#unique-group-icon-holder {
  margin-bottom: 0.3rem;
}

.search-tabs-icons.search-tabs-profile-gallery-icons .icon {
  margin-top: -1px;
}

.my-ticke-icon i {
  color: var(--gold);
}

.event-ticket-pusrchase .events-headings {
  margin-top: 5px;
}

.event-ticket-pusrchase .events-desc-wrapper {
  margin: 10px 0 10px;
}

.search-tabs-icons .icon .icon-plus {
  font-size: 18px;
}

.search-tabs-icons .icon.active {
  color: var(--gold);
}

.profile-notes-new-screen .search-tabs-icons .icon.active {
  margin-right: 10px;
}

.search-tabs-wrapper .search-form {
  display: flex;
  align-items: center;
  margin: 15px 0 0;
}

.phone-messages-call-input-holder .search-tabs-wrapper .search-form {
  display: block;
}

.search-tabs-wrapper .search-form .search-input {
  flex: 1;
}

.search-tabs-wrapper .search-form .search-input .form-control {
  border-radius: 8px;
}
.search-tabs-wrapper .search-form .search-input-location .form-control {
  border-radius: 0px;
  margin-top: 14px;
  width: 168%;
  height: 55px;
}
.search-tabs-wrapper
  .search-form
  .search-input-location
  .phone-messages-call-input-contain
  .form-control {
  width: 97%;
}

.phone-messages-call-input-holder {
  flex-grow: 1;
}

.emoji-item {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin: 0 auto 20px;
  cursor: pointer;
}

.emoji-item img {
  max-width: 100%;
  max-height: 35px;
}

.emoji-icon {
  position: absolute;
  top: 5px;
  left: -3px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 50%;
}

.emoji-icon img {
  border: 3px solid #fff;
  width: 100%;
  height: auto;
  object-fit: unset;
  max-height: 100%;
  border-radius: 0;
  background-color: #fff;
}

/* Invite Page */
.invite-page-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 25px;
}

.invite-heading-logo {
  max-width: 150px;
  font-size: 16px;
}

.invite-page-heading .back-btn {
  cursor: pointer;
}

.invite-heading-logo img {
  max-width: 100%;
}

.invite-link {
  font-size: 13px;
  color: var(--gold);
  cursor: pointer;
}

.invite-page-search {
  margin: 0 0 15px;
}

.invite-page-search p {
  font-size: 14px;
  line-height: 1.3em;
  margin: 0;
}

.invite-page-search .search-form {
  margin: 15px 0;
}

.ScheduleScreen_Style .invite-page-search .search-form {
  /* margin-top: 0px; */
}

.invite-persons-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invite-snapshot-page-search .search-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invite-snapshot-page-search .search-form .search-input {
  width: 65%;
}

.invite-persons-heading.invite-persons-custom-heading {
  flex-direction: row-reverse;
  justify-content: start;
}

.invite-persons-heading.invite-persons-custom-heading .search-input {
  padding-left: 10px;
  width: 100%;
  margin-right: 15px;
}
.messages-wrapper.fix-width-forms.message-chat-box-holder
  .invite-persons-heading.invite-persons-custom-heading
  .search-input {
  margin-right: 0px;
  padding-left: 0px;
}
.invite-persons-heading.invite-persons-custom-heading
  .search-input
  > input[type='text'].form-control {
  height: 53px;
  line-height: 53px;
}
.invite-persons-heading span {
  font-size: 14px;
  color: var(--gold);
  font-weight: 600;
}

.invite-persons-heading .circle-link-icon {
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.invite-persons-heading .circle-link-icon i {
  font-size: 23px;
  margin-left: 10px;
}

.invite-persons-wrapper {
  margin: 15px 0;
}

.system-roles-invite-holder {
  margin-top: 1px;
}

.invite-persons-heading {
  margin-bottom: 15px;
}

.invite-btns {
  padding: 15px;
  text-align: center;
  /* position: absolute;
    left: 0;
    bottom: 0;
    width: 100%; */
}

.invite-externally-form .btn,
.invite-btns .btn,
.invite-btns .btn:focus {
  width: auto;
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.invite-externally-form .react-tel-input .form-control {
  border-radius: 25px;
}

.modal-header-icons span {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.modal-header-icons span i {
  margin-right: 5px;
  font-size: 18px;
}

.onboarding-profile .profile-img {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2e2e2e;
  border-radius: 50%;
  margin: 0 0 25px;
  font-size: 22px;
  background-color: var(--green);
  position: relative;
}

.onboarding-profile .profile-img img {
  object-fit: cover;
}

.onboarding-profile .profile-img i {
  font-size: 22px;
  color: #c7c7c7;
}

.onboarding-customerprofile-holder .profile-img i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #fff;
}

.onboarding-profile.onboarding-customerprofile-holder .profile-img img {
  width: 100px;
  height: 100%;
}

.slct-catgris-fieldset label {
  margin-top: 7px;
  margin-bottom: 15px;
}

.onboarding-profile.onboarding-customerprofile-holder .profile-img {
  border: none;
}

.onboarding-profile .profile-img input[type='file'] {
  position: absolute;
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  width: 22px;
  height: 22px;
  z-index: 5;
  left: 50%;

  top: 50%;
  transform: translate(-50%, -50%);
}

.onboarding-profile .profile-img p {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  cursor: pointer;
}

.onboarding-profile h3 {
  font-size: 22px;
  margin: 0 0 8px;
  color: var(--dark);
  line-height: 1.2em;
  font-weight: 600;
  font-family: var(--avenext);
}

.onboarding-profile p {
  font-size: 13px;
  margin: 0 0 5px;
  color: var(--dark);
  line-height: 1.2em;
}

.modal-header-btns .skip-btn.close {
  font-size: 14px;
}

.onboarding-profile .skip-link {
  color: var(--gold);
  font-size: 12px;
  cursor: pointer;
}

.onboarding-step-wrapper .btn {
  width: 100%;
  margin: 20px 0 0;
  background-color: var(--dark);
  border-color: var(--dark);
}

.onboarding-step-wrapper .btn:focus,
.onboarding-step-wrapper .btn:hover {
  color: #fff;
}

.onboarding-questions-wrapper .questions-heading {
  margin: -20px 0 20px;
}

.onboarding-questions-wrapper .questions-heading h4 {
  font-size: 13px;
  color: #727272;
  margin: 0 0 15px;
}

.onboarding-questions-wrapper .questions-heading h2 {
  font-size: 18px;
  color: #464646;
  margin: 0 0 8px;
}

.onboarding-questions-wrapper .questions-heading h2 b {
  color: var(--gold);
  font-weight: 600;
}

.onboarding-questions-wrapper .questions-heading p {
  font-size: 14px;
  color: #464646;
  margin: 0 0 5px;
}

.onboarding-questions-wrapper .previous-steps {
  margin: 0 50px 10px 0;
}

.onboarding-questions-wrapper .previous-steps a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
  margin: 0 0 5px;
  display: inline-block;
  cursor: pointer;
}

.onboarding-questions-wrapper .icon-info {
  font-size: 20px;
  cursor: pointer;
}

.onboarding-questions-wrapper .icon-info .path1::before {
  color: var(--gold);
}

.onboarding-questions-wrapper .form-check {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
}

.onboarding-questions-wrapper .form-check label {
  font-size: 15px;
  color: #464646;
  margin: 4px 0 0;
  cursor: pointer;
}

.onboarding-questions-wrapper .form-check input[type='checkbox'] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #707070;
  cursor: pointer;
}

.onboarding-questions-wrapper .form-check input[type='checkbox']:checked {
  background-color: var(--gold);
  border-color: var(--gold);
}

.ques-options-list {
  margin-top: 15px;
}

.ques-options-list ul {
  margin: 0;
  padding: 0;
}

.ques-options-list ul li {
  list-style: none;
  padding: 5px 0;
  /* border-bottom: 1px solid var(--borders); */
  display: flex;
  align-items: center;
  cursor: pointer;

  font-size: 14px;
  color: #464646;
  margin: 0 0 5px;
}

.ques-options-list ul li span {
  color: #707070;
  font-size: 20px;
  margin-right: 10px;
}

.onboarding-profile label {
  color: var(--dark);
}

.sub-oppo-type-list {
  margin: 15px 0 0px 30px;
  font-size: 14px;
  color: #464646;
}

.sub-oppo-type-list span {
  width: 100%;
  margin: 0 0 5px;
}

.recommend-item {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid var(--borders);
}

.recommend-item:last-child {
  border-bottom: none;
}

.recommend-item .recommend-img {
  /* width: 100px; */
  margin-right: 15px;
}

.recommend-item .recommend-img img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.recommend-name h2 {
  font-size: 18px;
  margin: 0 0 5px;
  color: #333333;
}

.recommend-name p {
  margin: 0;
  font-size: 14px;
  color: #333333;
}

.recommend-btns {
  margin-left: auto;
}

.recommend-btns .btn {
  margin: 0;
  background: transparent;
  color: #333333;
  padding: 7px 25px;
}

.recommend-btns .btn:focus,
.recommend-btns .btn:hover {
  background-color: #333333;
  color: #fff;
}

.refferal-list-wrapper {
  flex: 1;
  width: 100%;
  position: relative;
}

.refferal-list-wrapper.refferal-builders-list-container {
  padding-bottom: 30px;
}

.refferal-list-wrapper.refferal-list-holder {
  margin-bottom: 15px;
}
.fix-width-forms.fix-width-refferal-form {
  padding-bottom: 30px;
}
.my-custom-list-wrapper {
  height: 100%;
}
.refferal-list-wrapper .custom-refferal-vertical-scrollbar > div:nth-child(3) {
  display: none;
}
.sessions-list-wrapper.analytcis_page
  .custom-refferal-vertical-scrollbar
  > div:nth-child(3) {
  display: none;
}
.invite-persons-list.custom-invite-persons-listt {
  padding-bottom: 70px;
}
.referral-img {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}

.referral-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 10px 0 0;
}

.referral-img span {
  font-size: 25px;
  color: var(--gold);
}

.refferals-list-heading {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}

.refferals-list-heading p {
  font-size: 14px;
  color: #a5a5a5;
  margin: 0;
  line-height: 1.5em;
}

.refferals-list-heading .close-icon {
  font-size: 20px;
  color: #707070;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.refferals-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.refferal-img {
  margin: 0 15px 0 0;
}

.refferal-img img {
  width: 70px;
  min-width: 70px;
  height: 70px;
  margin: 0;
  object-fit: cover;
  border-radius: 50%;
}

.refferal-content {
  margin-right: 15px;
}

.refferal-content h2 {
  font-size: 18px;
  color: #464646;
  margin: 0 0 5px;
}

.refferal-content span.refferal-auther {
  font-size: 13px;
  margin: 0 0 5px;
  display: inline-block;
  color: #464646;
}

.refferal-content p {
  font-size: 14px;
  color: #464646;
  margin: 0 0 5px;
}

.refferal-content .refferal-date,
.refferal-content .refferal-info-que {
  width: 100%;
  display: inline-block;
  margin: 0 0 0px;
  color: #969696;
  font-size: 12px;
}

.refferal-content .refferal-info-que {
  font-size: 14px;
  margin-top: 8px;
}

.refferal-like-unlike .refferal-icons {
  color: #1a1a1a;
  font-size: 18px;
  margin-left: 0px;
  /* width: 30px;
    height: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-btn-black-img-holder {
  display: flex;
  align-items: center;
}

.share-btn-black-img-holder span {
  margin-left: 7px;
  font-size: 15px;
}

.share-btn-black-img-holder > img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

/* Session Filter Modal */
.target-icon {
  width: 30px;
  height: 30px;
  /* background-color: #fff; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.target-icon i {
  font-size: 22px;
  color: #000;
}

.filter-wrapper {
  width: 100%;
  padding: 0 15px 0 0;
}

.filter-wrapper.company-pcos-profile-holder {
  padding-right: 0px;
}

.referals-dataa-holder {
  width: 100%;
}

.custom-modal-filter {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.filter-wrapper .filter-headings {
  margin: 0 0 5px;
}
.filter-headings {
  margin: 0 0 5px;
}
.filter-headings h2 {
  font-size: 18px;
  color: #4d4d4d;
  margin: 0 0 5px;
}

.filter-wrapper .filter-headings h2 {
  font-size: 18px;
  color: #4d4d4d;
  margin: 0 0 5px;
}
.filter-headings p {
  font-size: 14px;
  color: #4d4d4d;
  margin: 0 0 5px;
}

.filter-wrapper .filter-headings p {
  font-size: 14px;
  color: #4d4d4d;
  margin: 0 0 5px;
}

.filter-category-list ul {
  margin: 0;
  padding: 0;
}

.custom-modal-content .filter-category-list ul {
  padding-bottom: 60px;
}
.custom-modal-content .customer-search-filter-holder .filter-category-list ul {
  padding-bottom: 30px;
}

.filter-category-list ul li {
  margin: 0;
  list-style: none;
  padding: 12px 0;
  border-bottom: 1px solid var(--borders);
  cursor: pointer;
}

.filter-category-list ul li:last-child {
  border: none;
}

.filter-category-list .form-label {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  width: auto;
  cursor: pointer;
  color: var(--lightgray);
}

.filter-category-list li.active .form-label {
  color: var(--primary);
}

.filter-category-list .form-label input[type='checkbox'] {
  margin: 0;
  padding: 0;
  display: none;
}

.filter-category-list .form-label span {
  width: 25px;
  height: 25px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px 0 0;
}

.filter-category-list .form-label span:after {
  font-weight: 900;
  content: '';
  font-family: var(--fontawsome);
  color: var(--gold);
  font-size: 22px;
}

.filter-category-list .form-label input[type='checkbox']:checked + span:after {
  content: '\f00c';
}

.filter-category-list .form-check {
  width: auto;
  margin-left: auto;
}

.filter-wrapper .btn {
  margin: 20px 0 0;
  padding: 13px 45px;
}

.filter-wrapper .btn:focus,
.filter-wrapper .btn:hover {
  background-color: transparent;
  color: #000;
}

.location-box {
  margin: 15px 0 15px;
}

.location-box .location-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 12px;
}

.location-box .location-heading h2 {
  font-size: 15px;
  color: #4d4d4d;
  font-weight: 600;
  width: auto;
  margin: 0;
  font-family: var(--helveNeue);
}

.location-box .location-heading h2 i {
  color: var(--gold);
}

.location-box .location-heading .form-check {
  margin: 0;
}

.location-box .location-heading .form-check label {
  font-size: 14px;
  font-weight: 400;
  margin: 3px 0 0 0px;
  color: var(--dark);
}

form .form-check-input,
form .form-check-label {
  cursor: pointer;
}

form .form-check-input.is-valid,
form .was-validated .form-check-input:valid,
form .form-check-input:checked {
  background-color: var(--gold) !important;
  border-color: var(--gold) !important;
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: var(--light);
  cursor: pointer;
}

/* form .form-check-input[type='radio']:focus {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');
} */

/* form .form-check-input[type='radio']:checked, */
/* form .form-check-input[type='radio']:focus:checked {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
} */

.frequency-box {
  margin: 10px 0;
}

.frequency-box h2 {
  font-size: 15px;
  color: #4d4d4d;
  margin: 0 0 10px;
}

.oppertunity-frequency-box {
  overflow-x: hidden;
}

.filter-category-list input[type='radio'] {
  width: 20px;
  height: 20px;
}

.filter-category-list .form-check {
  /* display: flex;
    align-items: center; */
}

.filter-category-list .form-check label {
  margin: 5px 0 0 0px;
}

.filter-category-list .form-check-input[type='radio']:checked {
  background-image: none;
}

.filter-category-list .form-check-input[type='radio'] {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-category-list .form-check-input[type='radio']:after {
  font-weight: 900;
  content: '';
  font-family: var(--fontawsome);
  font-size: 12px;
  color: #fff;
}

.filter-category-list .form-check-input[type='radio']:checked:after {
  content: '\f00c';
}

.location-box .clear-link,
.location-box .show-more-link {
  margin: 20px 0 5px;
  font-weight: 600;
  font-size: 13px;
  color: var(--gold);
}

.location-box .clear-link {
  margin: 0;
}

.events-img-slider img {
  width: 100%;
  border-radius: 25px;
  overflow: hidden;
}

.events-icons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0;
}

.events-icons-wrapper.events-snapshot-icon-wrapper {
  margin-top: 5px;
}

.reffer-profile-scroll-block.reffer-profile-scroll-holder {
  padding-bottom: 13px;
}

.events-icons-wrapper span {
  font-size: 18px;
  color: var(--gold);
}

.snapshotDetail-leave-icon-holder .events-icons-wrapper span {
  color: var(--borders);
}

.events-icons-wrapper .left-icons span {
  margin: 0 10px 0 0;
}

.events-icons-wrapper .right-icons {
  margin-left: auto;
}

.events-icons-wrapper .right-icons span {
  margin: 0 0 0 15px;
}

.events-desc-wrapper .message-other-users {
  margin: 10px 0 0;
}

.message-other-users .icon {
  margin: 0 15px 0 0;
  color: var(--gold);
  font-size: 18px;
}

.events-headings {
  margin: 10px 0 10px;
}

.events-headings h2 {
  font-size: 16px;
  margin: 0 0 2px;
  color: var(--dark);
}

.events-headings .category-name {
  font-size: 14px;
  color: var(--lightgray);
  word-break: break-all;
}

.events-headings .category-name.date {
  font-size: 15px;
  color: var(--dark);
}

.events-img-slider {
  margin: 0 0 15px;
}

.events-desc-wrapper p {
  font-size: 15px;
  line-height: 1.3em;
  color: var(--dark);
  margin: 0;
  word-break: break-word;
}

.events-desc-wrapper .event-address {
  font-size: 14px;
  line-height: 1.3em;
  margin: 10px 0 0;
  color: var(--lightgray);
  display: flex;
  align-items: center;
}

.event-address i {
  margin-right: 5px;
}

.event-happening {
  margin: 15px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-happening span {
  color: var(--gold);
  font-size: 14px;
  font-weight: 500;
}

.event-happening .user-img {
  display: flex;
  align-items: center;

  font-size: 15px;
  font-weight: 600;
}

.event-happening .user-img img {
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  height: 40px;
  object-fit: cover;
  margin-right: 10px;
}

.event-btn-wrapper {
  margin: 15px 0 0;
}

.event-btn-wrapper .btn {
  width: auto;
  margin: 10px 0 0;
  /* background-color: #1f1f1f;
  border-color: #1f1f1f; */
  color: #fff;
}

.event-btn-wrapper .btn:focus,
.event-btn-wrapper .btn:active,
.event-btn-wrapper .btn:hover {
  background: #52bd41;
  color: #fff;
  border-color: var(--green);
}

.events-desc-wrapper {
  margin: 15px 0 0 0;
}

.events-time-wrapper {
  margin: 15px 0 15px;
}

.events-time-item {
  width: 100%;
  background: #fff;
  border-radius: 20px;
  min-height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #e6e6e6;
  padding: 15px 10px;
}

.events-time-item span {
  margin: 0 0 7px;
}

.events-time-item span:last-child {
  margin: 0;
}

.events-time-item .day {
  color: #1f1f1f;
  font-size: 15px;
}

.events-time-item .date {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 16px;
  color: #fff;
  border-radius: 50%;
  background-color: #9b9787;
}

.events-time-item .month-year {
  color: #1f1f1f;
  font-size: 14px;
}

.events-time-item .time {
  color: #1f1f1f;
  font-size: 12px;
}

.events-time-item.selected {
  background-color: #9b9787;
}

.events-time-item.selected span {
  color: #fff;
}

.events-time-item.selected .date {
  background-color: #fff;
  color: #9b9787;
}

.events-time-slide {
  padding: 0 5px;
}

.events-desc-wrapper span.show-more {
  color: var(--gold);
  width: 100%;
  display: inline-block;
  margin: 5px 0 5px;
  font-size: 13px;
  cursor: pointer;
}
.events-desc-wrapper.events-desc-snapshot-wrapper .profile-description {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
.slick-slider .slick-dots {
  bottom: -32px;
}

.slick-slider .slick-dots button:before {
  width: 10px;
  height: 10px;
  border: 1px solid var(--gold);
  content: '';
  border-radius: 50%;
  opacity: 1;
}

.slick-slider .slick-dots .slick-active button:before {
  background-color: var(--gold);
}

.event-btn-wrapper select:focus-visible,
.event-btn-wrapper select {
  width: auto;
  margin: 5px auto 10px;
  display: block;
  padding: 3px 8px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 25px;
  box-shadow: none;
}

.event-btn-wrapper select:focus-visible {
  box-shadow: none;
  outline: none;
}

.ticket-icons-options {
  margin: 0 0 15px 0;
}

.ticket-icons-options ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.ticket-icons-options ul li {
  list-style: none;
  margin: 0 10px 0 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #e5e5e5;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ticket-icons-options ul li i {
  font-size: 15px;
}

.ticket-icons-options ul li.active {
  background-color: var(--gold);
  border-color: var(--gold);
}

.ticket-icons-options ul li.active i {
  color: #fff;
}

.create-ticket-wrapper {
  width: 100%;
}

.create-ticket-tabs {
  margin: 15px 0 15px 0;
}

.create-ticket-tabs.create-business-ticket-tabs {
  margin-bottom: 0px;
}

.top-business-profile {
  margin-top: 15px;
}

.create-ticket-tabs .custom-tabs.event-tabs {
  /* width: 350px; */
}

/*.create-ticket-tabs .custom-tabs.event-tabs li {
    width: 50%;
} */

.host-img-wrapper {
  position: relative;
  margin: 0 auto;
  display: inline-block;
}

.host-img-wrapper img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.host-img-wrapper .img-handle {
  position: absolute;
  width: 25px;
  height: 25px;
  background: var(--gold);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.host-img-wrapper .img-handle i {
  font-size: 13px;
}

.ticket-img-wrapper input,
.host-img-wrapper .img-handle input {
  position: absolute; /*absolute*/
  /* width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 5; */

  width: 25px;
  height: 25px;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  cursor: pointer;
}
.ticket-img-wrapper input[type='file'] {
  cursor: pointer;
}
::-webkit-file-upload-button {
  cursor: pointer;
}
.ticket-dates-wrapper .btn,
.create-ticket-form .btn {
  /* background-color: #1f1f1f;
    border-color: #1f1f1f;
    color: #fff; */
  font-family: var(--int);
}

.create-ticket-form .info-text {
  font-size: 14px;
  color: var(--dark);
}

.ticket-img-wrapper {
  width: 180px;
  height: 110px;
  border-radius: 25px;
  background: #9b9787;
  color: #fff;
  font-size: 23px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 1px 1px rgb(0 0 0 / 16%);
  position: relative;
  /* cursor: pointer; */
}

.ticket-img-wrapper i {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  cursor: pointer;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.location-icons {
  margin-right: 20px;
}

.location-icons .icon,
.create-ticket-form .fa-sync-alt {
  color: var(--gold);
  margin: 5px 0px 5px 5px;
  width: 30px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.create-ticket-form .form-control[readonly] {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ticket-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #9b9787;
  border-radius: 25px;
}

.ticket-dates-wrapper label {
  width: auto;
}

.ticket-dates-wrapper .form-check {
  margin-left: auto;
}

.week-days {
  display: flex;
}

.week-days .day {
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  background: #fff;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.week-days .day.active {
  background-color: var(--gold);
  border-color: var(--gold);
  color: #fff;
}

.sidebar-heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 10px;
}

.sidebar-snapshot-head-wrapper.sidebar-heading-wrapper {
  margin-bottom: 0.5rem;
}

.sidebar-snapshot-head-wrapper label {
  margin-bottom: 0px;
}

.sidebar-heading-wrapper.with-panel {
  margin-right: 45px;
}

.sidebar-heading-wrapper h2 {
  font-size: 18px;
  color: var(--dark);
  word-break: break-all;
}

.sidebar-heading-wrapper .heading {
  flex: 1;
  margin-right: 15px;
}

.sidebar-heading-wrapper .heading .backbtn {
  cursor: pointer;
}

.middle-heading h2 {
  margin: 0;
  font-size: 18px;
}

.invite-persons-list {
  padding: 0 15px;
}

.invite-persons-list .close,
.sidebar-heading-wrapper .close {
  color: var(--gold);
  font-size: 20px;
  margin-left: auto;
}

.invite-persons-list .stage-list-item {
  cursor: pointer;
}

.custom-invite-persons-listt.invite-persons-list .stage-list-item {
  cursor: default;
}

.invite-persons-list-holder .invite-persons-list .stage-list-item {
  cursor: pointer;
  width: 70px;
  /* margin: 0 auto; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.system-roles-invite-provider .stage-list-item {
  cursor: default;
}

.sidebar-heading-wrapper .close.logout {
  color: var(--danger);
}

.sidebar-heading-wrapper .add-new-link {
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.sidebar-heading-wrapper .add-new-link i {
  margin-right: 10px;
}

.sidebar-heading-wrapper .with-user-img {
  display: flex;
  align-items: center;
}

.sidebar-heading-wrapper .with-user-snapshot-img {
  margin-right: 7px;
}
.form-outline.form-snapshot-outline {
  max-width: 368px;
}
.sidebar-heading-wrapper .user-profile-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

.sidebar-heading-wrapper.with-panel .user-profile-img img,
.sidebar-heading-wrapper .user-profile-img.no-margin img {
  margin: 0;
}

.sidebar-heading-wrapper .leave-btn-wrapper {
  margin: 0 0 0 auto;
}

.content-sidebar .messages-wrapper {
  padding: 0;
  min-height: 90vh;
}

.appointment-page-wrapper .tabs-wrappper {
  margin: 30px auto 0;
  width: 100%;
  max-width: 1024px;
}

.appointment-content-wrapper .col-md-8 {
  display: flex;
  flex: 1;
}

.calender-wrapper {
  display: flex;
  flex-direction: column;
}

.calender-wrapper .col-md-4 {
  display: flex;
}

.custom-tabs {
  position: relative;
}

.custom-tabs.event-tabs {
  width: auto;
  border-radius: 6px;
  background-color: #edebe5;
  position: relative;
  display: inline-block;
  margin: 0 auto;
}

.custom-tabs .activeHandle {
  position: absolute;
  width: 50px;
  height: 100%;
  background-color: var(--gold);
  border-radius: 6px;
  left: 0%;
  top: 0;
  z-index: 0;

  -webkit-transition: 0.4s all 0s ease;
  -moz-transition: 0.4s all 0s ease;
  transition: 0.4s all 0s ease;
}

.custom-tabs.dark-tabs .activeHandle {
  background-color: var(--dark);
}

.custom-tabs ul {
  position: relative;
  z-index: 1;
}

.custom-tabs ul li {
  width: auto;
}

.custom-tabs ul li .btn {
  padding: 12px 35px;
  background-color: transparent;
}

.custom-tabs ul li .btn:hover {
  background-color: transparent;
  color: #272727;
}

.session-profile-wrapper .custom-tabs ul li .btn.active,
.session-profile-wrapper .custom-tabs ul li .btn:focus,
.custom-tabs.dark-tabs ul li .btn.active:focus,
.custom-tabs.dark-tabs ul li .btn.active,
.custom-tabs ul li .btn.active:focus,
.custom-tabs ul li .btn.active {
  background-color: transparent;
  color: #fff;
}

.session-profile-wrapper .custom-tabs ul li .btn:hover {
  background-color: transparent;
}

.sidebar-modal {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  padding: 25px 25px 25px 10px;
  background-color: #f7f6f2;
}

.sidebar-modal.with-custom-scroll {
  padding: 0;
}

.new-session-modal-wrapper.createSessionModal .sidebar-modal {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  box-shadow: 0px 1px 4.01px 0px #00000040;
  -webkit-box-shadow: 0px 1px 4.01px 0px #00000040;
}

.praise-list-wrapper .feedback-count {
  margin: 0 0 0px;
  font-size: 13px;
  color: #9b9787;
  line-height: 1.5em;
  display: inline-block;
}

.praise-list-item {
  padding: 20px 0;
  border-bottom: 1px solid #e2e2e2;
}

.no-border,
.praise-list-item.no-border {
  border: none;
}

.praise-list-item h3 {
  font-size: 14px;
  color: #1f1f1f;
  margin: 0 0 5px;
}

.praise-list-item p {
  font-size: 14px;
  line-height: 1.3em;
  color: #1f1f1f;
  margin: 0;
}

.praise-user-info {
  display: flex;
  align-items: center;
  margin: 10px 0 0;
}

.praise-user-info .user-img {
  margin: 0 10px 0 0;
}

.praise-user-info .user-img img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.praise-user-info h4 {
  font-size: 14px;
  color: #1f1f1f;
  margin: 0 0 0;
}

.praise-user-info .date {
  color: #9b9787;
  font-size: 12px;
}

.praise-user-info .user-icons {
  margin-left: auto;
}

.praise-user-info .user-icons .icon {
  margin-left: 10px;
  font-size: 20px;
  color: var(--gold);
}

.praise-response {
  padding: 0 0 0 25px;
  margin: 10px 0 0;
}

.praise-response .praise-list-item {
  padding: 15px 0 15px;
}

.praise-response .praise-list-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.praise-response .praise-list-item h3 {
  font-size: 14px;
}

.praise-response .praise-user-info {
  margin-top: 15px;
}

.custom-scroll {
  width: 100%;
  flex: 1;
}

.custom-scroll-content {
  padding: 25px 25px 25px 10px;
}

.add-praise-form-wrapper {
  margin: 15px 0 0;
}

.add-praise-form-wrapper .question {
  font-size: 14px;
  color: #1f1f1f;
  margin: 0 0 15px;
  display: block;
}

.add-praise-form-wrapper .event-tabs .btn {
  font-size: 22px;
  color: #9b9787;
}

.add-praise-form-wrapper .event-tabs .btn i.icon-bars-2 {
  font-size: 16px;
}

.add-praise-form-wrapper .form-label {
  color: #1f1f1f;
  font-size: 14px;
}

.images-list .camera-icon {
  width: 45px;
  height: 45px;
  border: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #9b9787;
  border-radius: 10px;
  color: #fff;
  position: relative;
  cursor: pointer;
}

.images-list .camera-icon i {
  cursor: pointer;
}

.images-list .camera-icon input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.add-praise-form-wrapper .btn {
  width: 100%;
}

.images-list {
  display: flex;
  columns: 5;
  flex-wrap: wrap;
}

.images-list-item {
  display: inline-block;
  vertical-align: top;
  margin: 0 3px 6px;
}

.images-list-item img {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  object-fit: cover;
}

.images-list.large-size .camera-icon,
.large-size .images-list-item img {
  width: 70px;
  height: 70px;
}

.choose-appointment-wrapper {
  margin: 15px 0 0;
}

.choose-appointment-wrapper p {
  font-size: 15px;
  line-height: 1.3em;
}

.appointment-cost-wrapper {
  display: flex;
  align-items: center;
  margin: 0 0 25px;
}

.duration-cost {
  font-size: 14px;
  line-height: 1.5em;
}

.duration-cost label {
  font-weight: 900;
  width: 100%;
}

.duration-cost .icons-wrapper {
  text-align: center;
  justify-content: flex-start;
}

.duration-cost .icons-wrapper .icon {
  margin: 0 10px 0 0;
  cursor: pointer;
}

.duration-cost .icons-wrapper .icon i {
  font-size: 16px;
  color: var(--dark);
  margin: 0;
}

.appointment-cost-wrapper .info-icon {
  margin-left: auto;
}

.appointment-cost-wrapper .info-icon .path1:before {
  color: #9b9787;
}

.appointment-date-wrapper {
  position: relative;
  z-index: 99;
}

.appointment-date-wrapper .date-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0 25px;
}

.appointment-date-wrapper .selected-date {
  background: #fff;
  flex: 1;
  text-align: center;
  padding: 8px;
  border-radius: 25px;
  margin: 0 15px;
}

.appointment-date-wrapper .calender-wrapper {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 5;
}

.appointment-date-wrapper .navs {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.available-hours {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
}

.available-hours .btn {
  background: transparent;
  color: #9b9787;
  border-color: #9b9787;
  margin: 0 5px 15px;
  padding: 8px 15px;
  width: 30.33%;
  font-family: var(--int);
}

.available-hours .btn:focus,
.available-hours .btn:hover {
  background-color: #e8dfcd;
  color: black;
  border-color: #e8dfcd;
}

.appointment-date-wrapper .schedule-btn .btn {
  width: 100%;
}

.appointment-payment-wrapper {
  margin: 30px 0 0;
}

.appointment-payment-wrapper .back-btn {
  width: 45px;
  height: 45px;
  font-size: 20px;
  margin-left: -15px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.payment-heading {
  margin: 0;
}

.payment-heading h4 {
  font-size: 18px;
  line-height: 1.4em;
  margin: 0 0 5px;
  color: #000;
}

.payment-heading .appointment-cost {
  font-size: 14px;
  color: #000;
}

.pay-with-apple .btn {
  background-color: transparent;
  border-color: #9b9787;
  color: #9b9787;
  width: 70%;
}

.pay-with-apple .btn:focus,
.pay-with-apple .btn:hover {
  background-color: transparent;
  border-color: var(--gold);
  color: var(--gold);
}

.appointment-payment-wrapper .form-check {
  padding-left: 28px;
  align-items: center;
  display: flex;
}

.appointment-payment-wrapper .form-check label {
  display: inline-block;
  width: auto;
  margin: 3px 0 0 10px;
}

.appointment-payment-wrapper .form-check-input {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.custom-radio-action-detail-holder .form-check-input {
  width: 1.25em;
  height: 1.25em;
}

.custom-radio-action-detail-holder .task.mt-1 {
  margin-top: 0px !important;
  margin-bottom: 10px;
}

.stage-likes-icons {
  display: flex;
  align-items: center;
}

.message-user-item.no-link.new-message .stage-likes-icons {
  margin-top: -7px;
}

.appointment-form .payment-btn .btn {
  width: 100%;
}

.appointment-types-wrapper {
  margin: 20px 0;
}

.appointment-types-wrapper .edit-link-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-link-icons .edit-link {
  font-size: 14px;
  color: #272727;
  cursor: pointer;
  font-weight: 600;
}

.edit-link-icons .info-icon {
  margin-left: auto;
}

.appointment-type-list {
  margin: 15px 0 15px;
}

.appointment-type-list h3 {
  font-size: 14px;
  margin: 0 0 15px;
  text-align: center;
  color: #9b9787;
  font-weight: 600;
}

.type-list-item {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e2e2e2;
}

.type-list-item .list-img {
  margin-right: 15px;
}

.type-list-item .list-img img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.list-content-wrapper {
  flex: 1;
}

.list-date-wrapper,
.list-headings {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-headings {
  margin: 0 0 5px;
}

.list-headings h2 {
  font-size: 18px;
  margin: 0 0;
  color: #000;
  line-height: 1.2em;
}

.list-title-icon {
  display: flex;
  align-items: center;
}

.list-title-icon .remove-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: var(--lightgray);
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 5px;
  font-size: 22px;
}

.list-title-icon .remove-icon .fa-phone,
.list-title-icon .remove-icon .fa-video {
  font-size: 18px;
}

.list-headings span {
  font-size: 14px;
  line-height: 1.3em;
  margin: 0 0 5px;
}

.list-date-wrapper span {
  font-size: 13px;
  color: #000;
}

.search-form .info-text {
  display: inline-block;
  text-align: center;
  margin: 10px 0;
  color: #a5a5a5;
  font-size: 13px;
}

.injection-icon-wrapper i {
  font-size: 18px;
  cursor: pointer;
  color: #000;
}

.opportunies-content-wrapper .heading {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
  margin-bottom: 15px;
}

.skillset-desired-bg-wrapper {
  margin: 0 0 15px;
}

.gg-map-wrapper {
  display: flex;
  flex-direction: column;
}

.gg-map-wrapper .gg-map {
  flex: 1;
  position: relative;
  margin-bottom: 20px;
}

.gg-map-wrapper .gg-map iframe {
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.gg-map-wrapper .invite-btns {
  margin-top: auto;
  padding: 0 15px;
}

.intrested-for-wrapper .btn {
  background-color: #080808;
  color: #ffffff;
  border-color: #080808;
  margin-bottom: 15px;
  font-family: var(--int);
}

.intrested-for-wrapper .btn:hover,
.intrested-for-wrapper .btn.active {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #ffffff;
}

.intrested-for-wrapper .btn:focus {
  /* color: var(--gold);
    border-color: var(--gold); */
}

.use-my-contacts {
  color: var(--gold);
  font-size: 14px;
  line-height: 1.4em;
  font-weight: 600;
  cursor: pointer;
}

.availability-tabs {
  display: flex;
  align-items: center;
  /* margin: 30px 0 15px; */
}

.availability-tabs span {
  font-size: 15px;
  color: var(--primary);
  font-weight: 500;
  line-height: 1.5em;
  cursor: pointer;
  flex: 1;
}

.availability-days {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
  max-width: 90%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.availability-days span {
  padding: 20px 10px;
  text-align: center;
  max-width: 58px;
  cursor: pointer;
  color: #000;
  flex: 1;
}

.availability-days span.active {
  background-color: var(--light);
  border-radius: 50px;
  color: #fff;
}

.availability-times {
  margin: 30px 0;
  padding: 0 15px;
}

.availability-times .row {
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.availability-selected-times .remove-btn,
.availability-times .add-time {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  color: #fff;
  border-radius: 5px;
  font-size: 15px;
  margin-bottom: 0px;
  cursor: pointer;
}

.availability-selected-times {
  margin: 0;
  padding: 0 15px;
}

.availability-selected-times table {
  width: 100%;
}

.availability-selected-times thead {
  border-bottom: 1px solid #e2e2e2;
}

.availability-selected-times thead th,
.availability-selected-times table td {
  padding: 10px;
  font-size: 14px;
  color: #0b0b0b;
  line-height: 1.5em;
  font-weight: 500;
}

.availability-selected-times .remove-btn {
  background-color: transparent;
  color: var(--lightgray);
  margin: 0 auto;
  font-size: 22px;
  width: 20px;
  height: 20px;
}

.availability-selected-times label {
  color: #0b0b0b;
  font-weight: 600;
}

.availability-times .row .col-md-5 > div .form-label {
  margin-bottom: 0.25rem;
}

/* .availability-times.mb-3 > .row:nth-of-type(3){height: 0vh;} */
.availability-times .add-time-holder {
  height: 0vh;
}

.reminder-screen-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.reminder-screen-wrapper .invite-btns {
  margin-top: auto;
}

.primary-color {
  color: var(--gold);
}

.invite-page-search .user-profile-wrapper {
  display: flex;
  align-items: center;
  text-align: left;
}

.invite-page-search .user-profile-wrapper .user-img {
  margin: 0 15px 0 0;
}

.invite-page-search .user-profile-wrapper .user-img img {
  width: 45px;
  height: 45px;
}

.invite-page-search .user-profile-wrapper .user-content h2 {
  font-size: 14px;
  color: #a5a5a5;
  line-height: 1.2em;
  margin: 0 0 3px;
  font-weight: 400;
}

.invite-page-search .user-profile-wrapper .user-content h3 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  line-height: 1.2em;
  margin: 0;
}

.message-opportunity-wrapper .message-form {
  position: initial;
  top: 0;
  margin-top: auto;
  padding-bottom: 0;
}

.message-opportunity-wrapper .message-form input[type='text'] {
  border-radius: 25px;
  border-width: 1px;
}

.leave-btn-wrapper {
  margin: 15px 0 25px;
  align-items: center;
  display: flex;
}

.leave-btn-wrapper .btn {
  border-color: #e2e2e2;
  background-color: transparent;
  padding: 5px 15px;
  color: #333;
  margin-right: 15px;
  cursor: pointer;
}

.leave-btn-wrapper .icon-info-down {
  font-size: 22px;
  cursor: pointer;
}

.leave-btn-wrapper .icon-info-down .path1:before {
  color: var(--lightgray);
}

.leave-btn-wrapper .icon-info-down .path3:before,
.leave-btn-wrapper .icon-info-down .path2:before {
  color: #fff;
}

.leave-btn-wrapper .users-icons {
  margin-left: 10px;
  color: var(--lightgray);
  cursor: pointer;
  font-size: 18px;
}

.message-opportunity-wrapper .message-form .form-icon .btn {
  background: transparent;
  padding: 10px;
  margin: 0;
  border: none;
  color: var(--gold);
  font-size: 18px;
}

.message-opportunity-wrapper .message-form .form-icon .dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  border: none;
  border-radius: 6px;
  overflow: hidden;
  min-width: auto;
  width: 100%;
  padding: 0;
}

.dropdown-menu .dropdown-item {
  border-bottom: 1px solid var(--borders);
  padding: 8px 15px;
}

.dropdown-menu .dropdown-item:last-child {
  border: none;
}

.dropdown-menu .dropdown-item:focus {
  background-color: var(--green);
  color: #fff;
}

.widgets-wrapper {
  background-color: transparent;
}

.sessions-widget-wrapper {
  overflow: hidden;
}

.sessions-widget-scroll {
  flex: 1;
  width: 100%;
}

.sessions-widget-wrapper > .card {
  display: flex;
  height: 550px;
  flex-direction: column;
  flex: 1;
}

.appointment-widget-wrapper.sessions-widget-wrapper > .card {
  height: 650px;
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}

.sessions-widget-wrapper .appointment-content-wrapper {
  width: 100%;
  margin: 0 auto;
  border-radius: 50px;
  box-shadow: 0 0 10px rgb(0 0 0 / 16%);
}

.media-controls {
  display: none !important;
}

.media-controls-container {
  display: none !important;
}

.no-results-wrapper {
  text-align: center;
}

.no-results-wrapper h3 {
  font-size: 18px;
  font-weight: 600;
}

.img-bg-wrapper {
  margin: 15px auto 30px;
  max-width: 150px;
}

.no-results-wrapper .img-bg-wrapper img {
  width: 100%;
}

.no-results-content {
  max-width: 300px;
  margin: 0 auto;
}

.no-results-content h2 {
  font-size: 17px;
  max-width: 100%;
  margin: 0px auto 6px;
}

.no-results-content p {
  font-size: 15px;
  line-height: 1.4em;
  color: var(--dark);
}

.no-results-content p span {
  color: var(--primary);
  cursor: pointer;
}

.no-results-link {
  color: var(--primary);
  text-decoration: none;
}

.oppo-noresults .no-results-content {
  max-width: 100%;
  text-align: left;
}

.no-results-icons span {
  width: 100%;
  display: inline-block;
  margin: 0 0 10px;
  font-size: 15px;
  text-align: left;
}

.no-results-icons span i {
  width: 20px;

  font-size: 20px;
  margin: 0 15px 0 0;
}

.btn-wrapper {
  text-align: center;
}

.btn-wrapper .btn {
  margin: 0 auto 20px;
}

.qa-list-item .invited-by-wrapper {
  margin-top: 10px;
}

.qa-list-item .invited-by-wrapper .user-img h4 {
  font-size: 16px;
  color: #1f1f1f;
  margin: 0 0 5px;
}

.qa-list-item .invited-by-wrapper .user-img .date {
  color: #9b9787;
  font-size: 12px;
}

.read-more-read-less .btn {
  width: 100%;
  background: transparent;
  border: none;
  color: var(--primary);
  margin-top: 15px;
  text-align: left;
  padding: 0;
}

.sliding-tabs {
  width: 100%;
  border: none;
  text-align: left;
  overflow: visible;
  padding: 0 15px;
}

.sliding-tabs .btn {
  text-align: center;
}

.category-btns-wrapper-slider .slick-prev:before,
.category-btns-wrapper-slider .slick-next:before,
.sliding-tabs .slick-prev:before,
.sliding-tabs .slick-next:before {
  opacity: 1;
  color: var(--dark);
  font-family: var(--fontawsome);
  font-weight: 900;
  font-size: 16px;
}

.category-btns-wrapper-slider .slick-prev:before,
.sliding-tabs .slick-prev:before {
  content: '\f053';
}

.category-btns-wrapper-slider .slick-next:before,
.sliding-tabs .slick-next:before {
  content: '\f054';
}

.category-btns-wrapper-slider .slick-disabled,
.sliding-tabs .slick-disabled {
  opacity: 0.65;
}

.category-btns-wrapper-slider .slick-disabled {
  opacity: 0.35;
}

.pref-tabs-wrapper .event-tabs {
  margin: 0px auto;
}

.prefs-content-wrapper {
  text-align: center;
  padding: 0 15px;
}

.prefs-content-wrapper .sliding-tabs {
  margin-top: 20px;
  margin-bottom: 25px;
}

.prefs-inner-content-wrapper {
  text-align: center;
}

.prefs-inner-content-wrapper h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 15px 0;
  text-align: left;
}

.prefs-form-content-wrapper {
  padding-bottom: 53px;
}

.checkbox-list {
  margin: 0 0 20px;
}

.checkbox-list h2 {
  font-size: 18px;
  margin: 0 0 10px;
}

.checkbox-list ul {
  margin: 0;
  padding: 0;
}

.checkbox-list li {
  list-style: none;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid var(--borders);
  padding: 15px 0;
  /* display: flex;
    align-items: center;
    justify-content: space-between; */
  color: var(--lightgray);
  font-size: 15px;
  position: relative;
}

.checkbox-list li:last-child {
  border: none;
}

.checkbox-list li.active {
  color: var(--gold);
}

.checkbox-list li i {
  font-size: 20px;
  color: var(--gold);
}

.checkbox-list li .check-icon {
  width: 25px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 10px;
  position: absolute;
  top: 15px;
  right: 5px;
}

.roi-form-dropdown.lead_button_dropdown {
  flex: 0 0 22%;
}

.checkbox-list li .icon-info {
  font-size: 18px;
  margin-right: 10px;
}

.checkbox-list li .icon-info .path1:before {
  color: var(--lightgray);
}

.checkbox-list li.active .icon-info .path1:before {
  color: var(--primary);
}

.checkbox-list .form-check label,
.checkbox-list .form-check input,
.checkbox-list .form-check {
  cursor: pointer;
}

.checkbox-sub-wrapper {
  width: 100%;
  margin: 10px 0 -15px 0;
  padding-left: 10px;
}

.category-btns-wrapper-slider {
  margin-top: 25px;
  padding: 0 15px;
}

.category-btns-wrapper-slider .btn {
  margin: 0;
}

.category-btns-wrapper-slider .slick-slide {
  padding: 0 5px;
  min-width: 90px;
}

.text-left {
  text-align: left;
}

.location-list-wrapper {
  margin-top: 15px;
}

.location-list-wrapper h2 {
  font-size: 16px;
}

.location-list-wrapper ul {
  margin-top: 10px;
  padding: 0;
}

.location-list-wrapper ul li {
  list-style: none;
  margin-bottom: 10px;
}

.location-list-wrapper ul li span {
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.location-list-wrapper ul li span i {
  margin-left: 5px;
  margin-top: 3px;
  color: var(--danger);
}

.discover-builders-wrapper {
  margin: 0;
  padding: 15px;
}

.discover-builders {
  display: flex;
}

.discover-item {
  text-align: center;
}

.discover-item .img-wrapper {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #ddd; */
  border-radius: 50%;
  margin: 0 auto 10px;
}

.discover-item .img-magnify-wrapper {
  background: #e9e6df;
}

.discover-item .img-magnify-wrapper.img-wrapper i {
  color: #000;
}

.discover-item .img-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.discover-item .img-wrapper i {
  color: #c3bfaf;
}
.discover-item .img-circle-wrap {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #ddd; */
  border-radius: 50%;
  margin: 0 auto 10px;
  background: #e9e6df;
}

.discover-item .img-circle-wrap > .img-wrapper {
  width: 30px;
  height: 30px;
  margin: 0;
  border-radius: 0px;
}
.discover-item .img-circle-wrap > .img-wrapper > img {
  border-radius: 0px;
}
.discover-item h3 {
  font-size: 13px;
  color: #333333;
  margin: 0 0 0px;
  font-weight: 500;
  font-family: var(--ave);
}

.discover-item p {
  font-size: 12px;
  color: #808080;
  margin: 0;
}

.discover-builders-slider {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
}

.discover-builders-slider .swiper {
  margin: 0;
}

.discover-builders-slider.discover-custom-builders-slider .swiper {
  padding-left: 5px;
  padding-right: 5px;
}

.discover-builders-slider .swiper-slide {
  width: auto;
}

.discover-builders-slider .swiper-button-next,
.discover-builders-slider .swiper-button-prev {
  color: #c3bfaf;
  background: #edebe4;
  padding: 55px 15px;
  top: 56%;
  font-size: 10px;
  right: 0;
  border-radius: 5px;

  -webkit-transform: translateY(-37%);
  -moz-transform: translateY(-37%);
  transform: translateY(-37%);
}

.discover-builders-slider .swiper-button-prev {
  left: 0;
}

.discover-builders-slider .swiper-button-prev:after,
.discover-builders-slider .swiper-rtl .swiper-button-next:after,
.discover-builders-slider .swiper-button-next:after,
.discover-builders-slider .swiper-rtl .swiper-button-prev:after {
  font-size: 22px;
}

.home-users-listing.customer_user_row .swiper-slide {
  width: 73px; /* Adjust based on design */
  min-width: 73px; /* Prevent collapsing */
  max-width: 73px;
  text-align: center;
  overflow: hidden; /* Prevent content overflow */
  white-space: nowrap; /* Prevent text wrapping */
}

.home-users-listing.customer_user_row.custom_user_row_assignee .swiper-slide {
  white-space: normal;
}

.home-users-listing.customer_user_row .swiper-slide h3 {
  margin: 0;
  text-overflow: ellipsis; /* Add ellipsis for overflow */
  overflow: hidden;
}

.home-users-listing.customer_user_row.custom_user_row_assignee
  .swiper-slide
  h3 {
  text-overflow: unset;
  white-space: normal;
}
.rec-carousel .rec-arrow {
  background-color: transparent;
  border: none;
}

.discover-builders-slider .slider-nav {
  min-width: auto;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  font-size: 25px;
}

.slider-navigation {
  display: flex;
}

.slider-navigation button {
  background: transparent;
  border: none;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-images-list-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 15px;
  background-color: #fcfbf9;
  border-radius: 8px;
  padding: 6px 15px;
}

.bg-images-list-wrapper .images-list-item {
  background-color: transparent;
  margin: 0 10px 10px 0;
  width: 35px;
  height: 30px;
  border-radius: 10px;
  border: 2px solid var(--lightgray);
  background-size: cover;
}

.bg-images-list-wrapper .images-list-item.active {
  border-color: var(--primary);
}

.bg-images-list-wrapper .default-list-item {
  color: var(--lightgray);
  font-size: 17px;
  display: flex;
  margin-left: 10px;
}

.bg-images-list-wrapper .default-list-item i {
  margin-right: 10px;
}

.bg-images-list-wrapper .default-list-item.active {
  color: var(--primary);
}

.panel-wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.sessions-list-wrapper .panel-wrapper {
  flex: 1;
  min-height: auto;
}

.panel-primary {
  width: 420px;
  max-width: 420px;
  border-right: 1px solid var(--borders);

  padding: 25px 20px 15px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.panel-secondary {
  width: 60%;
  flex: 1;
  padding: 25px 15px 0px;
  position: relative;
  display: flex;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;

  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.panel-wrapper .panel-handle {
  display: none;
}

.floated-panel .panel-primary {
  position: fixed;
  height: 100%;
  right: 0;
  border-left: 1px solid var(--borders);
  border-right: none;
  z-index: 2;
  background-color: #f7f6f2;

  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;

  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  transform: translateX(100%);
}

.floated-panel .panel-secondary {
  padding-top: 5px;
}

.floated-panel.open .panel-primary {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}

.floated-panel.open .panel-secondary {
  padding-right: 435px;
}

.panel-secondary.with-img .session-video-wrapper {
  z-index: 1;
}

.panel-secondary.with-img:after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--dark);
  left: 0;
  z-index: 0;
  opacity: 0.2;
}

/* Custom Modal */
.animate__animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

.custom-modal {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(237, 237, 237, 0.9);
  height: 100%;
  width: 100%;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  z-index: 99;
}

.custom-modal.bottom {
  align-items: flex-end;
  padding: 15px 0px 0;
}

.custom-modal.bottom .custom-modal-content {
  border-radius: 25px 25px 0 0;
}

/* .custom-modal.bottom .custom-modal-content.custom-chat-content-modal{
  width: 91%;
  margin: 0 auto;
} */

.sliding-panel-wrapper .close,
.custom-modal .close {
  position: absolute;
  right: 30px;
  top: 25px;
  color: var(--lightgray);
  font-size: 28px;
  cursor: pointer;
  z-index: 99;
  background: unset;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.custom-modal.recursion-modal .close {
  z-index: 0;
}

.custom-modal .close {
  background-color: transparent;
}
.sliding-panel-wrapper.header-class-pannel {
  padding-left: 0px;
  padding-right: 0px;
}
.sliding-panel-wrapper.header-class-pannel .close {
  color: var(--borders);
  background: transparent;
  top: 45px;
  /* right: 18px!important; */
}
.custom-modal-content {
  background-color: #f7f6f2;
  border-radius: 25px;
  padding: 35px 25px;
  width: 100%;
  margin: 0 auto;
  max-height: 85%;
  opacity: 0;
  display: flex;

  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.webcamCustomModal .custom-modal-content {
  max-height: 100%;
}
/* .custom-chat-content-modal.custom-modal-content{
  max-height: 90%;
} */
.camera-btn-bottom-txt {
  position: absolute;
  left: 0px;
  right: 0px;
  text-align: center;
  top: auto;
  bottom: 12px;
}
/* .custom-modal-content-square{border-radius: 8.03px;} */
.custom-modal.small .custom-modal-content {
  max-width: 450px;
}

.custom-modal.medium .custom-modal-content {
  max-width: 650px;
  /* box-shadow: none; */
}

.custom-modal-content.show {
  opacity: 1;
}

.session-notes-form {
  width: 100%;
}

.session-notes-form textarea {
  padding: 15px 15px;
  border-radius: 15px;
}

.session-notes-form .btn {
  width: 100%;
}

.sliding-panel-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f6f2;
  display: flex;
  flex-direction: column;
  padding: 25px 15px 0px 25px;
  z-index: 99;

  opacity: 0;

  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.sliding-panel-wrapper.transparent {
  background-color: transparent;
}

.sliding-panel-wrapper.show {
  opacity: 1;
}

.sliding-panel-content {
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.leave-session-content {
  text-align: center;
}

.leave-session-content h3 {
  font-size: 18px;
  margin: 0 0 10px;
  line-height: 1.3em;
  color: var(--dark);
  text-align: center;
}

.leave-session-content p {
  font-size: 15px;
  line-height: 1.4em;
  color: var(--dark);
  text-align: center;
  margin: 0 0 10px;
}

.leave-session-content .btn {
  width: auto;
  margin: 10px 15px 0 0;
}

.videoElement {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
}

.spinner {
  position: absolute;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f6f2;
}

.curved-spinner-bg,
.curved-spinner-webcam {
  position: absolute;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f6f2;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}
.custom-modal .curved-spinner-bg {
  position: absolute;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f6f2;
  border-radius: 35px;
}

.curved-spinner-webcam {
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}

.spinner-center {
  text-align: center;
}

.video-action-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0 25px 0;
}

.video-action-icons .icon-item {
  width: 40px;
  height: 40px;
  background: rgba(26, 26, 26, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 19px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.video-action-icons .icon-item.hangup {
  font-size: 12px;
  background-color: rgba(128, 20, 20, 0.65);
}

.video-action-icons .icon-item.active {
  background-color: var(--gold);
}

.sliding-panel-content .filter-wrapper {
  width: 100%;
  margin-bottom: 20px;
}

.custom-modal-content .filter-wrapper {
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 0px;
}
.custom-modal-content .filter-wrapper.filter-wrapper2 {
  padding-bottom: 0px;
}

.custom-modal-content .filter-search-location-container {
  padding-bottom: 90px;
}
.shareables-workspace-modal.custom-modal .custom-modal-content .filter-wrapper {
  padding-bottom: 0px;
}
.custom-modal-content .bg_transparent {
  border-radius: 25px;
  bottom: 10px;
}
.camera-options-list {
  width: 100%;
}

.camera-options-list ul {
  margin: 0;
  padding: 0;
}

.camera-options-list ul li {
  margin: 0;
  padding: 15px 0px;
  font-size: 16px;
  margin: 0;
  line-height: 1.1875em;
  color: #333333;
  border-bottom: 1px solid var(--borders);
  list-style: none;
  display: flex;
  align-items: center;
}

.p-reg-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.input-plus-icon-holder {
  display: flex;
  align-items: center;
}
.input-plus-icon-holder .search-tabs-icons {
  margin-left: 10px;
  color: #a39f8d;
}

.cross-icon-field {
  color: #a39f8d;
}

.camera-options-list ul li i {
  font-size: 20px;
  margin: 0 10px 0 0;
}

.camera-options-list ul li:last-child {
  border-bottom: none;
}

.create-circle-panel {
  width: 100%;
}

.remove-user-text {
  text-align: center;
  width: 100%;
}

.remove-user-text h2 {
  font-size: 20px;
  font-weight: 600;
}

.remove-user-text p {
  font-size: 16px;
  line-height: 1.4em;
  margin: 0 0 15px;
}

.remove-user-text .btn {
  margin-right: 15px;
}

.serach-filter-wrapper {
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.filter-btn-wrapper {
  margin-top: auto;
}

.filter-btn-wrapper .with-border-top {
  border-top: 1px solid #e5e5e5;
  padding-top: 5px;
}

.filter-btn-wrapper .btn {
  width: auto;
  margin: 0 8px 10px 0;
  font-family: var(--int);
}

.reffer-profile-scroll-block.reffer-profile-scroll-holder
  .filter-btn-wrapper
  .btn {
  margin-right: 0px;
  margin-left: 31px;
}

.serach-filter-wrapper .modal-header-btns {
  /* margin-right: 35px; */
}
.filter-pad-bottom-wrap {
  padding-bottom: 50px;
}
.focus-modal-content h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 15px;
}

.focus-modal-content h3 {
  font-size: 16px;
}

.focus-modal-content p {
  font-size: 15px;
}

.focus-modal-content .checkbox-list {
  margin-bottom: 25px;
}

.location-list li {
  display: flex;
  width: 100%;
  align-items: center;
}

.location-list .remove-icon {
  margin-left: auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 15px;
  color: var(--lightgray);
  font-size: 22px;
}

.location-list .remove-icon i {
  color: var(--lightgray);
}

.qr-img {
  margin: 0px 0 15px;
}

.qr-img img {
  max-width: 50%;
}

.qr-link {
  font-size: 16px;
  line-height: 1.4em;
  margin: 0 0 15px;
}

.language-wrapper {
}

.language-wrapper h2 {
  font-size: 20px;
  margin: 0 0 5px;
  line-height: 1.3em;
  font-family: var(--avenext);
}

.language-wrapper .choosen-lang {
  font-family: var(--ave);
  color: var(--primary);
  font-size: 14px;
  margin: 0 0 15px;
  display: block;
}

.language-filter {
  margin: 20px 0 15px;
}

.language-wrapper {
  position: relative;
}

.languages-list-wrapper {
  padding-bottom: 45px;
}

.languages-list-wrapper ul {
  margin: 0;
  padding: 0;
}

.languages-list-wrapper li {
  list-style: none;
  padding: 15px 10px 15px 10px;
  border-bottom: 1px solid var(--borders);
  position: relative;
  cursor: pointer;
  color: var(--lightgray);
}

.languages-list-wrapper li.active {
  color: var(--primary);
}

.languages-list-wrapper li:last-child {
  border: none;
}

.languages-list-wrapper li i {
  position: absolute;
  right: 10px;
  top: 50%;
  color: var(--primary);
  font-size: 20px;

  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.menus-accordian.all-menus-accordian .accordion-button {
  position: static;
  width: 50%;
}

.saved-card-wrapper {
  margin: 25px 0 0;
}

.saved-card-wrapper h3 {
  font-size: 19px;
  font-weight: 600;
  margin: 0 0 15px;
}

.saved-card-item {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
  flex-direction: row;
}

.saved-card-item .remove-icons {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  align-items: center;
}

.saved-card-item .remove-icons .icon,
.saved-card-item .remove-icons .remove {
  margin-left: 5px;
  font-size: 22px;
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.saved-card-item .remove-icons .icon.active {
  color: var(--primary);
}

.upcoming-content-text {
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333333;
}

.upcoming-content-text i {
  font-size: 20px;
  margin-right: 10px;
  color: var(--lightgray);
}

.dont-forget-events {
  margin: 30px 0 0;
  padding: 25px 20px;
  font-size: 15px;
  background-color: rgba(0, 0, 0, 0.7);
  background-size: cover;
  border-radius: 10px;
  color: #fff;
  position: relative;
}

.dont-forget-events > div {
  position: relative;
  z-index: 1;
}

.dont-forget-events:after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
/* 
.events-bg-img {
  background-image: url('../images/event-bg-img.png');
} */

/* .session-bg-img {
  background-image: url('../images/sessions-bg-img.png');
} */

.icons-wrapper {
  margin: 5px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #f7f6f2;
}

.icons-wrapper > .d-block {
  text-decoration: underline;
}

.icons-wrapper i {
  display: block;
  margin-top: 5px;
  font-size: 20px;
}

.secondary-content-wrapper {
  widows: 100%;
  flex: 1;
}

.session-video-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.new-session-modal-wrapper {
  margin: 0 auto;
}

.new-session-modal-wrapper h3 {
  font-size: 23px;
  line-height: 1.3em;
  margin: 0 0 10px;
  color: var(--dark);
  word-wrap: break-word;
}

.new-session-modal-wrapper p {
  font-size: 15px;
  line-height: 1.3em;
  margin: 0 0 10px;
  color: var(--dark);
  word-wrap: break-word;
}

.new-session-modal span {
  color: var(--gold);
  font-size: 15px;
  word-wrap: break-word;
}

.new-session-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0 0 0;
}

.new-session-btn-wrapper .btn {
  margin: 0 10px;
}

.copy-link-text {
  cursor: pointer;
  display: block;
  margin: 0 0 0 15px;
  font-size: 15px;
  color: var(--gold);
}

.copy-link-text i {
  margin: 0 10px 0 0;
  color: var(--gold);
  font-size: 20px;
}

.msg-polls-wrapper {
  margin: 10px 0 0 0;
}

.msg-polls-wrapper h5 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 5px;
  line-height: 1.2em;
  white-space: normal;
  word-break: break-all;
}

.msg-polls-wrapper .participates-count {
  font-size: 14px;
  color: var(--dark);
  display: block;
  margin: 0 0 10px;
}

.reply-message .msg-polls-wrapper .participates-count {
  /* color: #fff; */
}

.msg-polls-wrapper .poll-item {
  /* display: flex;
    align-items: flex-end; */
  margin: 0 0 15px;
}

.msg-polls-wrapper .poll-item:last-child {
  margin: 0;
}

.msg-polls-wrapper .poll-item .poll-label {
  font-size: 13px;
  margin: 0 0 5px;
  display: block;
  white-space: normal;
  word-break: break-all;
}

.msg-polls-wrapper .poll-item .poll-left {
  flex: 1;
  margin-right: 15px;
}

.msg-polls-wrapper .poll-item .poll-right {
  margin-left: 10px;
}

.msg-polls-wrapper .poll-item .poll-vote {
  /* cursor: pointer; */
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 25px;
  color: var(--gold);
}

.make-btn-small {
  width: 100px !important;
  cursor: pointer;
}

.msg-polls-wrapper .progress-bar {
  background-color: var(--gold);
}

.msg-polls-wrapper.poll-closed .progress-bar {
  background-color: var(--lightgray);
}

.msg-polls-wrapper.poll-closed .poll-item .poll-vote {
  color: var(--lightgray);
}

.fix-width-forms {
  max-width: 550px;
  padding-right: 15px !important;
  /*   for-scrollbar overlap issue   */
}
.fixed-width-inputs {
  /* max-width: 860px; */
  /* padding-right: 15px; */
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.black-head-form-pad-left {
  padding: 0 20px;
}
.fixed-width-inputs.fix-width-forms {
  max-width: none;
}
/* .black-head-form-pad-right{padding-right: 15px;} */
/* .msg-create-group-list-holder .black-head-form-pad-right{padding-right: 10px;} */
.msg-create-group-list-holder .fixed-width-inputs {
  max-width: 870px;
}
.fix-width-padd-remove {
  padding-right: 0px !important;
}
.with-user-img.fix-width-forms.fix-width-padd-remove {
  padding-right: 10px !important;
}
.profile-gallery-wrapper.menus_gallery.profile-gallery {
  padding-right: 0px !important;
}
.catalog-content.catalog-content-extended.fix-width-forms.ml-1.pb-5.catalog-content-advancement {
  padding-right: 0px !important;
}
.gallery-imgs.my-snapshots.pb-5
  .gallery-snapshot-row-holder
  .col-md-4:last-child {
  padding-right: 0px;
}
.card.profile-block-wrapper.fix-width-forms.card-edit-profile-block-wrapp {
  padding-right: 20px !important;
  background: #f7f6f2;
  border: none;
  padding-bottom: 50px;
}
.scroll-profile-user-block {
  margin-bottom: 20px;
}
label.bio-label.form-label {
  font-weight: bold;
}
.message-chat-box-holder {
  max-width: 100%;
}
.messages-wrapper.fix-width-forms.message-chat-box-holder {
  padding-right: 0px !important;
}
.messages-wrapper.fix-width-forms.message-chat-box-holder .new-msg-item {
  padding-left: 70px;
}
.message-form.message-ellipse-form-holder {
  margin-bottom: 15px;
}
.session-address-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0;
}

.session-share-favs {
  display: flex;
  align-content: center;
  margin-left: auto;
}

.session-address-wrapper span {
  color: #464646;
  font-size: 15px;
}

.permission-modal-content {
  width: 100%;
}

.permission-modal-content h3 {
  font-size: 20px;
  margin: 0 0 15px;
  color: var(--dark);
}

.permission-modal-content h3 i {
  margin: 0 10px 0 0;
  color: var(--lightgray);
}

.permission-modal-content p {
  font-size: 15px;
  line-height: 1.4em;
  margin: 0 0 5px;
}

.permission-modal-content .btn {
  margin-right: 10px;
}

.permission-modal-content .btn:last-child {
  margin-right: 0;
}
.custom-modal-content
  .permission-modal-content
  .recruitement_dropdown_setInvites {
  margin-bottom: 35px;
}
.location-field.form-control {
  padding: 0px 5px;
  border-radius: 8px;
  padding-right: 0px;
}
.location-field.form-control .css-1hwfws3 {
  padding: 0px 8px;
  height: auto;
}
.location-field.form-control .css-1g6gooi {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  position: relative;
  left: -3px;
}
.location-field > div > div {
  border: none;
  background-color: #fff;
  box-shadow: none;
  border-radius: 25px;
}
.location-field.form-control input[type='text'] {
  padding: 11px 15px !important;
  padding-left: 0px !important;
}
.location-field > div > div > div:last-child {
  /* display: none; */
}
.create-ticket-form .css-2b097c-container .css-yk16xz-control {
  width: 100% !important;
  display: block;
}
.create-ticket-form-padding-applied {
  padding-bottom: 75px;
}
.create-ticket-form-padd-bottom {
  padding-bottom: 70px;
}
span.clear-btn {
  /* position: absolute; */
  /* display: inline-block;
    text-align: right; */
  /* width: 100%; */
  margin: 0 8px 0 10px;
  font-size: 13px;
  color: var(--green);
  cursor: pointer;
  font-family: Inter;
  font-size: 15.044px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* bootstrap Valoidation icon and colour remove */
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
  border-color: #ced4da !important;
  padding-right: 0.75em;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
  border-color: #ced4da !important;
  padding-right: 0.75em;
}

.calender-wrapepr {
  width: 100%;
  margin: 15px 0;
}

.spinner-border {
  color: var(--dark);
}

.heading-btn-wrapper {
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 15px 15px;
  border-bottom: 1px solid var(--borders);
}

.heading-btn-wrapper .btns-wrapper {
  margin-left: auto;
}

.heading-btn-wrapper .btn {
  width: auto;
  margin-left: 15px;
  padding: 12px 20px;
}

.heading-btn-wrapper .headings .icon {
  color: var(--primary);
  font-size: 20px;
}

.custom-datepicker-field .rmdp-container {
  width: 100%;
}

.filter-btn-wrapper .select-wrapper {
  width: 150px;
  border-radius: 25px;
  margin-left: 15px;
}

.purchase-filter-ticket-btn-wrapper .ticket-free-wrapper {
  width: 100%;
  flex: 0 0 35%;
}
.purchase-filter-ticket-btn-wrapper.filter-btn-wrapper .select-wrapper {
  width: auto;
}
.mb-3.payment-card-custom-block {
  margin-bottom: 8px !important;
}
/* .roi-tags-icons.flex.items-center .slick-slide{margin-right: 10px;} */
.mb-3.payment-card-custom-block {
  margin-bottom: 8px !important;
}
.roi-tags-icons.flex.items-center .flex-grow.overflow-x-auto .slick-slide div {
  justify-content: flex-start;
  padding-left: 5px;
  padding-right: 5px;
}
.roi-tags-icons.roi-profile-block-tags-icons .tag-icon {
  min-width: 40px;
  margin-right: 5px;
}
.roi-tags-icons.roi-profile-block-tags-icons .slick-track {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.roi-tags-icons.roi-profile-block-tags-icons {
  margin-top: 20px;
  margin-bottom: 20px;
}
.roi-tags-icons.roi-profile-block-tags-icons span.text-sm {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.profile-tags-card-holder .tag_card {
  max-height: 56px;
}
.availible-tag-hold h2 {
  padding-left: 2px;
}
.flex.flex-wrap.profile-tags-card-holder {
  padding-left: 15px;
}
.flex.flex-wrap.profile-tags-card-holder > .fix-width-forms {
  flex: 0 0 auto;
}
.flex.flex-wrap.profile-tags-card-holder > .fix-width-forms .tag_card {
  justify-content: flex-start;
  gap: 20px;
}
.flex.flex-wrap.profile-tags-card-holder .multi-line-ellipsis {
  max-width: 400px;
}
.panel-primary .flex.flex-wrap.profile-tags-card-holder .multi-line-ellipsis {
  max-width: 165px;
}

/* .catalog-content-extended .customized-select-wrapper{
  margin-left: 3px;
  margin-right: 150px;
}

.catalog-content-extended .customized-select-wrapper::after{right: 10px;}

.catalog-content-extended .customized-input-group .form-control{
  width: 81.5%!important;
  margin-left: 3px!important;
  margin-bottom: 10px;
} */

.catalog-content-extended {
  padding-bottom: 20px;
}
.catalog-content-advancement {
  margin-right: 0.25rem;
}
.customized-input-group .input-membership-hold {
  padding-left: 10px;
}
.textarea-field-box-shadow {
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25);
}
.customized-catalog-radio-btns {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

.customized-catalog-radio-btns .form-check label {
  margin-bottom: 0px;
}
.custom-filter-icon-holder .icon-filter-icon {
  font-size: 22px;
}
.messages-note-head-holder {
  width: 100%;
}
.messages-note-head-holder .event-tabs {
  width: 100%;
}
.messages-note-head-holder .LabelTabs {
  width: 100%;
}
.messages-note-head-holder .LabelTabs li:last-child {
  flex: 1;
  text-align: right;
}
.messages-note-head-holder .LabelTabs li:first-child {
  margin-left: 20px;
}
.messages-note-head-holder .LabelTabs.page-snapshots-tabs li:first-child {
  margin-left: 15px;
}
.messages-note-head-holder .event-tabs ul li .btn {
  width: auto;
}
.no-results-wrapper.note-no-result-wrap-holder {
  padding-bottom: 15px;
}
.customized-catalog-radio-btns .form-check {
  margin-right: 10px;
  min-height: auto;
  display: flex;
  align-items: center;
}

.customized-catalog-radio-btns .form-check:last-child {
  margin-right: 0px;
}

.customized-catalog-radio-btns .form-check-label {
  padding-left: 4px;
}
.customized-catalog-radio-btns .form-check .form-check-input {
  margin-left: -1.3em;
  float: none;
  margin-top: 0px;
}

.single-line-text-trunkey {
  position: relative;
}

.customized-catalog-radio-boxes {
  width: 87%;
}

.customized-catalog-content-holder,
.incentives-group-holder,
.roles-group-holder label,
.money-group-holder label,
.payment_milestone_label {
  font-family: 'inter';
}
.customized-catalog-content-holder label,
.incentives-group-holder label,
.roles-group-holder label,
.money-group-holder label {
  font-size: 15px;
}
.first-visits-block {
  margin: 0;
  padding: 0;
}
.payment_milestone_item .input-label {
  font-family: 'inter';
  font-size: 14px;
}
.input-group.customized-catalog-content-holder > label,
.input-group.incentives-group-holder > label {
  font-size: 17px;
}
.create-catalogue-accord-holder.accordion {
  max-width: 690px;
}
.catalog-content.catalog-content-extended
  .create-catalogue-accord-holder.accordion {
  max-width: 100%;
}
.catalog_screen .input-group.customized-catalog-content-holder > label,
.catalog_screen .input-group.incentives-group-holder > label {
  font-size: 15px;
}

.catalog_screen .customized-catalog-content-holder label,
.catalog_screen .incentives-group-holder label,
.catalog_screen .roles-group-holder label,
.catalog_screen .money-group-holder label {
  font-size: 14px;
}

.catalog_screen .customized-catalog-content-holder,
.catalog_screen .incentives-group-holder,
.catalog_screen .roles-group-holder label,
.catalog_screen .money-group-holder label {
  font-family: 'inter';
}

.create-catalogue-accord-holder .accordion-item .accordion-button:before {
  right: 2px;
}
.input-group.roles-group-holder .select-wrapper.roles-select-wrapper.dropdown {
  margin-left: 3px !important;
}
.triger_plus_main.triger_plus_main_extra {
  align-items: flex-end;
  position: relative;
  top: -8px;
  right: 15px;
}
.triger_plus_main.triger_plus_setting {
  align-items: flex-end;
  position: relative;
  bottom: 20px;
}

.input-group.customized-catalog-content-holder {
  margin-bottom: 10px;
}
.profile-gallery-wrapper.menus_gallery.profile-gallery
  .dropdown-search-icon
  .select-wrapper
  .dropdown
  .dropdown-toggle {
  width: 99.5%;
}
.profile-gallery-wrapper.menus_gallery.profile-gallery
  .dropdown-search-icon
  .select-wrapper
  .dropdown-menu.show {
  width: 100%;
}
.create-catalogue-accord-holder
  .accordion-header
  .accordion-button:not(.collapsed),
.create-catalogue-accord-holder .accordion-header .accordion-button,
.create-catalogue-accord-holder
  .accordion-header
  .accordion-button:not(.collapsed):focus,
.create-catalogue-accord-holder .accordion-header .accordion-button:focus {
  font-size: 18px;
  color: var(--gold);
  font-weight: bold;
}
.create-catalogue-accord-holder .accordion-header button {
  padding-top: 0px;
  padding-bottom: 8px;
}

.create-catalogue-accord-holder .input-group > input {
  width: 100% !important;
}
.create-catalog-main-block {
  padding-bottom: 10px;
}
.catalog-main-content-block {
  width: 100%;
}
.catalog_product_item.create-catalog-payment-item {
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
/* .catalog_product_item.create-catalog-payment-item.create-catalog-payment-item-new{background: #e4e6e6;} */
.offering-txt-head {
  margin: 0 0 10px;
}
.offering-txt-head > p {
  padding-left: 3px;
}
.first-visits-block-inner {
  margin: 0 auto;
  max-width: 90%;
  /* ---rehman's css below: ---- */
  background: #fcfbf9;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 41px;
  padding: 20px;
}

/* .visits-header {
  position: relative;
  padding: 20px;
  background-image: url('../images/about-bg-img.png');
  border-radius: 15px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 25px;
  background-size: cover;
} */

.visits-header:after {
  content: '';
  position: absolute;
  background-color: var(--dark);
  opacity: 0.7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.visits-header h2 {
  position: relative;
  z-index: 1;
  color: #fff;
  max-width: 80%;
  margin: 0 auto;
  font-size: 20px;
  line-height: 1.5em;
  font-weight: 600;
  font-family: var(--avenext);
}

.visits-list {
  margin: 0 0 30px;
}

.visits-list ul {
  margin: 0;
  padding: 0;
}

.visits-list ul li {
  list-style: none;
  margin: 0 0 10px;
  padding: 0;
  display: flex;
  align-items: center;
  color: var(--dark);
  font-size: 15px;
}

.visits-list ul li i {
  width: 20px;
  height: 20px;
  text-align: center;
  margin: 0 8px 0 0;
  color: var(--lightgray);
  font-size: 20px;
}

.visit-btn-wrapper {
}

.visit-btn-wrapper .btn {
  margin: 0;
  padding: 12px 45px;
}

.send-refferal-form-wrapper textarea {
  border-radius: 15px;
}

.send-refferal-form-wrapper .info-text {
  font-size: 13px;
  color: var(--lightgray);
  display: inline-block;
  margin: 10px 0 0 0;
}

.profile-content-infos h2 {
  font-size: 20px;
  color: var(--dark);
  margin: 0 0 10px;
}

.profile-content-infos p {
  font-size: 16px;
  color: var(--dark);
  margin: 0 0 10px;
  line-height: 1.3em;
}

.snapshot-preview-wrapper {
  margin: 0;
  padding: 15px 15px;
}

.snapshot-preview-wrapper .snapshot-item {
  position: relative;
  margin: 0 0 30px;
}

.snapshot-preview-wrapper .remove-icon {
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: var(--dark);
  border-radius: 50%;
  color: #fff;

  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

.snapshot-preview-wrapper img {
  max-width: 100%;
  border-radius: 15px;
}

.ticket-qty-wrapper h3 {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  color: var(--dark);
}

.send-referrals-wrapper {
  margin: 0 0 15px;
}

.send-referrals-wrapper h2 {
  font-size: 20px;
  margin: 0 0 5px;
  line-height: 1.2em;
  color: var(--dark);
}

.send-referrals-wrapper h3 {
  font-size: 15px;
  margin: 0 0 8px;
  line-height: 1.2em;
  color: var(--lightgray);
}
.send-feed-refferals-wrapper {
  margin-top: 15px;
}
.send-feed-refferals-wrapper h2 {
  font-size: inherit;
}
.interested-feed-for-wrapper h2 {
  margin-bottom: 7px;
}
.sendfeedback-holder {
  min-height: auto;
  height: auto;
}
.sendfeedback-holder .sendfeed-head {
  line-height: 0.2rem;
}
.date-share-wrapper span,
.date-share-wrapper .refferal-date {
  font-size: 14px;
  margin: 0 0 15px;
  line-height: 1.2em;
  color: var(--lightgray);
  display: block;
}

.date-share-wrapper span {
  font-size: 16px;
  color: var(--dark);
  margin: 0 0 5px;
  cursor: pointer;
}

.send-referrals-wrapper .praise-user-info .user-img {
  cursor: pointer;
}

.send-referrals-wrapper .praise-user-info .user-icons .icon {
  color: var(--lightgray);
  cursor: pointer;
}

.send-referrals-wrapper .praise-user-info .user-icons .icon.active {
  color: var(--gold);
}

.replies-user-wrapper {
  padding-right: 20px;
}

.replies-user-wrapper .events-img-slider video,
.replies-user-wrapper .events-img-slider img {
  max-width: 100%;
  margin: 0 auto;
}

.replies-user-wrapper .message-user-item {
  padding-left: 0px;
}

.replies-user-wrapper .message-user-item .user-content {
  flex: 1;
  padding-right: 0;
}

.user-reactions-emoji-lists {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 0 10px;
}

.reactions-emoji-item {
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #d5d5d5;
  display: flex;
  align-items: center;
}

.reactions-emoji-item:last-child {
  border: none;
}

.reactions-emoji-item .user-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.reactions-emoji-item h2 {
  flex: 1;
  margin: 0 15px;
  font-size: 18px;
  line-height: 1.3em;
  color: #333333;
}

.list-emoji-icon img {
  width: 30px;
}

.capture-with-camera-wrapper {
  width: 100%;
}

.capture-with-camera-wrapper .video-img,
.capture-with-camera-wrapper .video {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
}

.capture-with-camera-wrapper .video-img {
  margin-left: 15px;
}

.capture-with-camera-wrapper .video-img img {
  max-width: 100%;
}

.capture-with-camera-wrapper video {
  width: 100%;
  /* width: 450px; */
  height: auto;
  object-fit: cover;
}

.capture-with-camera-imgs {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-not-found-content {
  margin: auto;
  text-align: center;
}

.page-not-found-content h2 {
  font-size: 45px;
  line-height: 1em;
  margin: 0 0 5px;
  color: var(--dark);
  font-family: var(--avenext);
}

.page-not-found-content h3 {
  font-size: 25px;
  line-height: 1.3em;
  margin: 0 0 10px;
  color: var(--dark);
  font-family: var(--avenext);
}

.page-not-found-content p {
  font-size: 16px;
  margin: 0 0 20px;
  color: var(--dark);
}

.settign-integ-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.settign-integ-btns .btn {
  margin: 0 7px;
}

.snapshot-imgs-wrapper {
  margin: 15px 0 0;
}

.snapshot-imgs-wrapper .snapshot-imgs-item {
  margin: 0 10px 10px 0;
  cursor: pointer;
  position: relative;
  display: inline-block;

  overflow: hidden;
}

.snapshot-imgs-overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(110, 107, 95, 0.97);
  font-size: 18px;
  border-radius: 15px;
}

.snapshot-imgs-wrapper .snapshot-imgs-item video,
.snapshot-imgs-wrapper .snapshot-imgs-item img {
  width: auto;
  max-height: 150px;
  border-radius: 15px;
  /* object-fit: cover; */
  max-width: 100%;
}

.snapshot-imgs-wrapper .snapshot-imgs-item video {
  width: 100%;
}

.loadmore-imgs-link {
  margin: 10px 0 0;
}

.loadmore-imgs-link span {
  color: var(--gold);
  font-size: 15px;
  cursor: pointer;
}

.like-unlike-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.like-unlike-wrapper .icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  color: var(--lightgray);
  font-size: 16px;
}

.like-unlike-wrapper .icon i {
  color: var(--lightgray);
  font-size: 20px;
  margin-right: 10px;
}

.like-unlike-wrapper .icon.active,
.like-unlike-wrapper .icon.active i {
  color: var(--gold);
}

.chat-img-preview {
  text-align: center;
}

.chat-img-preview img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.react-tel-input .country-list {
  overflow-y: auto !important;
}

.no-pointer {
  cursor: initial !important;
}

.cancel-events-wrapper {
  margin-left: auto;
}

.cancel-events-wrapper .cancel-btn {
  color: var(--primary);
}

.event-persons-list {
  margin: 0;
  text-align: left;
  width: 100%;
}

.event-persons-list .stage-person-img {
  display: inline-block;
  width: auto;
  margin: 0 15px 20px;
  text-align: center;
}

/* Gifts Page */
.gifts-item.events-item .session-heading {
  align-items: center;
}

.gifts-item .event-date {
  color: #817d6c;
  margin: 0 0;
}

.gifts-item .event-titles h2 {
  color: var(--dark);
  margin: 5px 0;
}

.redeem-claim-btn .btn {
  padding: 8px 25px;
}

.gifts-info-wrapper {
  margin: 10px 0 10px;
}

.gifts-info-wrapper span,
.gifts-info-wrapper p {
  font-size: 15px;
  color: var(--dark);
  line-height: 1.2em;
  margin: 0 0 10px;
}

.gifts-info-wrapper span {
  font-weight: 600;
  display: inline-block;
  margin-bottom: 5px;
}

.gifts-receive-address-wrapper {
  margin: 0 0 0px;
}

.gifts-receive-address-wrapper h2 {
  font-family: var(--ave);
  font-size: 15px;
  color: var(--dark);
  line-height: 1.2em;
  font-weight: 600;
  margin-bottom: 20px;
}

.gifts-receive-address-wrapper .fieldset label {
  font-size: 15px;
  color: var(--dark);
  margin: 0 0 8px;
  display: inline-block;
}

.moderator-btns {
}

.moderator-btns .btn:hover,
.moderator-btns .btn:focus,
.moderator-btns .btn {
  background-color: transparent;
  border: none;
  color: var(--light);
  padding: 5px;
  margin: 0 0 0 5px;
}

.moderator-btns .btn i {
  font-size: 18px;
}

.app-notifications-wrapper {
  width: 400px;
  height: 100vh;
  padding: 15px 0 15px 15px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}

.app-notifications-wrapper .notification-items {
  width: 100%;
  background-color: #2a2b28;
  padding: 15px;
  border-radius: 15px;
  margin: 0 0 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  opacity: 0;

  /* -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    transform: translateX(100%); */

  -webkit-transition: all 0.5s ease-in 0s;
  -moz-transition: all 0.5s ease-in 0s;
  transition: all 0.5s ease-in 0s;
}

.app-notifications-wrapper-inner {
  padding-right: 10px;
}

.app-notifications-wrapper .notification-items.in {
  opacity: 1;

  /* -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    transform: translateX(0%); */
}

.notification-items .notification-userImg {
  margin: 0 15px 0 0;
}

.notification-items .notification-userImg img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.notification-items .close-btn {
  color: #fff;
  font-size: 22px;
  margin-left: auto;
}

.notification-items .notification-content {
  margin: 0 15px 0 0;
}

.notification-items .notification-content h3 {
  color: #fff;
  font-size: 16px;
  line-height: 1.2em;
  margin: 0 0 5px;
}

.notification-items .notification-content p {
  color: #fff;
  font-size: 12px;
  margin: 0;
}

.excluded-dates-wrapper {
  margin: 20px 0 10px;
}

.excluded-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 15px;
  border-bottom: 1px solid var(--borders);
  background: #fff;
  border-radius: 25px;
  font-size: 15px;
  margin: 0 0 10px;
}

.excluded-item:last-child {
  margin: 0;
}

.excluded-item .remove-item {
  color: var(--lightgray);
  font-size: 18px;
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.share-externally-link {
  color: var(--gold);
  cursor: pointer;
  font-size: 16px;
  margin: 0 0 10px;
}

.emoji-modal-wrapper .custom-modal-content {
  height: 69.2%;
  padding-bottom: 10px;
}

.emoji-modal-container {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.fixed-modal {
  position: fixed;
}

.fixed-modal .custom-modal-content {
  overflow-y: auto;
}

.claim-ticket-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.claim-ticket-item p {
  margin-bottom: 0;
}

.tab-select {
  width: 100%;
  padding: 0 15px;
}

.tab-select select {
  text-align: center;
}

.bodyVisible {
  overflow-y: auto;
}

.analytics-chart-wrapper {
  margin: 0 0 20px;
}

.analytics-chart-heading {
  margin: 0 0 15px;
}

.analytics-chart-heading h3 {
  font-size: 16px;
  text-align: center;
  color: #333;
  margin: 0 0 0;
}

.analytics-chart-heading h3 i {
  color: #9b9787;
  font-size: 16px;
}

.analytics-chart {
  width: 100%;
  height: auto;
  background-color: #e8e6df;
  border-radius: 25px;
  padding: 15px;
}

.analytics-chart canvas {
  width: 100% !important;
  height: auto;
}

.contributers-search-box {
  width: 90%;
  margin: 0 auto 15px;
}

.contributer-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 0 15px 0;
}

.contributer-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #eee;
}

.contributer-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contributer-content {
  width: 100%;
}

.contributer-content h2 {
  font-size: 18px;
  color: #333;
  margin: 0 0 5px;
}

.contributer-content .attendees-text {
  width: 100%;
  display: inline-block;
  margin: 0 0 5px;
  font-size: 14px;
  color: #272727;
  font-family: var(--helveNeue);
  font-weight: 400;
}

.contributer-content .attendees-sub-text {
  width: 100%;
  display: inline-block;
  font-size: 14px;
  color: #8d8d8d;
  font-family: var(--helveNeue);
  font-weight: 400;
}

.right-side-search .btn {
  padding: 0 13px 0 17px;
}

.business-profile-block {
  max-width: 450px;
  margin: 30px auto 0;
}

.business-profile-block .busi-img {
  margin: 0 auto 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.business-profile-block .busi-img img {
  width: 130px;
  height: 140px;
  object-fit: cover;
  border-radius: 25px;
}

.business-profile-block .busi-img .user-profile-img img {
  border-radius: 50%;
  border: 6px solid #fff;
  width: 120px;
  height: 120px;
}

.business-profile-block .user-profile-img {
  margin-left: -30px;
}

.business-social-list,
.business-followers-list {
  text-align: center;
  margin: 0 0 20px;
}

.business-social-list ul,
.business-followers-list ul {
  margin: 0;
  padding: 0;
}

.business-social-list ul li,
.business-followers-list ul li {
  display: inline-block;
  margin: 0 8px;
  list-style: none;
  position: relative;
}

.business-followers-list ul li img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.business-social-list li i {
  color: #9c9785;
  font-size: 25px;
}

.business-followers-list ul li .plus-number {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #858174;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  border-radius: 50%;
  cursor: pointer;
}

.business-services-list-wrapper {
  margin: 25px 0 30px;
}

.list-video-item {
  width: 100%;
  min-height: 200px;
  background-color: #2e2e2e;
  border-radius: 20px;
  margin-bottom: 25px;
  margin-bottom: 25px;
  overflow: hidden;
  position: relative;
}

.list-video-item video,
.list-video-item iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.business-list-item {
  margin: 0 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.business-list-item .business-img {
  width: 130px;
  height: 130px;
  margin: 0 15px 0 0;
}

.business-list-item .business-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}

.business-list-item .business-content {
}

.business-list-item .business-content h2 {
  font-size: 18px;
  color: #333;
  margin: 0 0 5px;
}

.business-list-item .business-content .attendees-text {
  width: 100%;
  display: inline-block;
  margin: 0 0 5px;
  font-size: 14px;
  color: #272727;
  font-family: var(--helveNeue);
  font-weight: 400;
}

.business-list-item .business-content .shop-icons {
  display: flex;
  align-items: center;
  margin: 5px 0 0;
}

.business-list-item .business-content .shop-icons i {
  margin: 0 15px 0 0;
  color: var(--primary);
  width: 25px;
  height: 25px;
}

.appointment-page-cotent {
  flex: 1;
  width: 100%;
  position: relative;
}

.appointment-block .business-profile-block .busi-img {
  margin-bottom: -25px;
  position: relative;
  z-index: 2;
}

.appointment-content .busi-img {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: -30px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.appointment-content .business-followers-list {
  margin: 10px 0 5px;
}

.appointment-content .busi-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.business-profile-block .with-share-icon {
  position: relative;
}

.business-profile-block .with-share-icon .profile-share {
  width: 35px;
  height: 35px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #efecec;
  color: var(--primary);
  font-size: 16px;
  position: absolute;
  bottom: -8px;
  right: -10px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.react-calendar__month-view__weekdays {
  background: #f7f6f2;
  border-radius: 15px;
  padding: 5px 0;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.editProfile .social-icons ul li i {
  color: var(--grey);
}

.ticket-img-wrapper2 {
  width: 180px;
  height: 110px;
  border-radius: 25px;
  background: #9b9787;
  color: #fff;
  font-size: 23px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 1px 1px rgb(0 0 0 / 16%);
  position: relative;
  cursor: pointer;
}

.font_work {
  font-family: 'DunbarTall' !important;
}
.shift_button.btn.btn-primary:hover {
  background-color: var(--green);
  color: #fff;
}

body {
  user-select: none;
}

.tags_title {
  font-size: 7px;
}
.tags_img {
  width: 15px;
  height: 15px;
}
.location-field.form-control.form-control-with-icon.create_session
  .css-1wa3eu0-placeholder {
  margin-top: 0px; /*-2px*/
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 99%;
}
.calendar-custom-relative-holder {
  margin-right: 10px;
}
.calendar-custom-relative-holder .clnd-date-time-txt {
  padding-top: 0;
  position: relative;
  top: 1px;
  padding-left: 0;
  padding-right: 3px;
  color: var(--grey);
}
.calendar-custom-relative-holder .date-time-pannel-head {
  background: var(--green);
}
.calendar-custom-relative-holder .relative button {
  box-shadow: none !important;
  /* background-color: #fff; */
  /* border-color: var(--borders); */
  color: #000;
  padding: 10px 15px;
  /* border-radius: 8px; */
  font-size: 14px;
  /* border: 1px solid var(--borders); */
  line-height: 1.5;
}
.calendar-custom-relative-holder .relative .clnd-date-time-txt {
  color: hsl(0, 0%, 50%);
}
.calendar-custom-relative-holder .relative .tail-calnd-dropdown {
  width: 100%;
  border: 1px solid #d3d3d3;
}
.create-session-calendar-fields-btn
  .calendar-custom-relative-holder
  .relative
  button {
  max-width: 100%;
}
.create-session-calendar-fields-btn .calendar-custom-relative-holder,
.create-ticket-wrapper .calendar-custom-relative-holder {
  margin-right: 0px;
}
.calendar-custom-modal-holder {
  background: #f7f6f2;
  box-shadow: 0px 1px 4.01px 0px #00000040;
  -webkit-box-shadow: 0px 1px 4.01px 0px #00000040;
  border-radius: 25px;
  padding: 15px 15px;
  position: relative;
  width: 50%;
}
.ticket-dates-wrapper .calendar-custom-modal-holder {
  width: 80%;
}
.panel-primary .calendar-custom-modal-holder {
  width: 85%;
}
.roi-bg-green {
  background: var(--green);
}
.round-custom-time {
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Allows the height to adjust based on the width */
  padding: 8px;
  line-height: 30px;
  font-size: 0.7rem;
  box-shadow: 0px 4px 10px rgba(211, 211, 211, 0.7);
  margin-bottom: 10px;
}
.panel-primary .calendar-custom-relative-holder .round-custom-time {
  width: 25px;
  height: 25px;
  line-height: 25px;
  padding: 4px;
}
.modal-custom-calendar-days {
  padding-left: 0px;
}
.modal-custom-calendar-days .round-custom-time {
  margin-left: auto;
  margin-right: auto;
}
.time-corner-date-time-calander .clnd-hour-txt {
  color: var(--black);
}
.calendar-custom-relative-holder .date-time-combn-section .roi-bg-green {
  background: transparent;
  color: var(--green);
}
.date-time-combn-section .time-btn {
  color: var(--grey);
}
.calendar-custom-relative-holder .date-custom-clear-btn {
  color: var(--grey);
}
.calendar-custom-relative-holder .date-custom-clear-btn:hover {
  color: var(--green);
}
.full-date-section .exact-date-holder {
  color: var(--grey);
}
.day-name-color {
  color: var(--grey);
}
/* Webkit-based browsers (Chrome, Safari, Opera) */
.time-corner-date-time-calander .h-24::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  background: transparent; /* Hide the default scrollbar background */
}

.time-corner-date-time-calander .h-24::-webkit-scrollbar-track {
  background: #272727; /* Gradient color for the scroll thumb */
  border-radius: 5px; /* Rounded corners for the scroll thumb */
  border: 2px solid transparent; /* Creates padding around scroll thumb */
}

.time-corner-date-time-calander .h-24::-webkit-scrollbar-thumb {
  background-color: #616161; /* Color of the scroll thumb */
  border-radius: 5px; /* Rounded corners of the scroll thumb */
}
@supports (-moz-appearance: none) {
  .time-corner-date-time-calander .h-24 {
    scrollbar-width: thin; /* Makes scrollbar thinner */
    scrollbar-color: #616161 #272727; /* Thumb color, Track color */
  }
}
.create-ticket-form.create-action-ticket-form .calendar-custom-relative-holder {
  margin-right: 0px;
}
.interested-send-feedback-holder .form-label {
  font-size: 15px;
  margin: 0 0 8px;
  line-height: 1.2em;
  color: var(--lightgray);
  font-weight: 600;
}
.interested-send-feedback-holder .refferal-new-date {
  font-size: 14px;
  margin: 0 0 15px;
  line-height: 1.2em;
  color: var(--lightgray);
  display: block;
  font-weight: 400;
}
.interested-send-feedback-holder .form-label.txt-talks,
.interested-send-feedback-holder .txt-talks {
  font-size: 16px;
  color: var(--dark);
  margin: 0 0 5px;
  cursor: pointer;
  line-height: 1.2em;
  display: block;
  font-weight: 400;
}
.interested-send-feedback-holder .event-tabs.event-new-send-tabs {
  margin-top: 0px !important;
}
.customer-name-fields-holder .customer_name {
  padding: 14px 15px;
}
.milesstones-field-block .d-flex.flex-column.mt-3:first-child {
  margin-top: 0px !important;
}
.deliverable-dropdowns {
  margin-left: 3px;
}
.page-content-wrapper .deliverable-dropdowns.dropdown .dropdown-toggle:hover,
.page-content-wrapper .deliverable-dropdowns.dropdown .dropdown-toggle:focus {
  /* border: none; */
  box-shadow: 0px 0.95637px 3.82547px 0px rgba(0, 0, 0, 0.25) !important;
}
.customer-name-fields-holder .dropdown-menu.show {
  margin-left: 3px;
}

#MainApp #ApplicationContainer #application.Embed.WACFrameWord:hover {
  border-color: transparent !important;
}
#MainApp #ApplicationContainer #application.Embed.WACFrameWord {
  border: 0px !important;
}
.heading.edit-profile-headings {
  padding-left: 0px;
}
.mobile_back_btn {
  display: none;
}

.roi-search-form .filter-icon-wrapper {
  align-items: center;
  background-color: initial;
  border-color: #0000;
  border-radius: 10px;
  color: #262626;
  display: flex;
  font-size: 18px;
  padding: 5px 5px 5px 15px;
}

.roi-search-form .filter-icon-wrapper .icon-filter-icon {
  cursor: pointer;
}
.capture-with-camera-smart-chat-wrapper {
  /* height: calc(100vh - 235px); */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.capture-with-camera-imgs-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* flex-grow: 1; */
  height: auto;
}
.capture-with-camera-smart-chat-wrapper .text-yellow {
  position: absolute;
  top: auto;
  bottom: auto;
  text-align: center;
  left: 0;
  right: 0;
}
.deals-detail-form {
  padding-bottom: 15px;
}
.invite-poc-persons-wrapper {
  padding-bottom: 20px;
}
.board-placeholder-field-container {
  display: flex;
  align-items: center;
  gap: 1%;
}
input.board-placeholder-field.form-control {
  width: 100%;
}
.board-placeholder-field-container .icon.active {
  font-size: 18px;
  cursor: pointer;
}
.board-placeholder-field-container .icon.active .icon-plus {
  color: var(--lightgray);
}
.search-tab-action-icons span {
  color: var(--green);
}
.panel-secondary
  .create-circle-panel.create_catalog_group
  .search-form.fix-width-forms {
  padding-right: 0px !important;
}
.all_builders-wrapp-listing {
  padding-bottom: 70px;
}
.serach-filter-wrapper.schedule_user_appointment {
  padding-bottom: 15px;
}
.refferal-list-wrapper.refferal-business-list-wrapper {
  padding-bottom: 15px;
  overflow-x: hidden;
}
.intrested-for-outer-wrapper {
  padding-bottom: 30px;
}
.createForm_wrapper {
  padding-top: 20px;
}
.message-form.message-smartchat-form .reply-wrapper {
  width: 95%;
}
.reffer_oppertunity_holder {
  padding-bottom: 70px;
}
.edit-appointment-wrapper {
  padding-bottom: 50px;
}
.session-notes-holder {
  padding-top: 20px;
}
.session-notes-img-list {
  display: flex;
  align-items: center;
}
.send-feedback-new-wrap {
  padding-bottom: 55px;
}
.profile_page_tranformation .fix-width-forms.primary_panel_content {
  padding-right: 0px !important;
}
.profile-notes-new-screen .person-search-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}
.event-card .event-location {
  display: flex;
}
.event-card .event-location span {
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.home_map_listing_holder {
  padding-left: 3px;
}
.analytcis_page_search_box {
  width: 100%;
}
.status_action_section .btn.btn-primary:hover {
  background: var(--green);
  color: #fff;
}
.status_action_section .btn.btn-primary:focus {
  color: #fff;
  border-color: var(--green);
}
.create-agreement-page-content .agreement-btns button:first-child {
  margin-left: 0px;
}
label.map-road-trip-container.form-label {
  display: flex;
  width: 47%;
  justify-content: space-between;
  margin-bottom: 0px;
}
label.map-road-trip-container.form-label span.text {
  padding-left: 0px;
}
label.map-road-trip-container.form-label .span-locator {
  margin-right: 3px;
}
.quick_destination_holder .quick_icon_txt {
  font-size: 14px;
}
.quick_destination_holder .mt-3 {
  display: flex;
  align-items: center;
}
.quick_destination_holder .mt-3:last-child {
  display: block;
}
.news-monitor-holder {
  padding-bottom: 20px;
}
.customer-name-fields-holder .praise-list-item.no-border.pt-0 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 50vh; */
  padding-bottom: 0px;
}
.customer-name-fields-holder .deliverable_item {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 425px;
}
div#application.Embed.WACFrameWord:hover {
  border-color: #fff !important;
}
.usehover .Embed.WACFrameWord:hover {
  border-color: #fff !important;
}
.mt-4.action-custom-scroll-wrapper {
  display: flex;
  flex-direction: column; /* Stack child elements */
  position: relative;
  width: 100%; /* Take full width of parent */
  height: 100%; /* Take full height of its parent */
  overflow: hidden; /* Ensure no unwanted overflow */
}
.refferal-list-wrapper.action-custom-scroll-holder {
  flex-grow: 1; /* Allow flexible height within available space */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  /* scrollbar-width: thin; */
  padding-bottom: 0;
  max-height: calc(100vh - 143px);
}

.actions_fixed_padding {
  height: 100%;
}

.actions_fixed_padding .refferal-list-wrapper.action-custom-scroll-holder {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0px;
  max-height: calc(100vh - 280px);
  /* Firefox scrollbar styles */
  scrollbar-width: thin; /* 'auto' or 'thin' */
  scrollbar-color: rgba(0, 0, 0, 0.2) #ccc; /* thumb color and track color */
}

.refferal-list-wrapper.action-custom-scroll-holder::-webkit-scrollbar {
  background: transparent;
  width: 6px;
}

.refferal-list-wrapper.action-custom-scroll-holder::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.refferal-list-wrapper.action-custom-scroll-holder::-webkit-scrollbar-track {
  background: #ccc;
}

/* .no-records-middle-screen-holder{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  right: 0px;
  text-align: center;
  transform: translateY(-50%);
  top: 50%;
  bottom: auto;
} */
.panel-secondary .refferal-list-wrapper.action-custom-scroll-holder {
  padding-bottom: 12.3rem;
}
.profile-refferal-list-holder {
  padding-bottom: 10px !important;
}
.create-template.serach-filter-wrapper .form-group {
  width: 100%;
}
.project_list_view .list_card_content h2 {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-group.project-content-txtarea textarea {
  min-height: 350px;
}
.iconModelProjContent .form-group.project-content-txtarea textarea{
  min-height: 245px;
}
.CreateSession-invite-radio-btns .form-check-input,
.CreateSession-invite-radio-btns .form-check-label {
  cursor: pointer;
}
.send-refferal-form-input-holder {
  padding-bottom: 80px;
}
.schedule-scrollbar-container {
  /* margin-bottom: 50px; */
}
.schedule_location .css-26l3qy-menu {
  max-height: 200px; /* Adjust the height to your needs */
  overflow-y: auto; /* Enable vertical scrolling */
  z-index: 999; /* Ensure it stays on top */
}

.designation-custom-icon {
  position: absolute;
  left: 50%;
  right: auto;
  width: auto;
  margin-left: 115px;
  margin-right: 0;
  margin-right: auto;
  pointer-events: none;
}
.all-product-item .all-product-item-inner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.map-custom-search-form.search-form > .form-control {
  background: transparent;
  border: none;
  /* padding-right: 0px; */
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 10px;
}
.map-custom-search-form > .form-control .css-yk16xz-control {
  border: none;
  border-radius: 6px;
  min-height: 45px;
}
.map-custom-search-form > .form-control .css-1pahdxg-control {
  border: none;
  border-radius: 6px;
  min-height: 45px;
}

.map-custom-search-form .btn.btn-primary,
.map-custom-search-form .btn.btn-primary:focus {
  border-radius: 6px;
  height: auto;
  padding: 10px;
  background: #fff;
  color: #000;
}

.search-form.map-custom-search-form {
  /* position: relative; */
  z-index: 9;
  /* top: -618px; */
  position: absolute;
  bottom: auto;
  margin: 0 18px;
  top: 30px;
  width: 91.5%;
}
.add_location.user_location {
  position: relative;
}
.menu_no_recorde {
  text-align: center;
  margin-top: 125px;
}

.search-pipeline-input {
  position: relative;
}
.search-pipeline-input i,
.search-pipeline-input-up i {
  font-family: 'Font Awesome 6 Free'; /* Specify Font Awesome font family */
  font-weight: 900; /* Use solid style */

  position: absolute; /* Positioning the icons absolutely */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for vertical centering */
  left: auto;
  right: 10px;
  color: var(--lightgray);
  cursor: pointer;
  font-weight: bold;
}
.search-pipeline-input-up::after {
  content: '\f077';
}

.pipeline-cross-icon i {
  color: var(--lightgray);
}

.call-center-btn-slide-wrapper {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 25px;
  max-width: 230px;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 0px;
}
.ScheduleScreen_Style.ScheduleScreenInvite_Style {
  padding-bottom: 40px;
}

.icon-alias-holder .alias_name{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.27;
}

.leatherboard-detail-screen-container .leaderboard_detail_screen{padding-bottom: 45px;}
.landingchat-compny-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 83px;
}
.upload-cross-symbol{
  position: relative;
  left: 8px;
  top: -8px;
}